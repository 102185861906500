import React from 'react';

import Arrow from '../../../../../../assets/svg/arrow';
import { HighlightedText } from '../../../../../highlightedText';
import RecursionList from '../recursionList';

import * as Styled from './styled';

const RecursionListItem = ({
  fieldValue,
  search,
  el,
  formik,
  onShow,
  currentMeasures,
  expandedKeys,
  setExpandedKeys,
}) => {
  const { nameField, index } = currentMeasures ?? {};

  const onAddElement = () => {
    if (currentMeasures) {
      formik?.setFieldValue(`${nameField}.${index}`, el);
    } else {
      formik?.setFieldValue(`${fieldValue}`, el);
    }
    onShow.off();
    setExpandedKeys([]);
  };

  const onClick = () => {
    const hasKey = !!expandedKeys?.find((key) => el.id === key);

    if (!hasKey) {
      const temp = [...expandedKeys, el.id];
      setExpandedKeys(temp);
    } else {
      const temp = expandedKeys.filter((key) => key !== el.id) || [];
      setExpandedKeys(temp);
    }
  };

  const isShow = expandedKeys?.some((key) => key === el.id);

  return (
    <Styled.Li>
      {el?.data?.length ? (
        <React.Fragment>
          {/*<Styled.Parent onClick={() => setIsShow(prevState => !prevState)}>*/}
          <Styled.Parent onClick={onClick}>
            <Styled.ParentArrow rotate={isShow ? 90 : 0}>
              <Arrow />
            </Styled.ParentArrow>
            <span>
              <HighlightedText text={el?.name || el?.parentName} highlight={search} />
            </span>
          </Styled.Parent>
          <RecursionList
            fieldValue={fieldValue}
            search={search}
            arr={el?.data}
            isRenderList={isShow}
            formik={formik}
            onShow={onShow}
            currentMeasures={currentMeasures}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
          />
        </React.Fragment>
      ) : (
        <Styled.Child onClick={onAddElement}>
          <HighlightedText text={el?.name || el?.parentName} highlight={search} />
        </Styled.Child>
      )}
    </Styled.Li>
  );
};

export default RecursionListItem;

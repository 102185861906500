import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../axios/axios';
import { convertSortDirections } from '../../../utils/convert';
import { logWarn } from '../../../utils/log';

export function usePostUser() {
  const [dataRoles, setDataRoles] = React.useState(undefined);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: {},
      search: '',
      tableParams: {
        page: 1,
        pageSize: 50,
        field: '',
        sort: '',
      },
    },
  });

  const getRoles = async () => {
    axios
      .get('/api/user/roles')
      .then((resp) => {
        if (resp.data?.data?.length) {
          setDataRoles(
            resp.data?.data?.map((x) => ({
              value: x.roleName,
              label: x.displayName,
            }))
          );
        }
      })
      .catch((e) => {
        logWarn({
          errorNumber: '1685084102343',
          errorMessage: `${e}`,
        });
      });
  };

  const onPostUser = async ({ page, pageSize, field, sort }) => {
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post(`/api/user/view${page ? `?page=${page}` : ''}`, {
        pagination: pageSize ?? '',
        field: sort && field ? field : '',
        sort: sort ?? '',
        text: 'something here',
      });

      if (data?.data?.data?.length) {
        formik?.setFieldValue('data', data?.data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostUserFind = async ({ value, sort, field }) => {
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post(`/api/user/view/find`, {
        value,
        field: sort && field ? field : '',
        sort: sort ?? '',
      });

      if (data?.data?.length) {
        formik?.setFieldValue('data', data);
        formik.setFieldValue('loading', false);
      } else {
        formik.setFieldValue('loading', false);
        formik?.setFieldValue('data', []);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685386993950',
        errorMessage: `${e}`,
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    const params = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      field: sorter.columnKey,
      sort: convertSortDirections(sorter.order),
    };
    formik.setFieldValue('tableParams', params);
    onPostUser(params);
  };

  useEffect(() => {
    onPostUser({ page: 1, pageSize: 50 });
  }, []);

  useEffect(() => {
    if (!dataRoles) {
      getRoles();
      setDataRoles([]);
    }
  }, [dataRoles]);

  const onRefetchUsers = async () => {
    const params = {
      page: formik.values.tableParams.page,
      pageSize: formik.values.tableParams.pageSize,
      field: formik.values.tableParams.field,
      sort: formik.values.tableParams.sort,
    };
    await onPostUser(params);
  };

  return {
    data: formik.values.data,
    tableParams: formik.values.tableParams,
    loading: formik.values.loading,
    onChangeTable,
    onRefetchUsers,
    dataRoles,
    onPostUser,
    onPostUserFind,
    postUserformik: formik
  };
}

export function usePostUserView({ formikUsers, isShowUserView }) {
  const [_, onShowUserView] = isShowUserView ?? [];

  const onPostUserView = async (id) => {
    try {
      const { data } = await axios.post('/api/user/view/edit', { id });

      if (data) {
        formikUsers?.resetForm();
        formikUsers.setValues(data?.data);
        onShowUserView.on();
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '16865982147463',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    onPostUserView: onPostUserView,
  };
}

export function usePostOrganizationData({ formikUsers, isShowOrganizationData }) {
  const [_, onShowOrganizationData] = isShowOrganizationData ?? [];

  const onPostOrganizationData = async (values) => {
    try {
      const { data } = await axios.post('/api/user/view/edit/enterprise', {
        id: values.id,
        fio: values.fio,
        email: values.email,
        phone: values.phone,
        roleName: values.roleName,
      });

      if (data) {
        formikUsers?.resetForm();
        formikUsers?.setValues(data?.data);
        onShowOrganizationData.on();
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '16865982147461',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    onPostOrganizationData,
  };
}

export function usePostViewEditSave({ isShowOrganizationData, onPostUser, onPostUserFind, formik }) {
  const [_, onShowOrganizationData] = isShowOrganizationData ?? [];

  const onPostViewEditSave = async (values) => {

    try {
      const { data } = await axios.post('/api/user/view/edit/save', values);

      if (data.status === 'success') {
        onShowOrganizationData.off();
        toast['success'](data?.msg);

        if (formik.values.search) {
          return onPostUserFind({
            value: formik.values.search,
            field: formik.values.tableParams.field,
            sort: formik.values.tableParams.sort
          });
        }

        return onPostUser({
          page: formik.values.tableParams.page,
          pageSize: formik.values.tableParams.pageSize,
          field: formik.values.tableParams.field,
          sort: formik.values.tableParams.sort,
        });
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '16865982147462',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    onPostViewEditSave,
  };
}

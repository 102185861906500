import React from 'react';
import { toast } from "react-toastify";

import axios from "../../../axios/axios";
import { logWarn } from "../../../utils/log";

export function useGetEnterprises() {
  const [dataRows, setDataRows] = React.useState([]);

  React.useEffect(() => {
    onGetEnterprises();
  }, [])

  const onGetEnterprises = async () => {

    try {
      const { data } = await axios.get('/api/admin/enterprise/view');
      // console.info('onPostUser11', data);
      if (data?.data?.data?.length) {
        setDataRows(data?.data?.data);
      }

    } catch (e) {
      setDataRows(e.data);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  const onPostEnterpriseFind = async (value) => {
    try {
      const { data } = await axios.post('/api/admin/enterprise/find', {value});

      if (data?.data?.data?.length) {
        setDataRows(data?.data?.data);
      }

    } catch (e) {
      setDataRows(e.data);
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685386993950",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    dataRows,
    onGetEnterprises,
    onPostEnterpriseFind
  }
}

import React from "react";
import {useDispatch} from "react-redux";

import Email from "../../../../assets/svg/email";
import Outlogout from "../../../../assets/svg/outlogout";
import Phone from "../../../../assets/svg/phone";
import Unlocked from "../../../../assets/svg/unlocked";
import * as authActions from "../../../../store/actions/authActions";
import Dialog from '../../../blocks/dialog';

import ChangeEmail from './changeEmail';
import ChangePassword from './changePassword';
import *  as Styled from './styled';

const styled = {
  top: 80,
  right: 100,
  position: 'absolute'
}

export default function SecuritySettings(props) {
  const {
    isRender,
    verifyEmail,
    userName,
    userEmail,
    showBlock,
    onShowBlock,
  } = props;

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(authActions.logout());
  };

  const handleSafety = () => {
    onShowBlock('email')
  }

  const handleClose = () => {
    onShowBlock(null)
  }

  return (
    <React.Fragment>
      <Dialog
        isRender={isRender}
        isClose={false}
        centered={false}
        width={230}
        onClose={handleClose}
        styled={styled}
        padding={'20px 24px'}
      >
      <Styled.Buttons
        direction={'column'}
      >
        <Styled.AtomButton onClick={handleSafety}>
          <Unlocked />
          <span>Безопасность</span>
        </Styled.AtomButton>
        <Styled.AtomButton onClick={onLogout}>
          <Outlogout />
          <span>Выход</span>
        </Styled.AtomButton>
      </Styled.Buttons>
      <Styled.H6>Поддержка</Styled.H6>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"left"}
        fWeight={400}
        lHeight={22}
      >
        <Styled.IconContacts>
          <Phone />
        </Styled.IconContacts>
         +7 (499) 164-98-64
      </Styled.Contacts>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"left"}
        fWeight={400}
        lHeight={22}
      >
        <Styled.IconContacts>
          <Email />
        </Styled.IconContacts>
         riskhelp@vcot.info
      </Styled.Contacts>
      </Dialog>
      <ChangeEmail
        isRender={showBlock === 'email'}
        verifyEmail={verifyEmail}
        userName={userName}
        userEmail={userEmail}
        onShowBlock={onShowBlock}
      />
      <ChangePassword
        isRender={showBlock === 'password'}
        onShowBlock={onShowBlock}
      />
    </React.Fragment>
  )
}


import * as actionTypes from "../actionTypes/measuruTypes";

const initialState = {
  list: [],
  measure: {
    id: "",
    parentId: null,
    code: "",
    name: "",
    clothes: "",
    standard: "",
    toolsPP: "",
    standardToolsPP: "",
    opened: "",
    closed: null,
  },
};

export function measureReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.MEASURU_DATA_SUCCESS:
      return { ...state, measure: action.payload };
    case actionTypes.MEASURU_DATA_CLEAR:
      return { ...state, measure: initialState.measure };

    default:
      return state;
  }
}

import { useQuery } from '@apollo/client';

import { ANALYTICS_LEVEL_RISK } from '../schemas/reports';

export const useChartLevelRisk = ({ id, type }) => {
  const { data, loading } = useQuery(ANALYTICS_LEVEL_RISK, {
    variables: {
      id,
      type,
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  return {
    data: data?.dash5,
    loading,
  };
};

import { toast } from "react-toastify";

import axios from '../../../../axios/axios';
import {logWarn} from '../../../../utils/log';


export function useUploadFiles({
  workplace,
  onPostRiskassessmentOne
}) {

const onUploadFiles = async (file) => {
  try {

    const formData = new FormData();

    formData.append("workplace", workplace);
    formData.append("file", file);

    const { data } = await axios.post(`/api/project/riskassessment/addfile`, formData);

    if (data?.data) {
      toast['success'](data?.msg);
      onPostRiskassessmentOne();
    }

  } catch (e) {
    console.log(e);

    toast['error'](e.data?.msg);

    logWarn({
      errorNumber: "1685084102343",
      errorMessage: `${e}`,
    });
  }
};


const onRemoveFiles = async (idFile) => {
  try {

    const { data } = await axios.post(`/api/project/riskassessment/deletefile`, {
      workplace,
      idFile
    });

    if (data?.data) {
      toast['success'](data?.msg);
      onPostRiskassessmentOne();
    }

  } catch (e) {
    console.log(e);

    toast['error'](e.data?.msg);

    logWarn({
      errorNumber: "1685084102343",
      errorMessage: `${e}`,
    });
  }
};


  return {
    onUploadFiles,
    onRemoveFiles
  }
}

import { useSelector } from 'react-redux';

import useCommonContext from '../../hooks/useCommonContext';

//Roles
// admin-role expert-role analytic-role employer-role

function Can({ roles, children }) {
  const auth = useSelector((state) => state.authReducer);
  const userRole = auth.user.roles[0].roleName;

  const common = useCommonContext();

  const allowed = roles?.some((role) => role === common.userRole);

  if (!userRole) {
    return null;
  }

  if (!allowed) {
    return null;
  }

  return children;
}

export default Can;

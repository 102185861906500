import React from 'react';

import ErrorClose from '../../../../assets/svg/errorClose';
import Warning from '../../../../assets/svg/warning';

import * as Styled from './styled';



export default function FieldSelect(props) {

  const {
    name,
    formik,
    label,
    type = 'text',
    placeholder,
    margin,
    disabled,
    isError = true,
    isWarning = false,
    required,
    autoComplete,
    onRequest,
    styled,
    options
  } = props;

  const [typeInput, setTypeInput] = React.useState(type)
  const {setFieldValue} = formik ?? {};
  const {value} = formik?.getFieldProps(name) ?? {};
  const {error, touched} = formik?.getFieldMeta(name) ?? {};
  const {setTouched} = formik?.getFieldHelpers(name) ?? {};

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  }

  const handleBlur = () => {
    setTouched(true)
  }

  return (
    <Styled.Field
      margin={margin}
    >
      {label ? <Styled.FieldLabel
        tagName={'label'}
        fSize={styled?.label?.fSize ?? 16}
        lHeight={styled?.label?.lHeight}
        fWeight={styled?.label?.fWeight ?? 500}
        padding={styled?.label?.padding}
      >{label}</Styled.FieldLabel> : ''}
      <Styled.InputTextWrap
        error={(!isWarning && isError) && error && touched}
      >
        <Styled.SelectText
          fSize={styled?.input?.fSize}
          lHeight={styled?.input?.lHeight}
          padding={styled?.input?.padding}
          type={typeInput ?? type}
          value={value}
          required={required}
          placeholder={placeholder}
          autoComplete={autoComplete}
          fWeight={400}
          onChange={onRequest || handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          options={options}
        />
      </Styled.InputTextWrap>
      {isError && error && touched ? <Styled.Valide>
        <ErrorClose />
        <Styled.ValideMessage
          tagName={'span'}
          fSize={10}
          lHeight={10}
          fWeight={400}
        >
          {error}
        </Styled.ValideMessage>
      </Styled.Valide> : ''}
      {isWarning && error && touched ? <Styled.Valide>
        <Warning />
        <Styled.ValideMessage
          tagName={'span'}
          fSize={10}
          lHeight={10}
          fWeight={400}
        >
          {error}
        </Styled.ValideMessage>
      </Styled.Valide> : ''}
    </Styled.Field>
  );
}

import React from "react";

import DateForm from "../../../core/DateForm";

function StringDataOrganization({
  organization,
  setVisible,
  setOrganizationId,
}) {
  return (
    <>
      <tr>
        <td>
          <DateForm className={"table-data"} date={organization.date} />
        </td>
        <td
          onClick={() => {
            setOrganizationId(organization.expertOrganizationId);
            setVisible(true);
          }}
          className="td-text-employers text-decoration-underline"
        >
          <span role="button">{organization.name}</span>
        </td>
      </tr>
    </>
  );
}

export default StringDataOrganization;

import styled from 'styled-components';

import Flex from '../../../../atoms/flex';
import Button from '../../../../atoms/nButton';

export const Buttons = styled(Flex)`
  justify-content: flex-end;
`;

export const ParentArrow = styled.div`
  transform: rotate(${(props) => props.rotate}deg);
  height: 24px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const SFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SFlexItem = styled.div`
  padding: ${(props) => props.padding ?? 0};
  flex-grow: ${(props) => props.grow ?? 0};
`;
export const PossibleTitle = styled.div`
  font-family: GolosTextWebVF, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 20px 0 15px;
`;

export const ButtonBottom = styled(Button)`
  font-size: 17px;
  padding: 7px 16px;
`;

export const PossibleTable = styled.div`
  font-family: GolosTextWebVF, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border: 1px solid #000;
  border-top: ${(props) => (props.first ? '1px solid #000' : 0)};
  margin-top: ${(props) => (props.first ? '15px' : 0)};
  padding: 5px;
  cursor: pointer;
`;

export const InputTextInput = styled.div`
  border: 1px solid rgb(24, 23, 28);
  border-radius: 10px;
  min-height: 35px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 24px;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  text-decoration: ${(props) => props?.decoration ?? 'none'};
`;

import React from 'react';

import axios from '../../../axios/axios';
import { useFormikContext } from '../../formik/formik.context';

import { StyledIsNeedApprove } from './isNeedApproveField.styled';

function isString(value) {
  return typeof value === 'string';
}

function isNumber(value) {
  return typeof value === 'number';
}

export default function IsNeedApprove(props) {
  const { name, index, disabled, auxiliaryProps } = props;
  const formikProps = useFormikContext();

  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps = () => {}, setFieldValue } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};
  const needApprove = getFieldProps(`data.${index}.is_need_approve`) ?? {};
  const id = getFieldProps(`data.${index}.id`) ?? {};

  const handleChange = (e) => {
    const checked = e.target.checked ?? false;

    axios
      .post(`/api/project-report/file-approve/${id?.value}`)
      .catch((error) => console.warn(error));

    if (isNumber(value)) {
      return setFieldValue(name, checked ? 1 : 0);
    }

    return setFieldValue(name, checked);
  };

  const parseValue = isString(value) ? JSON.parse(value) : value;

  if (!needApprove?.value) {
    return null;
  }

  return (
    <StyledIsNeedApprove disabled={disabled}>
      <input
        type="checkbox"
        className={'checkboxField'}
        checked={parseValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <span />
    </StyledIsNeedApprove>
  );
}

import React from 'react';

import { getFilter } from '../formikFields';
import Sceleton from '../skeleton';

import TCell from './cell';

export default function TFilter(props) {
  const { columns, numberOfcolumns = 0 } = props;

  if (!columns?.length) {
    return [...Array(numberOfcolumns)].map((_, i) => {
      return (
        <TCell key={i}>
          <Sceleton />
        </TCell>
      );
    });
  }

  return columns?.map((el, key) => {
    const TagName = getFilter(el.filter);

    if (!el.column) {
      return <TCell key={`${key}`} />;
    }

    return (
      <TCell {...el} key={`${key}-${el.column}`}>
        <TagName {...el} />
      </TCell>
    );
  });
}

import React, { useRef } from "react";
import ImageUploading from "react-images-uploading";
import { useSelector } from "react-redux";

function UserModal() {
  const [images, setImages] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const maxNumber = 1;
  const toogleBtn = () => {
    setOpen((prevState) => !prevState);
  };

  const inputName = useRef();
  const selectRole = useRef();
  const inputDateFrom = useRef();
  const inputDateTo = useRef();
  const inputPhone = useRef();
  const inputEmail = useRef();
  const inputPassword = useRef();

  const expertOrganizations = useSelector(
    (state) => state.expertOrganizationReducer.list
  );
  const user = useSelector((state) => state.authReducer.user);

  const onChange = (imageList) => {
    setImages(imageList);
  };

  return (
    <>
      <div
        className="modal fade"
        id="userProfileModal"
        tabIndex="-1"
        aria-labelledby="userProfileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-0">
            <div className="modal-header border-0">
              <h5 className="user-title" id="exampleModalLabel">
                ДАННЫЕ ПОЛЬЗОВАТЕЛЯ
              </h5>
              <button
                type="button"
                className="btn close-button rounded-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="bi bi-x ft-sz"></i>
                  <span>Закрыть</span>
                </div>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-body">
                <form>
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemove,
                      dragProps,
                    }) => (
                      <div
                        className="mb-3 add-photo-square d-flex flex-column justify-content-center align-items-center"
                        onClick={onImageUpload}
                        {...dragProps}
                        style={
                          imageList.length > 0
                            ? {
                                background:
                                  'url("' + imageList[0].data_url + '"',
                                backgroundSize: "contain",
                                position: "relative",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }
                            : { cursor: "pointer" }
                        }
                      >
                        {imageList.length > 0 ? (
                          <div
                            onClick={() => onImageRemove(0)}
                            style={{
                              position: "absolute",
                              right: "9px",
                              top: "5px",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </div>
                        ) : (
                          <i
                            className="bi bi-plus ft-sz-33"
                            style={{
                              textAlign: "center",
                              height: "75px",
                            }}
                          >
                            <p className="add-photo-text">Добавить фото</p>
                          </i>
                        )}
                      </div>
                    )}
                  </ImageUploading>

                  <div className="mb-3">
                    <label
                      htmlFor="user-name"
                      className="col-form-label grey-text-modal-user"
                    >
                      ФИО
                    </label>
                    <input
                      type="text"
                      required
                      ref={inputName}
                      className="form-control rounded-0"
                      id="user-name"
                      defaultValue={user.userName}
                      placeholder="Иванов Иван Иванович"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="user-role"
                      className="col-form-label grey-text-modal-user"
                    >
                      Роль
                    </label>
                    <select
                      required
                      ref={selectRole}
                      className="form-select rounded-0"
                      id="user-role"
                    >
                      <option value="1">Администратор</option>
                      <option value="2">Эксперт</option>
                      <option value="3">Аналитик ВНИИ</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-3 col-6 pr-10">
                      <label
                        htmlFor="user-date"
                        className="col-form-label grey-text-modal-user"
                      >
                        Срок действия с
                      </label>
                      <input
                        required
                        ref={inputDateFrom}
                        type="date"
                        className="form-control rounded-0"
                        id="user-date"
                      />
                    </div>
                    <div className="mb-3 col-6 pl-10">
                      <label
                        htmlFor="user-date"
                        className="col-form-label grey-text-modal-user"
                      >
                        Срок действия по
                      </label>
                      <input
                        required
                        ref={inputDateTo}
                        type="date"
                        className="form-control rounded-0"
                        id="user-date"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="user-role"
                      className="col-form-label grey-text-modal-user"
                    >
                      Организация
                    </label>
                    <select
                      className="form-select rounded-0"
                      id="user-role"
                      list="lst"
                    >
                      {expertOrganizations !== null
                        ? Object.keys(expertOrganizations)?.map((item, i) => (
                            <option key={i}>
                              {expertOrganizations[item].title}{" "}
                            </option>
                          ))
                        : "<option>Заглушка</option>"}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="user-name"
                      className="col-form-label grey-text-modal-user"
                    >
                      Телефон
                    </label>
                    <input
                      required
                      ref={inputPhone}
                      type="text"
                      className="form-control rounded-0"
                      id="user-name"
                      placeholder="+7(123)456-78-90"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-3">
                      <label
                        htmlFor="user-email"
                        className="col-form-label grey-text-modal-user"
                      >
                        Email
                      </label>
                      <input
                        required
                        ref={inputEmail}
                        type="email"
                        className="form-control rounded-0"
                        id="user-email"
                        placeholder="example@mail.ru"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="user-pass"
                        className="col-form-label position-relative grey-text-modal-user"
                      >
                        Пароль
                      </label>
                      <input
                        ref={inputPassword}
                        type={open ? "text" : "password"}
                        className="form-control rounded-0"
                        id="user-pass"
                      />
                      <div className="eyes">
                        {open ? (
                          <i className="bi bi-eye" onClick={toogleBtn}></i>
                        ) : (
                          <i
                            className="bi bi-eye-slash"
                            onClick={toogleBtn}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <span className="href-text pddng-lft-227">
                    сбросить пароль
                  </span>
                </form>
              </div>

              <div className="modal-footer border-0">
                <button type="button" className="btn save-button">
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserModal;

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedLayout({ availableRoles }) {
  const auth = useSelector((state) => state.authReducer);
  const allowed = availableRoles?.some((role) => auth.user.roles.some((user) => role === user.roleName));

  if (!allowed) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default ProtectedLayout;

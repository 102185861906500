import styled from 'styled-components';

export const Spinner = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
import { gql } from '@apollo/client';

export const DASHBOARD_INFO = gql`
  query DashboardInfo($id: String!) {
    dash1(id: $id) {
      cnt
    }
    dash2(id: $id) {
      cnt
    }
    dash3(id: $id) {
      okved2
    }
    dash9(id: $id) {
      cnt
    }
    dash10(id: $id) {
      cnt
    }
  }
`;

export const DASHBOARD_INFO_HAZARDS = gql`
  query DashboardInfo($id: String!, $type: Boolean!) {
    dash4(id: $id, type: $type) {
      cnt
    }
  }
`;

export const DASHBOARD_INFO_TRANING = gql`
  query DashboardInfoTraining($id: String!) {
    dash11(id: $id) {
      project_id
      items {
        id
        name
        cnt
      }
    }
  }
`;

export const ANALYTICS_LEVEL_RISK = gql`
  query ChartLevelRiskData($id: String!, $type: Boolean!) {
    dash5(id: $id, type: $type) {
      id
      name
      project_id
      cnt
    }
  }
`;

export const ANALYTICS_HIGHEST_LEVEL_RISK = gql`
  query ChartHighestLevelRiskData($id: String!, $type: Boolean!) {
    dash6(id: $id, type: $type) {
      id
      name
      val
    }
  }
`;

export const ANALYTICS_IDENTIFIED_HAZARDS = gql`
  query ChartIdentifiedHazards($id: String!, $nn: Int!) {
    dash7(id: $id, nn: $nn) {
      id
      cnt
      name
      nn
      val
    }
  }
`;

export const ANALYTICS_ADDITIONAL_MEASURES = gql`
  query ChartAdditionalMeasures($id: String!, $type: Boolean!) {
    dash8(id: $id, type: $type) {
      id
      cnt
      name
      nn
      type
    }
  }
`;

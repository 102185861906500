import React from 'react';
//import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

import axios from "../../../axios/axios";
import { logWarn } from "../../../utils/log";
import {Role} from "../../role";

import * as Styled from './styled';

//import HeaderAdmin from '../../blocks/headerAdmin';
//import HeaderGrade from '../../Menu/MenuRating/HeaderGrade';
//import Nav from '../../Nav/Nav';
let role = Role();
//console.log(role);
function queryEmail(){

 const data = async () => {
  try{
    //console.log('111')
    const { dd } = await axios.get('/api/user/secondemail');
      //console.log(dd)
      toast['success']("Письмо отправлено")
  } catch(e){
    logWarn({
      errorNumber: "1685084102343",
      errorMessage: `${e}`,
    });
  }
}
data()
 //console.log('Hello')
};

export default function DemoLayot() {

  //console.log(useSelector((state) => state.authReducer.user.roles.some((el) => el.roleName)));
  return (
    <>

    <main style={{"display": "flex", "align-items": "center", "vertical-align": "center", "height": "90vh"}}>

      <Styled.DivCenter>
        <h2 style={{"text-align": "center", width: "576px"}}>Проверьте свою  почту</h2>
        <p style={{"text-align": "center", width: "400px", "margin-left": "88px"}}>Для создания Вашей учетной записи остался всего один шаг. Мы хотим убедиться,
        что вы указали правильный адрес электронной почты, поэтому просим  его подтвердить.</p>
        <p style={{"text-align": "center", width: "400px", "margin-left": "88px"}}>Не удается найти электронное письмо?<br />
          Нажмите <span style={{"cursor": 'pointer', color: "blue" }} onClick={queryEmail}>здесь</span>, и мы отправим его повторно.
        </p>
      </Styled.DivCenter>


  </main>
</>

  );
}


//export default DemoLayot;

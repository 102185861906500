import React from 'react';

import * as hooks from '../../../../hooks';
import Button from '../../../atoms/nButton';
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';

import Measures from './fields/measures';
import { usePostAdditionalControls } from './hooks';
import { ManagementMeasures } from './managementMeasures';
import * as Styled from './styled';

const { useBoolean } = hooks;

export default function AdditionalControls(props) {
  const {
    showAdditionalControls,
    onShowAdditionalControls,
    onPostEvaluationcardAdditionaledit,
    onPostProjectHazard,
    rowId,
    commonHazards,
    formik
  } = props;

  const [showMeasures, onShowMeasures] = useBoolean(false);
  const [currentMeasures, setCurrentMeasures] = React.useState(null);

  const { onPostEvaluationcardAdditionalsave } = usePostAdditionalControls({
    hazard_id: rowId,
    onShowAdditionalControls,
    formik,
    onPostEvaluationcardAdditionaledit,
    onPostProjectHazard,
    commonHazards
  });

  const isDisabled = !formik.values.data?.some(el => el.name !== '') && !formik?.touched?.data;

  const handleClose = () => {
    formik.resetForm();
    onShowAdditionalControls.off();
  };

  return (
    <Dialog
      isRender={showAdditionalControls}
      isClose={true}
      centered={false}
      onClose={handleClose}
      title={'ДОПОЛНИТЕЛЬНЫЕ МЕРЫ УПРАВЛЕНИЯ'}
      width={'700px'}
      paddingWrapper={'0 36px 0 0'}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 40px',
        },
        close: {
          height: 28,
          width: 28,
          right: '8px',
          top: '8px',
        },
      }}
    >
      <div>
        <ManagementMeasures
          formik={formik}
          onCurrentMeasures={setCurrentMeasures}
          onShowMeasures={onShowMeasures}
        />
        <Styled.Buttons jContent={'flex-end'}>
          <Button
            disabled={isDisabled}
            variant={'secondary'}
            onClick={onPostEvaluationcardAdditionalsave}
          >
            Сохранить
          </Button>
        </Styled.Buttons>
      </div>
      <Portal id={'measures'}>
        <Measures 
          show={showMeasures}
          currentMeasures={currentMeasures}
          onShow={onShowMeasures}
          formik={formik}
        />
      </Portal>
    </Dialog>
  );
}

import styled from 'styled-components';

export const List = styled.div`
  column-count: 2;
  column-gap: 45px;
`;

export const ListOne = styled.div`
  column-count: 1;
  column-gap: 45px;
`;

export const GET_OKVEDS = 'GET_OKVEDS';
export const GET_TYPES_OF_WORKS = 'GET_TYPES_OF_WORKS';
export const SET_SELECTED_CLAFFISIERS = "SET_SELECTED_CLAFFISIERS";
export const GET_WORK_PLACES = 'GET_WORK_PLACES';
export const GET_NAS_CLASSIFIERS = "GET_NAS_CLASSIFIERS";
export const GET_OK01694 = "GET_OK01694";
export const GET_SOUT = "GET_SOUT";
export const GET_DANGEROUS_SITUATION = "GET_DANGEROUS_SITUATION";
export const GET_INSTRUMENTS_AND_EQUIPMENTS = "GET_INSTRUMENTS_AND_EQUIPMENTS";
export const GET_WORK_PLACES2 = 'GET_WORK_PLACES2';
export const GET_MATERIALS = 'GET_MATERIALS';
export const GET_BIO_OBJECTS = 'GET_BIO_OBJECTS';
export const GET_MACHINERY = 'GET_MACHINERY';
export const GET_DANGER = 'GET_DANGER';
export const GET_DANGER_EVENT = 'GET_DANGER_EVENT';
export const GET_RISK_MANAGEMENT = 'GET_RISK_MANAGEMENT';
export const GET_MEDICAL = 'GET_MEDICAL';
export const GET_MEDICAL_VPF = 'GET_MEDICAL_VPF';
export const GET_MEDICAL_VPF302 = 'GET_MEDICAL_VPF302';
export const GET_SIZ = 'GET_SIZ';
export const GET_SIZ_NEW = 'GET_SIZ_NEW';
export const GET_WORK_STAGES = "GET_WORK_STAGES";
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_EXPERT_ORGANIZATION = 'GET_EXPERT_ORGANIZATION';
export const GET_TYPE_COMPANY = 'GET_TYPE_COMPANY';
export const GET_STAGES_WORK = 'GET_STAGES_WORK';
export const GET_REQUIREMENT = 'GET_REQUIREMENT';
export const GET_MEDICAL_SOUT = 'GET_MEDICAL_SOUT';
export const GET_PERSONNEL_REQUIRE = 'GET_PERSONNEL_REQUIRE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_PROF_STANDART = 'GET_PROF_STANDART';
export const ADD_SOUTDATA = 'ADD_SOUTDATA';
export const ADD_SOUTMEDICALDATA = 'ADD_SOUTMEDICALDATA';


import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import axios from "../../../axios/axios";
import { AsyncSelect } from "../../common/Select";
import AppButton from "../../core/AppButton";

function ModalFIAS({
  visibleFIAS,
  setVisibleFIAS,
  typeAdress = false,
  create,
  organization,
}) {
  const reBuilding = /^[А-Яа-я0-9.,]+$/;
  const reNumb = /^[0-9]+$/;
  const [address, setAddress] = useState(
    organization && organization.addresses && organization.addresses.length > 0
      ? organization.addresses[
          organization.addresses[0].status === typeAdress ? 0 : 1
        ]
      : organization && organization.address
      ? organization.address
      : {}
  );

  function saveFIAS() {
    address.status = typeAdress;

    if (typeAdress === false) {
      create({
        ...organization,
        address: { ...organization.address, ...address },
      });
    } else {
      let addresses = [];

      if (organization.addresses.length < 2) {
        if (
          organization.addresses.length === 0 ||
          organization.addresses[0]?.status === address.status
        ) {
          addresses = [address];
        } else {
          addresses = [organization.addresses[0], address];
        }
      } else {
        for (let i = 0; i <= 1; i++) {
          if (organization.addresses[i].status === address.status) {
            addresses[i] = address;
          } else {
            addresses[i] = organization.addresses[i];
          }
        }
      }
      create({ ...organization, addresses });
    }
    setVisibleFIAS(false);
  }

  const handleFieldChange = (fieldName) => {
    return (event) => {
      if (fieldName === "postalCode") {
        const { value } = event.target;
        if (value === "" || (reNumb.test(value) && value.length <= 6)) {
          return setAddress((state) => ({
            ...state,
            postalCode: value,
          }));
        } else {
          alert(
            "Почтовый индекс должен состоять только из чисел, введите 6 знаков"
          );
          return;
        }
      }
      if (fieldName === "building") {
        const { value } = event.target;
        if (value === "" || (reBuilding.test(value) && value.length <= 20)) {
          return setAddress((state) => ({
            ...state,
            building: value,
          }));
        } else {
          alert(
            'Корпус должен состоять из чисел, кириллицы, ".", "," и не должен быть более 20 символов'
          );
          return;
        }
      }
      if (fieldName === "region") {
        return setAddress((state) => ({
          ...state,
          [fieldName]: event,
          regionId: event.value,
          regionName: event.label,
          districtName: "",
          districtId: null,
          cityName: "",
          cityId: null,
          localityName: "",
          localityId: null,
          streetName: "",
          streetId: null,
          building: "",
          house: "",
        }));
      }
      if (fieldName === "district") {
        return setAddress((state) => ({
          ...state,
          districtId: event.value,
          districtName: event.label,
          cityName: "",
          cityId: null,
          localityName: "",
          localityId: null,
          streetName: "",
          streetId: null,
          building: "",
          house: "",
        }));
      }
      if (fieldName === "city") {
        return setAddress((state) => ({
          ...state,
          cityId: event.value,
          cityName: event.label,
          localityName: "",
          localityId: null,
          streetName: "",
          streetId: null,
          building: "",
          house: "",
        }));
      }
      if (fieldName === "locality") {
        return setAddress((state) => ({
          ...state,
          localityId: event.value,
          localityName: event.label,
          streetName: "",
          streetId: null,
          building: "",
          house: "",
        }));
      }
      if (fieldName === "street") {
        return setAddress((state) => ({
          ...state,
          streetId: event.value,
          streetName: event.label,
          building: "",
          house: "",
        }));
      }
      if (typeof event === "object") {
        return setAddress((state) => ({
          ...state,
          [fieldName]: event.target.value,
        }));
      }
      if (typeof event === "string") {
        return setAddress((state) => ({
          ...state,
          [fieldName]: event,
        }));
      }
    };
  };

  const getLoadOptionsUrl = useCallback(
    (fieldName, value) => {
      if (!fieldName) {
        return null;
      }

      let requestUrl = `/api/dictionary/address?part_name=${value}`;

      if (fieldName === "region") {
        requestUrl = `/api/dictionary/address?part_name=${value}&object_level=1`;
      } else if (fieldName === "district") {
        if (!address.regionId) {
          return null;
        } else {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.regionId}&object_level=2`;
        }
      } else if (fieldName === "city") {
        if (address.districtId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.districtId}&object_level=5`;
        } else if (address.regionId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.regionId}&object_level=5`;
        } else {
          return null;
        }
      } else if (fieldName === "locality") {
        if (address.districtId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.districtId}&object_level=6`;
        } else if (address.regionId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.regionId}&object_level=6`;
        } else {
          return null;
        }
      } else if (fieldName === "street") {
        if (address.localityId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.localityId}&object_level=8`;
        } else if (address.cityId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.cityId}&object_level=8`;
        } else if (address.districtId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.districtId}&object_level=8`;
        } else if (address.regionId) {
          requestUrl = `/api/dictionary/address?part_name=${value}&parent_id=${address.regionId}&object_level=8`;
        } else {
          return null;
        }
      }
      return requestUrl;
    },
    [address]
  );

  const fetchOptions = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.data.status === "success") {
        const options = response.data.data?.map((item) => {
          return {
            value: item.objectguid,
            label: `${item.typename} ${item.name}`,
          };
        });
        return options;
      }
    } catch {
      return [];
    }
  };

  const loadOptions = (fieldName) => {
    return async (value) => {
      const url = getLoadOptionsUrl(fieldName, value);
      const options = await fetchOptions(url);
      return Promise.resolve(options);
    };
  };

  useEffect(() => {
    if (
      organization &&
      organization.addresses &&
      organization.addresses.length > 0
    ) {
      setAddress(
        organization.addresses[
          organization.addresses[0].status === typeAdress ? 0 : 1
        ]
      );
    }
  }, [organization, typeAdress]);

  return (
    <div
      className={
        visibleFIAS
          ? "modal-step-2 modal-fias active"
          : "modal-step-2 modal-fias"
      }
      onClick={() => setVisibleFIAS(false)}
    >
      <div
        className="modal-content container-sm container-small"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-info mt-50 mb-50">
          <button
            className="btn close-button rounded-0"
            style={{
              top: "0",
              right: "0",
              display: "flex",
              position: "absolute",
              alignItems: "center",
            }}
            onClick={() => setVisibleFIAS(false)}
          >
            <i className="bi bi-x ft-sz"></i>
            <span style={{ paddingTop: "3px" }}>Закрыть</span>
          </button>
          <div className="modal-form">
            <h3 className="title-modal-form">ВВОД АДРЕСА</h3>
            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Индекс</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    value={address?.postalCode || ""}
                    onChange={handleFieldChange("postalCode")}
                    defaultValue={address?.postalCode || ""}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Субъект* - обязательное поле</Form.Label>
                  <AsyncSelect
                    defaultInputValue={address?.regionName || ""}
                    nn={true}
                    loadOptions={loadOptions("region")}
                    onChange={handleFieldChange("region")}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Район</Form.Label>
                  <AsyncSelect
                    defaultInputValue={address?.districtName || ""}
                    loadOptions={loadOptions("district")}
                    onChange={handleFieldChange("district")}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Город</Form.Label>
                  <AsyncSelect
                    defaultInputValue={address?.cityName || ""}
                    loadOptions={loadOptions("city")}
                    onChange={handleFieldChange("city")}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Населенный пункт</Form.Label>
                  <AsyncSelect
                    defaultInputValue={address?.localityName || ""}
                    loadOptions={loadOptions("locality")}
                    onChange={handleFieldChange("locality")}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Улица</Form.Label>
                  <AsyncSelect
                    defaultInputValue={address?.streetName || ""}
                    loadOptions={loadOptions("street")}
                    onChange={handleFieldChange("street")}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <Form.Group className="mb-3">
                  <Form.Label>Дом</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    value={address?.house || ""}
                    onChange={handleFieldChange("house")}
                    defaultValue={address?.house || ""}
                  />
                </Form.Group>
              </div>
              <div className="col-4">
                <Form.Group className="mb-3">
                  <Form.Label>Строение/корпус</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    value={address?.building || ""}
                    onChange={handleFieldChange("building")}
                    defaultValue={address?.building || ""}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="d-flex justify-content-end">
                <Form.Group className="mb-3">
                  <AppButton onClick={saveFIAS} className="btn save-button">
                    Сохранить
                  </AppButton>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalFIAS;

import React from 'react';

import Button from '../../../../../atoms/nButton';
import Dialog from '../../../../../blocks/dialog';
import Portal from '../../../../../blocks/portal';
import FieldInput from '../../../../fields/input';

import {useSoutResults} from './hooks';
import * as Styled from './styled';

export default function WorkplaceName(props) {
  const {isRender, onShow, formik, workplace, onPostRiskassessmentOne} = props;

  const {onPostRiskassessmentNumber} = useSoutResults({
    workplace,
    onShow,
    onPostRiskassessmentOne
  })

  const closeShowAddDivision = () => {
    onShow.off();
  }

  const handleAddDivision = () => {
    const {value} = formik.getFieldProps('data.analogPM') ?? {};
    onPostRiskassessmentNumber(value);
  }

  return (
    <Portal id={'workplaceName'}>
      <Dialog
        isRender={isRender}
        isClose={true}
        centered={true}
        onClose={closeShowAddDivision}
        title={'Количество аналогичных мест'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
      <form>
        <FieldInput
          margin={'0 0 30px'}
          formik={formik}
          name={'data.analogPM'}
        />
        <Styled.Buttons jContent={'center'}>
          <Button
            variant={'secondary'}
            onClick={handleAddDivision}
          >
            Добавить
          </Button>
        </Styled.Buttons>
      </form>
    </Dialog>
  </Portal>
  )}


import React, { memo } from 'react';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

const RemoveButtonWithConfirm = ({ itemId, onClick }) => {
  const [modal, contextHolder] = Modal.useModal();

  const onHandleClick = () => {
    onClick(itemId);
  };

  const confirm = () => {
    modal.confirm({
      title: 'Вы действительно хотите удалить данную запись?',
      icon: <WarningOutlined style={{ color: '#D73C1D' }} />,
      okText: 'Подтвердить',
      cancelText: 'Отменить',
      onOk: onHandleClick,
      confirmLoading: true,
    });
  };

  return (
    <>
      <Button danger shape="circle" type="text" icon={<DeleteOutlined />} onClick={confirm} />
      {contextHolder}
    </>
  );
};

export default memo(RemoveButtonWithConfirm);

export const dictionaryTypes = {
  typesOfWorks: '77581fda-e9ed-4d00-bb49-6831ebd11111',
  workPlaces: '77581fda-e9ed-4d00-bb49-6831ebd12222',
  workPlaces2: '77581fda-e9ed-4d00-bb49-6831ebd36666',
  nas: '77581fda-e9ed-4d00-bb49-6831ebd13333',
  instrumentsAndEquipments: '77581fda-e9ed-4d00-bb49-6831ebd14444',
  materials: '77581fda-e9ed-4d00-bb49-6831ebd15555',
  bioObjects: '77581fda-e9ed-4d00-bb49-6831ebd16666',
  machinery: '77581fda-e9ed-4d00-bb49-6831ebd17777',
  danger: '77581fda-e9ed-4d00-bb49-6831ebd18888',
  dangerEvent: '77581fda-e9ed-4d00-bb49-6831ebd19999',
  riskManagement: '77581fda-e9ed-4d00-bb49-6831ebd20000',
  medicalVPF: '77581fda-e9ed-4d00-bb49-6831ebd26666',
  medical: '77581fda-e9ed-4d00-bb49-6831ebd27777',
  siz: '77581fda-e9ed-4d00-bb49-6831ebd29999',
  ok01694: '77581fda-e9ed-4d00-bb49-6831ebd28888',
  typeCompany: '77581fda-e9ed-4d00-bb49-6831ebd22222',
  stagesWork: '77581fda-e9ed-4d00-bb49-6831ebd23333',
  sout: '77581fda-e9ed-4d00-bb49-6831ebd21111',
  requirement: '77581fda-e9ed-4d00-bb49-6831ebd24444',
  medicalSout: '77581fda-e9ed-4d00-bb49-6831ebd25555',
  personnelRequire: '77581fda-e9ed-4d00-bb49-6831ebd30000',
  okved: '77581fda-e9ed-4d00-bb49-6831ebd34444'
}
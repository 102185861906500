import React from 'react';

import TRow from './row';
import { StyledTable, StyledTableWrapper, StyledThead } from './table.styled';
import TBody from './tbody';
import TFilter from './tfilter';
import THeader from './theader';

export default function Table(props) {
  const { columns, numberOfcolumns, ...owenprops } = props;

  const isFilter = columns?.every(({ filter }) => Boolean(filter));

  return (
    <StyledTableWrapper>
      <StyledTable>
        <StyledThead>
          <THeader columns={columns} numberOfcolumns={numberOfcolumns} />
        </StyledThead>
        {isFilter ? (
          <TRow>
            <TFilter columns={columns} numberOfcolumns={numberOfcolumns} />
          </TRow>
        ) : (
          ''
        )}
        <TBody
          {...owenprops}
          columns={columns}
          numberOfcolumns={numberOfcolumns}
        />
      </StyledTable>
    </StyledTableWrapper>
  );
}

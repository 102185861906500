import React from "react";

import axios from "../../../../axios/axios";
import { logWarn } from "../../../../utils/log";

export function usePostFiasPostal() {
  const [dataPostFiasPostal, setDataFiasPostal] = React.useState([]);

  const onPostFiasPostal = async (text) => {

    if (text?.length < 3) {
      return null
    }

    try {
      const { data } = await axios.post('/api/fias/postal', {
        postalCode: text,
        regionName: '',
        cityName: '',
        streetName: ''
      });

      if (data?.data) {
        setDataFiasPostal(data?.data);
      }

    } catch (e) {
      setDataFiasPostal(e.data);

      logWarn({
        errorNumber: "1685587487927",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    dataPostFiasPostal,
    onPostFiasPostal
  }
}

export function usePostFiasRegion() {
  const [dataPostFiasRegion, setDataFiasRegion] = React.useState([]);

  const onPostFiasRegion = async (text) => {
    if (text?.length < 3) {
      return null
    }

    try {
      const { data } = await axios.post('/api/fias/region', {
        postalCode: '',
        regionName: text,
        cityName: '',
        streetName: ''
      });

      if (data?.data) {
        setDataFiasRegion(data.data);
      }

    } catch (e) {
      setDataFiasRegion(e.data);

      logWarn({
        errorNumber: "1685587487927",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    dataPostFiasRegion,
    onPostFiasRegion
  }
}

export function usePostFiasCity() {
  const [dataPostFiasCity, setDataFiasCity] = React.useState([]);
  const onPostFiasCity = async (text) => {

    if (text?.length < 3) {
      return null
    }

    try {
      const { data } = await axios.post('/api/fias/city', {
        postalCode: '',
        regionName: '',
        cityName: text,
        streetName: ''
      });

      if (data?.data) {
        setDataFiasCity(data?.data);
      }

    } catch (e) {
      setDataFiasCity(e.data);

      logWarn({
        errorNumber: "1685587487927",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    dataPostFiasCity,
    onPostFiasCity
  }
}

export function usePostFiasStreet() {
  const [dataPostFiasStreet, setDataFiasStreet] = React.useState([]);

  const onPostFiasStreet = async (text) => {
    if (text?.length < 3) {
      return null
    }

    try {
      const { data } = await axios.post('/api/fias/street', {
        postalCode: '',
        regionName: '',
        cityName: '',
        streetName: text
      });

      if (data?.data) {
        setDataFiasStreet(data.data);
      }

    } catch (e) {
      setDataFiasStreet(e.data);

      logWarn({
        errorNumber: "1685587487927",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    dataPostFiasStreet,
    onPostFiasStreet
  }
}

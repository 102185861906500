import React from "react";

import axios from "../../../../axios/axios";
import { logWarn } from "../../../../utils/log";

export function usePostRecoverAccount(formik) {

  const [respData, setRespData] = React.useState(null);

  const onPostRegistr = async () => {
    const {values} = formik;

    try {
      const { data } = await axios.post('/api/user/recovery/password', values);

      setRespData(data);
    
    } catch (e) {
      setRespData(e.data);

      logWarn({
        errorNumber: "676644435454",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    respData,
    onPostRegistr
  }
}

export function usePostRecoverLogin(formik) {

  const [respData, setRespData] = React.useState(null);

  const onPostRecoverLogin = async () => {
    const {values} = formik;

    try {
      const { data } = await axios.post('/api/user/recovery/login', {email: values.email});

      setRespData(data);

    } catch (e) {
      setRespData(e.data);

      logWarn({
        errorNumber: "676644435454",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    respRecoverLogin: respData,
    onPostRecoverLogin
  }
}
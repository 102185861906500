import React from "react";
import { Outlet } from "react-router-dom";

import HeaderAdmin from "../../blocks/headerAdmin";

import { navMap } from "./navMap";

export default function classif() {

  return (
    <React.Fragment>
      <HeaderAdmin navMap={navMap} />
      <main>
        <Outlet />
      </main>
    </React.Fragment>
  )
}

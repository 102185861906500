import React from 'react';

import { useTabs } from './hooks';
import * as Styled from './styled';

export default function Tabs(props) {
  const { tabs, flex } = props;
  const { currentIndex, currentItem, changeItem } = useTabs(0, tabs);

  const hanleNextTab = (activeIndex) => (e) => {
    e.preventDefault();

    const findCurrentTab = tabs.find((_, index) => index === activeIndex);

    if (!findCurrentTab.content) {
      return null;
    }

    return changeItem(activeIndex);
  };

  return (
    <React.Fragment>
      <Styled.List flex={flex} >
        {tabs?.map((tab, index) => (
          <Styled.ListItem key={tab.label}>
            <Styled.Button
              key={tab.label}
              onClick={hanleNextTab(index)}
              current={currentIndex === index}
              disabled={tab.disabled}
            >
              {tab.label}
            </Styled.Button>
          </Styled.ListItem>
        ))}
      </Styled.List>
      {currentItem.content}
    </React.Fragment>
  );
}

import React, { useState } from 'react';
import { toast } from 'react-toastify';

import ErrorClose from '../../../../assets/svg/errorClose';
import Successconfirmed from '../../../../assets/svg/successconfirmed';
import Warning from '../../../../assets/svg/warning';
import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

import * as Styled from './styled';

export default function Status(props) {
  const {
    loading,
    verifyEmail,
    userEmail,
    userName,
    recoveryEmail,
    isEditEmail,
    onRecoveryEmail,
    onResetFormik,
    onCancel,
    isError,
  } = props;
  const [respData, setRespData] = React.useState(null);
  const [fetch, setFetch] = useState(false);

  const handleSendMailAgain = async () => {
    try {
      setFetch(true);
      const { data } = await axios.post('/api/user/recovery/repeatemail', { userName: userName, email: userEmail });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        setRespData(data);
        setFetch(false);
      }
    } catch (e) {
      setRespData(e.data);
      setFetch(false);
      logWarn({
        errorNumber: '676644435454',
        errorMessage: `${e}`,
      });
    }
  };
  const handleRecoveryEmail = () => {
    onRecoveryEmail();
  };

  if (!verifyEmail && !isError) {
    return (
      <React.Fragment>
        <Styled.Status>
          <Warning />
          <Styled.StatusMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            Не подтвержден
          </Styled.StatusMessage>
        </Styled.Status>
        <Styled.SendMailAgain disabled={fetch} onClick={handleSendMailAgain}>
          Отправить письмо еще раз
        </Styled.SendMailAgain>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {verifyEmail && !isError && recoveryEmail?.status !== 'error' ? (
        <Styled.Status>
          <Successconfirmed />
          <Styled.StatusMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            Подтвержден
          </Styled.StatusMessage>
        </Styled.Status>
      ) : null}

      {isError || recoveryEmail?.status === 'error' ? (
        <Styled.Status>
          <ErrorClose />
          <Styled.StatusMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            {isError && !recoveryEmail?.msg ? isError : null}
            {!isError && recoveryEmail?.msg ? recoveryEmail?.msg : null}
            {isError && recoveryEmail?.msg ? recoveryEmail?.msg : null}
          </Styled.StatusMessage>
        </Styled.Status>
      ) : null}

      {/*{isError || recoveryEmail?.status === 'error' ? (*/}
      {/*  <Styled.Status>*/}
      {/*    <ErrorClose />*/}
      {/*    <Styled.StatusMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>*/}
      {/*      {recoveryEmail?.msg*/}
      {/*        ? 'Пользователь  с таким адресом электронной почты уже существует'*/}
      {/*        : 'Введите существующий адрес электронной почты'}*/}
      {/*    </Styled.StatusMessage>*/}
      {/*  </Styled.Status>*/}
      {/*) : (*/}
      {/*  ''*/}
      {/*)}*/}

      {isEditEmail ? (
        <Styled.EditEmailButtons direction={'row'}>
          <Styled.CustomButtonSecond
            isError={isError}
            loading={loading}
            disabled={isError}
            onClick={handleRecoveryEmail}
          >
            Сохранить
          </Styled.CustomButtonSecond>
          <Styled.CustomButtonSecond disabled={loading} type="primary" onClick={onCancel}>
            Отмена
          </Styled.CustomButtonSecond>
        </Styled.EditEmailButtons>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

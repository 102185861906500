import styled from 'styled-components';

import List from '../../../../../atoms/list';

export const Measures = styled(List)`
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 20px 20px 20px 5px;
`

export const MeasureItem = styled.div`
  display: inline-flex;
`

export const MeasureCount = styled.div`
  white-space: nowrap;
  color: rgb(30, 133, 235);
  text-decoration: underline;
  cursor: pointer;
`

export const MeasureName = styled.div`
  color: #52575C;
  text-align: left;
  white-space: nowrap;
`

export const MeasureDividing = styled.div`
  padding: 0 5px;
`

export const navMap = [
  {
    id: '1',
    path: '/organizations/employers',
    label: 'Работодатели',
  },
  {
    id: '2',
    path: '/organizations/organizations',
    label: 'Экспертные организации',
  },
  // {
  //   id: '3',
  //   path: '/organizations/classifiers',
  //   label: 'Классификаторы',
  // },
  {
    id: '4',
    path: '/organizations/matrix',
    label: 'Матрицы',
  }
];
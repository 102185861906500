import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const options = [
  {
    value: 1,
    label: 'В учебном центре',
  },
  {
    value: 2,
    label: 'У работодателя',
  },
];

const CellSelect = ({ record, onSave }) => {
  const [state, setState] = useState(record.training_place.value);

  useEffect(() => {
    setState(record.training_place.value);
  }, [record.training_place.value]);

  const handleChange = (v) => {
    setState(v);
    onSave({
      training_type: record.training_type,
      training_place: v,
      id: record.id,
    });
  };

  return <Select style={{ width: 180 }} options={options} value={state} onChange={handleChange} />;
};

export default CellSelect;

import React from 'react';

import axios from '../../../axios/axios';
import { useFormikContext } from '../../formik/formik.context';

import { StyledAutoComplete } from './autoCompleteFilter.styled';

export default function AutoCompleteFilter(props) {
  const { column } = props;
  const formikProps = useFormikContext();
  const [options, setOptions] = React.useState([]);
  const { values, setFieldValue } = formikProps ?? {};

  const onSearch = (val) => {
    if (val.length <= 2) {
      return null;
    }

    return axios
      .post(`/api/constructor/${column}?value=${val}`)
      .then(({ data }) => {
        const options = data?.map((el) => ({
          ...el,
          value: el[column],
        }));

        setOptions(options);
      })
      .catch((error) => console.warn(error));
  };

  const onSelect = (_, option) => {
    setFieldValue('filters', {
      ...values?.filters,
      ...{ [column]: option.id },
    });
  };

  return (
    <StyledAutoComplete
      options={options}
      onSelect={(val, option) => onSelect(val, option)}
      onSearch={onSearch}
    />
  );
}

import styled from 'styled-components';


export const Header = styled.header`
  width: 100wh;
  padding: 31px 100px 18px;
  box-sizing: border-box;
`;

export const Link = styled.span`
  display: block;
  font-family: GolosTextWebVF, serif;
  padding: 25px 15px 10px;
  border-bottom: 4px solid  ${props => props.isActive ? '#000' : '#EDEDEF'};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  &:hover {color: #18171C};
`

import {treeSearchStructure} from "../../Components/core/appFunction";
import * as actionTypes from '../actionTypes/employerTypes';
import {AUTH_LOGOUT} from "../actionTypes/loginTypes";

const initialState = {
  list: [],
  employer: {
	"fullName": "",
	"shortName": "",
	"inn": "",
	"kpp": "",
	"okved": '',
	"okogu": "",
	"ogrn": "",
    "oktmo": "",
	"okpo": "",
	"chiefFullName": "",
	"chiefJobTitle": "",
    "total_employees": "",
    "okato": "",
	"addresses": [
	  {
		"expertOrganization": null,
		"employer": null,
		"status": "ra",
		"postalCode": "",
		"regionId": null,
		"regionName": "",
		"autonomousId": null,
		"autonomousName": null,
		"districtId": null,
		"districtName": "",
		"cityId": null,
		"cityName": "",
		"innerCityId": null,
		"innerCityName": null,
		"localityId": null,
		"localityName": "",
		"streetId": null,
		"streetName": "",
		"additionalElementId": null,
		"additionalElementName": null,
		"house": "",
		"estate": null,
		"building": null,
		"structure": null,
		// "office": "",
		// "comment": "",
	  },
	  {
		"expertOrganization": null,
		"employer": null,
		"status": "la",
		"postalCode": "",
		"regionId": null,
		"regionName": "",
		"autonomousId": null,
		"autonomousName": null,
		"districtId": null,
		"districtName": "",
		"cityId": null,
		"cityName": "",
		"innerCityId": null,
		"innerCityName": null,
		"localityId": null,
		"localityName": "",
		"streetId": null,
		"streetName": "",
		"additionalElementId": null,
		"additionalElementName": null,
		"house": "",
		"estate": null,
		"building": null,
		"structure": null,
		// "office": "",
		// "comment": "",
	  }
	],
	"employees": [],
	"structure": [],
	"structureNew": [],
	"job_title": [],
	"assessment": "",
	"phone": "",
	"email": "",
  },
	"selectedStructure": null,
	"openedStructures": []
}

export function employersReducer(state = initialState, action) {
  switch (action.type) {
	case actionTypes.EMPLOYERS_DATA_SUCCESS:
	  return {...state, list: action.payload}
	case actionTypes.EMPLOYER_DATA_SUCCESS:
	  return {...state, employer: action.payload}
	case actionTypes.EMPLOYER_DATA_CLEAR:
	  return {...state, employer: initialState.employer, structureNew: []}
	case actionTypes.EMPLOYER_STRUCTURE_LOAD:
	  return {...state, structureNew: action.payload}
	case actionTypes.EMPLOYER_STRUCTURE_NEW:
	  return {...state, structureNew: action.payload,
			selectedStructure: !state.selectedStructure ? null : (treeSearchStructure(action.payload, state.selectedStructure.value.id) || null)}
	case actionTypes.EMPLOYER_SELECTED_STRUCTURE_SET:
		return {...state, selectedStructure: action.payload}
	case actionTypes.EMPLOYER_OPENED_STRUCTURES_ADD:
		return {...state, openedStructures: [...state.openedStructures, action.payload]}
	case actionTypes.EMPLOYER_OPENED_STRUCTURES_DELETE:
		return {...state, openedStructures: state.openedStructures.filter(id => id !== action.payload)}
	case actionTypes.EMPLOYER_SELECTED_STRUCTURE_CLEAR:
		return {...state, selectedStructure: null, openedStructures: []}
	case AUTH_LOGOUT:
	  return initialState
	default:
	  return state
  }
}

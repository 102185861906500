import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from "antd";

//import { Modal } from "antd";
import Delete from '../../../../assets/svg/delete.jsx';
import Edit from '../../../../assets/svg/edit.jsx';
import TableData from "../components/table/index.jsx";

//import { Button } from "antd";
import ModalHac from "./modalhac/classhac.jsx";
import * as Styled from './styled.js';

const { confirm } = Modal;
//import Nas from './nas.jsx';

class Hac extends Component
{

  constructor() {

    super();

    this.state = {
      placeSource: [],
      sprSource: [],
      //sprVid: [],
      sprPlace: [],
      title: [

        {
          title: 'Опасное событие',
          dataIndex: 'event',
          key: 'event',
        },
        {
          title: 'СОУТ',
          dataIndex: 'sout',
          key: 'sout',
        },
        {
          title: 'Действие',
          dataIndex: 'key',
          render:  (key) => <Styled.IconButtons>
          <Styled.IconButton
              variant={'link'}
              onClick={() => this.handleChange(key)}
          >

          <Edit />

          </Styled.IconButton>
          <Styled.IconButton
              variant={'link'}
              onClick={() => this.handleDelete(key)}
          >
          <Delete />
          </Styled.IconButton>
          </Styled.IconButtons>,
        },
      ],
      isModalOpen: false,
      id: "",
      data: [],


    }
    let title;
    }

    reloadTabl(){
      console.log('reload')
      fetch('/api/classifier/bundle/work', {
        method: 'POST'
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.setState((state, props) => ({
            workType: data.data
          }))
          //console.log(this.state.workType)
        })
    }

    async handleDelete(key){
      confirm({
        title: "Подтвердите удаление связи",
        icon: <ExclamationCircleOutlined />,
        okText: "Подтвердить",
        cancelText: "Отмена",
        onOk() {
          //console.log(key);
          fetch('/api/classifier/bundle/eventsout/delete', {
            method: 'POST',
            body: JSON.stringify({id: key})
          }).then((response) => {
            return response.json();
          }).then((data) => {
            //console.log(data);
            if(data.code === 200){
              toast['success'](data.msg)
              this.reloadTabl();
              this.setState({isModalOpen: false})
            }
          })
        }

      })
    }


    async handleChange(key){
      //console.log('111 ' + key)
      if(key === undefined){
        this.titleMod = 'Добавить связь'
        this.setState({isModalOpen: true})
      }else{
        this.titleMod = 'Редактировать связь'
        await fetch('/api/classifier/bundle/eventsout/id',
          {
            method: 'POST',
            body: JSON.stringify({id: key})
          }
        ).then((response) => {
          return response.json();
        }).then((data) => this.setState({titleMod: 'Редактирование связи', data: data.data, id: key, isModalOpen: true}))

      }


      //console.log('test ' + key)

      //await this.setState({id: key, isModalOpen: true})
      //ModalHac()
      //console.log(this.state.id);
    };

    modalCancel(){
      this.setState({data: [], id: ""})
      this.setState({isModalOpen: false})
      //console.log(props)
    }

    async componentDidMount(){
      await fetch('/api/classifier/bundle/eventsout', {
        method: 'POST'
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.setState((state, props) => ({
            placeSource: data.data
          }))
          console.log(this.state.placeSource)
        })
      await fetch('/api/classifier/bundle/spreventsout',
        {
          method: 'POST'
        }
      ).then((response) => {
        return response.json();
        }
      ).then((data) => {
          this.setState(() => ({sprType: data.data}))
        }
      )
    }



    render() {
      //console.log(this.state.placeSource)
      return (
        <>

      <Button
        style={{width: '150px'}}
        onClick={() => this.handleChange()}

      >
        Добавить
      </Button>
      <br></br>

        <TableData
          dataSource={this.state.placeSource}
          columns={this.state.title}

        />
        {this.state.isModalOpen &&
        <ModalHac
          isModalOpen = {this.state.isModalOpen}
          modalCancel = {() => this.modalCancel()}
          id  = {this.state.id}
          sprType = {this.state.sprType}
          data  = {this.state.data}
          title = {this.titleMod}
        />}

        </>

      )
    }

}


export default Hac;



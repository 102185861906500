import React from 'react';
import { useSelector } from 'react-redux';

import useKeyEvents from '../../../hooks/useEventKey';
import SecuritySettings from '../../formik/forms/securitySettings';
import HeaderNav from '../headerNav';

import * as Styled from './styled';

function HeaderAdmin({ navMap }) {
  const user = useSelector((state) => state.authReducer.user);
  const [showBlock, setShowBlock] = React.useState(null);

  useKeyEvents('Escape', () => {
    setShowBlock('close');
  });
  const handleSettings = () => {
    setShowBlock('securitySettings');
  };

  return (
    <Styled.Wrapper>
      <div className="menus">
        <HeaderNav navMap={navMap} />
        <Styled.Account>
          <Styled.NameUser>{user.userName}</Styled.NameUser>
          <Styled.SettingsButton onClick={handleSettings}>
            <Styled.SvgAnimate />
          </Styled.SettingsButton>
          <div className="accord-account d-none"></div>
          <SecuritySettings
            isRender={showBlock === 'securitySettings'}
            verifyEmail={user.verifyEmail}
            userName={user.userName}
            userEmail={user.email}
            showBlock={showBlock}
            onShowBlock={setShowBlock}
          />
        </Styled.Account>
      </div>
    </Styled.Wrapper>
  );
}

export default HeaderAdmin;

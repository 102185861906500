import {createContext, useMemo} from "react";
import {useSelector} from "react-redux";

const initContext = {
  user: null,
  userRole: null,
}

const CommonPropsContext = createContext(initContext);

export const CommonPropsProvider = ({children}) => {
  const auth = useSelector((state) => state.authReducer)


  const valueMemoized = useMemo(() => ({
    user: auth.user,
    userRole: auth.user.roles[0].roleName,
  }), [auth.user])

  return <CommonPropsContext.Provider value={valueMemoized}>{children}</CommonPropsContext.Provider>
}
export default CommonPropsContext;

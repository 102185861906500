import styled from 'styled-components';

import Flex from '../../atoms/flex';

export const DivCenter = styled(Flex)`
display: flex;
width: 576px;
height: 385px;
align: left;
margin: auto;

align-items: center;
text-align: center;
border: 1px black solid;
padding: auto;
border-radius: 15px;


`

import React from 'react';
import _ from 'lodash';


function HighlightedTextItem(props) {
  const {part, highlight, el} = props;

  const isMark = part?.toLowerCase() === highlight?.toLowerCase();

  if (isMark) {
    return (
      <mark style={{ padding: 0, background: 'transparent', color: '#1E85EB' }}>{part}</mark>
    )
  }

  return part

}

export const HighlightedText = ({ text, highlight}) => {
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text?.split(regex);

  console.log('HighlightedText text', text)
  console.log('HighlightedText highlight', highlight)


  if (!highlight?.length) {
    return text;
  }

  return (
    <>
      {parts?.map((part, index) => (
        <HighlightedTextItem
          key={index}
          part={part}
          highlight={highlight}
        />
      ))}
    </>
  );
};
import React from 'react';

import * as Styled from './svg.styled';

export default function AddCircle({
  width = "12",
  height = "11",
  viewBox = "0 0 12 11"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6665 0.928897C6.6665 0.508109 6.36803 0.166992 5.99984 0.166992C5.63165 0.166992 5.33317 0.508109 5.33317 0.928897V4.83366H1.42841C1.00762 4.83366 0.666504 5.13214 0.666504 5.50033C0.666504 5.86852 1.00762 6.16699 1.42841 6.16699H5.33317V10.0718C5.33317 10.4925 5.63165 10.8337 5.99984 10.8337C6.36803 10.8337 6.6665 10.4925 6.6665 10.0718V6.16699H10.5713C10.9921 6.16699 11.3332 5.86852 11.3332 5.50033C11.3332 5.13214 10.9921 4.83366 10.5713 4.83366H6.6665V0.928897Z" fill="white"/>
    </Styled.Svg>
  )
}
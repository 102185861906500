import React from "react";

import { StyledTRow } from "./table.styled";

export default function TRow(props) {
    const { children } = props;

    if (!children) {
        return null;
    }

    return <StyledTRow>{children}</StyledTRow>;
}

import * as Antd from 'antd';
import styled, { css } from 'styled-components';

import Flex from '../../../atoms/flex';
import Text from '../../../atoms/text';

export const FieldLabel = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  padding: ${(props) => props.padding ?? '0 0 12px'};
  display: inline-block;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}}
`;

export const Valide = styled(Flex)`
  padding-top: 8px;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ValideMessage = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  color: #aaa;
  padding-left: 9px;
  display: inline-block;
`;

export const AutoComplete = styled(Antd.AutoComplete)`
  & .ant-select-selector {
    font-family: 'GolosTextWebVF', serif;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #18171c !important;
    //padding: 10px 20px !important;
    height: auto !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  & .ant-select-selection-search input {
    font-family: 'GolosTextWebVF', serif;
    height: 100% !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }
`;

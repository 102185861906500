import React from 'react';

import * as Styled from './svg.styled';

export default function Printer({
  fill = "#52575C",
  width = "16",
  height = "16",
  viewBox = "0 0 16 16"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M4 5.99967V1.33301H12V5.99967"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.9987 12H2.66536C2.31174 12 1.9726 11.8595 1.72256 11.6095C1.47251 11.3594 1.33203 11.0203 1.33203 10.6667V7.33333C1.33203 6.97971 1.47251 6.64057 1.72256 6.39052C1.9726 6.14048 2.31174 6 2.66536 6H13.332C13.6857 6 14.0248 6.14048 14.2748 6.39052C14.5249 6.64057 14.6654 6.97971 14.6654 7.33333V10.6667C14.6654 11.0203 14.5249 11.3594 14.2748 11.6095C14.0248 11.8595 13.6857 12 13.332 12H11.9987"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.33301H4V14.6663H12V9.33301Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Svg>
  )
}
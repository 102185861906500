import { AUTH_LOGOUT } from "../actionTypes/loginTypes";
import * as actionTypes from "../actionTypes/rolesTypes";

const initialState = {
    list: []
};

export function roleReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ROLES_DATA_SUCCESS:
            return { ...state, list: action.payload };

        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

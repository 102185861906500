import React from 'react';

import { useFormikContext } from '../../formik/formik.context';

import { StyledcCeckboxCircle } from './checkbox.styled';

function isString(value) {
  return typeof value === 'string';
}

function isNumber(value) {
  return typeof value === 'number';
}

export default function Checkbox(props) {
  const { name, disabled, auxiliaryProps } = props;
  const formikProps = useFormikContext();

  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps = () => {}, setFieldValue } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};

  const handleChange = (e) => {
    const checked = e.target.checked ?? false;

    if (isNumber(value)) {
      return setFieldValue(name, checked ? 1 : 0);
    }

    return setFieldValue(name, checked);
  };

  const parseValue = isString(value) ? JSON.parse(value) : value;

  return (
    <StyledcCeckboxCircle disabled={disabled}>
      <input
        type="checkbox"
        className={'checkboxField'}
        checked={parseValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <span />
    </StyledcCeckboxCircle>
  );
}

import React, {useCallback, useState} from "react";
import {Typeahead} from "react-bootstrap-typeahead";
import {useSelector} from "react-redux";

import axios from "../../../../axios/axios";
import {useBoolean} from "../../../../hooks";
import {logWarn} from "../../../../utils/log";
import Spinner from "../../../spinner";

import { getHazardColumns, getOrgColumns
} from "./tableWrapper/constants";
import TableWrapper from "./tableWrapper";

import './styles.scss';


function ManagementResults() {
  const [selected, setSelected] = useState(null);
  const [loading, onLoading] = useBoolean(false);
  const [data, setData] = useState(null);
  const employers = useSelector((state) => state.employersReducer.list);


  const getCompanyData = useCallback(async (companyId) => {
    onLoading.on();
    const url = `/api/results-management/${companyId}`

     await axios.get(url)
      .then((response) => {
        setData(response?.data)
        onLoading.off()
      })
      .catch((error) => {
        onLoading.off()
        logWarn({
          errorMessage: `${error}`,
        })
      })
  }, [onLoading])

  // eslint-disable-next-line no-console
  console.log(selected, 'selected');

  const handleChange = (selected) => {
    if (selected?.[0]?.id) {
      setSelected(selected?.[0]);
      getCompanyData(selected?.[0]?.id)
    }
  }

  const handleInputChange = (value) => {
    if (!value && data) {
      setData(null);
    }
  }

  return (
    <div className="management-results">
        <div className="management-results__head">
          {selected ? <span className="management-results__title">{selected?.shortName}</span> : null}

            <Typeahead
              id="basic-typeahead-single"
              className="base-input base-input-search"
              placeholder="Поиск по названию предприятия"
              filterBy={['shortName']}
              labelKey="shortName"
              options={employers}
              onChange={handleChange}
              onInputChange={handleInputChange}
            />
        </div>

        <TableWrapper className="mb-3" title="Статистика по организации" columns={getOrgColumns(data?.[0]?.matrix)} data={data} />
        <TableWrapper className="mb-3" title="Статистика по опасностям" columns={getHazardColumns(data?.[0]?.matrix)} data={data} />
    <Spinner loading={loading} />
    </div>
  )
}

export default ManagementResults;

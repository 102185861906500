import styled from 'styled-components';

import Flex from '../../../../../atoms/flex';
import Button from '../../../../../atoms/nButton';

export const TitleEditFlex = styled(Flex)`
  margin: 48px 0 17px;
`
export const Text = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-align: center;
  display: block;
  outline: none;
  border: 0;
  color: #101331;
`

export const IconButtonEdit = styled(Button)`
  margin: 0 20px 0 0;
`

export const IconButtonDelete = styled(Button)`
  margin: 0 0 0 20px;
`

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { employerAddOpenedStructures, employerDeleteOpenedStructures,employerSetSelectedStructure } from "../../../../../../store/actions/employersActions";

const StructureTreeItem = ({ structureId, structure, children }) => {
  const selectedStructureId = useSelector(state => state.employersReducer.selectedStructure?.value?.id);
  const isOpen = useSelector(state => state.employersReducer.openedStructures?.includes(structureId));
  const dispatch = useDispatch();

  const onClickLabel = () => selectedStructureId !== structureId && dispatch(employerSetSelectedStructure(structure));

  const onClickTriangle = () => {
    if (!isOpen) {
      dispatch(employerAddOpenedStructures(structureId))
    } else {
      dispatch(employerDeleteOpenedStructures(structureId));
    }
  }
  
  return (
    <>
      <div className="tree-item">
        {children.length > 0 ? (
            <div onClick={onClickTriangle} className={isOpen ? 'triangle-down' : 'triangle-right'}></div>
          ) : (
            <div style={{width: '20px'}}></div>
          )
        }
        <div 
          onClick={onClickLabel} 
          className={selectedStructureId === structureId ? 'tree-item-label-active break-word' : 'tree-item-label break-word'}
        >
          {structure.value.name}
        </div>
      </div>
      <div className="tree-children">{isOpen && children}</div>
    </>
  )
}

export default StructureTreeItem;
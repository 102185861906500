import React from 'react';

import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';
import FieldAutoComplete from '../../fields/autoComplete';
import FieldInput from '../../fields/input';

import {
  usePostFiasCity,
  usePostFiasPostal,
  usePostFiasRegion,
  usePostFiasStreet
} from './hooks';
import * as Styled from './styled';

const styled = {
  label: {
    fSize: 16,
    lHeight: 16,
    fWeight: 400,
    padding: '0 0 5px'
  },
  input: {
    fSize: 14,
    lHeight: 14,
    fWeight: 400,
    padding: '16px 11px'
  }
}

export default function LegalAddress(props) {
  const {formik, onClose} = props;
  const {dataPostFiasCity, onPostFiasCity} = usePostFiasCity();
  const {dataPostFiasRegion, onPostFiasRegion} = usePostFiasRegion();
  const {dataPostFiasPostal, onPostFiasPostal} = usePostFiasPostal();
  const {dataPostFiasStreet, onPostFiasStreet} = usePostFiasStreet();

  const {values} = formik ?? {};

  const isDisabled  = values.project.postalCode && values.project.cityName
   && values.project.streetName && values.project.house;

  const optionsFiasCity = dataPostFiasCity?.length ?
    dataPostFiasCity?.map(el => ({
      value: el.cityName,
      id: el.cityName
    })) : [];

  const onChangeFiasCity = (data) => {
    const {setFieldValue} = formik;

    setFieldValue('project.cityName', data);
  };

  const onSelectFiasCity = (_, option) => {
    const {setFieldValue} = formik;
  
    setFieldValue('project.cityName', option.value);
  };

  const optionsFiasRegion = dataPostFiasRegion?.length ?
    dataPostFiasRegion?.map(el => ({
      value: el.regionName,
      id: el.regionName
    })) : [];

  const onChangeFiasRegion = (data) => {
    const {setFieldValue} = formik;

    setFieldValue('project.regionName', data);
  };

  const onSelectFiasRegion = (_, option) => {
    const {setFieldValue} = formik;

    setFieldValue('project.regionName', option.value);
  };

  const optionsFiasStreet = dataPostFiasStreet?.length ?
    dataPostFiasStreet?.map(el => ({
      value: el.streetName,
      postalCode: el.postalCode,
      id: el.streetName
    })) : [];

  const onChangeFiasStreet = (data) => {
    const {setFieldValue} = formik;

    setFieldValue('project.streetName', data);
  };

  const onSelectFiasStreet = (_, option) => {
    const {values, setValues} = formik;

    setValues({
      ...values,
      project: {
        ...values.project,
        streetName: option.value,
        postalCode: option.postalCode,
      }
    });
  };

  const optionsFiasPostal = dataPostFiasPostal?.length ?
    dataPostFiasPostal?.map(el => ({
      value: el.postalCode,
      regionName: el.regionName,
      cityName: el.cityName,
      id: el.postalCode
    })) : [];

  const onChangeFiasPostal = (data) => {
    const {setFieldValue} = formik;

    setFieldValue('project.postalCode', data);
  };

  const onSelectFiasPostal = (_, option) => {
    const {values, setValues} = formik;

    setValues({
      ...values,
      project: {
        ...values.project,
        postalCode: option.value,
        cityName: option.cityName,
        regionName: option.regionName,
      }
    });
  };

  return (
    <React.Fragment>
      <Styled.PostalCode>
        <FieldAutoComplete
          styled={styled}
          margin={'0 0 20px'}
          label={'Индекс'}
          formik={formik}
          name={'project.postalCode'}
          options={optionsFiasPostal}
          onChange={onChangeFiasPostal}
          onSearch={onPostFiasPostal}
          onSelect={onSelectFiasPostal}
        />
      </Styled.PostalCode>
      <FieldAutoComplete
        styled={styled}
        margin={'0 0 20px'}
        label={'Субъект'}
        formik={formik}
        name={'project.regionName'}
        options={optionsFiasRegion}
        onChange={onChangeFiasRegion}
        onSearch={onPostFiasRegion}
        onSelect={onSelectFiasRegion}
      />
      <FieldAutoComplete
        styled={styled}
        margin={'0 0 20px'}
        label={'Город'}
        formik={formik}
        name={'project.cityName'}
        options={optionsFiasCity}
        onChange={onChangeFiasCity}
        onSearch={onPostFiasCity}
        onSelect={onSelectFiasCity}
      />
      <FieldAutoComplete
        styled={styled}
        margin={'0 0 20px'}
        label={'Улица'}
        formik={formik}
        name={'project.streetName'}
        options={optionsFiasStreet}
        onChange={onChangeFiasStreet}
        onSearch={onPostFiasStreet}
        onSelect={onSelectFiasStreet}
      />
      <Flex
        jContent={'space-between'}
        widthItem={[192, 192]}
      >
        <FieldInput
          margin={'0 0 30px'}
          label={'Дом'}
          formik={formik}
          name={'project.house'}
        />
        <FieldInput
          margin={'0 0 30px'}
          label={'Строение/корпус'}
          formik={formik}
          name={'project.building'}
        />
      </Flex>
      <Styled.Buttons jContent={'center'}>
        <Button
          variant={'secondary'}
          onClick={onClose}
          disabled={!isDisabled}
        >
          Сохранить
        </Button>
      </Styled.Buttons>
    </React.Fragment>
  );
}
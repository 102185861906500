//import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
//import useCommonContext from '../../hooks/useCommonContext';

export function Role()  {
  const cookies = new Cookies();
  const userRole = cookies.get('Role');
  console.log(cookies.get('Role'));

  return userRole;

}







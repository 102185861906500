export const getTableRows = (
  data
) => {

  if (!data?.length) {
    return
  }

  return data?.map((item, index) => {

    return {
      key: index,
      cells: [
        {
          key: 'userName',
          label: item.userName,
          type: 'view',
          align: 'center',
        },
        {
          key: 'email',
          label: item.email,
          type: 'view',
          align: 'center',
        },
        {
          key: 'data',
          label: item.data,
          type: 'view',
          align: 'center',
        },

        {
          key: 'phone',
          label: item.phone,
          type: 'view',
          align: 'center',
        },
        {
          key: 'fio',
          label: item.fio,
          type: 'view',
          align: 'center',
        },
        {
          key: 'enterprise',
          label: item.enterprise,
          type: 'view',
          align: 'center',
        }
      ],
    };
  });
};

export const tableHead = [
  {
    key: 'data',
    label: 'Дата/Время',
    type: 'header',
    align: 'center',
  },
  {
    key: 'email',
    label: 'E-mail',
    type: 'header',
    align: 'center',
  },
  {
    key: 'userName',
    label: 'Логин',
    type: 'header',
    align: 'center',
  },

  {
    key: 'phone',
    label: 'Контактный телефон',
    type: 'header',
    align: 'center',
  },
  {
    key: 'fio',
    label: 'ФИО',
    type: 'header',
    align: 'center',
  },
  {
    key: 'enterprise',
    label: 'Организация',
    type: 'header',
    align: 'center',
  }
];

import styled, {css} from 'styled-components';

export const Svg = styled.svg`
  ${props =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate});
    `
  }}

  ${props =>
    props.transition &&
    css`
      transition: ${props.transition};
    `
  }}
`
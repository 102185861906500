import styled from 'styled-components';

import Link from '../../../atoms/link';


export const WorkplaceLink = styled(Link)`
  color: #1E85EB;
  height: auto;
  display: block;
  font-size: 14px;
  line-heigth: 20px;
  font-weight: 400;
  text-decoration: none;
`;

export const WorkplaceType = styled.span`
  color: #52575C;
  display: block;
  font-size: 10px;
  line-heigth: 20px;
  font-weight: 400;

`
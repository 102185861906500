import styled from 'styled-components';

import LogoBottom from '../../../assets/svg/logoBottom';

export const SVGLogoBottom = styled(LogoBottom)`
  margin: 0px 53px 65px;
`;

export const SVGLogoTop = styled.div`
  margin: 55px 43px 65px;
  width: 100%;
  height: 54px;
`;

export const MainNavigation = styled.nav`
  position: fixed;
  display: flex;
  height: 100%;
  width: 290px;
  background: rgb(24, 23, 28);
  min-height: 100vh;
  overflow: hidden;
  z-index: 100;
`;

export const Version = styled.div`
  margin-top: 15px;
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(255, 255, 255, 0.38);
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

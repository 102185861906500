import styled from 'styled-components';

export const Tree = styled.div`
  .ant-tree-title {
    font-family: 'GolosTextWebVF', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
  }

  .ant-tree-switcher {
    line-height: 42px;
  }

  .ant-tree-draggable-icon {
    line-height: 40px !important;
  }

`

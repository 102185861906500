import React from 'react'

import Button from '../../../atoms/nButton';

export default function EditRowsAction(props) {
  const {id, children, onPostProjectEdit} = props;

  const onClickEditRow = () => {

    if (!id) {
      return
    }

    return onPostProjectEdit(id);
  }

  return (
    <Button
      variant={'link'}
      onClick={onClickEditRow}
    >
      {children}
    </Button>
  )
};

import React from 'react';

import './IconButton.css';

const IconButton = ({ icon, children, ...otherProps }) => {
  return (
      <button type="button" className="btn btn-link icon-btn" {...otherProps}>
        {children ? children : <img src={icon} alt="" />}
      </button>
  )
};

IconButton.propTypes = {
};

export default IconButton;

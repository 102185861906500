import { useQuery } from '@apollo/client';

import { DASHBOARD_INFO, DASHBOARD_INFO_HAZARDS, DASHBOARD_INFO_TRANING } from '../schemas/reports';

export const useDashboardInfo = ({ id }) => {
  const { data, previousData, loading, error } = useQuery(DASHBOARD_INFO, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    dashInfo: data || previousData,
    dashLoading: loading,
    dashError: error,
  };
};

export const useDashboardInfoHazards = ({ id, type }) => {
  const { data, previousData, loading, error } = useQuery(DASHBOARD_INFO_HAZARDS, {
    variables: {
      id,
      type,
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    dashInfoHazards: data || previousData,
    dashLoadingHazards: loading,
    dashErrorHazards: error,
  };
};

export const useDashboardTraining = ({ id }) => {
  const { data, previousData, loading, error } = useQuery(DASHBOARD_INFO_TRANING, {
    variables: {
      id
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    dashInfoTraining: data || previousData,
    dashLoadingTraining: loading,
    dashErrorTraining: error,
  };
};

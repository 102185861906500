import React from 'react';

import { StyledTCell, StyledTCellSeparator } from './table.styled';

function Separator(props) {
  const { label } = props.separator ?? {};

  if (!label?.length) {
    return null;
  }

  return <StyledTCellSeparator {...props}>{label}</StyledTCellSeparator>;
}

export default function TCell(props) {
  const { children, auxiliaryProps, cell } = props;
  const { column, separator } = auxiliaryProps ?? {};

  return (
    <StyledTCell column={column} cell={cell}>
      <Separator separator={separator} />
      {children}
    </StyledTCell>
  );
}

import React from 'react';

import StyledSpinner from './spinner.styled';

export default function Spinner(props) {
  const { loading, type = 'fullScreen' } = props;

  if (!loading) {
    return null;
  }

  return (
    <StyledSpinner type={type}>
      <div className={'spinner'}>Загрузка...</div>
    </StyledSpinner>
  );
}

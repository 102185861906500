import React from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { Popover, Table } from 'antd';

import CellCheckbox from './cellCheckbox';
import CellNumber from './cellNumber';
import CellSelect from './cellSelect';
import * as Styled from './styled';

const getContent = (description) => {
  return description?.map((item) => {
    if (item.type === 'p') {
      return <Styled.DescriptionText>{item.text}</Styled.DescriptionText>;
    }
    if (item.type === 'l') {
      return <Styled.DescriptionLi>{item.text}</Styled.DescriptionLi>;
    }
  });
};

const TrainingTable = ({ loading, data, onSave }) => {
  const columns = [
    {
      key: 'training_type',
      title: 'Вид обучения',
      align: 'center',
      width: '30%',
      render: (record) => record.training_type,
    },
    {
      key: 'periodicity',
      title: 'Продолжительность/частота',
      align: 'center',
      width: '30%',
      render: (record) => {
        if (typeof record?.internship_amt === 'number') {
          return <CellNumber record={record} onSave={onSave} />;
        }

        return record.periodicity;
      },
    },
    {
      key: 'place',
      title: 'Место прохождения обучения',
      align: 'center',
      render: (record) => {
        if (record?.training_place?.type === 'select') {
          return <CellSelect record={record} onSave={onSave} />;
        }
        return record?.training_place?.text;
      },
    },
    {
      key: 'notation',
      title: 'Отметка о необходимости',
      align: 'center',
      render: (record) => <CellCheckbox record={record} onSave={onSave} />,
    },
    {
      key: 'action',
      title: '',
      render: (record) => {
        return (
          <Popover
            overlayClassName="training-table-description"
            trigger="hover"
            placement="left"
            content={getContent(record.description)}
          >
            <Styled.BookIconWrap>
              <ReadOutlined style={{ color: '#FFFFFF', fontSize: 22 }} />
            </Styled.BookIconWrap>
          </Popover>
        );
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.training_type}
      loading={loading}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default TrainingTable;

import axios from '../../axios/axios';
import { logWarn } from '../../utils/log';
import { AUTH, AUTH_CHANGE_EMAIL, AUTH_FAIL, AUTH_LOGOUT, UPDATE_USER_ROLE } from '../actionTypes/loginTypes';
//TODO:  csrf
//! csrf()

export const csrf = () => {
  let url = '/sanctum/csrf-cookie';
  axios.get(url).catch((error) =>
    logWarn({
      errorNumber: '1665743455331',
      errorMessage: `${error}`,
    })
  );
};

export const csrfWithLogin = (loginObject, errorCb) => {
  const url = '/sanctum/csrf-cookie';
  return async (dispatch) => {
    await axios.get(url).catch(() => errorCb?.());
    return dispatch(login(loginObject, errorCb));
  };
};

/*
export const csrfWithLogin = 0;
export const csrf = 0;
*/
//!
export const login = (loginObject, errorCb) => {
  const url = '/api/login';
  return async (dispatch) => {
    await axios
      .post(url, loginObject)
      .then((data) => {
        dispatch(getAuthSuccess(data.data));
      })
      .catch((e) => {
        dispatch(getAuthFail(e));
        errorCb?.();
      });

    // try {
    //   const { data } = await axios.post(url, loginObject);
    //
    //   dispatch(getAuthSuccess(data));
    // } catch (e) {
    //   logWarn({
    //     errorNumber: '1665743530043',
    //     errorMessage: `${e}`,
    //   });
    //   dispatch(getAuthFail(e));
    //   return Promise.reject(e);
    // }
  };
};

export const logout = () => {
  const url = '/api/logout';
  return async (dispatch) => {
    try {
      const { data } = await axios.post(url);
      dispatch(getLogout(data));
    } catch (e) {
      logWarn({
        errorNumber: '1665743543894',
        errorMessage: `${e}`,
      });
      dispatch(getLogout(e));
    }
  };
};

const getAuthFail = (data) => {
  return {
    type: AUTH_FAIL,
    payload: data,
  };
};

const getLogout = (data) => {
  return {
    type: AUTH_LOGOUT,
    payload: data,
  };
};

export const roleUpdate = (role) => {
  return {
    type: UPDATE_USER_ROLE,
    payload: role,
  };
};

const getAuthSuccess = (data) => {
  return {
    type: AUTH,
    payload: data,
  };
};

export const changeEmailAction = (data) => {
  return {
    type: AUTH_CHANGE_EMAIL,
    payload: data,
  };
};

import * as yup from 'yup';

export const userYup = yup.object().shape({
  email: yup.string().email(),
});
//! Проверка ИНН и даты
export const companyYup = yup.object().shape({
  inn: yup
    .string()
    .matches(/^[0-9]+$/, 'Только цифры')
    .min(10, 'Не меньше 10 цифр')
    .max(12, 'Не больше 12 цифр')
    .required(''),
  contract_number: yup.string().required('Введите номер договора'),
  expiration_date: yup.string().required('Выберите дату'),
});

import axios from "../../axios/nsiAxios";
import { logWarn } from "../../utils/log";
import {
  ADD_SOUTDATA,
  ADD_SOUTMEDICALDATA,
  GET_BIO_OBJECTS,
  GET_DANGER,
  GET_DANGER_EVENT,
  GET_DANGEROUS_SITUATION,
  GET_INSTRUMENTS_AND_EQUIPMENTS,
  GET_MACHINERY,
  GET_MATERIALS,
  GET_MEDICAL,
  GET_MEDICAL_SOUT,
  GET_MEDICAL_VPF,
  GET_MEDICAL_VPF302,
  GET_NAS_CLASSIFIERS,
  GET_OK01694,
  GET_OKVEDS,
  GET_PERSONNEL_REQUIRE,
  GET_PROF_STANDART,
  GET_REQUIREMENT,
  GET_RISK_MANAGEMENT,
  GET_SIZ,
  GET_SIZ_NEW,
  GET_SOUT,
  GET_TYPE_COMPANY,
  GET_TYPES_OF_WORKS,
  GET_WORK_PLACES,
  GET_WORK_PLACES2,
  GET_WORK_STAGES,
  UPLOAD_FILE,
} from "../actionTypes/NSITypes";

function getDictionary({ url, type }) {
  return async (dispatch) => {
    if (!url) {
      return null;
    }

    try {
      const response = await axios.get(url);

      return dispatch({ type, payload: response.data });
    } catch (error) {
      logWarn({
        errorNumber: "1664186245428",
        errorMessage: `${error}`,
      });
    }
  };
}

function getDictionaryId(id) {
  if (!id) {
    return null;
  }

  return `/dictionary/data/?id=${id}`;
}

export const fetchOkved = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd34444"),
    type: GET_OKVEDS,
  });

export const typesOfWorks = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd11111"),
    type: GET_TYPES_OF_WORKS,
  });

export const organizationStructure = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd11111"),
    type: GET_TYPES_OF_WORKS,
  });

export const getWorkPlaces = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd12222"),
    type: GET_WORK_PLACES,
  });

export const getNas = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd13333"),
    type: GET_NAS_CLASSIFIERS,
  });

export const getOk01694 = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd28888"),
    type: GET_OK01694,
  });

export const getProfStandart = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd38888"),
    type: GET_PROF_STANDART,
  });

export const getSout = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd21111"),
    type: GET_SOUT,
  });

export const getDangerousSituation = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd13333"),
    type: GET_DANGEROUS_SITUATION,
  });

export const getInstrumentsAndEquipments = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd14444"),
    type: GET_INSTRUMENTS_AND_EQUIPMENTS,
  });

export const getWorkPlaces2 = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd36666"),
    type: GET_WORK_PLACES2,
  });

export const getMaterials = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd15555"),
    type: GET_MATERIALS,
  });

export const getBioObjects = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd16666"),
    type: GET_BIO_OBJECTS,
  });

export const getMachinery = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd17777"),
    type: GET_MACHINERY,
  });

export const getDanger = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd18888"),
    type: GET_DANGER,
  });

export const getDangerEvent = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd19999"),
    type: GET_DANGER_EVENT,
  });

export const getRiskManagement = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd20000"),
    type: GET_RISK_MANAGEMENT,
  });

export const getMedical = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd27777"),
    type: GET_MEDICAL,
  });

export const getMedicalVPF = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd26666"),
    type: GET_MEDICAL_VPF,
  });

//Использован справочник Структура Справочника «Медосмотры (ВПФ)»
export const getMedicalVPF302 = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd37777"),
    type: GET_MEDICAL_VPF302,
  });

export const getSIZ = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd29999"),
    type: GET_SIZ,
  });

export const getSIZNew = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd39999"),
    type: GET_SIZ_NEW,
  });

export const getWorkStages = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd23333"),
    type: GET_WORK_STAGES,
  });

export const getTypeCompany = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd22222"),
    type: GET_TYPE_COMPANY,
  });

export const getRequirement = (dangerousID) => {
  if (!dangerousID) {
    return null;
  }

  return getDictionary({
    url: getDictionaryId(
      `77581fda-e9ed-4d00-bb49-6831ebd20000&rowId=${dangerousID}`
    ),
    type: GET_REQUIREMENT,
  });
};

export const getMedicalSout = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd25555"),
    type: GET_MEDICAL_SOUT,
  });

export const getPersonalRequire = () =>
  getDictionary({
    url: getDictionaryId("77581fda-e9ed-4d00-bb49-6831ebd30000"),
    type: GET_PERSONNEL_REQUIRE,
  });

export const uploadClassiffier = (id, structureFile) => {
  let url = `/dictionary/upload/?id=${id ?? ""}`;

  var formData = new FormData();

  formData.id = id;
  formData.file = structureFile;

  return (dispatch) =>
    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: UPLOAD_FILE });
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743665278",
          errorMessage: `${error}`,
        })
      );
};

export const findSoutClass = (id) => {
  const url = `/dictionary/data/?id=77581fda-e9ed-4d00-bb49-6831ebd21111&rowId=${id}`;

  return (dispatch) =>
    id &&
    axios
      .get(url)
      .then(
        (res) =>
          res?.data?.length &&
          res.data[0]?.name &&
          dispatch({
            type: ADD_SOUTDATA,
            payload: { [id]: res.data[0] },
          })
      )
      .catch((error) => {
        logWarn({
          errorNumber: "1665743708547",
          errorMessage: `${error}`,
        });
        dispatch({
          type: ADD_SOUTDATA,
          payload: { [id]: "failed" },
        });
      });
};

export const findSoutMedical = (id) => {
  const url = `/dictionary/data/?id=77581fda-e9ed-4d00-bb49-6831ebd25555&rowId=${id}`;

  return (dispatch) =>
    id &&
    axios
      .get(url)
      .then(
        (res) =>
          res?.data?.length &&
          res.data[0]?.typeOrder &&
          dispatch({
            type: ADD_SOUTMEDICALDATA,
            payload: { [id]: res.data[0] },
          })
      )
      .catch((error) => {
        logWarn({
          errorNumber: "1665743728001",
          errorMessage: `${error}`,
        });
        dispatch({
          type: ADD_SOUTMEDICALDATA,
          payload: { [id]: "failed" },
        });
      });
};

import React from 'react';

import Can from '../../../can';

import * as Styled from './styled';

export default function Workplace(props) {
  const { type, workplace, onIsRenderCard, id, onRowId } = props;

  const handleIsRenderCard = () => {
    onRowId(id);
    onIsRenderCard.on();
  };

  return (
    <React.Fragment>
      <Styled.WorkplaceLink onLink={handleIsRenderCard}>{workplace}</Styled.WorkplaceLink>
      <Can roles={['admin-role']}>
        <Styled.WorkplaceType>{type}</Styled.WorkplaceType>
      </Can>
    </React.Fragment>
  );
}

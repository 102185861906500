import React, { useState } from 'react';
import { Col, Modal, Row } from 'antd';

import message from "./message.png";

const App = ({content1, content2, buttonName}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  //const label = label;
  //console.log(content);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>

      <span style={{"cursor": "pointer"}}onClick={showModal}>{buttonName.label}</span>
      <Modal
        open={open}
        title="  "
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[]}
        width={600}
        height={148}
      >

      <Row justify="space-around" align="middle" width={100}>
        <Col span={4}>
          <img src={message} width={"50px"}></img>
        </Col>
        <Col span={20}>
          <p style={{"margin": "auto"}}>{content1}<br/>{content2}</p>
        </Col>
      </Row>


      </Modal>
    </>
  );
};
export default App;

import React from 'react';

import { useBoolean } from '../../../../hooks';
import EvaluationCard from '../../../blocks/riskAssessmentCard/components/evaluationCard';
import { useRiskAssessmentEvaluationCard } from '../hazardAssessment/hooks';

import ClarifyFactor from './components/clarifyFactor';
import MedicalTable from './components/table';
import WorkType from './components/workType';
import { useMedicalExamination } from './hooks';
import { ActionButton, EvaluationCardEx, EvaluationCardExActions, FactorsNeedText } from './styled';

const MedicalCheckups = ({ project, workplace }) => {
  const [showFactor, onShowFactor] = useBoolean(false);
  const [showWork, onShowWork] = useBoolean(false);
  const { loading, data, text, onChangeTable, onRefetchMedicalExamination } = useMedicalExamination({
    workplaceId: workplace,
  });
  const { formikEvaluationCard } = useRiskAssessmentEvaluationCard({ project, workplace });
  return (
    <>
      <EvaluationCard
        formik={formikEvaluationCard}
        project={project}
        workplace={workplace}
        onRefetchProjectHazard={onRefetchMedicalExamination}
      />
      <EvaluationCardEx>
        <FactorsNeedText>{`${text || ''}`}</FactorsNeedText>
        <EvaluationCardExActions>
          <ActionButton size="large" onClick={onShowFactor.on}>
            Уточнить фактор
          </ActionButton>
          <ActionButton size="large" onClick={onShowWork.on}>
            Добавить вид работ
          </ActionButton>
        </EvaluationCardExActions>
      </EvaluationCardEx>
      <MedicalTable loading={loading} data={data} onChangeTable={onChangeTable} />
      <ClarifyFactor
        show={showFactor}
        workplace={workplace}
        onShow={onShowFactor}
        refetchTable={onRefetchMedicalExamination}
      />
      <WorkType show={showWork} workplace={workplace} onShow={onShowWork} refetchTable={onRefetchMedicalExamination} />
    </>
  );
};

export default MedicalCheckups;

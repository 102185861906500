import { Button as AButton } from 'antd';
import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';
import Text from '../../../atoms/text';

export const P = styled(Text)`
  margin-top: 28px;
  font-family: 'GolosTextWebVF', serif;
`;

export const ChangePassword = styled.p`
  font-family: 'GolosTextWebVF', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

export const ChangePasswordH5 = styled.h5`
  font-family: 'GolosTextWebVF', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin: 35px 0 5px;
`;

export const H6 = styled.h6`
  font-family: 'GolosTextWebVF', serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin: 16px 0 5px;
`;

export const AtomButton = styled(Button)`
  margin-bottom: 12px;
  color: #000;
  font-family: 'GolosTextWebVF', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 0;
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    padding-left: 11px;
  }
`;

export const ButtonPencil = styled(Button)`
  border: 0;
  background-color: ${(props) => (props.isError || props.disabled ? '#EDEDEF' : '#0078D6')};
  height: 42px;
  width: 42px;
  border-radius: 4px;
  padding: 10px;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Buttons = styled(Flex)`
  flex-direction: column;
`;

export const EditEmailButtons = styled(Flex)`
  margin-top: 26px;
`;

export const Contacts = styled(Text)`
  margin-bottom: 2px;
  font-family: 'GolosTextWebVF', serif;
`;

export const IconContacts = styled.span`
  padding-right: 5px;
`;
export const CustomButton = styled(Button)`
  font-family: 'GolosTextWebVF', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 10px 42px;
`;

export const CustomButtonSecond = styled(AButton)`
  font-family: 'GolosTextWebVF', serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  margin-right: 15px;
  padding: 9px;

  &:disabled {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
export const Status = styled(Flex)`
  padding-top: 8px;
  flex-wrap: nowrap;
  align-items: center;
`;

export const StatusMessage = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  color: #aaa;
  padding-left: 9px;
  display: inline-block;
`;

export const SendMailAgain = styled(Button)`
  font-family: 'GolosTextWebVF', serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  border: 0;
  color: #000;
  background-color: #ededef;
  padding: 5px;
  margin: 9px 9px 9px 0;
  border-radius: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    color: #c8c6c5;
  }
`;

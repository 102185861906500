import {useState} from 'react'
import {Form} from 'react-bootstrap'

function RoleForm({record, disabled, onChange, onSubmit}) {
  const [form, setForm] = useState({roleName: '', displayName: '', desription: '', ...record})

  function onFieldChange(key, {value}) {
    const tmp = {...form}
    tmp[key] = value
    setForm(tmp)
    onChange(tmp)
  }

  function onFormSubmit(e) {
    e.preventDefault()
    onSubmit(form)
  }

  return (
    <>
      <Form onSubmit={onFormSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Роль</Form.Label>
          <Form.Control value={form.roleName} onChange={({target}) => onFieldChange('roleName', target)} autoFocus readOnly={disabled} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Название группы</Form.Label>
          <Form.Control value={form.displayName} onChange={({target}) => onFieldChange('displayName', target)} readOnly={disabled} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Описание</Form.Label>
          <Form.Control value={form.description} onChange={({target}) => onFieldChange('description', target)}  readOnly={disabled} as="textarea" rows="5" />
        </Form.Group>

        <input type="submit" className="d-none" />
      </Form>
    </>
  )
}

export default RoleForm
import React, { memo } from 'react';
import { Skeleton, Switch } from 'antd';

import { cx } from '../../../../../utils';

import './styles.scss';

const InfoBlock = ({ loading, title, value, small, large, isShowSwitch, onChange }) => {
  const classNames = cx('info-block', {
    'info-block--small': small,
    'info-block--large': large,
  });

  return (
    <div className={classNames}>
      {isShowSwitch ? (
        <div className="info-block__top">
          <Switch size="small" onChange={onChange} />
        </div>
      ) : null}
      <div className="info-block__content">
        <span className="info-block__title">{title}</span>
        {loading ? <Skeleton.Button active /> : <span className="info-block__val">{value}</span>}
      </div>
    </div>
  );
};

export default memo(InfoBlock);

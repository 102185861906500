import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button as AButton } from 'antd';

import Button from '../../../atoms/nButton';
import Dialog from '../../../blocks/dialog';
import FieldInput from '../../fields/input';

import * as Styled from './styled';

export default function Login({ formik, isRender, onLink, onSubmit, onGoBack }) {
  return (
    <Dialog isRender={isRender} isClose={false} title={'Восстановление пароля'} centered={true}>
      <Styled.H5 tagName={'h5'} fSize={18} align={'left'} fWeight={600} lHeight={22}>
        Адрес электронной почты
      </Styled.H5>

      <Styled.Wrapper>
        <Styled.PText tagName={'p'} fSize={16} align={'left'} fWeight={400} lHeight={22}>
          {formik.values.email}
        </Styled.PText>
        <AButton shape="circle" type="text" size="small" icon={<EditOutlined />} onClick={onGoBack} />
      </Styled.Wrapper>
      <FieldInput margin={'0 0 24px'} formik={formik} placeholder={'Логин'} name={'userName'} />
      <Styled.RecoverAccount onLink={onLink}>Забыли логин</Styled.RecoverAccount>
      <Styled.Buttons direction={'row'} jContent={'flex-end'}>
        <Button disabled={formik.values.userName.length < 3} type={'submit'} variant={'secondary'} onClick={onSubmit}>
          Отправить
        </Button>
      </Styled.Buttons>
    </Dialog>
  );
}

import React from 'react';
import * as Antd from 'antd';

import Edit from '../../../../assets/svg/edit';
import Flex from '../../../atoms/flex';
import InputSearch from '../../../atoms/search';
import Dialog from '../../../blocks/dialog';

import {useClassifierMeasures} from './hook'
import * as Styled from './styled';

export default function ClassifierMeasures(props) {
  const {
    title,
    project,
    formik,
    isShow,
    onIsShow,
    loading,
    onShowEditClassifierMeasures
  } = props;

  const {
    onPostMeasuresExceptions,
    loadingMeasuresExceptions
  } = useClassifierMeasures({formik, isShow});

  const onEditControlmeasures = ({id, rate, name}) => () => {
    formik?.setFieldValue('rowEditControlmeasures', {
      id,
      rate,
      name
    });
    onShowEditClassifierMeasures();
  }

  const columns = [
    {
      key: 'action',
      title: '',
      align: 'center',
      render: (record) => (
        <Styled.EditButton
          active={record?.active ?? false}
          onClick={onEditControlmeasures({
            id: record?.id,
            rate: record?.rate,
            name: record?.name
          })}>
          <Edit />
        </Styled.EditButton>
      ),
    },
    {
      key: 'rate',
      title: 'Вес меры',
      align: 'center',
      render: (record) => record?.rate,
    },
    {
      key: 'name',
      title: ' Наименование позиции',
      align: 'center',
      width: 300,
      render: (record) => record?.name,
    },
  ];

  const valuesData = formik?.values ?? {};
  const dataControlmeasures = !!valuesData?.controlmeasures?.length ? valuesData?.controlmeasures : [];
  const dataMeasuresExceptions = !!valuesData?.measuresExceptions?.length ? valuesData?.measuresExceptions : [];
  const isRenderTable = !!dataMeasuresExceptions?.length;

  const onControlmeasures = ({parent_id, name}) => () => {
    formik?.setFieldValue('currentContMeasure', {
      parent_id,
      name
    });
    onPostMeasuresExceptions({parent_id, project_id: project})
  }

  const onSerchControlmeasures = (e) => {
    const eValue = e.target?.value;
    const { controlmeasures } = formik.values ?? {};
    const checkNameValue = (name) => name?.toLowerCase().includes(eValue?.toLowerCase());

    const find = controlmeasures?.map((el) => {

      if (!checkNameValue(el.name)) {
        return {
          parent_id: el.parent_id,
          name: el.name,
        };
      }

      if (checkNameValue(el.name)) {
        return {
          ...el,
          active: true,
        };
      }

      return el;
    });

    if (eValue.length < 3) {
      return null;
    }

    formik.setFieldValue('controlmeasures', find);
  }

  const onMeasuresExceptions = (e) => {
    const eValue = e.target?.value;
    const { measuresExceptions } = formik.values ?? {};
    const checkNameValue = (name) => name?.toLowerCase().includes(eValue?.toLowerCase());

    const find = measuresExceptions?.map((el) => {

      if (!checkNameValue(el.name)) {
        return {
          id: el?.id,
          name: el?.name,
          rate: el?.rate
        };
      }

      if (checkNameValue(el.name)) {
        return {
          ...el,
          active: true,
        };
      }

      return el;
    });

    if (eValue.length < 3) {
      return null;
    }

    formik.setFieldValue('measuresExceptions', find);
  }

  const onClose = () => {
    const { controlmeasures } = formik.values ?? {};

    formik.setFieldValue('measuresExceptions', []);
    formik.setFieldValue('controlmeasures', controlmeasures?.map(el => ({...el, active: false})));
    onIsShow.off();
  }

  return (
    <Dialog
      isRender={isShow}
      width={'1000px'}
      isClose={true}
      centered={true}
      title={title}
      paddingWrapper={'0 36px 0 0'}
      onClose={onClose}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 40px',
        },
        close: {
          height: 20,
          width: 20,
          right: '8px',
          top: '8px',
        },
      }}
    >
      <Flex
        direction={'row'}
        wrap={'nowrap'}
        jContent={'space-between'}
        widthItem={['50%', '50%']}
        paddingItem={['0 10px 0 0', '0 0 0 10px']}
      >
        <React.Fragment>
          <Styled.Title>
            Разделы классификатора
          </Styled.Title>
          <InputSearch
            placeholder={'Поиск'}
            onChange={onSerchControlmeasures}
          />
          <Styled.MiddleTitle>
            Наименование раздела
          </Styled.MiddleTitle>
            <Styled.List>
              {dataControlmeasures?.map(el => 
                <Styled.ListItem active={el?.active ?? false}>
                  <Styled.LeftButton
                    onClick={onControlmeasures({
                      parent_id: el?.parent_id,
                      name: el?.name
                    })}
                  >
                    {el?.name}
                  </Styled.LeftButton>
                </Styled.ListItem>
              )}
            </Styled.List>
        </React.Fragment>
        <React.Fragment>
          <Styled.Title>
            {valuesData?.currentContMeasure?.name}
          </Styled.Title>
          <InputSearch
            placeholder={'Поиск'}
            onChange={onMeasuresExceptions}
          />
          {isRenderTable ? <Styled.AntdTable>
            <Antd.Table
              rowKey={record => record?.id}
              loading={loadingMeasuresExceptions || loading}
              columns={columns}
              dataSource={dataMeasuresExceptions}
              pagination={false}
            />
          </Styled.AntdTable> : ''}
        </React.Fragment>
      </Flex>
    </Dialog>
  )
}

import React from 'react';

import * as Fields from './fields';

export const getTableRows = (
  data,
  onPostUser
) => {

  if (!data?.length) {
    return
  }

  return data?.map((item, index) => {

    return {
      key: index,
      cells: [
        {
          key: 'inn',
          label: item.inn,
          type: 'view',
          align: 'center',
        },
        {
          key: 'name',
          label: item.name,
          type: 'view',
          align: 'center',
        },
        {
          key: 'kpp',
          label: item.kpp,
          type: 'view',
          align: 'center',
        },

        {
          key: 'contract',
          label: item.contract,
          type: 'view',
          align: 'center',
        },
        {
          key: 'editLink',
          width: 87,
          content: <Fields.EditUserLink
            id={item?.id}
            onPostUser={onPostUser}
          />,
        }
      ],
    };
  });
};

export const tableHead = [
  {
    key: 'inn',
    label: 'ИНН',
    type: 'header',
    align: 'center',
  },
  {
    key: 'name',
    label: 'Наименование',
    type: 'header',
    align: 'center',
  },
  {
    key: 'kpp',
    label: 'КПП',
    type: 'header',
    align: 'center',
  },

  {
    key: 'contract',
    label: 'Договор',
    type: 'header',
    align: 'center',
  },
  {
    key: 'editLink',
    label: 'Пользователи',
    type: 'header',
    width: 87,
    align: 'center',
  },
];

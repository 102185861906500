import React from 'react';
import { Button, Form, Input } from 'antd';

import Dialog from '../../../blocks/dialog';

import { usePostRecoveryPassword } from './hooks';
import * as Styled from './styled';

export default function RecoveryPassword() {
  const [form] = Form.useForm();

  const { onPostRecoveryPassword } = usePostRecoveryPassword();

  const onFinish = (values) => {
    onPostRecoveryPassword(values);
  };

  return (
    <Dialog isRender={true} centered={true} title={'Установите новый пароль'}>
      <Form form={form} requiredMark={false} layout="vertical" name="recover" onFinish={onFinish}>
        <Form.Item
          name="password"
          label="Новый пароль"
          rules={[
            {
              required: true,
              pattern: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-z])(?=.*[#$@!%&*?]).*$/,
              message:
                'Пароль должен содержать 8 и более символов, прописные латинские буквы, строчные латинские буквы, цифры и спецсимволы',
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Повторите пароль"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, подтвердите пароль!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают!'));
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Styled.Buttons direction={'row'} jContent={'center'}>
          <Button size="large" type="primary" htmlType="submit">
            Отправить
          </Button>
        </Styled.Buttons>
      </Form>

      {/*<FieldInput*/}
      {/*  margin={'0 0 24px'}*/}
      {/*  formik={formik}*/}
      {/*  type={'password'}*/}
      {/*  placeholder={'Новый пароль'}*/}
      {/*  name={'newPassword'}*/}
      {/*/>*/}
      {/*<FieldInput*/}
      {/*  margin={'0 0 24px'}*/}
      {/*  formik={formik}*/}
      {/*  type={'password'}*/}
      {/*  placeholder={'Повторите пароль'}*/}
      {/*  name={'repeatPassword'}*/}
      {/*/>*/}

      {/*<Styled.Buttons direction={'row'} jContent={'center'}>*/}
      {/*  <Button type={'submit'} variant={'secondary'} onClick={onSubmit}>*/}
      {/*    Отправить*/}
      {/*  </Button>*/}
      {/*</Styled.Buttons>*/}
    </Dialog>
  );
}

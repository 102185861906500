import React from 'react';

import Button from '../../../atoms/nButton';
import FieldInput from '../../fields/input';
import FieldSelect from '../../fields/select';

import * as Styled from './styled';

export default function UserInfoForm(props) {
  const {
    formik,
    onShowUserWiew,
    onPostOrganizationData,
    dataRoles
  } = props;


  const onSubmit = () => {
    onShowUserWiew.off();
    onPostOrganizationData(formik.values);
  }


  return (
    <div>
      <FieldInput
        margin={'0 0 24px'}
        formik={formik}
        label={'ФИО'}
        name={'fio'}
      />
      <FieldSelect
        margin={'0 0 24px'}
        formik={formik}
        label={'Роль'}
        options={dataRoles}
        name={'roleName'}
      />
      <FieldInput
        margin={'0 0 24px'}
        formik={formik}
        label={'Контактный телефон'}
        name={'phone'}
      />
      <FieldInput
        margin={'0 0 24px'}
        formik={formik}
        label={'E-mail'}
        name={'email'}
      />
      <Styled.Buttons>
        <Button
          type={"submit"}
          variant={'primary'}
          disabled={null}
          onClick={onSubmit}
        >
          Данные организации
        </Button>
      </Styled.Buttons>
    </div>
  )
}

import React from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../axios/axios';
import { useBoolean } from '../../../../hooks';
import { logWarn } from '../../../../utils/log';

export function usePostProjectRiskassessment({
  project,
  workplace,
  formik,
  formikAddDanger,
  onShowAddDanger,
  formikHeaderTop,
  additionalControlsFormik,
  onIsRenderSpinner
}) {
  const [loading, onLoading] = useBoolean(false);
  React.useEffect(() => {
    onPostProjectHazard();
    onPostRiskassessmentOne();
  }, []);

  const onPostProjectHazard = async (current) => {
    try {
      onLoading.on();
      const { data } = await axios.post(
        `/api/project/riskassessment/evaluationcard/grouphazard${current ? `?page=${current}` : ''}`,
        {
          project,
          workplace,
          page: 50,
        }
      );

      if (data?.data) {
        formik?.setStatus('riskassessment');
        formik?.setFieldValue('data', data?.data);
        onLoading.off();
      }
    } catch (e) {
      console.log(e);
      onLoading.off();
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostEvaluationcardEdithazard = async (hazard_id) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/edithazard', {
        hazard_id,
      });

      if (data.status === 'success') {
        formikAddDanger?.setFieldValue('data', data?.data[0]);
        onShowAddDanger.on();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    } finally {
    }
  };

  const onPostEvaluationcardDelete = async (hazard_id) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/deletehazard', {
        hazard_id,
        workplace_id: workplace,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        onPostProjectHazard();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostRiskassessmentOne = async () => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard', {
        project,
        workplace,
        page: '',
        field: '',
        sort: '',
      });

      if (data.status === 'success') {
        formikHeaderTop?.setFieldValue('data', data?.data);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onEvaluationcardApproved = async () => {
    try {
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/approved`, {
        workplace,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostEvaluationcardAdditionaledit = async (hazard_id) => {

    try {
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/additionaledit`, {
        hazard_id,
      });

      if (data?.status === 'success') {
        additionalControlsFormik?.setFieldValue('data', !data?.data?.length ? [{ name: '' }] : data?.data);
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onAutomaticEvaluation = async (hazard_id, e) => {
    console.log(e);
    await fetch('/api/project/fillrisk/savenewtyp',
      {
        method: "POST",
        body: JSON.stringify({
          workplace: hazard_id,
          typical: e
        })
      }
    )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    try {
      const token = localStorage.getItem('token');



      const { data } = await axios.get(`/api/project/fillrisk/workplace/?project_id=${hazard_id}&bearer=${token}`);
      //console.log(hazard_id);
      if (data.status === 'Автоматическая оценка завершена') {
        onPostProjectHazard();
        toast['success'](data?.status);
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    } finally {
      onIsRenderSpinner.off();
    }
  };

  return {
    loading,
    dataRows: formik?.values?.data?.map((el) => {
      const hasChildren = !!el.data;
      if (hasChildren) {
        return {
          ...el,
          children: [...el.data],
        };
      }
      return {
        ...el,
      };
    }),
    onPostProjectHazard,
    onPostEvaluationcardEdithazard,
    onPostRiskassessmentOne,
    onPostEvaluationcardDelete,
    onEvaluationcardApproved,
    onPostEvaluationcardAdditionaledit,
    onAutomaticEvaluation,
  };
}


export const useRiskAssessmentEvaluationCard = ({ project, workplace }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: {},
    },
  });

  const onPostRiskAssessmentOne = async () => {
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard', {
        project,
        workplace,
        page: '',
        field: '',
        sort: '',
      });

      if (data.status === 'success') {
        formik?.setFieldValue('data', data?.data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
      formik.setFieldValue('loading', false);
    }
  };

  React.useEffect(() => {
    onPostRiskAssessmentOne();
  }, []);

  return {
    formikEvaluationCard: formik,
    onPostRiskAssessmentOne,
  };
};

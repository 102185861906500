import { Modal } from 'antd';
import styled from 'styled-components';

import Text from '../../atoms/text';

export const H3 = styled(Text)`
  margin: ${(props) => props.margin ?? '21px 0 24px'};
  padding: 0 20px;
  font-family: 'GolosTextWebVF', serif;
`;

export const Dialog = styled(Modal)`
  &:where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-close:hover {
    background-color: transparent;
    color: transparent;
  }

  &:where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-content {
    padding-right: 17px;
  }
`;

export const DialogWrapper = styled.div`
  overflow: auto;
  height: ${(props) => props.height ?? 'auto'};
  padding: ${(props) => props.padding ?? 'auto'};
`;

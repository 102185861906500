export const SET_EMPLOYER_ORGANIZATION = 'SET_EMPLOYER_ORGANIZATION';
export const SET_EXPERT_ORGANIZATION = 'SET_EXPERT_ORGANIZATION';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const STRUCTURE_LOAD = 'STRUCTURE_LOAD';
export const JOB_TITLE_LOAD = 'JOB_TITLE_LOAD';
export const WORK_LIST_LOAD = 'WORK_LIST_LOAD';
export const ADD_SHEDULE_WORKS = 'ADD_SHEDULE_WORKS';
export const REMOVE_SCHEDULE_WORKS = 'REMOVE_SCHEDULE_WORKS';
export const GET_SHEDULE_WORKS = 'GET_SHEDULE_WORKS';
export const GET_CURRENT_PROJECT = 'GET_CURRENT_PROJECT';
export const UPDATE_STATE_CURRENT_PROJECT = 'UPDATE_STATE_CURRENT_PROJECT';
export const SAVE_CURRENT_PROJECT = 'SAVE_CURRENT_PROJECT';
export const PROJECT_IS_LOADED = 'PROJECT_IS_LOADED';
export const UPDATE_WORKLIST = 'UPDATE_WORKLIST';
export const SAVE_WORPLACE = 'SAVE_WORPLACE';
export const GET_WORKPLACE = 'GET_WORKPLACE';
export const UPDATE_WORKPLACE = 'UPDATE_WORKPLACE';
export const GET_EMPLOYERS_INFO_FOR_JOBTITLE = 'GET_EMPLOYERS_INFO_FOR_JOBTITLE';
export const GET_QUESTIONS_LIST = 'GET_QUESTIONS_LIST';
export const CLEAR_WORKPLACE = 'CLEAR_WORKPLACE';
export const GET_WORKPLACE_HAZARDS = 'GET_WORKPLACE_HAZARDS';
export const DELETE_HAZARD = 'DELETE_HAZARD';
export const DELETE_WORKPLACE = 'DELETE_WORKPLACE';
export const GENERATE_RM = 'GENERATE_RM';
export const CLEAR_SHEDULE_WORKS = 'CLEAR_SHEDULE_WORKS';
export const GET_VISUAL_INSPECTIONS_FILELIST = 'GET_VISUAL_INSPECTIONS_FILELIST';
export const GET_DOCUMENTS_FILELIST = 'GET_DOCUMENTS_FILELIST';
export const SAVE_SHEDULE_FILE_LINK = 'SAVE_SHEDULE_FILE_LINK';
export const CHANGE_CURRENT_HAZARD = 'CHANGE_CURRENT_HAZARD';


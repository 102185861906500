import styled from 'styled-components';

import Button from '../../../atoms/nButton';

export const ButtonSection = styled(Button)`
  font-weight: 400;
  line-height: normal;
  padding: 3px;
  font-size: 12px;
  min-width: 170px;
  justify-content: center;
  width: auto;
  padding: 10px;
  margin: 55px 0 20px;
`

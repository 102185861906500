import styled, {css} from 'styled-components';

export const Text = styled.span`
  text-align: ${props => props.align ?? 'left'};
  font-style: ${props => props.fStyle ?? 'normal'};
  font-family: 'GolosTextWebVF', serif;

  ${props =>
    props.width &&
      css`
      width: ${props.width}px;
      `
  }}

  ${props =>
    props.fWeight &&
      css`
        font-weight: ${props.fWeight};
      `
  }}

  ${props =>
    props.fSize &&
      css`
        font-size: ${props.fSize}px;
      `
  }}

  ${props =>
    props.color &&
      css`
        color: ${props.color};
      `
  }}

  ${props =>
    props.lHeight &&
      css`
        line-height: ${props.lHeight}px;
      `
  }}

`;

import React, { useState } from "react";

import polygonClose from "../../../../../../image/Polygon_close.svg";
import polygonOpen from "../../../../../../image/Polygon_open.svg";

function AddStructure({ classBtn, create, children }) {
  // Виды работ
  const [open1, setOpen1] = React.useState(false);
  const tooglePolygon1 = () => {
    setOpen1((prevState) => !prevState);
  };

  const [open1_1, setOpen1_1] = React.useState(false);
  const tooglePolygon1_1 = () => {
    setOpen1_1((prevState) => !prevState);
  };

  const [open1_2, setOpen1_2] = React.useState(false);
  const tooglePolygon1_2 = () => {
    setOpen1_2((prevState) => !prevState);
  };

  const [open2_1, setOpen2_1] = React.useState(false);
  const tooglePolygon2_1 = () => {
    setOpen2_1((prevState) => !prevState);
  };

  const [open2_2, setOpen2_2] = React.useState(false);
  const tooglePolygon2_2 = () => {
    setOpen2_2((prevState) => !prevState);
  };

  const [arrTypeOfJobs, setTypeOfJobs] = useState({
    id: "1",
    type: { name: "P", id: 11 },
    object_name: "",
  });
  function addThishValue(e) {
    e.preventDefault();
    document.querySelectorAll(".cursor-pointer span").forEach((element) => {
      element.style.color = "#000";
    });
    e.target.style.color = "rgb(220,53,69)";

    const newObj = {
      type: { name: "P", id: 11 },
      object_name: e.target.innerText,
    };
    setTypeOfJobs(newObj);
  }

  const [danger, setDanger] = useState(false);
  function saveTypes() {
    create(arrTypeOfJobs);
  }

  return (
    <>
      <button
        type="button"
        className={classBtn}
        data-bs-toggle="modal"
        data-bs-target="#addDangerousModal"
      >
        {children}
      </button>

      <div
        className="modal fade"
        id="addDangerousModal"
        tabIndex="-1"
        aria-labelledby="addDangerousModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modalWindow modal-dialog-centered">
          <div className="modal-content rounded-0">
            <div className="modal-header border-0">
              <h5 className="user-title pddng-lft-10" id="exampleModalLabel">
                структура
              </h5>
              <button
                type="button"
                className="btn close-button rounded-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="bi bi-x ft-sz"></i>
                  <span>Закрыть</span>
                </div>
              </button>
            </div>
            <div></div>
            <a className="href-text pddng-lft-346" href=" ">
              добавить новое значение
            </a>
            <div className="modal-body pddng-tp-0">
              <form>
                <div className="modalAddBox  modalAddBox-str shadow px-4 py-4 fz-13 ">
                  {/* Первая строка */}
                  {open1 ? (
                    // Если открыта первая строка
                    <div className="cursor-pointer">
                      <img
                        className="p-2"
                        src={polygonOpen}
                        onClick={tooglePolygon1}
                        alt=""
                      />
                      ООО “Краткое наименование
                      {open1_1 ? (
                        // Если открыта подстрока первой строки
                        <div className="cursor-pointer">
                          <img
                            className="pddng-lft-15"
                            src={polygonOpen}
                            onClick={tooglePolygon1_1}
                            alt=""
                          />
                          <span className="pddng-lft-5">Админстрация</span>
                          <div className="cursor-pointer">
                            <img
                              className="pddng-lft-25"
                              src={polygonOpen}
                              alt=""
                            />
                            <span
                              className="pddng-lft-5"
                              onClick={addThishValue}
                              value="Земляные"
                            >
                              Админстрация1
                            </span>
                          </div>
                          {open1_2 ? (
                            // Если открыта подподстрока
                            <div>
                              <div className="cursor-pointer">
                                <img
                                  className="pddng-lft-25"
                                  src={polygonOpen}
                                  onClick={tooglePolygon1_2}
                                  alt=""
                                />
                                <span className="pddng-lft-5">
                                  Окрасочные работы
                                </span>
                              </div>
                              <span
                                className="pddng-lft-65"
                                value={danger}
                                onClick={addThishValue}
                              >
                                Работа под водой
                              </span>
                            </div>
                          ) : (
                            // Если закрыта подподстрока
                            <div className="">
                              <img
                                className="pddng-lft-25"
                                src={polygonClose}
                                onClick={tooglePolygon1_2}
                                alt=""
                              />
                              <span
                                className="pddng-lft-5"
                                value={danger}
                                onChange={(e) => setDanger(e.target.value)}
                                onClick={addThishValue}
                              >
                                Окрасочные работы
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        // Если закрыта подстрока первой строки
                        <div className="cursor-pointer">
                          <img
                            className="pddng-lft-15"
                            src={polygonClose}
                            onClick={tooglePolygon1_1}
                            alt=""
                          />
                          <span className="pddng-lft-5" onClick={addThishValue}>
                            ООО “Краткое наименование
                          </span>
                        </div>
                      )}
                      {/* Вторая строка */}
                      {open2_1 ? (
                        // Если открыта подстрока первой строки
                        <div className="cursor-pointer">
                          <img
                            className="pddng-lft-15"
                            src={polygonOpen}
                            onClick={tooglePolygon2_1}
                            alt=""
                          />
                          <span className="pddng-lft-5">Администрация 1</span>
                          <div className="cursor-pointer">
                            <img
                              className="pddng-lft-25"
                              src={polygonOpen}
                              alt=""
                            />
                            <span className="pddng-lft-5">Бухгалтерия 1</span>
                          </div>
                          {open2_2 ? (
                            // Если открыта подподстрока
                            <div>
                              <div className="cursor-pointer">
                                <img
                                  className="pddng-lft-25"
                                  src={polygonOpen}
                                  onClick={tooglePolygon2_2}
                                  alt=""
                                />
                                <span className="pddng-lft-5">
                                  Отдел кадров 1
                                </span>
                              </div>
                              <span className="pddng-lft-65 text-danger">
                                Отдел кадров
                              </span>
                            </div>
                          ) : (
                            // Если закрыта подподстрока
                            <div className="cursor-pointer">
                              <img
                                className="pddng-lft-25"
                                src={polygonClose}
                                onClick={tooglePolygon2_2}
                                alt=""
                              />
                              <span className="pddng-lft-5">
                                Подразделение (Хабаровск) 2
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        // Если закрыта подстрока первой строки
                        <div className="cursor-pointer">
                          <img
                            className="pddng-lft-15"
                            src={polygonClose}
                            onClick={tooglePolygon2_1}
                            alt=""
                          />
                          <span className="pddng-lft-5">Админстрация 2</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    // Если закрыта первая строка
                    <div className="cursor-pointer">
                      <img
                        className="p-2"
                        src={polygonClose}
                        onClick={tooglePolygon1}
                        alt=""
                      />
                      Структура
                    </div>
                  )}
                </div>
              </form>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  onClick={saveTypes}
                  className="btn save-button"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddStructure;

import styled from 'styled-components';

import Flex from '../../atoms/flex';
import Button from '../../atoms/nButton'

export const Section = styled.section`
  padding-top: 18px;
  font-size: 14px;
  color: #52575C;
`
export const SectionHead = styled(Flex)`
  padding: 18px 0 42px;
`
export const ButtonCreate = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  padding: 10px 0;
  justify-content: center;
  width: 100%;
`
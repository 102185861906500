
import { toast } from "react-toastify";

import axios from "../../../../../../axios/axios";
import { logWarn } from "../../../../../../utils/log";

export function useSoutResults({
  workplace,
  onShow,
  onPostRiskassessmentOne
}) {

  const onPostRiskassessmentNumber = async (value) => {

    try {
      const { data } = await axios.post(`/api/project/riskassessment/number`, {
        workplace,
        value
      });

      if (data?.status) {
        toast['success'](data?.msg);
        onPostRiskassessmentOne();
        onShow.off();
      }

    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    onPostRiskassessmentNumber
  }
}

import React from 'react';

import * as Styled from './styled';

export default function Date(props) {
  const {data} = props;
  const formatDate = data?.split('-').reverse().join('/');

  return (
    <Styled.Date>
      {formatDate}
    </Styled.Date>
  )
};
import React from "react";
import {useSelector} from "react-redux";
import {Navigate} from "react-router";
import {Outlet} from "react-router-dom";

import Sidebar from "../../blocks/sidebar";

import * as Styled from './styled';

const getInitPath = (role) => {
  switch (role) {
    case 'admin-role':
      return '/administration/userslist'
    case 'analytic-role':
      return '/organizations/employers'
    default:
      return '/grade/projects'
  }
}

function UnauthorizedLayout() {
  const auth = useSelector((state) => state.authReducer)
  const userRole = auth.user.roles[0].roleName

  if (auth.isLoggedIn) {
    return <Navigate to={getInitPath(userRole)} />
  }

  return (
    <React.Fragment>
      <Sidebar />
      <Styled.UnauthorizedLayout>
        <Outlet />
      </Styled.UnauthorizedLayout>
    </React.Fragment>

  )
}

export default UnauthorizedLayout

// import React from "react";
import { toast } from "react-toastify";

import axios from "../../../../../axios/axios";
import { logWarn } from "../../../../../utils/log";

export function usePostNewWorkplace({id, onShowAddWorkplace, formik}) {

  const onPostNewWorkplace = async (structure) => {
    console.log(structure)
    try {
      const {data} = await axios.post('/api/project/structure/newworkplace', {
        name: structure?.name,
        num: structure?.num,
        structure: structure?.id,
        project: id,
      });

      if (data?.data?.length && structure?.name) {
        formik.setFieldValue('right', data?.data);
        toast['success']('Рабочее место добавлено.');
      }
      formik.setFieldValue('addWorkplace', {});
      onShowAddWorkplace.off()

    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685587487927",
        errorMessage: `${e}`,
      });
    } finally {

    }
  }

  return {
    onPostNewWorkplace
  }
}

import React from 'react';
import { toast } from 'react-toastify';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import  axios from 'axios';

import Delete from '../../../../../assets/svg/delete';
import Edit from '../../../../../assets/svg/edit';

import EditModal from './editmodal/editmodal';
import * as Styled from './styled.js';

const { confirm } = Modal;

export default function Parent(props){

  const [mouseLeave, setMouseLeave] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [data, setData] = React.useState(props.el);
  //const [data, setData] = React.useState(props.el);
  //setShowModal(false);
  //console.log(props.el);
  const handleMouseEnter = () => {
    setMouseLeave(true);
  }

  const handleMouseLeave = () => {
    setMouseLeave(false);

  }

  function handleEditworkplace(){
    //setData(props.el);
    setShowModal(true);
  }

  const showDeleteConfirm = () => {
    confirm({
      title: 'Подтвердите удаление позиции.',
      icon: <ExclamationCircleOutlined />,
      content: props.el.code + "  " + props.el.name,
      okText: 'Подвердить',
      //okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        //console.log('OK');
        axios.post('/api/classifier/position/delete',
          {
            table: props.table,
            id: props.el.id
          }
        )
        .then(function(response){
          //console.log(props.del)
          toast['success']("Позиция удалена");
          props.del()
          //console.log(response);
          //setRight(response.data);
        })
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  };
//onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
return (
<>
  <table>
              <tr onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <td width="10%" valign="top">
                  {data.code}
                </td>
                <td width="70%">
                  <span >{data.name}</span>
                </td>
                <td>
                {mouseLeave ? <Styled.IconButtons>
                <Styled.IconButton
                    variant={'link'}
                    onClick={handleEditworkplace}
                >

                <Edit />

                </Styled.IconButton>
                <Styled.IconButton
                    variant={'link'}
                    onClick={showDeleteConfirm}
                >
                <Delete />
                </Styled.IconButton>
                </Styled.IconButtons>
                : ''}

                </td>
              </tr>
        </table>

      <EditModal
                  showModal = {showModal}
                  table = {props.table}
                  data = {data}
                  parent = {props.parent}
                  del={props.del}
              //onOpen={() => setShowModal(false)}
                  onClose={() => setShowModal(false)}
                  />
        </>

)
}

import React from "react";
import {Outlet} from "react-router-dom";

import HeaderAdmin from "../../blocks/headerAdmin";

import {navMap} from "./navMap";
function KnowledgeBaseLayout() {
    return (
        <>
          <HeaderAdmin navMap={navMap} />

          <main className="">
            <Outlet />
          </main>
        </>
     );
}

export default KnowledgeBaseLayout;

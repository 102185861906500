import { Button, Checkbox as ACheckbox } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.h6`
  font-size: 11px;
  line-height: 13px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin: 13px 0;
`;

export const Text = styled.span`
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
`;

export const RowWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const Checkbox = styled(ACheckbox)`
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const ActionButton = styled(Button)`
  font-family: GolosTextWebVF, serif;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0.1px;
  width: 170px;
`;

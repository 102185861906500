import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { useBoolean } from '../../../../hooks';
import { logWarn } from '../../../../utils/log';

export function usePostSearchInnKpp(formik) {
  const [respSearchInnKpp, setRespSearchInnKpp] = React.useState(null);

  const onPostSearchInnKpp = async (id) => {
    const { values } = formik;

    try {
      const { data } = await axios.post('/api/project/new', {
        inn: values.project.inn,
        kpp: values.project.kpp,
        employer: values.project.employer,
      });

      formik.setFieldValue('project', {
        ...data?.data,
        id,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
      }

      setRespSearchInnKpp(data);
    } catch (e) {
      setRespSearchInnKpp(e.data);
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084166223',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    respSearchInnKpp,
    onPostSearchInnKpp,
  };
}

export function usePostSaveProject({ formik, onPostProject, onCloseGeneralInfo }) {
  const [respSaveProject, setRespSaveProject] = React.useState(null);

  const onPostSaveProject = async () => {
    const { prevProject, ...ownerdata } = formik?.values ?? {};

    try {
      const { data } = await axios.post(`/api/project/${formik.status}/save`, {
        data: {
          ...ownerdata,
          project: {
            ...ownerdata.project,
            id: ownerdata.project.id ?? null,
          },
        },
      });

      if (data.status === 'success') {
        onPostProject();
        onCloseGeneralInfo();
        toast['success'](data?.msg);
      }
    } catch (e) {
      setRespSaveProject(e.data);
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084178293',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    respSaveProject,
    onPostSaveProject,
  };
}

export function usePostSaveUser({ formik, onPostUser, onCloseGeneralInfo }) {
  const [respSaveUser, setRespSaveUser] = React.useState(null);

  const onPostSaveUser = async () => {
    const { values } = formik;

    try {
      const { data } = await axios.post(`/api/user/view/edit/save`, {
        data: {
          ...values,
          user: {
            ...values.user,
            id: values.user.id ?? null,
          },
        },
      });

      if (data.status === 'success') {
        onPostUser();
        onCloseGeneralInfo();
        toast['success'](data?.msg);
      }
    } catch (e) {
      setRespSaveUser(e.data);
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084178293',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    respSaveUser,
    onPostSaveUser,
  };
}

export function usePostOkvedFind() {
  const [loading, onLoading] = useBoolean(false);
  const [dataOkved, setDataOkved] = React.useState([]);

  const onPostOkvedFind = async (text) => {
    onLoading.on();
    try {
      const { data } = await axios.post('/api/dictionary/okved', { value: text });

      if (data.status === 'success') {
        toast['success'](data?.msg);
      }

      if (data?.data.length) {
        setDataOkved(data.data);
        onLoading.off();
      }
    } catch (e) {
      setDataOkved(e.data);
      onLoading.off();
      //toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  useEffect(() => {
    onPostOkvedFind('');
  }, []);

  return {
    loadingOkved: loading,
    dataOkved,
    onPostOkvedFind,
  };
}

import React, {useCallback, useState} from "react";
import { useSelector } from 'react-redux';
import { Checkbox, Tree } from 'antd';

import * as hooks from '../../../../hooks';
import Button from '../../../atoms/nButton';
import InputSearch from "../../../atoms/search";
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';

import {HighlightedText} from "./highligtedText";
import {usePostProjectWorkplace} from './hooks';
import * as Styled from './styled';

const {useBoolean} = hooks;

export const getExpandedKeys = (arrMap, text) => {
  return arrMap.map(el => {

    if (el?.children?.length) {
      return getExpandedKeys(el?.children, text)
    }

    if ((el?.findTitle)?.toLowerCase()?.includes(text?.toLowerCase())) {
      return el.parentId ?? el.id
    }

    return null
  })?.flat(Infinity)?.filter(Boolean);
};

const workplacesRecurse = (workplaces, search) => workplaces.map(el => {
  if (el?.structure?.length) {
    return ({
      children: workplacesRecurse([
        ...(el?.structure?.length ? el?.structure : []),
        ...(el?.workplace?.length ? el?.workplace : []),
      ], search),
      key: el.id,
      id: el.id,
      parentId: el?.parentId,
      title: el.parent ?? el.name,
      findTitle: el.parent ?? el.name
    })
  }

  if (el?.workplace?.length) {
    return ({
      children: workplacesRecurse([
        ...(el?.structure?.length ? el?.structure : []),
        ...(el?.workplace?.length ? el?.workplace : []),
      ], search),
      key: el.id,
      id: el.id,
      parentId: el?.parentId,
      title: el?.parent ?? el?.name,
      findTitle: el.parent ?? el.name
    })
  }

  return ({
    key: el.id,
    id: el.id,
    parentId: el?.parentId,
    title: (
      <Checkbox value={el?.id}>
        <HighlightedText text={el?.parent ?? el?.name} highlight={search} />
      </Checkbox>
    ),
    findTitle: el.parent ?? el.name
  })
})

export default function CopyMap(props) {
  const [showAproveCopyMap, onShowAproveCopyMap] = useBoolean(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selected, setSelected] = useState([]);

  const onCheck = useCallback((checkedKeys) => {
    setSelected(checkedKeys);
  }, []);

  const {
    workplace,
    project,
    showCopyMap,
    onShowCopyMap,
    onIsRenderSpinner,
  } = props;

  const {
    workplaces,
    onCopyMap,
    onWorkplaceIds,
  } = usePostProjectWorkplace({
    workplace,
    project,
    onShowAproveCopyMap,
    onShowCopyMap,
    onIsRenderSpinner,
    setExpandedKeys
  });

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (e) => {
    const text = e.target.value;

    if (!!text.length) {
      setExpandedKeys(getExpandedKeys(workplacesRecurse(workplaces), text));
    } else {
      setExpandedKeys([]);
    }

    setSearchValue(text);
    setAutoExpandParent(true);
  };

  const onClose = () => {
    setExpandedKeys([]);
    onShowCopyMap.off();
  }

  const auth = useSelector((state) => state.authReducer);
  const isStartRole = auth.user.roles.some((user) => 'start-role' === user.roleName);

  return (
    <React.Fragment>
      <Dialog
        isRender={showCopyMap}
        isClose={true}
        centered={true}
        onClose={onClose}
        title={'ВЫБОР РАБОЧИХ МЕСТ'}
        width={700}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
      <Styled.Form>
        <InputSearch
            width={'225px'}
            placeholder={'Поиск'}
            margin={'0 0 1rem 2rem'}
            onChange={onChange}
        />
        <Checkbox.Group onChange={onWorkplaceIds}>
          <Tree
            onExpand={onExpand}
            selectable={false}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={workplacesRecurse(workplaces, searchValue)}
            checkedKeys={selected}
            onCheck={onCheck}
          />
        </Checkbox.Group>
      </Styled.Form>
      <Styled.Buttons jContent={'flex-end'}>
        <Button

          variant={'secondary'}
          onClick={onShowAproveCopyMap.on}
        >
          Копировать
        </Button>
      </Styled.Buttons>
    </Dialog>
    <Portal id={'aproveCopyMap'}>
      <Dialog
          isRender={showAproveCopyMap}
          isClose={true}
          centered={true}
          onClose={onShowAproveCopyMap.off}
          title={''}
          settings={{
            close: {
              height: 28,
              width: 28,
              right: '8px',
              top: '8px',
            }
          }}
        >
        <Styled.AproveCopyMapTitle>
          Вы действительно хотите скопировать Карту РМ?
        </Styled.AproveCopyMapTitle>
        <Styled.Buttons jContent={'center'}>
          <Button
            variant={'secondary'}
            onClick={onCopyMap}
          >
            Подтвердить
          </Button>
        </Styled.Buttons>
      </Dialog>
    </Portal>
    </React.Fragment>
  )
}

import React from 'react';
import { useFormik } from 'formik';

import * as hooks from '../../../hooks';
import InputSearch from '../../atoms/search';
import HeaderUsers from '../../Menu/MenuAdministration/HeaderUsers';

import EditUserInfo from './editUserInfo.formik';
import { usePostOrganizationData, usePostUser, usePostUserView, usePostViewEditSave } from './hooks';
import * as Styled from './styled';
import UsersTable from './table';
import { companyYup } from './valide.yup';

const { useBoolean } = hooks;

export default function UsersList() {
  const { loading, data, postUserformik, tableParams, onChangeTable, onRefetchUsers, dataRoles, onPostUser, onPostUserFind } =
    usePostUser();

  const isShowUserView = useBoolean(false);
  const isShowOrganizationData = useBoolean(false);

  const formikUsers = useFormik({
    enableReinitialize: true,
    validationSchema: companyYup,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      fio: null,
      email: null,
      phone: null,
      roleName: null,
      displayName: null,
      start_date: null,
      expiration_date: null,
      contract_number: null,
      enterprise: null,
      inn: null,
      kpp: null,
    },
  });

  const { onPostUserView } = usePostUserView({
    formikUsers,
    isShowUserView,
  });

  const { onPostOrganizationData } = usePostOrganizationData({
    formikUsers,
    isShowOrganizationData,
  });

  const { onPostViewEditSave } = usePostViewEditSave({
    isShowOrganizationData,
    onPostUser,
    formik: postUserformik,
    onPostUserFind
  });

  const handleChangeSearchUser = (event) => {
    postUserformik.setFieldValue('search', event.target.value);
    onPostUserFind({ value: event.target.value, field: tableParams.field, sort: tableParams.sort });
  };

  return (
    <React.Fragment>
      <HeaderUsers />
      <Styled.Section>
        <Styled.SectionHead jContent={'space-between'} widthItem={[465, 170]}>
          <InputSearch placeholder={'Поиск'} onChange={handleChangeSearchUser} />
        </Styled.SectionHead>

        <UsersTable
          loading={loading}
          data={data}
          onChangeTable={onChangeTable}
          onPostUserView={onPostUserView}
          onPostUser={onRefetchUsers}
        />

        <EditUserInfo
          formik={formikUsers}
          isShowUserWiew={isShowUserView}
          isShowOrganizationData={isShowOrganizationData}
          onPostOrganizationData={onPostOrganizationData}
          onPostViewEditSave={onPostViewEditSave}
          dataRoles={dataRoles}
        />
      </Styled.Section>
    </React.Fragment>
  );
}

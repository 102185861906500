import * as Antd from 'antd';
import styled from 'styled-components';

import Button from '../../../../../atoms/nButton';

export const IconButton = styled(Button)`
  border: none;
  border-radius: 0;
  background: transparent;
`
export const PopoverButton = styled(Button)`
  border: none;
  border-radius: 0;
  background: transparent;
  font-size: 10px;
  line-height: normal;
  justify-content: flex-start;
  padding: 5px;
  color: #000;

  svg {
    margin-right: 5px;
  }
`

export const Popover = styled(Antd.Popover)`
  padding: 0;
`
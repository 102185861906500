import React from 'react'

import SortUp from '../../../assets/svg/sortUp';

import * as Styled from './styled';

export default function Tcell(props) {
  const {
    type,
    label,
    width,
    align,
    onSort,
    isSortby,
    content: children
  } = props;

  const [methodSort, setMethodSort] = React.useState('DESC');

  const handleSort = () => {
    if (isSortby) {
      onSort(methodSort);
      setMethodSort(prevState => prevState === 'ASC' ? 'DESC' : 'ASC')
    }
  }

  if (type === 'header' && label?.length) {
    return (<Styled.Td
      width={width}
    >
      <Styled.HeadFlex>
        <Styled.Header 
          align={align}
          dangerouslySetInnerHTML={{__html: label}}
        />
        <Styled.SortButton
          onClick={handleSort}
          isDown={methodSort === 'ASC'}
          isSortby={isSortby}
        >
          <SortUp />
        </Styled.SortButton>
      </Styled.HeadFlex>
    </Styled.Td>)
  }

  if (type === 'view' && label?.length) {
    return (
      <Styled.Td
        width={width}
        align={align}
       >
        <Styled.View>
          {label}
        </Styled.View>
      </Styled.Td>
    )
  }

  return (
    <Styled.Td
      width={width}
      align={align}
    >
      {children}
    </Styled.Td>
  )
};
import React from "react";

import axios from "../../../../../axios/axios";
import { logWarn } from "../../../../../utils/log";

export function usePostProjectEdit(formik) {
  const [ respDataProjectEdit, setRespDataProjectEdit] = React.useState(null);

  const onPostProjectEdit = async (id) => {

    if (!id) {
      return null
    }

    try {
      const { data } = await axios.post('/api/project/edit', {id});

      setRespDataProjectEdit(data);
      formik.setStatus('edit');
      formik.setValues(data.data);
    
    } catch (e) {
      setRespDataProjectEdit(e.data);

      logWarn({
        errorNumber: "1685084117527",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    respDataProjectEdit,
    onPostProjectEdit
  }
}
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export function usePostAdditionalControls({
  hazard_id,
  formik,
  onShowAdditionalControls,
  onPostEvaluationcardAdditionaledit,
  onPostProjectHazard,
  commonHazards
}) {

  const onPostEvaluationcardAdditionalsave = async () => {

    try {
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/${commonHazards ? 'additionalprojectsave' : 'additionalsave'}`, {
        hazard_id,
        measures: formik?.values?.data?.map(el => ({id: el.id}))
      });

      if (data?.status === 'success') {
        onPostProjectHazard();
        onPostEvaluationcardAdditionaledit(hazard_id);
        onShowAdditionalControls.off();
        toast['success'](data?.msg);
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    formik,
    onPostEvaluationcardAdditionalsave
  };
}

import React, { useEffect, useState} from "react";
import { Button, Col, Input, Row, Select } from 'antd';

let ddd = [];
//let typical = [];
export default function Data(e)
{
  //var ddd;
  const [typical, setTypical] = useState();
  const [newTyp, setNewTyp] = useState();
  const [typ, setTyp] = useState();
//console.log(e.id);

const typone = async () => { await fetch('/api/project/fillrisk/typical', {
  method: 'POST',
  body: JSON.stringify({
    id: e.id
  })
}).then((response) => {
  return response.json();
}).then((data) => {
  //console.log(data.data)
  setTypical(data.data);
  setNewTyp(data.data.id);
})}


const typicList = async () => {await fetch('/api/project/fillrisk/typicallist', {
  method: 'POST'
})
.then((response) => {
  return response.json();
}).then((data) => {
  //console.log(data.data);
  //ddd = data.data;
  setTyp(data.data);

})
}
 useEffect(() => {
  typicList();
  typone();
  }, []);
//console.log(typical ? typical.id : "1")

const handleChange = (value) => {
    setNewTyp(value);
    //console.log(value);
}
const saveTyp = async () => {
  await fetch('/api/project/fillrisk/savenewtyp',
    {
      method: "POST",
      body: JSON.stringify({
        workplace: e.id,
        typical: newTyp
      })
    }
  )
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    console.log(data);
  })

  console.log(typical);
  e.ok(typical.id);

}

  if(e.type === 1){
    return (

      <div>
      <br/><br />
      <Row>
        <Col span={16}>
          <Input value={typical ? typical.name : ""} disabled={true}></Input>
        </Col>
        <Col span={2} />
        <Col span={6} align={'right'}>
          <Button
            onClick={() => e.ok(newTyp)}
          >
            Сохранить
          </Button>
        </Col>
      </Row>
      <br />
      <h6>Выбрать профессию из "Базы знаний АИС" </h6>

      <Select
        margin-top={50}
        showSearch
        style={{ width: '100%', 'margin-top': '15px', 'margin-bottom': '15px'}}
        placeholder="Search to Select"
        value={newTyp}
        optionFilterProp="label"
        onChange={handleChange}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={typ}
      />
      </div>
    )
  }else if(e.type === 2){
    return(
      <div>
        <br /><br />
        Раздел в стадии разработки
      </div>
    )
  }
}


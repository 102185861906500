import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';
import Text from '../../../atoms/text';

export const P = styled(Text)`
  margin-top: 19px;
  font-family: 'GolosTextWebVF', serif;
  font-size: 14px;
  font-weight: 600;
  width: 198px;
  text-align: right;
`;

export const UploadFileFlex = styled(Flex)`
  flex-direction: row;
`;

export const UploadFileLink = styled.a`
  font-family: 'GolosTextWebVF', serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${(props) => (props.disabled ? '#C8C6C5' : '#000')};
  display: flex;
  width: 198px;
  height: 84px;
  border: 1px dashed #d0d3d8;
  border-radius: 12px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;

  &:hover {
    color: ${(props) => (props.disabled ? '#C8C6C5' : '#000')};
  }
`;

export const Icon = styled.span`
  padding-bottom: 10px;
`;

export const UploadFileList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 169px;
`;

export const UploadFileListItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UploadFileListItem = styled.div`
  font-family: 'GolosTextWebVF', serif;
  font-size: 12px;
  color: #1e85eb;
  width: 120px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;
export const DeleteFileButton = styled(Button)`
  padding: 0;
  background-color: #fff;
`;

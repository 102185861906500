import React from "react";

import Button from "../../../atoms/nButton";
import Dialog from "../../../blocks/dialog";

import * as Styled from "./styled";

export default function ResetPassword({ formik, isRender, onCloseDialog }) {

  const dangerouslyHTML = `Мы отправили письмо для сброса пароля<br />
    по адресу ${formik?.values.email ?? ''}. <br />
    Перейдите по ссылке для сброса пароля в письме,<br />
    чтобы задать новый пароль.
  `
  return (
    <Dialog
      isRender={isRender}
      isClose={false}
      title={"Проверьте свою почту"}
      onClose={onCloseDialog}
    >
      <Styled.P
        tagName={"p"}
        fSize={16}
        align={"center"}
        fWeight={400}
        lHeight={22}
        dangerouslyHTML={dangerouslyHTML}
      />
      <Styled.Buttons direction={"row"} jContent={"center"}>
        <Button type={"submit"} variant={"secondary"} onClick={onCloseDialog}>
          Готово
        </Button>
      </Styled.Buttons>
    </Dialog>
  );
}

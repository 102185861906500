export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const PUT_DATA_SUCCESS = 'PUT_DATA_SUCCESS';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';

export const USERS_DATA_SUCCESS = 'USERS_DATA_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const POST_LNA = 'POST_LNA'; // добавление ЛНА 
export const GET_LNA = 'GET_LNA'; // вывод ЛНА после добавления 
export const PUT_LNA = 'PUT_LNA'; // редактирование конкретного ЛНА
export const ROLES_DATA_SUCCESS="ROLES_DATA_SUCCESS"
export const MATRIX_DATA_SUCCESS="MATRIX_DATA_SUCCESS"
import React from 'react';

import { useBoolean } from '../../../../../hooks';
import UploadFiles from '../../../../formik/forms/uploadFiles';
import InfoBlock from '../infoBlock';
import Ok01694Profession from '../ok01694Profession';
import SoutResults from '../soutResults';
import { Grid, InfoBlockText, Wrapper } from '../styled';
import * as Styled from '../styled';

const EvaluationCard = ({ formik, workplace, onPostRiskAssessmentOne, onRefetchProjectHazard }) => {
  const [showOk01694Professional, onShowOk01694Professional] = useBoolean(false);
  const [showSoutResults, onShowSoutResults] = useBoolean(false);


  return (
    <>
      <Wrapper>
        <Grid>
          <InfoBlock gridName="num" title="Индивидуальный номер" loading={formik.values.loading}>
            <InfoBlockText>{formik.values.data?.number}</InfoBlockText>
          </InfoBlock>
          <InfoBlock gridName="place" title="Наименование рабочего места" loading={formik.values.loading}>
            <InfoBlockText>{formik.values.data?.workplace}</InfoBlockText>
          </InfoBlock>
          <InfoBlock gridName="unit" title="Структурное подразделение" loading={formik.values.loading}>
            <InfoBlockText>{formik.values.data?.structure}</InfoBlockText>
          </InfoBlock>
          <InfoBlock gridName="ok" title="ОК-016 94" loading={formik.values.loading}>
            <Styled.InputTextInput onClick={onShowOk01694Professional.on}>
              {formik.values.data?.ok01694}
            </Styled.InputTextInput>
          </InfoBlock>
          {/*<InfoBlock*/}
          {/*  gridName="places"*/}
          {/*  title="Количество аналогичных мест"*/}
          {/*  loading={formik.values.loading}*/}
          {/*>*/}
          {/*  5*/}
          {/*</InfoBlock>*/}
          <InfoBlock gridName="result" title="Результаты СОУТ" loading={formik.values.loading}>
            <Styled.InputTextInput onClick={onShowSoutResults.on} decoration={'underline'}>
              Редактировать факторы СОУТ
            </Styled.InputTextInput>
          </InfoBlock>
        </Grid>
        <UploadFiles
          workplace={workplace}
          formik={formik}
          onPostRiskassessmentOne={onPostRiskAssessmentOne}
        />
      </Wrapper>
      <Ok01694Profession
        formik={formik}
        workplace={workplace}
        show={showOk01694Professional}
        onPostRiskassessmentOne={onPostRiskAssessmentOne}
        onShow={onShowOk01694Professional}
      />
      <SoutResults
        formik={formik}
        workplace={workplace}
        show={showSoutResults}
        onPostProjectHazard={onRefetchProjectHazard}
        onShow={onShowSoutResults}
      />
    </>
  );
};

export default EvaluationCard;

import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  font-size: inherit;
  margin: 0;
  background-color: #fff!important;
  border: 0px;
  outline: none;
  transition: color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s;
  font-family: 'GolosTextWebVF', serif;


  &::placeholder {
    color: #999;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &[data-autocompleted] {
     background-color: transparent !important;
  }

  &:disabled,
  &[disabled=disabled] {
    color: #A5A5A7;
  }

`;


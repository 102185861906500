import React from 'react';

import * as Styled from './svg.styled';

export default function Search({
  fill = "#C9C8D3",
  width = "14",
  height = "14",
  viewBox = "0 0 14 14"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3 0C9.77939 0 12.6 2.82061 12.6 6.3C12.6 7.78734 12.0846 9.15431 11.2226 10.232L13.795 12.805C14.0683 13.0784 14.0683 13.5216 13.795 13.795C13.5426 14.0473 13.1456 14.0667 12.871 13.8532L12.805 13.795L10.232 11.2226C9.15431 12.0846 7.78734 12.6 6.3 12.6C2.82061 12.6 0 9.77939 0 6.3C0 2.82061 2.82061 0 6.3 0ZM6.29844 1.40039C3.59224 1.40039 1.39844 3.5942 1.39844 6.30039C1.39844 9.00659 3.59224 11.2004 6.29844 11.2004C9.00463 11.2004 11.1984 9.00659 11.1984 6.30039C11.1984 3.5942 9.00463 1.40039 6.29844 1.40039Z"
        fill={fill}
      />
    </Styled.Svg>
  )
}
import React from "react";
import {NavLink} from "react-router-dom";

import List from '../../atoms/list';

import * as Styled from './styled';

export default function HeaderNav(props) {
  const {navMap} = props;
/*
  if (!navMap?.length) {
    return null
  }
*/
  return (
    <List>
      {navMap.map((el, index) =>
        <NavLink key={`${el.id}-${index}`} to={el.path}>
          {({ isActive }) => (
            <Styled.Link isActive={isActive ?? false}>
              {el.label}
            </Styled.Link>
          )}
        </NavLink>)}
    </List>
  );
}

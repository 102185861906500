function AppBoot() {
    console.info('' +
    '                             %c########\n' +
    '                     ########################\n' +
    '                 ################################\n' +
    '              ##############          ##############\n' +
    '            ##########                     ###########\n' +
    '   %c##%c       #######                           #########\n' +
    '  %c#######%c       #                                ########\n' +
    ' %c#############%c                                    ########\n' +
    ' %c#################%c                                 ########\n' +
    '%c######################%c                              ########\n' +
    '%c##########################%c                          ########\n' +
    '%c##########################%c    %c' + process.env.REACT_APP_VERSION?.padStart(17,' ') + '     %c########\n' +
    '%c######################%c                              ########\n' +
    ' %c#################%c                                 ########\n' +
    ' %c#############%c                                    ########\n' +
    '  %c#######%c       #                                ########\n' +
    '   %c##%c       #######                            #########\n' +
    '            ###########                    ###########\n' +
    '              ###############        ###############\n' +
    '                 ################################\n' +
    '                     ########################\n' +
    '                             ########',
    'color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);','color:initial;','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);',
    'color:rgb(217,32,32);','color:rgb(0,102,178);');
}

export default AppBoot;

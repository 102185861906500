import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  margin: 0;
  background: none;
  border: 0px;
  outline: none;
  transition: color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s;
  font-family: 'GolosTextWebVF', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding-right: 10px;

  &::placeholder {
    color: #999;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }
`;

export const Search = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid #18171C;
  border-radius: 8px;
  padding: ${props => props.padding ?? '10px 20px'};
  margin: ${props => props.margin ?? '0'};
  box-sizing: border-box;
  width: ${props => props.width ?? 'auto'};

  &:hover {
    border-color: #AAA;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: #014880;
  }
`

export const Icon = styled.div`
  padding-left: 10px;
`


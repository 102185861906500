import React from 'react';
import { Select } from 'antd';

import './styles.scss';

const AnalyticsHead = ({ loading, projectList, onSelect, onSubmit }) => {
  const option = projectList.map((el) => {
    return {
      value: el.id,
      label: el.name,
    };
  });

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className="analytics-head">
      <div className="analytics-head__block">
        <span className="analytics-head__block-label">Наименование проекта</span>
        <Select
          loading={loading}
          showSearch
          placeholder="Наименование проекта"
          size="large"
          options={option}
          filterOption={filterOption}
          onChange={onSelect}
        />
      </div>
    </div>
  );
};

export default AnalyticsHead;

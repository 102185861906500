import React from 'react';

import * as Styled from './svg.styled';

export default function EyeOn({
  fill = "#25282B",
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  ...styled
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      {...styled}
      fill={"none"}
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Svg>
  )
}
import styled from 'styled-components';

import Flex from '../../../atoms/flex';

export const Wrapper = styled(Flex)`
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 220px);
  grid-template-areas:
    'num place unit'
    'ok places result';
  row-gap: 36px;
  column-gap: 40px;
  padding: 20px;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  grid-area: ${(props) => props.gridName};
  align-items: center;
`;

export const InfoBlockTitle = styled.span`
  display: inline-flex;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  color: #000000;
  font-family: GolosTextWebVF, serif;
`;

export const InfoBlockText = styled.span`
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  color: #52575c;
  font-family: GolosTextWebVF, serif;
  text-align: center;
`;

export const InputTextInput = styled.div`
  border: 1px solid #18171c;
  border-radius: 8px;
  padding: 10px 20px;
  width: 100%;
  height: 42px;
  font-size: 14px;
  line-height: 24px;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  text-decoration: ${(props) => props?.decoration ?? 'none'};

  &:hover {
    border-color: #aaaaaa;
  }
`;

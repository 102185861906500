import React, { useMemo, useState } from 'react';
import { Button, Checkbox } from 'antd';

import Dialog from '../../../../../blocks/dialog';
import { useWorkType } from '../../hooks';

const WorkType = (props) => {
  const { show, onShow, workplace, refetchTable } = props;
  const [selected, setSelected] = useState([]);

  const { loading, data, onSaveWorkTypes } = useWorkType({ workplaceId: workplace });

  const onChange = (checkedValues) => {
    setSelected(checkedValues);
  };

  const options = useMemo(() => {
    return data?.map((el) => {
      return {
        label: el.name,
        value: el.id,
      };
    });
  }, [data]);

  const defaultChecked = useMemo(() => {
    return data?.filter((item) => item.check).map((el) => el.id);
  }, [data]);

  const onSubmit = async () => {
    await onSaveWorkTypes(selected);
    await refetchTable({ page: 1, pageSize: 50 }).then(() => {
      onShow.off();
      setSelected([]);
    });
  };

  return (
    <Dialog
      isRender={show}
      isClose={true}
      centered={true}
      onClose={onShow.off}
      title={'ДОБАВИТЬ ВИД РАБОТ'}
      width={800}
      destroyOnClose={true}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 20px',
        },
      }}
    >
      <div>
        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column' }}
          options={options}
          defaultValue={defaultChecked}
          onChange={onChange}
        ></Checkbox.Group>
        <div style={{ display: 'flex', marginTop: 12 }}>
          <Button
            size="large"
            style={{ marginLeft: 'auto', width: 170 }}
            type="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default WorkType;

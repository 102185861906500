import styled from 'styled-components';

export const Block = styled.a`
  display: flex;
  align-items: center;
  column-gap: 18px;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 15px;
  padding: 8px;
  border-radius: 25px;

  &:hover {
    cursor: pointer;
    background-color: rgba(30, 133, 235, 0.1);
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f6f6f7;
`;

export const Text = styled.span`
  font-family: 'Golos Text VF', sans-serif;
  font-size: 16px;
  line-height: 19.2px;
  color: #000000;
`;

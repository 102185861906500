import React from "react";
import { toast } from "react-toastify";

import axios from "../../../../../axios/axios";
import { logWarn } from "../../../../../utils/log";

export function usePostUserEdit({id, onPostUser}) {

  const [ respDataUserEdit, setRespDataUserEdit] = React.useState(null);

  const onPostUserDelete = async() => {

    try {
      const { data } = await axios.post('/api/user/view/check', {id});
      if (data.status === 'success') {
        toast['warn'](data?.msg);
        onPostUser()
      }
    } catch (e) {
      setRespDataUserEdit(e.data);

      logWarn({
        errorNumber: "1685378212184",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    respDataUserEdit,
    onPostUserDelete,
  }
}

export function usePostSendEmail({id, onPostUser}) {

  const [ respDataUserEdit, setRespDataUserEdit] = React.useState(null);

  const onPostUserSendEmail = async() => {

    try {
      // console.log('formik1', {id})
      const { data } = await axios.post('/api/user/view/email', {id});
      if (data.status === 'success') {
        toast['success']("Письмо отправлено!");
        onPostUser()
      }
    } catch (e) {
      setRespDataUserEdit(e.data);

      logWarn({
        errorNumber: "1685378212184",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    respDataUserEdit,
    onPostUserSendEmail
  }
}

import * as Styled from './styled';

export default function Text(props) {
  const {
    children,
    dangerouslyHTML,
    tagName: TagName,
    ...styled
  } = props;


  if (dangerouslyHTML) {
    return (
      <Styled.Text
        {...styled}
        as={TagName}
        dangerouslySetInnerHTML={{__html: dangerouslyHTML}}
      />
    )
  }

  return (
    <Styled.Text
      {...styled}
      as={TagName}
    >
      {children}
    </Styled.Text>
  )
}
import React from 'react';

import * as Cells from '../../../blocks/tableCells';

import * as Fields from './fields';

export const getTableRows = ({
  data,
  onPostEvaluationcardEdithazard,
  onPostEvaluationcardDelete,
  onShowAddDanger,
  onShowAdditionalControls,
  onPostEvaluationcardAdditionaledit,
  setRowId
}) => {

  if (!data?.length) {
    return [];
  }

  return data?.map((item, index) => {

    const contentMeasures = item?.measures?.length ? (
      <Fields.Measures
        measures={item?.measures}
        onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
        onRowId={setRowId}
        id={item?.id}
      />
    ) : (
      <Fields.AdditionalMeasures
        onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
        onRowId={setRowId}
        id={item?.id}
      />
    );

    const checkAdditionalMeasures = item?.additional_measures?.length ? (
      <Fields.MeasuresAdditional
        measures={item?.additional_measures}
        onShowAdditionalControls={onShowAdditionalControls}
        onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
        onRowId={setRowId}
        id={item?.id}
      />
    ) : (
      <Fields.AdditionalControls
        onShowAdditionalControls={onShowAdditionalControls}
        onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
        onRowId={setRowId}
        id={item?.id}
      />
    );

    return {
      key: index,
      cells: [
        {
          key: 'actions',
          content: (
            <Fields.ActionsRow
              onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
              onPostEvaluationcardDelete={onPostEvaluationcardDelete}
              onShowAddDanger={onShowAddDanger}
              onRowId={setRowId}
              id={item?.id}
            />
          ),
          align: 'center',
        },
        {
          key: 'source_danger',
          label: item?.source_danger,
          type: 'view',
          align: 'center',
        },
        {
          key: 'danger',
          label: item?.danger,
          type: 'view',
          align: 'center',
        },
        {
          key: 'probability_id',
          content: (
            <Cells.RiskLevel
              type={item?.probability_id}
            />
          ),
          align: 'center',
        },
        {
          key: 'status',
          content: (
            <Cells.Status
              type={item?.status}
            />
          ),
          align: 'center',
        },
        {
          key: 'measures',
          content: !item?.data?.length ? contentMeasures : null,
          align: 'center',
        },
        {
          key: 'additionalControls',
          content: !item?.data?.length ? checkAdditionalMeasures : null,
          align: 'center',
        }
      ],
    };
  });
};

export const tableHead = [
  {
    key: 'actions',
    label: '',
    width: 24,
    align: 'center',
  },
  {
    key: 'source_danger',
    label: 'Источник опасности',
    type: 'header',
    align: 'center',
  },
  {
    key: 'danger',
    label: 'Опасность',
    type: 'header',
    align: 'center',
  },
  {
    key: 'measures',
    label: 'Существующие меры <br /> управления',
    type: 'header',
    align: 'center',
  },
  {
    key: 'probability_id',
    label: 'Уровень риска',
    type: 'header',
    align: 'center',
  },
  {
    key: 'additionalControls',
    label: 'Дополнительные <br /> меры управления',
    type: 'header',
    align: 'center',
  },
];


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchEmployer } from "../../../../../store/actions/employersActions";
import { EMPLOYER_DATA_CLEAR } from "../../../../../store/actionTypes/employerTypes";
import { isExpertRole } from "../../../../../store/selectors/authReducer";
import Tabs from "../../../../tabs";

import GeneralInformation from "./Generalinformation/GeneralInformation";
import EditStructurePosition from "./EditStructurePosition";

import "./Modal.css";

const tabs = (props) => {
  const { notRenderTab, ...ownprops } = props;

  return [
    {
      title: "Добавить/Редактировать работодателя",
      children: <GeneralInformation {...ownprops} />,
    },
    {
      notRenderTab,
      title: "Редактировать структуры и должности",
      children: <EditStructurePosition {...ownprops} />,
    },
  ];
};

function ModalViewEmployer(props) {
  const { visible, setVisible, organizationId } = props;
  const dispatch = useDispatch();
  const hasExpertRole = useSelector((state) => isExpertRole(state.authReducer));

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchEmployer(organizationId));
    }
  }, [organizationId]);

  const renderTabs = tabs({ ...props, hasExpertRole });

  return (
    <div
      className={visible ? "myModal active" : "myModal"}
      onClick={() => {
        setVisible(false);
        dispatch({ type: EMPLOYER_DATA_CLEAR });
      }}
    >
      <div
        className="modal-content rounded-0 container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="form-modal  mt-50 mb-50 ">
          <button
            className="btn-close btn-blue"
            onClick={() => {
              setVisible(false);
              dispatch({ type: EMPLOYER_DATA_CLEAR });
            }}
          >
            <i className="bi bi-x ft-sz" style={{ paddingTop: "3px" }}></i>
            <span>Закрыть</span>
          </button>

          <Tabs tabs={renderTabs} />
        </div>
      </div>
    </div>
  );
}

export default ModalViewEmployer;

import React, { useEffect } from "react";

import packageJson from "../../../package.json";
import FontStyles from '../../assets/fonts/fontStyles';
import axios from "../../axios/axios";
import Router from "../Router";

import "../../assets/style/style.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    axios
      .get("/meta.json", { headers: { "Cache-Control": "no-cache" } })
      .then((meta) => {
        if (meta?.data?.version && meta.data.version !== packageJson.version) {
          window.location.reload(true);
        } else {
          console.warn("current client version = " + meta.data.version);
        }
      });
  }, []);

  return (
    <div className="app">
      {/*<ChangePasswordModal />*/}
      <FontStyles />
      <Router />
    </div>
  );
}

export default App;

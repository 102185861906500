import React from 'react'

function AppButton({children, ...props}) {
    return (
        <button {...props}>
            {children}
        </button>
    );
}

export default AppButton;

import React from "react";
import { Table } from "antd";
//import {Button} from "antd";

function TableData(props)
{




    return (
      <>

      <Table
        size="small"
        dataSource={props.dataSource}
        columns={props.columns}
        pagination={{
          pageSize: 50,
          showSizeChanger: false}}
      />
      </>
    )


}

export default TableData;

import React from 'react'

export default function NameIp(props) {
  const {
    name,
    ip
  } = props;

  return (
    <React.Fragment>
      {name}
      <div className="text-muted small">{ip}</div>
    </React.Fragment>
  )
};
import styled, { css } from 'styled-components';

import Flex from '../../../atoms/flex';
import Link from '../../../atoms/link';
import Button from '../../../atoms/nButton';
import Text from '../../../atoms/text';

export const Pagination = styled(Flex)`
  padding: 24px;
  justify-content: center;
`;

export const SectionHeadMiddle = styled(Flex)`
  padding: 0 0 25px;
`;

export const FlexConfirmation = styled(Flex)``;

export const FlexHeaderTopIn = styled(Flex)``;

export const TextConfirmation = styled(Text)`
  margin-bottom: 40px;
`;

export const ButtonSectionHead = styled(Button)`
  font-weight: 400;
  line-height: normal;
  height: 100%;
  padding: 3px;
  font-size: 10px;
  min-width: 170px;
  justify-content: center;
  width: 100%;
`;

export const Buttons = styled(Flex)`
  padding: 40px 0;
`;

export const ButtonNormal = styled(Button)`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  padding: 14px;
`;

export const ButtonLarge = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`;

export const FlexButtonSectionHead = styled(Flex)``;

export const HeaderTop = styled(Flex)`
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const HeaderTopItem = styled(Flex)`
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
`;

export const HeaderTopItem2 = styled(Flex)`
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: flex-start;
`;

export const TextHeaderTop = styled(Text)`
  font-size: 14px;
  line-height: normal;
  text-align: center;
  margin-bottom: 0;
  padding: ${(props) => props.padding ?? 0};

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const InputTextInput = styled.div`
  border: 1px solid #18171c;
  border-radius: 8px;
  padding: 10px 20px;
  height: 42px;
  font-size: 14px;
  line-height: 24px;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  text-decoration: ${(props) => props?.decoration ?? 'none'};

  &:hover {
    border-color: #aaaaaa;
  }
`;

export const ButtonAddDanger = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 33px;
  margin-right: 48px;
`;

export const ButtonConfirmAutomatic = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 33px;
`;

export const ButtonIconSmall = styled(Button)`
  font-size: 12px;
  line-heigth: 18px;
`;

export const ButtonIconSmalllabel = styled.span`
  padding-left: 5px;
`;

export const LinkPrint = styled(Link)`
  font-size: 12px;
  line-heigth: 18px;
  text-decoration: none;
  color: rgb(82, 87, 92);
`;

export const OkMsg = styled.div`
  font-family: GolosTextWebVF, serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: center;
  color: #aaa;
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  justify-content: center;
  height: 20px;
  padding: 10px;
  margin: 0 0 -30px;
`;

export const Icon = styled.div`
  height: 20px;
  width: 20px;
`;

export const OkMsgText = styled.div`
  width: 130px;
`;

export const DropdownLabel = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

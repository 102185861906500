import styled from 'styled-components';

import Flex from '../../../../../atoms/flex';

export const Buttons = styled(Flex)`
  padding: 24px 0;

`;
export const ParentArrow = styled.div`
  transform: rotate(${props => props.rotate}deg);
  height: 24px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Parent = styled.div`
  padding: 0;
  font-size: 12px;
  font-family: GolosTextWebVF, serif;
  color: #000;
  display: flex;
  align-items: center;
`;

export const Child = styled.span`
  padding: 0;
  font-size: 12px;
  font-family: GolosTextWebVF, serif;
  color: #000;
`;

export const Li = styled.li`
  padding-bottom: 10px;
`

import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: #000;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  min-height: 60px;
`;

export const MiddleTitle = styled.h3`
  font-family: Golos Text VF;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  color: #000;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  margin: 0;
  padding: 27px 0 16px 10px;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  padding: 0 0 0 10px;
  border:  2px solid ${props => props.active ? '#1E84EB' : 'transpаrent'};
`

export const LeftButton = styled.button`
  font-family: GolosTextWebVF, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  margin: 0;
  color: #101331;
`

export const AntdTable = styled.div`
  table {
    padding-top: 24px;
  }

  .ant-table-wrapper .ant-table-thead >tr>th,
  .ant-table-wrapper .ant-table-thead >tr>td {
    background: none !important;
  }

  .ant-table-thead {
    white-space: nowrap;
  }
`

export const EditButton = styled.button`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;

  & svg {
    fill: ${props => props.active ? '#1E84EB' : 'transpаrent'};
  }

`

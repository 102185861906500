import { Button } from 'antd';
import styled from 'styled-components';

export const EvaluationCardEx = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const FactorsNeedText = styled.span`
  display: inline-block;
  font-family: GolosTextWebVF, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #b00000;
  opacity: 0.75;
`;

export const EvaluationCardExActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 44px;
`;

export const ActionButton = styled(Button)`
  font-family: GolosTextWebVF, serif;
  font-size: 12px !important;
  line-height: 14.4px;
  letter-spacing: 0.1px;
  width: 198px;
`;

import React from "react";

import AddCircle from '../../../../assets/svg/addCircle';
import Delete from '../../../../assets/svg/delete';
import Flex from '../../../atoms/flex';

import * as Styled from './styled';

function ManagementMeasure(props) {
  const {
    onCurrentMeasures,
    id,
    index,
    onShowMeasures,
    name,
    nameField,
    formik,
    isDelete
  } = props;

  const {value} = formik?.getFieldProps(`${nameField}.${index}.visible`) ?? {};
  const onDelete = () => {
    formik?.setFieldTouched('data', true);
    formik?.setFieldValue('data', formik?.values?.data?.filter((_, idx ) => idx !== index));
  }

  return (
    <Flex
      widthItem={['calc(100% - 15px)']}
      aItems={'center'}
      wrap={'nowrap'}
    >
      <Styled.InputTextmeasure
        onClick={() => {
          onCurrentMeasures({
            index,
            id: id ?? null,
            visible: value,
            nameField
          })
          onShowMeasures.on();
        }}
      >
        {name}
      </Styled.InputTextmeasure>
        {isDelete ?
          <Styled.DeleteFieldsButton
            onClick={onDelete}
          >
            <Delete />
          </Styled.DeleteFieldsButton> : ''
        }
    </Flex>
  )
};

export function ManagementMeasures(props) {
  const {
    formik,
    onCurrentMeasures,
    onShowMeasures
  } = props;
  const {values, setFieldValue }  = formik ??  {} ;
  const {value: hazard_id} = formik?.getFieldProps('data.id') ?? {};

  const onAddPlusMeasures = () => {
    setFieldValue(`data.${values?.data?.length ?? 0}`, {
      name: '',
      id: null,
    });
  }

  return (
    <React.Fragment>
      <Styled.AddFieldsButton
        varialt={'link'}
        onClick={onAddPlusMeasures}
      >
        Дополнительные меры управления
        <Styled.IconCircle>
          <AddCircle  />
        </Styled.IconCircle>
      </Styled.AddFieldsButton>
        {values?.data?.map((el, index) =>
          <ManagementMeasure
            key={index}
            index={index}
            id={el?.id}
            formik={formik}
            isDelete={true}
            onCurrentMeasures={onCurrentMeasures}
            onShowMeasures={onShowMeasures}
            name={el?.name}
            hazard_id={hazard_id}
            nameField={'data'}
          />
        )}
      </React.Fragment>
    )
}

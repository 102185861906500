import React from 'react';

import * as hooks from '../../../../../hooks';
import Dialog from '../../../../blocks/dialog';
import Portal from '../../../../blocks/portal';
import Tabs from '../../../../blocks/tabs';
import { useFormikContext } from '../../../../formik/formik.context';
import LegalAddress from '../../../../formik/forms/legalAddress';

import { getTabs } from './helpers';
import * as Styled from './styled';

const { useBoolean } = hooks;

export default function EditRow(props) {
  const { respDataProjectEdit, isShowInfoOrganization, onPostProject } = props;
  const [showInfoOrganization, onShowInfoOrganization] = isShowInfoOrganization ?? [];
  const [showLegalAddress, onShowLegalAddress] = useBoolean(false);
  const formikGrade = useFormikContext();

  React.useEffect(() => {
    if (respDataProjectEdit?.status === 'success') {
      onShowInfoOrganization.on();
    }
  }, [respDataProjectEdit]);

  const onClickShowLegalAddress = () => {
    onShowInfoOrganization.off();
    formikGrade.setFieldValue('prevProject', formikGrade?.values?.project);
    onShowLegalAddress.on();
  };

  const onSaveAdress = () => {
    onShowInfoOrganization.on();
    onShowLegalAddress.off();
  };

  const closeLegalAddress = () => {
    formikGrade.setFieldValue('project', formikGrade?.values?.prevProject);
    onShowInfoOrganization.on();
    onShowLegalAddress.off();
  };

  const onCloseLegalAddress = () => {
    onShowInfoOrganization.off();
  };

  const onCloseGeneralInfo = () => {
    onShowInfoOrganization?.off();
    onPostProject();
  };

  const tabs = getTabs({
    formikGrade,
    onCloseGeneralInfo,
    onPostProject,
    onClickShowLegalAddress,
  });

  return (
    <React.Fragment>
      <form>
        <Portal id={'pageGraede'}>
          <Styled.EditDialog
            isRender={showInfoOrganization}
            isClose={true}
            title={'ОРГАНИЗАЦИЯ'}
            onClose={onCloseLegalAddress}
            innerTop={'20px'}
            width={1400}
            settings={{
              title: {
                size: 14,
                fWeight: 600,
                lHeight: 17,
                align: 'left',
                margin: '20px -20px 17px',
              },
              close: {
                height: 20,
                width: 20,
                right: '8px',
                top: '8px',
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between'
              }}
            >
              <Tabs
                tabs={tabs}
                flex={'inline-flex'}
              />
            </div>
          </Styled.EditDialog>
        </Portal>
        <Portal id={'legalAddress'}>
          <Dialog
            isRender={showLegalAddress}
            isClose={true}
            onClose={closeLegalAddress}
            title={'ЮРИДИЧЕСКИЙ АДРЕС'}
            settings={{
              title: {
                size: 14,
                fWeight: 600,
                lHeight: 17,
                align: 'left',
                margin: '20px -20px 53px',
              },
              close: {
                height: 20,
                width: 20,
                right: '8px',
                top: '8px',
              },
            }}
          >
            <form>
              <LegalAddress formik={formikGrade} onClose={onSaveAdress} />
            </form>
          </Dialog>
        </Portal>
      </form>
    </React.Fragment>
  );
}

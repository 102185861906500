import React from 'react';
import { Radio, Skeleton, Space } from 'antd';

import './styles.scss';

export const DashboardTraining = (props) => {
  const { title, loading, values } = props;

  const [value, setValue] = React.useState(null);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const valueCnt = values?.items?.find((el) => el.id === value);

  return (
    <div className="info-training">
      <span className="info-training__title">{title}</span>
      <div className="info-training__wrap">
        {loading ? <Skeleton.Button active /> : <span className="info-training__val">{valueCnt?.cnt}</span>}
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {values?.items?.map((el) => (
              <Radio value={el?.id}>{el?.name}</Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};

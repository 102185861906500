import styled from 'styled-components';

export const StyledIsNeedApprove = styled.label`
  label,
  .checkboxField[type='checkbox'] + span {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  .checkboxField[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + span:before {
    border: 1px solid rgba(0, 0, 0, 0.25);
    content: '';
    display: inline-block;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }

  .checkboxField[type='checkbox']:checked + span:before {
    background: #fff;
    border: 4px solid
      ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : '#0d6efd')};
    box-shadow: inset 0px 0px 0px 1px #fff;
    color: #333;
    content: '';
    text-align: center;
    border-radius: 50%;
  }

  .checkboxField[type='checkbox'] + span:hover::before {
    border-color: ${({ disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.25)' : '#86b7fe'};
    outline: 0;
    box-shadow: 0 0 0 0.25rem
      ${({ disabled }) => (disabled ? '#fff' : 'rgb(13 110 253 / 25%)')};
  }
`;

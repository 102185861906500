import React from "react";

import HeaderAdmin from "../../blocks/headerAdmin";

import UsersLogs from "./UsersLogs/UsersLogs";
import {navMap} from './navMap';
function AdministrationUserLogs() {
  return (
    <>
      <HeaderAdmin navMap={navMap} />
      <main>
        <UsersLogs />
      </main>
    </>
  );
}

export default AdministrationUserLogs;

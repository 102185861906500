import React from 'react';
import { toast } from "react-toastify";
import { useFormik } from "formik";

import axios from "../../../../axios/axios";
import { logWarn } from "../../../../utils/log";

export function usePostProjectWorkplace({
  project,
  workplace,
  onShowAproveCopyMap,
  onShowCopyMap,
  onIsRenderSpinner,
  setExpandedKeys
}) {

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      workplaces: [],
      curentWorkplaceIds: [],
    },
  });

  React.useEffect(() => {
    onPostProjectWorkplace();
  }, [])

  const onPostProjectWorkplace = async () => {

    try {
      const { data } = await axios.post(`/api/project/workplace`, {
        project,
        workplaceId: workplace
      });

      if (data?.data) {
        formik?.setFieldValue('workplaces', data?.data);
      }

    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  const onCopyMap = async () => {
    try {
      onIsRenderSpinner.on();

      const response = await axios.post(`/api/project/copy/`, JSON.stringify({
          src : workplace,
          dst : formik?.values?.curentWorkplaceIds
        }), {
        headers: {
          'Content-Length': 0,
          'Content-Type': 'text/plain'
        },
        responseType: 'text'
      });


      if (response?.status === 200) {
        toast['success'](response?.data?.Status);
        onIsRenderSpinner.off();
      }

    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);
      onIsRenderSpinner.off();

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    } finally {
      setExpandedKeys([]);
      onShowAproveCopyMap.off();
      onShowCopyMap.off();
    }
  }

  const onWorkplaceIds = (checkedValues) => {
    formik?.setFieldValue('curentWorkplaceIds', checkedValues)
  };

  return {
    formik,
    workplaces: formik?.values?.workplaces,
    onCopyMap,
    onWorkplaceIds,
  }
}

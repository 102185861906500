import React, { memo } from 'react';
import { Button } from 'antd';

import { cx } from '../../../../../utils';

const TypeButton = ({ label, type, currentType, callback }) => {
  const onClick = () => callback(type);
  return (
    <Button size="small" type="text" className={cx({ 'active-type': type === currentType })} onClick={onClick}>
      {label}
    </Button>
  );
};

export default memo(TypeButton);

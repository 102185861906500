import React from 'react';

import * as Styled from './svg.styled';

export default function Delete({
  fill = "#1B263B",
  width = "15",
  height = "15",
  viewBox = "0 0 15 15"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M10.4307 14.0586H4.56836C3.55908 14.0586 2.7373 13.2368 2.7373 12.2275V5.79395C2.7373 5.5918 2.90137 5.42773 3.10352 5.42773C3.30566 5.42773 3.46973 5.5918 3.46973 5.79395V12.2275C3.46973 12.834 3.96191 13.3262 4.56836 13.3262H10.4292C11.0356 13.3262 11.5278 12.834 11.5278 12.2275V5.79395C11.5278 5.5918 11.6919 5.42773 11.894 5.42773C12.0962 5.42773 12.2603 5.5918 12.2603 5.79395V12.2275C12.2617 13.2368 11.4399 14.0586 10.4307 14.0586ZM13.2109 4.05664H1.78809C1.58594 4.05664 1.42188 3.89258 1.42188 3.69043C1.42188 3.48828 1.58594 3.32422 1.78809 3.32422H13.2109C13.4131 3.32422 13.5771 3.48828 13.5771 3.69043C13.5771 3.89258 13.4131 4.05664 13.2109 4.05664Z"
        fill={fill}
      />
      <path
        d="M6.07617 11.4224C5.87402 11.4224 5.70996 11.2583 5.70996 11.0562V5.78711C5.70996 5.58496 5.87402 5.4209 6.07617 5.4209C6.27832 5.4209 6.44238 5.58496 6.44238 5.78711V11.0562C6.44238 11.2583 6.27832 11.4224 6.07617 11.4224ZM8.9458 11.4224C8.74365 11.4224 8.57959 11.2583 8.57959 11.0562V5.78711C8.57959 5.58496 8.74365 5.4209 8.9458 5.4209C9.14795 5.4209 9.31201 5.58496 9.31201 5.78711V11.0562C9.31201 11.2583 9.14795 11.4224 8.9458 11.4224ZM11.4609 3.61328H10.7285V2.77246C10.7285 2.16602 10.2363 1.67383 9.62988 1.67383H5.37012C4.76367 1.67383 4.27148 2.16602 4.27148 2.77246V3.54883H3.53906V2.77246C3.53906 1.76318 4.36084 0.941406 5.37012 0.941406H9.63135C10.6406 0.941406 11.4624 1.76318 11.4624 2.77246V3.61328H11.4609Z"
        fill={fill}
      />
    </Styled.Svg>
  )
}
import React from 'react';

import ErrorClose from '../../../../../../assets/svg/errorClose';

import * as Styled from './styled';

export default function ChairmanInput(props) {

  const {
    name,
    formik,
    label,
    type = 'text',
    placeholder,
    margin,
    disabled,
    isError = true,
    required,
    autoComplete,
    styled
  } = props;

  const {setFieldValue} = formik ?? {};
  const {value} = formik?.getFieldProps(name) ?? {};
  const {error, touched} = formik?.getFieldMeta(name) ?? {};
  const {setTouched} = formik?.getFieldHelpers(name) ?? {};

  const handleChange = (e) => {
    setFieldValue('isChief.isChief', true);
    setFieldValue(name, e.target.value);
  }

  const handleBlur = () => {
    setTouched(true)
  }

  return (
    <Styled.Field
      margin={margin}
    >
      {label ? <Styled.FieldLabel
        tagName={'label'}
        fSize={styled?.label?.fSize ?? 16}
        lHeight={styled?.label?.lHeight}
        fWeight={styled?.label?.fWeight ?? 500}
        padding={styled?.label?.padding}
      >{label}</Styled.FieldLabel> : ''}
      <Styled.InputText
        fSize={styled?.input?.fSize}
        lHeight={styled?.input?.lHeight}
        padding={styled?.input?.padding}
        type={type}
        value={value}
        required={required}
        placeholder={placeholder}
        autoComplete={autoComplete}
        fWeight={400}
        error={error && touched}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
      {isError && error && touched ? <Styled.Valide>
        <ErrorClose />
        <Styled.ValideMessage
          tagName={'span'}
          fSize={10}
          lHeight={10}
          fWeight={400}
        >
          {error}
        </Styled.ValideMessage>
      </Styled.Valide> : ''}
    </Styled.Field>
  );
}
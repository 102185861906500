import React from "react";

import HeaderAdmin from "../../blocks/headerAdmin";

import {navMap} from "./navMap"
function AdministrationActions() {
  return (
    <>
      <HeaderAdmin navMap={navMap} />
      <main />
    </>
  );
}

export default AdministrationActions;

import { combineReducers } from 'redux'

import { authReducer } from './authReducer';
import { employersReducer } from './employersReducer';
import { expertOrganizationReducer } from './expertOrganizationReducer'
import { gradeReducer } from "./gradeReducer"
import { LNAReducer } from './LNAReducer';
import {matrixReducer} from "./matrixReducer"
import {measureReducer} from "./measureReducer";
import { NSIReducer } from "./NSIReducer";
import {projectReducer} from "./projectReducer"
import {roleReducer} from "./rolereducer";
import { sheduleWorksReducer } from "./sheduleWorksReducer";
import { usersReducer } from './usersReducer';
const rootReducer = combineReducers({
  usersReducer,
  authReducer,
  employersReducer,
  expertOrganizationReducer,
  LNAReducer,
  NSIReducer,
  projectReducer,
  gradeReducer,
  sheduleWorksReducer,
  roleReducer,
  measureReducer,
  matrixReducer
})

export default rootReducer

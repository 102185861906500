import React, { useEffect, useMemo, useState } from 'react';

import { getArrMap, getExpandedKeys } from '../../../../../../utils';
import InputSearch from '../../../../../atoms/search';
import Dialog from '../../../../../blocks/dialog';
import RecursionList from '../recursionList';

import { usePostReferenceWorkplace } from './hooks';

export default function SourceDanger(props) {
  const [search, setSearch] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const { formik, show, onShow } = props;
  const { referenceWorkPlace } = usePostReferenceWorkplace();

  const arrMap = useMemo(() => getArrMap(referenceWorkPlace), [referenceWorkPlace]);

  const handleChangeSearch = (event) => {
    const text = event.target.value;

    if (text.length > 2) {
      const { newExpandedKeys } = getExpandedKeys(arrMap, text);
      setExpandedKeys(newExpandedKeys);
    } else {
      setExpandedKeys([]);
    }

    setSearch(event.target.value);
  };

  useEffect(() => {
    if (!show) {
      setSearch('');
    }
  }, [show]);

  return (
    <Dialog
      isRender={show}
      isClose={true}
      centered={false}
      onClose={onShow.off}
      title={'ИСТОЧНИК ОПАСНОСТИ'}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 20px',
        },
        close: {
          height: 20,
          width: 20,
          right: '8px',
          top: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '12px' }}>
        <InputSearch width={'225px'} placeholder={'Поиск'} onChange={handleChangeSearch} />
        <RecursionList
          fieldValue="data.source_danger"
          search={search}
          formik={formik}
          arr={referenceWorkPlace}
          onShow={onShow}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
        />
      </div>
    </Dialog>
  );
}

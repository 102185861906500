
export const navMap = [
    {
        id: '1',
        path: '/management/results',
        label: 'Управление результатами ПР',
    },
    // {
    //     id: '2',
    //     path: '/management/events',
    //     label: 'Мероприятия',
    // }
]

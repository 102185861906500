import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import Dialog from '../../../blocks/dialog';

import { usePostRegistration } from './hooks';

import './styles.scss';

export default function Registration() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { loading, onPostRegistration } = usePostRegistration();
  const handleClose = () => navigate('/');

  const onFinish = async (values) => {
    await onPostRegistration(values);
  };

  // if (status === 'success') {
  //   return (
  //     <Dialog
  //       isRender={true}
  //       isClose={true}
  //       title={`Подтвердите свой адрес <br />
  //         электронной почты`}
  //       onClose={handleClose}
  //     >
  //       <Styled.P
  //         tagName={'p'}
  //         fSize={16}
  //         align={'center'}
  //         fWeight={400}
  //         lHeight={22}
  //         dangerouslyHTML={`Для создания Вашей учетной записи остался всего <br />
  //           один шаг. Мы хотим убедиться, что Вы указали <br />
  //           правильный адрес электронной почты, поэтому <br />
  //           просим его подтвердить. <br /> <br />
  //           Спасибо! <br />
  //           Команда АИС “Лаборатория риска”`}
  //       />
  //     </Dialog>
  //   );
  // }

  return (
    <Dialog isRender={true} isClose={true} centered={true} title={'Регистрация'} onClose={handleClose}>
      <Form
        className="registration-form"
        form={form}
        requiredMark={false}
        layout="vertical"
        name="registration"
        onFinish={onFinish}
      >
        <Form.Item
          name="userName"
          rules={[
            {
              required: true,
              pattern: /^[a-zA-Z][0-9a-zA-Z]{5,19}$/,
              message:
                'Логин должен состоять не менее чем из 6 и не более чем из 20 символов; можно использовать латинские буквы, цифры; спецсимволы недопустимы',
            },
          ]}
        >
          <Input placeholder="Логин" size="large" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Введите адрес электронной почты!',
            },
            {
              required: true,
              message: 'Введите адрес электронной почты!',
            },
          ]}
        >
          <Input placeholder="Адрес электронной почты" size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              pattern: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-z])(?=.*[#$@!%&*?]).*$/,
              message:
                'Пароль должен содержать 8 и более символов, прописные латинские буквы, строчные латинские буквы, цифры и спецсимволы',
            },
          ]}
        >
          <Input.Password placeholder="Пароль" size="large" />
        </Form.Item>

        <Button size="large" type="primary" htmlType="submit" loading={loading} disabled={loading}>
          Отправить
        </Button>
      </Form>
    </Dialog>
  );
}

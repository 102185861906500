import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Pencil from '../../../../assets/svg/pencil';
import axios from '../../../../axios/axios';
import { useBoolean } from '../../../../hooks';
import { changeEmailAction } from '../../../../store/actions/authActions';
import Flex from '../../../atoms/flex';
import Link from '../../../atoms/link';
import Dialog from '../../../blocks/dialog';
import FieldInput from '../../fields/input';

import Status from './status';
import * as Styled from './styled';

export default function ChangeEmail(props) {
  const [loading, onLoading] = useBoolean(false);
  const { isRender, verifyEmail, userName, userEmail, onShowBlock } = props;

  const [isEditEmail, setIsEditEmail] = React.useState(false);
  const [recoveryEmail, setRecoveryEmail] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      userEmail: yup.string().email('Введите существующий адрес электронной почты').required(''),
    }),
    initialValues: {
      userEmail: userEmail,
    },
    validateOnBlur: true,
    validateOnChange: true,
  });

  const isError = formik.errors.userEmail;
  const email = formik.values.userEmail;

  const handleEdit = () => {
    if (isError) {
      return null;
    }

    setIsEditEmail((prevState) => !prevState);
  };

  const handleChangePassword = () => {
    onShowBlock('password');
  };

  const onCancel = () => {
    setIsEditEmail(false);
    setRecoveryEmail(null);
    formik.setFieldValue('userEmail', userEmail);
  };

  const onSecurity = async () => {
    onLoading.on();
    try {
      const { data } = await axios.post('/api/user/view/security');
      if (data?.data) {
        dispatch(changeEmailAction(data?.data));
        onLoading.off();
      }
    } catch (e) {
      onLoading.off();
    }
  };

  useEffect(() => {
    if (isRender) {
      onSecurity();
    }
  }, [isRender]);

  const onRecoveryEmail = async () => {
    onLoading.on();
    try {
      const { data } = await axios.post('/api/user/recovery/changemail', { email: email });

      setRecoveryEmail(data);
      onCancel();
      toast['success']('Адрес электронной почты изменен');
      dispatch(changeEmailAction(data.data));
      onShowBlock(null);
      onLoading.off();
    } catch (e) {
      setRecoveryEmail(e.data);
      onLoading.off();
    }
  };

  const handleClose = () => navigate('/');

  return (
    <Dialog
      isRender={isRender}
      isClose={true}
      title={'НАСТРОЙКИ БЕЗОПАСНОСТИ'}
      onClose={handleClose}
      padding={'0 34px 50px'}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '2px -20px 53px',
        },
        close: {
          height: 20,
          width: 20,
          right: '8px',
          top: '8px',
        },
      }}
    >
      <form>
        <Flex direction={'row'} aItems={'flex-end'} jContent={'space-between'} widthItem={['calc(100% - 64px)']}>
          <FieldInput
            label={'Адрес электронной почты'}
            placeholder={'examples@vcot.info'}
            formik={formik}
            name={'userEmail'}
            disabled={!isEditEmail}
            isError={false}
          />
          <Styled.ButtonPencil
            isError={(isError || recoveryEmail?.status === 'error') && !email}
            disabled={isEditEmail}
            onClick={handleEdit}
          >
            <Pencil />
          </Styled.ButtonPencil>
        </Flex>
        <Status
          loading={loading}
          verifyEmail={verifyEmail}
          userEmail={email}
          userName={userName}
          onClose={handleClose}
          isEditEmail={isEditEmail}
          recoveryEmail={recoveryEmail}
          onRecoveryEmail={onRecoveryEmail}
          onResetFormik={formik.setFieldValue}
          onCancel={onCancel}
          isError={isError || !email}
        />
        <Styled.ChangePasswordH5>Пароль</Styled.ChangePasswordH5>
        <Styled.ChangePassword>
          <Link onLink={handleChangePassword}>Изменить пароль.</Link> Повысьте безопасность, выбрав более надежный
          пароль.
        </Styled.ChangePassword>
      </form>
    </Dialog>
  );
}

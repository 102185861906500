import * as fp from "../../fp";

const { pipe, curryFind, curryFilter, ifArray } = fp;

export const simpleSearch = (data = false, searchId) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === searchId) {
      return data[i];
    }
  }
};

export const treeSearch = (data = false, searchId) => {
  let res = false;

  for (let i = 0; i < data.length; i++) {
    if (data[i].value.id === searchId) {
      return data[i].value;
    }

    if (data[i].children.length > 0) {
      if ((res = treeSearch(data[i].children, searchId))) {
        return res;
      }
    }
  }
};

export const treeSearchStructure = (data = false, searchId) => {
  let res = false;

  for (let i = 0; i < data.length; i++) {
    if (data[i].value.id === searchId) {
      return data[i];
    }

    if (data[i].children.length > 0) {
      if ((res = treeSearchStructure(data[i].children, searchId))) {
        return res;
      }
    }
  }
};

function leaveChildren(obj) {
  return obj?.children;
}

export const treeCodeCloser = (data, searchCode) => {
  const [first, second] = searchCode?.split(".");
  const code = [first, second].join(".");

  const findCode = pipe(
    curryFind(({ value }) => value?.code === first),
    leaveChildren,
    curryFilter(({ value }) => value?.code === code)
  )(data);

  return ifArray(findCode);
};

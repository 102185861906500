import React, { memo } from 'react';
import { DeleteOutlined, EditOutlined, MenuOutlined, WarningOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Table, Tooltip } from 'antd';

import * as Cells from '../../../../blocks/tableCells';
import * as Fields from '../fields';
import * as Styled from '../styled';

const TableHazardAssessment = ({
  loading,
  data,
  setRowId,
  onShowAdditionalControls,
  onPostEvaluationcardEdithazard,
  onPostEvaluationcardAdditionaledit,
  onPostEvaluationcardDelete,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const items = [
    {
      key: 'edit',
      label: (
        <Styled.DropdownLabel>
          <EditOutlined />
          <span>Редактировать</span>
        </Styled.DropdownLabel>
      ),
    },
    {
      key: 'remove',
      label: (
        <Styled.DropdownLabel>
          <DeleteOutlined style={{ color: '#D73C1D' }} />
          <span>Удалить</span>
        </Styled.DropdownLabel>
      ),
    },
  ];

  const onClickActions = (item, recordId) => {
    if (item.key === 'edit') {
      onPostEvaluationcardEdithazard(recordId);
      setRowId(recordId);
    }
    if (item.key === 'remove') {
      modal.confirm({
        title: 'Вы действительно хотите удалить данную запись?',
        icon: <WarningOutlined style={{ color: '#D73C1D' }} />,
        okText: 'Подтвердить',
        cancelText: 'Отменить',
        onOk: () => onPostEvaluationcardDelete(recordId),
        confirmLoading: true,
      });
    }
  };

  const columns = [
    {
      key: 'data',
      title: '',
      render: (record) => {
        const hasChildren = !!record.data;
        if (hasChildren) {
          return null;
        }
        return (
          <Dropdown trigger="click" menu={{ items, onClick: (item) => onClickActions(item, record.id) }}>
            <MenuOutlined />
          </Dropdown>
        );
      },
    },
    {
      key: 'danger',
      title: 'Опасность',
      align: 'center',
      render: (record) => {
        return <Tooltip title={record.parent_event || record.event}>{record.danger || record.parent_danger}</Tooltip>;
      },
    },
    {
      key: 'work_place',
      title: 'Место выполнения работ',
      align: 'center',
      render: (record) => record.work_place || record.parent_place,
    },
    {
      key: 'work_type',
      title: 'Вид работ/НАС',
      align: 'center',
      render: (record) => record.work_type || record.parent_type,
    },
    {
      key: 'source_danger',
      title: 'Источник опасности',
      align: 'center',
      render: (record) => record.source_danger || record.parent_source,
    },
    {
      key: 'measures',
      title: 'Существующие меры управления',
      align: 'center',
      render: (record) => {
        const hasChildren = !!record.data;
        if (hasChildren) {
          return null;
        }

        return record?.measures?.length ? (
          <Fields.Measures
            measures={record?.measures}
            onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
            onRowId={setRowId}
            id={record?.id}
          />
        ) : (
          <Fields.AdditionalMeasures
            onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
            onRowId={setRowId}
            id={record?.id}
          />
        );
      },
    },
    {
      key: 'probability_id',
      title: 'Уровень риска',
      align: 'center',
      render: (record) => <Cells.RiskLevel type={record?.probability_id} />,
    },
    {
      key: 'additionalControls',
      title: 'Дополнительные меры управления',
      align: 'center',
      render: (record) => {
        const hasChildren = !!record.data;

        if (hasChildren) {
          return null;
        }

        return record?.additional_measures?.length ? (
          <Fields.MeasuresAdditional
            measures={record?.additional_measures}
            onShowAdditionalControls={onShowAdditionalControls}
            onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
            onRowId={setRowId}
            id={record?.id}
          />
        ) : (
          <Fields.AdditionalControls
            onShowAdditionalControls={onShowAdditionalControls}
            onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
            onRowId={setRowId}
            id={record?.id}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record.id || record.parentId}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      {contextHolder}
    </>
  );
};

export default memo(TableHazardAssessment);

import React from 'react';

import ErrorClose from '../../../../assets/svg/errorClose';
import Warning from '../../../../assets/svg/warning';

import * as Styled from './styled';

export default function FieldAutoComplete(props) {
  const {
    name,
    formik,
    label,
    margin,
    isError = true,
    isWarning,
    options,
    onChange,
    onSearch,
    onSelect,
    styled,
  } = props;
  const { value } = formik?.getFieldProps(name) ?? {};
  const { error, touched } = formik?.getFieldMeta(name) ?? {};
  const { setTouched } = formik?.getFieldHelpers(name) ?? {};

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <Styled.Field margin={margin}>
      {label ? (
        <Styled.FieldLabel
          tagName={'label'}
          fSize={styled?.label?.fSize ?? 16}
          lHeight={styled?.label?.lHeight}
          fWeight={styled?.label?.fWeight ?? 500}
          padding={styled?.label?.padding}
        >
          {label}
        </Styled.FieldLabel>
      ) : (
        ''
      )}
      <Styled.AutoComplete
        size="large"
        value={value}
        options={options}
        onSelect={(val, option) => onSelect(val, option)}
        onChange={onChange}
        onBlur={handleBlur}
        onSearch={onSearch}
        filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      />
      {isError && error && touched ? (
        <Styled.Valide>
          <ErrorClose />
          <Styled.ValideMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            {error}
          </Styled.ValideMessage>
        </Styled.Valide>
      ) : (
        ''
      )}
      {isWarning && error && touched ? (
        <Styled.Valide>
          <Warning />
          <Styled.ValideMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            {error}
          </Styled.ValideMessage>
        </Styled.Valide>
      ) : (
        ''
      )}
    </Styled.Field>
  );
}

import * as actionTypes from '../actionTypes/actionTypes';
import {AUTH_LOGOUT} from "../actionTypes/loginTypes";

export const initialState = {
  list: null,
  user: {
    id:	null,
    userName: null,
    email: null,
    isEmailVerified: true,
    isApproved:	true,
    isLockedOut: false,
    lastLoggedOn: null,
    lastPasswordChanged: null,
    lastLockedOut: null,
    failedLoginAttemptCount: null,
    photo: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
    fio:"",
    roles:	[{
      "0": {
        "id": "4570c2ea-404b-4bf3-a8b6-7684aa3f72a8",
        "roleName": "admin-role",
        "displayName": "Администратор системы",
        "description": "Главная роль в системе",
        "created_at": "2022-06-15 08:53:43",
        "updated_at": null,
        "deleted_at": null,
        "pivot": {
          "userId": "0bcfafc3-7a00-450c-9995-41384505dd18",
          "roleId": "4570c2ea-404b-4bf3-a8b6-7684aa3f72a8"
        }
      }
    }],
    experts	: [],
    expert_organization:""
  },
  userInitial:{
    "fio": "",
    "phone": "",
    "roles": [
        {
            "id": ""
        }
    ],
    "userName": "",
    "email": "",
    "password": "",
    "expert_organization": ""
}
}

export function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.USERS_DATA_SUCCESS:
      return {...state, list: action.payload}

    case actionTypes.EDIT_USER:
    return {
      ...state, users: state.users.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            userName: action.payload.userName,
            userRole: action.payload.userRole,
            dateFromUserCard: action.payload.dateFromUserCard,
            dateToUserCard: action.payload.dateToUserCard,
            userPhone: action.payload.userPhone,
            userEmail: action.payload.userEmail,
            userPassword: action.payload.userPassword,
          };
        }
        return el;
      }),
    };
    case AUTH_LOGOUT:
      return initialState

    default:
      return state
  }
}

import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_NSI_API_HOST
});
// console.log(instance.baseURL, "baseURL")N

// instance.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('token');
//   // config.headers.Authorization =  `Bearer ${token ?? ''}`;
//   return config;
// });

export default instance;


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import axios from "../../../axios/nsiAxios";
import { dictionaryTypes as dictionaryTypesTypeId } from "../../../Components/core/appDictionaryTypes";
import { uploadClassiffier } from "../../../store/actions/NSIActions";
import { SET_SELECTED_CLAFFISIERS } from "../../../store/actionTypes/NSITypes";

import ModalAddClassifiers from "./Classifiers/ModalAddClassifiers/ModalAddClassifiers";
import HeaderOrganization from "./HeaderOrganization";

import "./Classifiers/classifiers.css";

function OrganizationClassifier() {
  const dispatch = useDispatch();
  const classifiersList = useSelector((state) => state.NSIReducer);
  const userList = useSelector((state) => state.usersReducer.list);
  const [dateSourceList, setDateSourceList] = useState(null);

  useEffect(() => {
    axios.get("/dictionary/list/").then((res) => {
      if (res.data.length) {
        const dateSource = {};
        const dictionaryTypesIdType = Object.fromEntries(
          Object.entries(dictionaryTypesTypeId)?.map(([k, v]) => [v, k])
        );
        res.data.forEach((dictionary) => {
          if (dictionaryTypesIdType[dictionary.id]) {
            const dictionaryId = dictionary.id;
            const classifierType = dictionaryTypesIdType[dictionary.id];
            const userId = dictionary.published
              ? dictionary.publishedBy
              : dictionary.createdBy;
            const dateRow = dictionary.published || dictionary.created;
            const date = dateRow?.split("T")[0]?.split("-").reverse().join(".");
            const user = userList?.data?.length
              ? userList.data.find((user) => user.id === userId)
              : {};
            const source = user?.fio || user?.userName || "";

            dateSource[classifierType] = {
              date,
              source,
              dictionaryId,
              name: dictionary.name,
            };
          }
        });
        setDateSourceList(dateSource);
      }
    });
  }, [userList?.data?.length]);

  const [structureFiletypesOfWorks, setStructureFiletypesOfWorks] =
    useState("");
  const [structureFileworkPlaces, setStructureFileworkPlaces] = useState("");
  const [structureFilenas, setStructureFilenas] = useState("");
  const [structureFileIAE, setStructureFileIAE] = useState("");
  const [structureFileworkPlaces2, setStructureFileworkPlaces2] = useState("");
  const [structureFilematerials, setStructureFilematerials] = useState("");
  const [structureFilebioObjects, setStructureFilebioObjects] = useState("");
  const [structureFilemachinery, setStructureFilemachinery] = useState("");
  const [structureFiledanger, setStructureFiledanger] = useState("");
  const [structureFiledangerEvent, setStructureFiledangerEvent] = useState("");
  const [structureFileriskManagement, setStructureFileriskManagement] =
    useState("");
  const [structureFilemedicalVPF, setStructureFilemedicalVPF] = useState("");
  const [structureFilemedical, setStructureFilemedical] = useState("");
  const [structureFilesiz, setStructureFilesiz] = useState("");

  return (
    <>
      <HeaderOrganization />

      {dateSourceList && (
        <main>
          <ModalAddClassifiers />
          <div className="d-flex">
            <div className="col-6 class-title">
              <p>КЛАССИФИКАТОРЫ</p>
            </div>
            <div className="col-6 px-3 class-title">
              <p>СПРАВОЧНИКИ</p>
            </div>
          </div>
          <div
            className="d-flex justify-content-between px-4"
            id="leftTablePosition"
          >
            {/* Первая таблица */}
            <div className="card_bg col-6 row">
              <table className="table table-hover">
                <thead>
                  <tr className="tr-bg">
                    <th className="title_card br-8 col-2" scope="col">
                      Дата
                    </th>
                    <th className="title_card col-6" scope="col">
                      Источник
                    </th>
                    <th className="title_card col-6" scope="col">
                      Наименование
                    </th>
                    {/* <th className='title_card' scope="col">Статус</th> */}
                    <th className="title_card br-8-end" scope="col"></th>
                    <th className="title_card br-8-end" scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.typesOfWorks?.date}
                    </td>
                    <td>{dateSourceList?.typesOfWorks?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.typesOfWorks?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.typesOfWorks,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerWorks"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFiletypesOfWorks
                            ? structureFiletypesOfWorks.name.length >= 10
                              ? structureFiletypesOfWorks.name.slice(0, 7) +
                                "..."
                              : structureFiletypesOfWorks.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerWorks"
                          style={{ visibility: "hidden", display: "none" }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          // value={structureFile}
                          onChange={(e) =>
                            setStructureFiletypesOfWorks(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFiletypesOfWorks ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd11111",
                                  structureFiletypesOfWorks
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.workPlaces?.date}
                    </td>
                    <td>{dateSourceList?.workPlaces?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.workPlaces?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.workPlaces,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerworkPlaces"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFileworkPlaces
                            ? structureFileworkPlaces.name.length >= 10
                              ? structureFileworkPlaces.name.slice(0, 7) +
                                "..."
                              : structureFileworkPlaces.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerworkPlaces"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFileworkPlaces(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFileworkPlaces ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd12222",
                                  structureFileworkPlaces
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">{dateSourceList?.nas?.date}</td>
                    <td>{dateSourceList?.nas?.source}</td>
                    <td className="td-text">{dateSourceList?.nas?.name}</td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.nas,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickernas"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilenas
                            ? structureFilenas.name.length >= 10
                              ? structureFilenas.name.slice(0, 7) + "..."
                              : structureFilenas.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickernas"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFilenas(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilenas ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd13333",
                                  structureFilenas
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.instrumentsAndEquipments?.date}
                    </td>
                    <td>
                      {dateSourceList?.instrumentsAndEquipments?.source}
                    </td>
                    <td className="td-text">
                      {dateSourceList?.instrumentsAndEquipments?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.instrumentsAndEquipments,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerIAE"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFileIAE
                            ? structureFileIAE.name.length >= 10
                              ? structureFileIAE.name.slice(0, 7) + "..."
                              : structureFileIAE.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerIAE"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFileIAE(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFileIAE ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd14444",
                                  structureFileIAE
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.workPlaces2?.date}
                    </td>
                    <td>{dateSourceList?.workPlaces2?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.workPlaces2?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.workPlaces2,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerworkPlaces2"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFileworkPlaces2
                            ? structureFileworkPlaces2.name.length >= 10
                              ? structureFileworkPlaces2.name.slice(0, 7) +
                                "..."
                              : structureFileworkPlaces2.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerworkPlaces2"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFileworkPlaces2(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFileworkPlaces2 ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd36666",
                                  structureFileworkPlaces2
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.materials?.date}
                    </td>
                    <td>{dateSourceList?.materials?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.materials?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.materials,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickermaterials"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilematerials
                            ? structureFilematerials.name.length >= 10
                              ? structureFilematerials.name.slice(0, 7) +
                                "..."
                              : structureFilematerials.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickermaterials"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFilematerials(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilematerials ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd15555",
                                  structureFilematerials
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.bioObjects?.date}
                    </td>
                    <td>{dateSourceList?.bioObjects?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.bioObjects?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.bioObjects,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>

                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerbioObjects"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilebioObjects
                            ? structureFilebioObjects.name.length >= 10
                              ? structureFilebioObjects.name.slice(0, 7) +
                                "..."
                              : structureFilebioObjects.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerbioObjects"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFilebioObjects(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilebioObjects ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd16666",
                                  structureFilebioObjects
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.machinery?.date}
                    </td>
                    <td>{dateSourceList?.machinery?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.machinery?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.machinery,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>

                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickermachinery"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilemachinery
                            ? structureFilemachinery.name.length >= 10
                              ? structureFilemachinery.name.slice(0, 7) +
                                "..."
                              : structureFilemachinery.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickermachinery"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          // value={structureFile}
                          onChange={(e) =>
                            setStructureFilemachinery(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilemachinery ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd17777",
                                  structureFilemachinery
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.danger?.date}
                    </td>
                    <td>{dateSourceList?.danger?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.danger?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.danger,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>

                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerdanger"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFiledanger
                            ? structureFiledanger.name.length >= 10
                              ? structureFiledanger.name.slice(0, 7) + "..."
                              : structureFiledanger.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerdanger"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          // value={structureFile}
                          onChange={(e) =>
                            setStructureFiledanger(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFiledanger ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd18888",
                                  structureFiledanger
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.dangerEvent?.date}
                    </td>
                    <td>{dateSourceList?.dangerEvent?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.dangerEvent?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.dangerEvent,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>

                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerdangerEvent"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFiledangerEvent
                            ? structureFiledangerEvent.name.length >= 10
                              ? structureFiledangerEvent.name.slice(0, 7) +
                                "..."
                              : structureFiledangerEvent.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerdangerEvent"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFiledangerEvent(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFiledangerEvent ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd19999",
                                  structureFiledangerEvent
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.riskManagement?.date}
                    </td>
                    <td>{dateSourceList?.riskManagement?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.riskManagement?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.riskManagement,
                            name: "riskmanagement",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickerriskManagement"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFileriskManagement
                            ? structureFileriskManagement.name.length >= 10
                              ? structureFileriskManagement.name.slice(0, 7) +
                                "..."
                              : structureFileriskManagement.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickerriskManagement"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFileriskManagement(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFileriskManagement ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd20000",
                                  structureFileriskManagement
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.medicalVPF?.date}
                    </td>
                    <td>{dateSourceList?.medicalVPF?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.medicalVPF?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.medicalVPF,
                            name: "medicalVPF",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>

                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickermedicalVPF"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilemedicalVPF
                            ? structureFilemedicalVPF.name.length >= 10
                              ? structureFilemedicalVPF.name.slice(0, 7) +
                                "..."
                              : structureFilemedicalVPF.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickermedicalVPF"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          onChange={(e) =>
                            setStructureFilemedicalVPF(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilemedicalVPF ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd26666s",
                                  structureFilemedicalVPF
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">
                      {dateSourceList?.medical?.date}
                    </td>
                    <td>{dateSourceList?.medical?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.medical?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.medical,
                            name: "medical",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickermedical"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilemedical
                            ? structureFilemedical.name.length >= 10
                              ? structureFilemedical.name.slice(0, 7) + "..."
                              : structureFilemedical.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickermedical"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          // value={structureFile}
                          onChange={(e) =>
                            setStructureFilemedical(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilemedical ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd27777",
                                  structureFilemedical
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-text">{dateSourceList?.siz?.date}</td>
                    <td>{dateSourceList?.siz?.source}</td>
                    <td className="td-text">{dateSourceList?.siz?.name}</td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.siz,
                            name: "siz",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                    <td className="href-text">
                      <form
                        type="POST"
                        enctype="multipart/form-data"
                        action="/upload"
                      >
                        <label
                          htmlFor="filePickersiz"
                          className="btn download-button"
                          style={{
                            height: "auto",
                            width: "auto",
                            paddingRight: 12,
                          }}
                        >
                          {structureFilesiz
                            ? structureFilesiz.name.length >= 10
                              ? structureFilesiz.name.slice(0, 7) + "..."
                              : structureFilesiz.name
                            : "Загрузить"}
                        </label>
                        <input
                          id="filePickersiz"
                          style={{
                            visibility: "hidden",
                            display: "none",
                          }}
                          type={"file"}
                          accept=".csv"
                          multipart
                          // value={structureFile}
                          onChange={(e) =>
                            setStructureFilesiz(e.target.files[0])
                          }
                        />
                      </form>
                      {structureFilesiz ? (
                        <span
                          style={{
                            wordBreak: "break-all",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            className="btn button-transparent"
                            onClick={() => {
                              dispatch(
                                uploadClassiffier(
                                  "77581fda-e9ed-4d00-bb49-6831ebd29999",
                                  structureFilesiz
                                )
                              );
                            }}
                          >
                            Загрузить
                          </button>
                          {/* {file.name} */}
                        </span>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Вторая таблица */}

            <div className="card_bg col-6 row gx-5">
              <table className="table table-hover">
                <thead>
                  <tr className="tr-bg">
                    <th className="title_card br-8 col-2" scope="col">
                      Дата
                    </th>
                    <th className="title_card br-8 col-2">Источник</th>
                    <th className="title_card col-6" scope="col">
                      Наименование
                    </th>
                    <th className="title_card br-8-end" scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.ok01694?.date}
                    </td>
                    <td>{dateSourceList?.ok01694?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.ok01694?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.ok01694,
                            name: "ok01694",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.typeCompany?.date}
                    </td>
                    <td>{dateSourceList?.typeCompany?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.typeCompany?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.typeCompany,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.stagesWork?.date}
                    </td>
                    <td>{dateSourceList?.stagesWork?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.stagesWork?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.stagesWork,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">{dateSourceList?.sout?.date}</td>
                    <td>{dateSourceList?.sout?.source}</td>
                    <td className="td-text">{dateSourceList?.sout?.name}</td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.sout,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.requirement?.date}
                    </td>
                    <td>{dateSourceList?.requirement?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.requirement?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.requirement,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.medicalSout?.date}
                    </td>
                    <td>{dateSourceList?.medicalSout?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.medicalSout?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.medicalSout,
                            name: "medicalSout",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">
                      {dateSourceList?.personnelRequire?.date}
                    </td>
                    <td>{dateSourceList?.personnelRequire?.source}</td>
                    <td className="td-text">
                      {dateSourceList?.personnelRequire?.name}
                    </td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.personnelRequire,
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">{dateSourceList?.okved?.date}</td>
                    <td>{dateSourceList?.okved?.source}</td>
                    <td className="td-text">{dateSourceList?.okved?.name}</td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/classifiers/container"}
                        onClick={() => {
                          dispatch({
                            type: SET_SELECTED_CLAFFISIERS,
                            payload: classifiersList.okved,
                            name: "okved",
                          });
                        }}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">10.06.2022</td>
                    <td></td>
                    <td className="td-text">Организации</td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/employers"}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">10.06.2022</td>
                    <td></td>
                    <td className="td-text">Экспертная организация</td>
                    <td className="href-text">
                      <NavLink
                        to={"/organizations/organizations"}
                        className="href-text"
                      >
                        открыть
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="td-text">10.06.2022</td>
                    <td></td>
                    <td className="td-text">ФИАС</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Нижний блок (запросы на изменения) */}
          </div>
          <p className="redText mt-5">ЗАПРОСЫ НА ИЗМЕНЕНИЯ</p>
          <div className="card_bg rounded-0">
            <table className="table h-129">
              <tbody>
                <tr className="va-center">
                  <td className="td-text col-1">01.01.2022</td>
                  <td className="td-text col-8">
                    Пользователь Иванов И.И (ООО Экспертиза) предлагает внести
                    новое значение в Классификатор виды работ
                  </td>
                  <td className="text-align-right"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      )}
    </>
  );
}

export default OrganizationClassifier;

import React, { useState } from "react";
import { Modal } from 'antd';

//import HeaderAdmin from '../../../blocks/headerAdmin';
import { List } from '../../../atoms/list/styled';

import Data from './data';
import  * as Styled from './styled'

//import { navMap } from './navMap';
//import { StyledAutoComplete } from '../../../formikFields/autoCompleteFilter/autoCompleteFilter.styled';



export default function SelectCard(modal)
{
  const [data, setData] = useState(1);
  const [active, setActive] = useState(1);



  //const [openModal, setOpenModal] = useState(e.openCard);
  //setOpenModal(e.openCard);
  //setOpenModal(e.openCard);
  //console.log(modal);

  const handleCancel = () => {
    modal.closeCard();
  }
  function ais(){
    setData(1);
    setActive(1);
  }

  function favorites()
  {
    setData(2);
    setActive(2)
  }

  return (
    <Modal
      title="ВЫБОР КАРТЫ"
      open={modal.openCard}
      onCancel={handleCancel}
      onOk={handleCancel}
      footer={[]}

    >
      <List >
        <Styled.Link onClick={ais} isActive={active === 1 ? true : false}>База знаний АИС</Styled.Link>
        <Styled.Link onClick={favorites} isActive={active === 2 ? true : false}>Избранное</Styled.Link>
      </List>
      <Data
        type={data}
        id={modal.data}
        ok={(e) => modal.okCard(e)}
        />

    </Modal>
  )
}

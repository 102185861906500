export const navMap = [
  {
    id: '1',
    path: '/administration/requests',
    label: 'Заявки',
  },
  {
    id: '2',
    path: '/administration/userslist',
    label: 'Пользователи',
  },
  {
    id: '3',
    path: '/administration/enterprises',
    label: 'Организации',
  },
  {
    id: '4',
    path: '/administration/roles',
    label: 'Роли',
  },
  {
    id: '5',
    path: '/administration/userslogs',
    label: 'Действия',
  }
]

import React from "react";

import * as Styled from './styled';

export default function EditUser(props) {
  const {id, onPostUserWiew} = props;

  const handleEditUser = () => {
    onPostUserWiew(id)
  }

  return (
    <Styled.EditUser
      onLink={handleEditUser}
    >
      Редактировать
    </Styled.EditUser>
  )
}
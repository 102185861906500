import { createGlobalStyle } from "styled-components";

import GolosTextWebVFWoff from "./golos-text/golos-text_vf.woff";
import GolosTextWebVFWoffWoff2 from "./golos-text/golos-text_vf.woff2";

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'GolosTextWebVF';
    src: url(${GolosTextWebVFWoffWoff2}) format('woff2'),
        url(${GolosTextWebVFWoff}) format('woff');
  }
`;

export default FontStyles;
import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Link from '../../../atoms/link';
import Text from '../../../atoms/text';

export const Buttons = styled(Flex)`
  padding-top: 19px;
`;

export const RecoverAccount = styled(Link)`
  margin: 10px 0 13px 10px;
  display: block;
`;

export const H5 = styled(Text)`
  margin: 10px 0 2px;
  display: block;
`;

export const P = styled(Text)`
  margin-bottom: 20px;
  font-family: 'GolosTextWebVF', serif;
`;

export const PText = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  margin-bottom: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 20px;
`;

export const Contacts = styled(Text)`
  margin-bottom: 2px;
  font-family: 'GolosTextWebVF', serif;
`;

export const H5Left = styled(Text)`
  margin-bottom: 8px;
  margin-left: 20px;
  font-family: 'GolosTextWebVF', serif;
`;

export const IconContacts = styled.span`
  padding-right: 5px;
`;

import { Component } from "react";
import { toast } from "react-toastify";
import { Modal, Select } from "antd";

//import * as Styled from './styled.js';


class ClassHac extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      props: props,
      data: [],
      modalOpen: props.isModalOpen,
      type: "",
      typical: ""
    }
    //console.log(props)
    //this.typical = props.data?.typical_profession_id ?? "";
    this.source = props.data?.danger_source_id ?? "";
    this.place = props.data?.work_place_id ?? "";
  }

  async componentDidMount()
  {
    /*
    await fetch('/api/classifier/bundle/work/id',
      {
        method: 'POST',
        body: JSON.stringify({id: this.state.props.id})
      }
    ).then((response) => {
      return response.json();
    }).then((data) => this.setState({data: data.data}))
    */
  }

  async modalSave(){
    await fetch('/api/classifier/bundle/placesource/save',
      {
        method: 'POST',
        body: JSON.stringify({
          id: this.props.id,
          //work_place_id: this.typical,
          work_place_id: this.place,
          danger_source_id: this.source,
        })
      }
    ).then(
      (response) => {return response.json()}
    ).then((data) => {
      if(data.code === 201){
        toast['error'](data.msg)
      }else{
        toast['success'](data.msg)
        this.props.modalCancel()
      }


    }
    )

  }


  sourceChange(value){
    this.source = value;
  }

  placeChange(value){
    this.place = value;
  }

  render() {


    return (
      <>
      <Modal
          title={this.props.title}
          open={this.state.modalOpen}
          onOk={() => this.modalSave(this.state)}
          onCancel={this.props.modalCancel}
          >
          <table style={{'font-size': '16px'}}>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Место выполнения работ</b>
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  showSearch
                  id="typical"
                  style={{
                  width: '440px',
                  }}

                  filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.work_place_id ?? '']}
                  options={this.props.sprPlace.place}

                  onChange={(value) => this.placeChange(value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Источник опасности</b>
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  showSearch
                  style={{
                    width: '440px',
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.danger_source_id ?? '']}
                  options={this.props.sprPlace.source}

                  onChange={(value) => this.sourceChange(value)}
                />
              </td>
            </tr>



          </table>
        </Modal>
</>
    )
  }
}
export default ClassHac;

import React from "react";
import {useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import axios from "../../../../axios/axios";
import { logWarn } from "../../../../utils/log";
import Dialog from "../../../blocks/dialog";
import FieldInput from "../../fields/input";

import * as Styled from "./styled";

export default function ChangePassword(props) {
  const {isRender, onShowBlock} = props;
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
      ver_password: ""
    },
  });

  const handleClose = () => navigate('/');

  const isError = Object.keys(formik.errors)?.length;

  const onSubmit = async () => {

    if (isError) {
      return null
    }

    try {
      const { data } = await axios.post('/api/user/recovery/changepassword', formik.values);

      if (data.status === 'success') {
        toast['success'](data?.msg);
        onShowBlock(null);
      }
  
    } catch (e) {

      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: "676644435454",
        errorMessage: e,
      });
    }
  };

  return (
    <form>
      <Dialog
        isRender={isRender}
        isClose={true}
        title={"СМЕНИТЬ ПАРОЛЬ"}
        onClose={handleClose}
        padding={'0 34px 50px'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '2px -20px 53px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
        <FieldInput
          margin={"0 0 20px"}
          formik={formik}
          type={'password'}
          label={"Старый пароль"}
          name={"old_password"}
        />
        <FieldInput
          margin={"0 0 20px"}
          formik={formik}
          type={'password'}
          label={"Новый пароль"}
          name={"new_password"}
        />
        <FieldInput
          margin={"0 0 20px"}
          formik={formik}
          type={'password'}
          label={"Повторите новый пароль"}
          name={"ver_password"}
        />

        <Styled.Buttons direction={"row"} aItems={"flex-end"}>
          <Styled.CustomButton type={"submit"} variant={"secondary"} onClick={onSubmit}>
            Сохранить
          </Styled.CustomButton>
        </Styled.Buttons>
      </Dialog>
    </form>
  );
}
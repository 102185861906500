import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchOrganization } from '../../../../store/actions/expertOrganizationsActions';
import { EXPERT_DATA_CLEAR } from '../../../../store/actionTypes/expertOrganizationTypes';

import GeneralInformation from './Generalinformation/GeneralInformation';

import '../Employers/ModalAddEmployer/Modal.css';
function ModalAddOrganization({ visible, setVisible, organizationId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganization(organizationId));
    }
  }, [organizationId]);

  return (
    <>
      <div
        className={visible ? 'myModal active' : 'myModal'}
        onClick={() => {
          setVisible(false);
          dispatch({ type: EXPERT_DATA_CLEAR });
        }}>
        <div
          className="modal-content rounded-0 container"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className="form-modal  mt-50 mb-50 ">
            <button
              className="btn-close btn-blue"
              onClick={() => {
                setVisible(false);
                dispatch({ type: EXPERT_DATA_CLEAR });
              }}>
              <i className="bi bi-x ft-sz" style={{ paddingTop: '3px' }}></i>
              <span>Закрыть</span>
            </button>

            <h2 className="title-m-f">
              Добавить/Редактировать Экспертную организацию
            </h2>
            {/* заменил на след строке на див (была форм) */}
            <div>
              <div className="content-form">
                <div className="nav-modal">
                  <div
                    className="nav nav-tabs"
                    id="nav-tab"
                    role="tablist"></div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  {/* Закладка Общие сведения об организации */}
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab">
                    <GeneralInformation setVisible={setVisible} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalAddOrganization;

//import React from "react";
//import { Navigate } from "react-router-dom";
//import axios from 'axios';
//import { Outlet } from "react-router-dom";
//import HeaderAdmin from "../../blocks/headerAdmin";

//import { navMap } from "./navMap";

export default function offer() {

  //let download = require('./download.min');
  //return (<Navigate to="https://riski.vcot.info/api/offer" />)
  //window.open("api/offer")

    fetch('/api/offer')

    .then( res => res.blob() )
    .then( blob => {
    var file = window.URL.createObjectURL(blob);
    //window.location.assign(file, 'offer.pdf');
    const dummy = document.createElement('a');
dummy.href = file;
dummy.open = 'offer.pdf';

document.body.appendChild(dummy);
dummy.click(); // Готово
    //URL.revokeObjectURL(blob)
    });

/*
  const File = async () => {
    try {
    const response = axios.get(
      '/api/offer',
      {
        responseType: "blob",
      }
    );
    console.log(response);
    // Create a Blob from the response data
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    console.log(pdfBlob)
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(pdfBlob)
    window.URL.revokeObjectURL(url)

  }
  catch (error)
  {

  }
  }
  return (
    <File />
  );
  */
/*
  axios.get('/api/offer', {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf'
    }
}).then(response => {
  // response.data is an empty object
  const blob = new Blob([response.data], {
    type: 'application/pdf',
  });
  window.URL.createObjectURL(blob, 'file.pdf');
});
*/
}

import React from 'react';

import * as Styled from './styled';

export default function MeasuresAdditional(props) {
  const { measures, onPostEvaluationcardEdithazard, onRowId, id } = props;

  const handleClick = () => {
    onPostEvaluationcardEdithazard(id);
    onRowId(id);
  };

  if (!measures?.length) {
    return null;
  }

  return (
    <Styled.Measures>
      {measures?.map((el, index) => (
        <Styled.MeasureItem key={`${index}-${el?.name}`}>
          <Styled.MeasureCount onClick={handleClick}>{el?.count} шт.</Styled.MeasureCount>
          <Styled.MeasureName>{el?.name}</Styled.MeasureName>
        </Styled.MeasureItem>
      ))}
    </Styled.Measures>
  );
}

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from '../../../../axios/axios';
import { csrfWithLogin } from '../../../../store/actions/authActions';
import { logWarn } from '../../../../utils/log';

export function usePostRegistration() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onPostRegistration = async (values) => {
    try {
      setLoading(true);
      await axios.post('/api/user/registr', values).then(() => {
        dispatch(
          csrfWithLogin({
            userName: values.userName,
            password: values.password,
          })
        );
      });
    } catch (e) {
      setLoading(false);
      logWarn({
        errorNumber: '676644435454',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    loading,
    onPostRegistration,
  };
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';

import nsiAxios from '../../../../../axios/nsiAxios';
import { dictionaryTypes } from '../../../../../Components/core/appDictionaryTypes';
import { createOrganization, updateOrganization } from '../../../../../store/actions/expertOrganizationsActions';
import AppButton from '../../../../core/AppButton';
import ModalFIAS from '../../../MenuRating/ModalFIAS';
import AddStructure from '../../Employers/ModalAddEmployer/Generalinformation/addStructure';

import '../../Employers/ModalAddEmployer/Modal.css';

function ModalObshieSvedeniyaObOrganiz({ setVisible }) {
  const getDataOrganization = useSelector((state) => state.expertOrganizationReducer.organization);
  const [structureFile, setStructureFile] = useState('');
  const [jobTitleFile, setJobTitle] = useState('');
  const [organizer, setOrganization] = useState(getDataOrganization);
  const [_, setDisabled] = useState('btn save-button disabled');
  const [inpActive] = useState('form-control rounded-0 active');
  const [inp] = useState('form-control rounded-0');
  const dispatch = useDispatch();
  const nsiDictionary = useSelector((state) => state.NSIReducer);
  const [selectedOkved, setSelectedOkved] = useState(false);

  useEffect(() => {
    setOrganization(getDataOrganization);
  }, [getDataOrganization]);

  const getOkved = () => {
    const url = `/dictionary/data/?id=${dictionaryTypes.okved}&rowId=${organizer.okved}`;

    nsiAxios.get(url).then((res) => {
      if (res?.data[0]) {
        const val = res.data[0];

        if (!val || !val.classOKVED || !val.economicActivity) {
          return;
        }

        val.classOKVED = val.classOKVED.trim();

        const selected = nsiDictionary.okved[0].length
          ? nsiDictionary.okved[0].find((item) => item.classOKVED.trim() === val.classOKVED)
          : false;
        if (selected) {
          setSelectedOkved(selected);
        } else {
          val.codeName = val.classOKVED + ' ' + val.economicActivity;
          setSelectedOkved(val);
          nsiDictionary.okved[0].unshift(val);
        }
      }
    });
  };

  useEffect(() => {
    const selected = nsiDictionary?.okved?.length
      ? nsiDictionary.okved[0].find((item) => item.id === organizer.okved)
      : false;

    if (selected) {
      setSelectedOkved(selected);
    } else if (organizer.okved) {
      getOkved();
    }
  }, [nsiDictionary.length, organizer.okved]);

  useEffect(() => {
    if (setVisible === false) {
      setJobTitle('');
      setStructureFile('');
    }
  }, [setVisible]);

  function addFormGeneral(e) {
    e.preventDefault();

    if (validInput()) {
      if (organizer.id) {
        dispatch(updateOrganization(organizer));
      } else {
        dispatch(createOrganization(organizer));
      }
    }
  }

  function validInput() {
    if (
      organizer.fullName.length > 0 &&
      organizer.shortName.length > 0 &&
      (organizer.inn.length === 12 || organizer.inn.length === 10) &&
      organizer.okpo.length >= 8 &&
      organizer.ogrn.length === 13 &&
      organizer.okved.length > 0 &&
      (organizer.oktmo.length === 8 || organizer.oktmo.length === 11) &&
      organizer.kpp.length === 9 &&
      organizer.chiefJobTitle.length > 3 &&
      organizer.chiefFullName.length > 3
    ) {
      setDisabled('btn save-button');
      setVisible(false);
      return true;
    } else {
      setVisible(true);
      setDisabled('btn save-button');
      alert('Заполните все поля');
      return false;
    }
  }

  // Добавление и изменение адреса

  const [openModalFIAS, setOpenModalFIAS] = useState(false);
  const [typeAdress, setTypeAdress] = useState('');

  function openFIASreal(e) {
    e.preventDefault();
    setOpenModalFIAS(true);
    setTypeAdress('ra');
  }

  function openFIASlegal(e) {
    e.preventDefault();
    setOpenModalFIAS(true);
    setTypeAdress('la');
  }

  const reText = /^[А-ЯЁA-Za-zа-яё0-9.№—"\s-]+$/;
  const reNumb = /^[0-9]+$/;
  const reName = /^[А-ЯЁа-яё.—\s-]+$/;

  const makeAddressString = (status) => {
    const addressObj = organizer?.addresses?.find((el) => el.status === status);

    if (!addressObj) {
      return '';
    }

    const addressKeys = [
      'postalCode',
      'regionName',
      'districtName',
      'localityName',
      'cityName',
      'streetName',
      'house',
      'building',
    ];
    let addressString = ' ';

    addressKeys.forEach((k) => (addressString += addressObj[k] ? addressObj[k] + ', ' : ''));

    return addressString.slice(0, -2);
  };

  return (
    <>
      {openModalFIAS ? (
        <ModalFIAS
          visibleFIAS={openModalFIAS}
          setVisibleFIAS={setOpenModalFIAS}
          typeAdress={typeAdress}
          create={setOrganization}
          organization={organizer}
        />
      ) : (
        <></>
      )}

      {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
      <div className="modal-dialog modal-dialog-centered modalWidthObshieSvedeniya">
        <div className="modal-content rounded-0">
          <div className="modal-body">
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="user-name" className="col-form-label grey-text-modal-user">
                    Полное наименование предприятия
                  </label>
                  <input
                    value={organizer.fullName}
                    onChange={(e) => {
                      if (e.target.value === '' || (reText.test(e.target.value) && e.target.value.length <= 300)) {
                        setOrganization({
                          ...organizer,
                          fullName: e.target.value,
                        });
                      } else {
                        alert('Допускаются цифры, русские и латинские буквы, некоторые символы');
                      }
                    }}
                    type="text"
                    autoComplete="off"
                    className={organizer.fullName.length ? inp : inpActive}
                    id="user-name"
                    placeholder="Общество с ограниченной ответственностью Компания"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mb-3 col-5 ">
                    <label htmlFor="name-company" className="col-form-label grey-text-modal-user">
                      Краткое наименование предприятия
                    </label>
                    <input
                      value={organizer.shortName}
                      onChange={(e) => {
                        if (e.target.value === '' || (reText.test(e.target.value) && e.target.value.length <= 100)) {
                          setOrganization({
                            ...organizer,
                            shortName: e.target.value,
                          });
                        } else {
                          alert('Допускаются цифры, русские и латинские буквы, некоторые символы');
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.shortName.length ? inp : inpActive}
                      id="name-company"
                      placeholder="ООО Компания"
                    />
                  </div>
                  <div className="mb-3 col-2.3 pl-10">
                    <label htmlFor="inn-company" className="col-form-label grey-text-modal-user">
                      ИНН
                    </label>
                    <input
                      value={organizer.inn}
                      onChange={(e) => {
                        if (e.target.value === '' || (reNumb.test(e.target.value) && e.target.value.length <= 12)) {
                          setOrganization({
                            ...organizer,
                            inn: e.target.value,
                          });
                        } else {
                          alert('ИНН должен состоять только из чисел, введите 10 или 12 знаков');
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.inn.length === 12 || organizer.inn.length === 10 ? inp : inpActive}
                      id="inn-company"
                      placeholder="1234567891"
                    />
                  </div>
                  <div className="mb-3 col-2.3 pl-10">
                    <label htmlFor="okpo-company" className="col-form-label grey-text-modal-user">
                      ОКПО
                    </label>
                    <input
                      value={organizer.okpo}
                      onChange={(e) => {
                        if (e.target.value === '' || (reNumb.test(e.target.value) && e.target.value.length <= 10)) {
                          setOrganization({
                            ...organizer,
                            okpo: e.target.value,
                          });
                        } else {
                          alert('ОКПО должен состоять только из чисел, введите от 8 до 10 знаков');
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.okpo.length >= 8 ? inp : inpActive}
                      id="okpo-company"
                      placeholder="1234567891"
                    />
                  </div>
                  <div className="mb-3 col-2.3 pl-10">
                    <label htmlFor="ogrn-company" className="col-form-label grey-text-modal-user">
                      ОГРН
                    </label>
                    <input
                      value={organizer.ogrn}
                      onChange={(e) => {
                        if (e.target.value === '' || (reNumb.test(e.target.value) && e.target.value.length <= 13)) {
                          setOrganization({
                            ...organizer,
                            ogrn: e.target.value,
                          });
                        } else {
                          alert('ОГРН должен состоять только из чисел, введите 13 знаков');
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.ogrn.length === 13 ? inp : inpActive}
                      id="ogrn-company"
                      placeholder="1234567891011"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="mb-5 col-5 ocved">
                    <label htmlFor="okved-company" className="col-form-label grey-text-modal-user">
                      ОКВЭД
                    </label>
                    <Multiselect
                      className={
                        organizer.okved
                          ? 'my-multiselect multiselect-border multiselect-height '
                          : 'my-multiselect multiselect-border multiselect-height b-red'
                      }
                      options={
                        nsiDictionary.okved !== null
                          ? nsiDictionary.okved[0]
                          : [
                              {
                                codeName: 'Данных нет',
                              },
                            ]
                      }
                      caseSensitiveSearch={false}
                      selectedValues={selectedOkved ? [selectedOkved] : null}
                      selectionLimit="1"
                      closeIcon="close"
                      hidePlaceholder={true}
                      onSelect={(e, s) => {
                        setOrganization({
                          ...organizer,
                          okved: s.id,
                        });
                        setSelectedOkved(s);
                      }}
                      onRemove={() => {
                        setOrganization({
                          ...organizer,
                          okved: '',
                        });
                        setSelectedOkved('');
                      }}
                      displayValue="codeName"
                      placeholder="ОКВЭД"
                    />
                  </div>
                  <div className="mb-3 col-2.3 pl-10">
                    <label htmlFor="oktmo-company" className="col-form-label grey-text-modal-user">
                      ОКТМО
                    </label>
                    <input
                      value={organizer.oktmo}
                      onChange={(e) => {
                        if (e.target.value === '' || (reNumb.test(e.target.value) && e.target.value.length <= 11)) {
                          setOrganization({
                            ...organizer,
                            oktmo: e.target.value,
                          });
                        } else {
                          alert('ОКТМО должен состоять только из чисел, 8 или 11 знаков');
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.oktmo.length === 11 || organizer.oktmo.length === 8 ? inp : inpActive}
                      id="oktmo-company"
                      placeholder="1234567891"
                    />
                  </div>
                  {/*<div className="mb-3 col-2.3 pl-10">*/}
                  {/*<label*/}
                  {/*  htmlFor="okogu-company"*/}
                  {/*  className="col-form-label grey-text-modal-user"*/}
                  {/*>*/}
                  {/*    ОКОГУ*/}
                  {/*</label>*/}
                  {/*<input*/}
                  {/*  value=""*/}
                  {/*  // onChange={(e) => {*/}
                  {/*  //     if (e.target.value === '' || reNumb.test(e.target.value)) {*/}
                  {/*  //         //    setOrganization({...organizer, okogu: e.target.value})*/}
                  {/*  //     } else {*/}
                  {/*  //         alert('Допустимы только числа')*/}
                  {/*  //     }*/}
                  {/*  // }*/}
                  {/*  // }*/}
                  {/*//   type="text"*/}
                  {/*//   autoComplete="off"*/}
                  {/*//   className="form-control rounded-0"*/}
                  {/*//   id="okogu-company"*/}
                  {/*//   placeholder="1234567891"*/}
                  {/*// />*/}
                  {/*</div>*/}
                  <div className="mb-3 col-2.3 pl-10">
                    <label htmlFor="kpp-company" className="col-form-label grey-text-modal-user">
                      КПП
                    </label>
                    <input
                      value={organizer.kpp}
                      onChange={(e) => {
                        if (e.target.value === '' || (reNumb.test(e.target.value) && e.target.value.length <= 9)) {
                          setOrganization({
                            ...organizer,
                            kpp: e.target.value,
                          });
                        } else {
                          alert('КПП должен состоять только из чисел, введите 9 знаков');
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.kpp.length === 9 ? inp : inpActive}
                      id="kpp-company"
                      placeholder="123456789"
                    />
                  </div>
                </div>

                {/* Cтрока с адресами*/}
                <div className="mb-5 d-flex justify-content-between">
                  <div className="col-6 pddng-rght-30">
                    <div className="mb-1">
                      <span className="class-title-adresss">Фактический адрес</span>:
                      <span>{makeAddressString('ra')}</span>
                    </div>
                    <div className="href-text">
                      <AppButton className="button-transparent" onClick={openFIASreal}>
                        {(organizer.addresses &&
                          organizer.addresses.length > 0 &&
                          organizer.addresses[0]?.postalCode !== '') ||
                        organizer.addresses[0]?.districtName !== '' ||
                        organizer.addresses[0]?.regionName !== '' ||
                        organizer.addresses[0]?.cityName !== '' ||
                        organizer.addresses[0]?.localityName ||
                        organizer.addresses[0]?.streetName !== '' ||
                        organizer.addresses[0]?.streetName !== ''
                          ? 'Изменить'
                          : 'Добавить'}
                        {/*{organizer.addresses &&*/}
                        {/*organizer.addresses.length >*/}
                        {/*0 &&*/}
                        {/*organizer.addresses[*/}
                        {/*    organizer.addresses[0]*/}
                        {/*        ?.status === 'la'*/}
                        {/*        ? 0*/}
                        {/*        : 1*/}
                        {/*    ].id*/}
                        {/*    ? 'Изменить'*/}
                        {/*    : organizer.addresses[*/}
                        {/*        organizer.addresses[0]*/}
                        {/*            ?.status === 'la'*/}
                        {/*            ? 0*/}
                        {/*            : 1*/}
                        {/*        ].regionName || organizer.addresses[*/}
                        {/*        organizer.addresses[0]*/}
                        {/*            ?.status === 'la'*/}
                        {/*            ? 0*/}
                        {/*            : 1*/}
                        {/*        ].postalCode ? 'Добавить' : 'Изменить'}*/}
                      </AppButton>
                    </div>
                  </div>

                  <div className="col-6 pl-10">
                    <div className="mb-1">
                      <span className="class-title-adresss">Юридический адрес</span>:
                      <span>{makeAddressString('la')}</span>
                    </div>
                    <div className="href-text">
                      <AppButton className="button-transparent" onClick={openFIASlegal}>
                        {(organizer.addresses &&
                          organizer.addresses.length > 0 &&
                          organizer.addresses[1]?.postalCode !== '') ||
                        organizer.addresses[1]?.districtName !== '' ||
                        organizer.addresses[1]?.regionName !== '' ||
                        organizer.addresses[1]?.cityName !== '' ||
                        organizer.addresses[1]?.localityName ||
                        organizer.addresses[1]?.streetName !== '' ||
                        organizer.addresses[1]?.streetName !== ''
                          ? 'Изменить'
                          : 'Добавить'}
                        {/*{organizer.addresses &&*/}
                        {/*organizer.addresses.length >*/}
                        {/*0 &&*/}
                        {/*organizer.addresses[*/}
                        {/*    organizer.addresses[0]*/}
                        {/*        ?.status === 'la'*/}
                        {/*        ? 0*/}
                        {/*        : 1*/}
                        {/*    ].id*/}
                        {/*    ? 'Изменить'*/}
                        {/*    : (organizer.addresses[*/}
                        {/*        organizer.addresses[0]*/}
                        {/*            ?.status === 'la'*/}
                        {/*            ? 0*/}
                        {/*            : 1*/}
                        {/*        ].regionName || organizer.addresses[*/}
                        {/*        organizer.addresses[0]*/}
                        {/*            ?.status === 'la'*/}
                        {/*            ? 0*/}
                        {/*            : 1*/}
                        {/*        ].postalCode) ? 'Добавить' : 'Изменить'}*/}
                      </AppButton>
                    </div>
                  </div>
                </div>

                {/* Четвертая строка */}
                {organizer.id && organizer.id != null && 0 ? (
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center mb-3 col-6">
                      Структура
                      <AddStructure
                        classBtn={'href-text pddng-lft-25 href-text href_as_button d-none'}
                        dataStructu={{}}
                      />
                      <div className="pddng-lft-20">
                        {/*<AddStructure
                                                    children={"Добавить"}
                                                    classBtn={
                                                        "btn vvesti-button d-none"
                                                    }
                                                    create={CreateStructure}
                                                    dataStructu={{}}
                                                />*/}
                      </div>
                      <div className="pddng-lft-20">
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="btn download-button"*/}
                        {/*>*/}
                        {/*    <label htmlFor="file" className="feedback__label">*/}
                        <form>
                          <label
                            htmlFor="filePicker"
                            className="btn download-button"
                            style={{
                              height: 'auto',
                              width: 'auto',
                              paddingRight: 12,
                            }}
                          >
                            {structureFile ? structureFile.name : 'Загрузить'}
                          </label>
                          <input
                            id="filePicker"
                            style={{
                              visibility: 'hidden',
                              display: 'none',
                            }}
                            type={'file'}
                            accept=".csv"
                            multipart
                            // value={structureFile}
                            onChange={(e) => setStructureFile(e.target.files[0])}
                          />
                        </form>
                        {/*<button className="feedback__text">Загрузить</button>*/}
                        {/*</label>*/}

                        {/*    Загрузить*/}
                        {/*</button>*/}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 col-6">
                      Должности
                      <AddStructure
                        classBtn={'href-text pddng-lft-25 href-text href_as_button d-none'}
                        dataStructu={{}}
                      />
                      <div className="pddng-lft-20 d-none">
                        {/*<AddStructure
                                                    children={"Добавить"}
                                                    classBtn={
                                                        "btn vvesti-button"
                                                    }
                                                    create={CreateStructure}
                                                    dataStructu={{}}
                                                />*/}
                      </div>
                      <div className="pddng-lft-20">
                        <form>
                          <label
                            htmlFor="filePicker1"
                            className="btn download-button"
                            style={{
                              height: 'auto',
                              width: 'auto',
                              paddingRight: 12,
                            }}
                          >
                            {jobTitleFile ? jobTitleFile.name : 'Загрузить'}
                          </label>
                          <input
                            id="filePicker1"
                            style={{
                              visibility: 'hidden',
                              display: 'none',
                            }}
                            type={'file'}
                            accept=".csv"
                            multipart
                            // value={structureFile}
                            onChange={(e) => setJobTitle(e.target.files[0])}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="mb-3">
                  <label htmlFor="temp_accreditations" className="col-form-label grey-text-modal-user">
                    Аккредитация
                  </label>
                  <input
                    value={organizer.temp_accreditations ? organizer.temp_accreditations : ''}
                    onChange={(e) => {
                      if (e.target.value === '' || (reText.test(e.target.value) && e.target.value.length <= 1000)) {
                        setOrganization({
                          ...organizer,
                          temp_accreditations: e.target.value,
                        });
                      } else {
                        alert('Допускаются цифры, русские и латинские буквы, некоторые символы');
                      }
                    }}
                    type="text"
                    autoComplete="off"
                    className={inp}
                    id="temp_accreditations"
                    placeholder=""
                  />
                </div>

                {/* Пятая строка */}
                <div className="d-flex justify-content-between">
                  <div className={'mb-3 col-6'}>
                    <label htmlFor="manager-position" className="col-form-label grey-text-modal-user">
                      Руководитель
                    </label>
                    <input
                      value={organizer.chiefJobTitle}
                      onChange={(e) => {
                        if (e.target.value === '' || (reName.test(e.target.value) && e.target.value.length <= 30)) {
                          setOrganization({
                            ...organizer,
                            chiefJobTitle: e.target.value,
                          });
                        } else {
                          alert('Допускаются цифры, русские и латинские буквы, некоторые символы');
                        }

                        //     if (e.target.value === '' &&
                        //         e.target.value.length <= 30) {
                        //         if (reName.test(e.target.value)) {
                        //
                        //             setOrganization({
                        //                 ...organizer,
                        //                 chiefJobTitle:
                        //                 e.target.value,
                        //             })
                        //         } else {
                        //             alert(
                        //                 'Допускаются цифры, русские и латинские буквы, некоторые символы'
                        //             )
                        //         }
                        //     } else {
                        //         setDisabledInp('mb-3 col-6 disabled')
                        //     }
                      }}
                      type="text"
                      className={organizer.chiefJobTitle.length ? inp : inpActive}
                      autoComplete="off"
                      id="manager-position"
                      placeholder="Должность"
                    />
                  </div>
                  <div className="mb-3 col-6 pl-10">
                    <label htmlFor="manager-name" className="col-form-label position-relative grey-text-modal-user">
                      ФИО
                    </label>
                    <input
                      value={organizer.chiefFullName}
                      onChange={(e) => {
                        if (e.target.value === '' || (reName.test(e.target.value) && e.target.value.length <= 30)) {
                          setOrganization({
                            ...organizer,
                            chiefFullName: e.target.value,
                          });
                        } else {
                          alert('Допускаются цифры, русские и латинские буквы, некоторые символы');
                        }
                      }}
                      autoComplete="off"
                      name="fio1"
                      type="text"
                      className={organizer.chiefFullName.length ? inp : inpActive}
                      id="manager-name"
                      placeholder="ФИО"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-0">
              {/*{organizer.id ?  <button*/}
              {/*  type="button"*/}
              {/*  className="btn big-red-button"*/}
              {/*  onClick={delOrg}*/}
              {/*>*/}
              {/*    Удалить*/}
              {/*</button> : ''}*/}

              <button type="button" className="btn save-button" onClick={addFormGeneral}>
                Сохранить
              </button>
            </div>

            {organizer.id ? (
              <div className="experts-form">
                <h3>Сотрудники</h3>

                <div className="experts-list">
                  {organizer?.experts?.map((item) => (
                    <div className="experts-item">
                      {item.surname} {item.name} {item.patronymic} - {item.email}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalObshieSvedeniyaObOrganiz;

import { Col, Modal } from "antd";
import styled from "styled-components";

import Flex from '../../../atoms/flex';
import List from '../../../atoms/list';
import Button from '../../../atoms/nButton';

export const Buttons = styled(Flex)`
  padding-bottom: 24px;
`;

export const Colu = styled(Col)`
  border-right: 3px #B5B9C1 solid;
  padding-right: 20px;
`;
export const ModalBack = styled(Modal)`
  overflow: hidden;
  backdrop: 'static';

`;
export const ColCenter = styled(Col)`
  margin: auto;
  padding: 10px;
`;
export const Add = styled(Button)`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  padding: 15px 10px;
  white-space: nowrap;
  align: 'center';
`;

export const WorkplaceList = styled(List)`
  align-items: flex-start;
  flex-direction: column;

  li {
    min-width: 550px;
    width: 100%
  }
`;

export const Tbody = styled.tbody`
          display: block;
          width: 100%;
          height: 300px;
          overflow: auto;
`;

export const IconButtons = styled.div`
  display: flex;
`

export const IconButton = styled(Button)`
  margin: 0 10px 0 0;
`

import React from 'react';

export function useTabs(initialTab, allTabs) {
  const [currentIndex, setCurrentIndex] = React.useState(initialTab);
  if (!allTabs || !Array.isArray(allTabs)) {
    return;
  }
  return {
    currentIndex,
    currentItem: allTabs[currentIndex],
    changeItem: setCurrentIndex
  };
}
import React, {useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'

import FormOptions from './FormOptions'

export function canSubmit(form) {
  if (!form.name || !form.probability || !form.severity || !form.risk) {
    return false
  }

  for (const key of ['probabilities', 'severities', 'risks']) {
    // Проверка на нулевые значения в параметрах
    if (!form[key] || !form[key].length || form[key].filter((i) => i.id <= 0 || i.min <= 0 || i.max <= 0).length) {
      return false
    }
    // Проверка покрытия диапазон значений
    const maxValue = key === 'risks' ? 100 : 10
    let sum = 0
    form[key].forEach((i) => {
      sum += i.max - i.min + 1
    })
    if (sum !== maxValue) {
      return false
    }
  }

  return true
}


function MatrixForm({record, titles, disabled, onChange, onSubmit}) {
  const [form, setForm] = useState({
    name: '',
    description: '',
    probability: 0,
    severity: 0,
    risk: 0,
    probabilities: [],
    severities: [],
    risks: [],
    ...record,
  })

  function onFieldChange(key, {value}) {
    const newForm = {...form}
    newForm[key] = value
    setForm(newForm)
    onChange(newForm)
  }

  function onFormSubmit(e) {
    e.preventDefault()
    if (canSubmit(form)) {
      onSubmit(form)
    }
  }

  function PropertyHeader({field, title, placeholder = 'Выберите количество опций'}) {
    return (
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <Form.Select value={form[field]} disabled={disabled} onChange={({target}) => onFieldChange(field, target)}>
          <option key={0} value={0} disabled>{placeholder}</option>
          <option key={3} value={3}>3</option>
          <option key={4} value={4}>4</option>
          <option key={5} value={5}>5</option>
        </Form.Select>
      </Form.Group>
    )
  }

  return (
    <>
      <Form onSubmit={onFormSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Название матрицы</Form.Label>
          <Form.Control value={form.name} onChange={({target}) => onFieldChange('name', target)} readOnly={disabled} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Описание</Form.Label>
          <Form.Control value={form.description} onChange={({target}) => onFieldChange('description', target)} readOnly={disabled} as="textarea" rows="2" />
        </Form.Group>

        <Row className="mt-3">
          <Col md={6}>
            <PropertyHeader field="probability" title="Вероятности" placeholder="Выбрать кол-во вероятностей" />
            <FormOptions amount={form.probability} values={form.probabilities} titles={titles.probabilities} disabled={disabled} onChange={(value) => onFieldChange('probabilities', {value})} />
          </Col>
          <Col md={6}>
            <PropertyHeader field="severity" title="Тяжести" placeholder="Выбрать кол-во тяжестей" />
            <FormOptions amount={form.severity} values={form.severities} titles={titles.severities} disabled={disabled} onChange={(value) => onFieldChange('severities', {value})} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <PropertyHeader field="risk" title="Уровни риска" placeholder="Выбрать кол-во уровней" />
            <FormOptions amount={form.risk} values={form.risks} titles={titles.risks} maxValue={100} disabled={disabled} onChange={(value) => onFieldChange('risks', {value})} />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default MatrixForm
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as employersActions from "../../../store/actions/employersActions";
import { EMPLOYER_DATA_CLEAR } from "../../../store/actionTypes/employerTypes";
import AppButton from "../../core/AppButton";

import EmployerCard from "./Employers/EmployerCard/EmployerCard";
import ModalViewEmployer from "./Employers/ModalAddEmployer/ModalViewEmployer";
import HeaderOrganization from "./HeaderOrganization";

import "./Employers/employers.css";

function OrganizationEmployers() {
  const dispatch = useDispatch();
  const employers = useSelector((state) => state.employersReducer.list);

  useEffect(() => {
    if (!employers.length) {
      dispatch(employersActions.fetchEmployers());
    }
  }, [employers]);

  const [openModal, setOpenModal] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);

  function addNewGrade(e) {
    e.preventDefault();
    dispatch({ type: EMPLOYER_DATA_CLEAR });
    setOrganizationId(false);
    setOpenModal(true);
  }

  return (
    <>
      <HeaderOrganization />
      {openModal ? (
        <ModalViewEmployer
          visible={openModal}
          setVisible={setOpenModal}
          organizationId={organizationId}
        />
      ) : (
        ""
      )}

      <main>
        <div className="d-flex justify-content-between">
          <div className="form-floating mb-3">{/* <AppInput /> */}</div>
          <div>
            <AppButton
              onClick={addNewGrade}
              type="button"
              className="btn add-button"
            >
              Добавить
            </AppButton>
          </div>
        </div>

        <div className="card_bg">
          <table className="table table-hover table-form">
            <thead className="thead-form">
              <tr className="tr-bg">
                <th className="title_card br-8 col-2" scope="col">
                  <div className="d-flex">
                    Дата создания
                    {/* <div className="d-flex flex-column justify-content-center ml">
                                              <img
                                                  className="mnbtm-4"
                                                  src={shapeUp}
                                                  alt=""
                                              />
                                              <img
                                                  className=""
                                                  src={shapeDown}
                                                  alt=""
                                              />
                                          </div> */}
                  </div>
                </th>
                <th className="title_card col-2" scope="col">
                  <div className="d-flex">
                    Наименование
                    {/* <div className="d-flex flex-column justify-content-center ml">
                                              <img
                                                  className="mnbtm-4"
                                                  src={shapeUp}
                                                  alt=""
                                              />
                                              <img
                                                  className=""
                                                  src={shapeDown}
                                                  alt=""
                                              />
                                          </div> */}
                  </div>
                </th>
                <th className="title_card col-1" scope="col">
                  <div className="d-flex">
                    ИНН
                    {/* <div className="d-flex flex-column justify-content-center ml">
                                              <img
                                                  className="mnbtm-4"
                                                  src={shapeUp}
                                                  alt=""
                                              />
                                              <img
                                                  className=""
                                                  src={shapeDown}
                                                  alt=""
                                              />
                                          </div> */}
                  </div>
                </th>
                <th className="title_card col-2" scope="col">
                  <div className="d-flex">
                    Оценка
                    {/* <div className="d-flex flex-column justify-content-center ml">
                                              <img
                                                  className="mnbtm-4"
                                                  src={shapeUp}
                                                  alt=""
                                              />
                                              <img
                                                  className=""
                                                  src={shapeDown}
                                                  alt=""
                                              />
                                          </div> */}
                  </div>
                </th>
                <th className="title_card br-8-end" scope="col">
                  <div className="d-flex">
                    Субъект
                    {/* <div className="d-flex flex-column justify-content-center ml">
                                              <img
                                                  className="mnbtm-4"
                                                  src={shapeUp}
                                                  alt=""
                                              />
                                              <img
                                                  className=""
                                                  src={shapeDown}
                                                  alt=""
                                              />
                                          </div> */}
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="tbody-form">
              {employers && employers.length ? (
                employers?.map((employer) => (
                  <EmployerCard
                    setVisible={setOpenModal}
                    setOrganizationId={setOrganizationId}
                    key={employer.id}
                    employer={employer}
                  />
                ))
              ) : (
                <svg className="spinner" viewBox="0 0 50 50">
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke-width="5"
                  ></circle>
                </svg>
              )}

              <div className="height-76"></div>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {/* <td className="vertical-al-mid">
                                  <nav
                                      className="paginationPosition"
                                      aria-label=""
                                  >
                                      <ul className="pagination">
                                          <li className="page-item">
                                              <a
                                                  className="page-link radius-4 pagination-text"
                                                  href="#"
                                                  aria-label="Previous"
                                              >
                                                  <span aria-hidden="true">
                                                      <i className="bi bi-chevron-left"></i>
                                                  </span>
                                              </a>
                                          </li>
                                          <li className="page-item pddng-lft-4">
                                              <a
                                                  className="page-link radius-4 pagination-text"
                                                  href="#"
                                              >
                                                  1
                                              </a>
                                          </li>
                                          <li className="page-item pddng-lft-4">
                                              <a
                                                  className="page-link radius-4 pagination-text"
                                                  href="#"
                                              >
                                                  2
                                              </a>
                                          </li>
                                          <li className="page-item pddng-lft-4">
                                              <a
                                                  className="page-link radius-4 pagination-text"
                                                  href="#"
                                              >
                                                  3
                                              </a>
                                          </li>
                                          <li className="page-item pddng-lft-4">
                                              <a
                                                  className="page-link radius-4 pagination-text"
                                                  href="#"
                                                  aria-label="Next"
                                              >
                                                  <span aria-hidden="true">
                                                      <i className="bi bi-chevron-right"></i>
                                                  </span>
                                              </a>
                                          </li>
                                      </ul>
                                  </nav>
                              </td> */}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
}

export default OrganizationEmployers;

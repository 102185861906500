import React from 'react';

import * as Styled from './styled';

export default function Link(props) {
  const {
    children,
    href,
    onLink,
    target,
    rel,
    ...styled
  } = props;


  if (!children) {
    return null
  }

  return (
    <Styled.Link
      {...styled}
      href={href}
      target={target}
      rel={rel}
      onClick={onLink}
    >
      {children}
    </Styled.Link>
  )
}
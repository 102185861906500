import {useState} from 'react'

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (data) => {
    setValue(data.target ? data.target.value : data)
  }

  return {value, onChange}
}

export default useInput
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//TODO: Алешин поправить путь, что бы был автоматический
const httpLink = createHttpLink({
  uri: `https://riski.vcot.info/graphql/`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token ?? ''}` : '',
    },
  };
});

const link = ApolloLink.from([authLink, httpLink]);

const cache = new InMemoryCache({});

export const client = new ApolloClient({
  link: link,
  connectToDevTools: true,
  cache,
});

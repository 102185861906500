import React from "react";

const FormikContext = React.createContext();

export function useFormikContext() {
    return React.useContext(FormikContext);
}

export function FormikProvider({ children, formik }) {
    return (
        <FormikContext.Provider value={formik}>
            {children}
        </FormikContext.Provider>
    );
}

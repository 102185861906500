import * as actionTypes from '../actionTypes/gradeTypes';
import {AUTH_LOGOUT} from "../actionTypes/loginTypes";

const initialState = {
  list: [],
  grade: null,
  workPlace: null,
  matrixList: null,
}

export function gradeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GRADE_DATA_SUCCESS:
      return {...state, grade: action.payload}
    case actionTypes.GRADES_DATA_SUCCESS:
      return {...state, list: action.payload}
    case actionTypes.UPDATE_GRADE_PROJECT_SUCCESS:
      return {...state, grade: action.payload}
    case actionTypes.CREATE_GRADE_PROJECT_SUCCESS:
      return {...state, grade: action.payload}
    case actionTypes.GET_MATRIX_LIST:
      return {...state, matrixList: action.payload.rows}
    case AUTH_LOGOUT:
      return initialState

    default:
      return state
  }
}
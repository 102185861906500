

export const navMap = [
  {
    id: '1',
    path: '/knowledge-base/hac',
    label: 'ТП-ВР/НАС-МВР',
  },
  {
    id: '2',
    path: '/knowledge-base/sourcedanger',
    label: 'МВР-ИО',
  },
  {
    id: '3',
    path: '/knowledge-base/typesource',
    label: 'ВР-ИО',
  },

  {
    id: '4',
    path: '/knowledge-base/dangermeasure',
    label: 'ИО-О-МР',
  },
  {
    id: '5',
    path: '/knowledge-base/eventsout',
    label: 'ОС-СОУТ'
  },
  {
    id: '6',
    path: '/knowledge-base/typicalok',
    label: 'ТП-ОКПДТР'
  }

]

import React from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export function usePostProjectEvaluationcardHazard({ isRender, project, workplace, onShowAddDanger }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [],
    },
  });

  const formikAddDanger = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: {
        work_place: {
          id: null,
          name: null,
        },
        work_type: {
          id: null,
          name: null,
        },
        source_danger: {
          id: null,
          name: null,
        },
        danger: {
          id: null,
          name: null,
        },
        measures: [],
        custom_measures: [],
      },
      isCommonHazards: true,
    },
  });

  const additionalControlsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [
        {
          name: '',
        },
      ],
    },
  });

  const onPostProjectEvaluationcardHazard = async () => {
    try {
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/projecthazard`, {
        project,
        page: '',
      });

      if (data?.data) {
        formik?.setFieldValue('data', data?.data);
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostEvaluationcardEdithazard = async (hazard_id) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/editprojecthazard', {
        hazard_id,
      });

      if (data.status === 'success') {
        formikAddDanger?.setFieldValue('data', data?.data);
        onShowAddDanger.on();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    } finally {
    }
  };

  const onPostEvaluationcardDelete = async (hazard_id) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/deleteprojecthazard', {
        hazard_id,
        workplace_id: workplace,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        onPostProjectEvaluationcardHazard();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostEvaluationcardAdditionaledit = async (hazard_id) => {
    try {
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/additionalprojectedit`, {
        hazard_id,
      });

      if (data?.status === 'success') {
        additionalControlsFormik?.setFieldValue('data', !data?.data?.length ? [{ name: '' }] : data?.data);
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  React.useEffect(() => {
    if (isRender) {
      onPostProjectEvaluationcardHazard();
    }
  }, [isRender]);

  return {
    dataRows: formik.values?.data,
    formikAddDanger,
    onPostProjectEvaluationcardHazard,
    onPostEvaluationcardEdithazard,
    onPostEvaluationcardDelete,
    onPostEvaluationcardAdditionaledit,
    additionalControlsFormik,
  };
}

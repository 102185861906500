import React from 'react';

import {DeleteReport} from "./fields/deleteReport";
import {DownloadReport} from './fields/downloadReport';

export const getTableRows = ({
  data,
  reload
}) => {

  if (!data?.length) {
    return [];
  }


  return data?.map((item, index) => {
    return {
      key: index,
      cells: [
        {
          key: 'delete',
          width: 87,
          content: (
            <DeleteReport
              link={item?.link}
              //idfile = {item?.id}
              reload={reload}
            />
          ),
        },
        {
          key: 'name',
          label: item?.name,
          type: 'view',
          align: 'center',
        },
        {
          key: 'file',
          content: (
            <div>{item?.file}</div>
          ),
          align: 'left',
        },
        {
          key: 'date',
          content: (
            <div>{item?.date}</div>
          ),
          align: 'center',
        },
        {
          key: 'actions',
          width: 87,
          content: (
            <DownloadReport
              link={item?.link}
              file={item?.file}
            />
          ),
        }
      ],
    };
  });
};

export const tableHead = [
  {
    key: 'delete',
    label: '',
    width: 87,
    align: 'center',
  },
  {
    key: 'name',
    label: 'Наименование отчета',
    type: 'header',
    align: 'center',
  },
  {
    key: 'file',
    label: 'Наименование файла',
    type: 'header',
    align: 'center',
  },
  {
    key: 'date',
    label: 'Дата и время',
    type: 'header',
    align: 'center',
  },
  {
    key: 'actions',
    label: '',
    width: 87,
    align: 'center',
  },
];

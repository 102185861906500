import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { App as AntApp, ConfigProvider } from 'antd';
import ruRu from 'antd/locale/ru_RU';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from './Components/App/App';
import AppBoot from './Components/App/AppBoot';
import Spinner from './Components/spinner';
import rootReducer from './store/reducers/rootReducer';
import { theme } from './theme/theme';
import { CommonPropsProvider } from './common';
import { client } from './graphql';

Sentry.init({
  dsn: 'http://fdde0700952941b6b1b4bed605e9be87@sentry.vcot.info/3',
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <CookiesProvider>
          <Suspense fallback={<Spinner />}>
            <CommonPropsProvider>
              <ConfigProvider theme={theme} locale={ruRu}>
                <AntApp>
                  <App />
                </AntApp>
              </ConfigProvider>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                pauseOnFocusLoss={false}
                draggable={true}
                pauseOnHover={true}
              />
            </CommonPropsProvider>
          </Suspense>
        </CookiesProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
AppBoot();

import React, {memo} from "react";

import {cx} from "../../../../../utils";
import Table from "../../../../table";

function TableWrapper({title, className, columns, data}) {
    const classNames = cx('management-results__stat', className)
    return (
        <div className={classNames}>
            <span className="management-results__stat-title">{title}</span>
            <Table columns={columns} data={data}/>
        </div>
    )
}

export default memo(TableWrapper)

import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export const useTraining = ({ workplace }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: [],
      info: [],
    },
  });

  const onPostTraining = async () => {
    try {
      formik.setFieldValue('loading', true);

      const { data } = await axios.post('/api/project/riskassessment/training', {
        workplace,
      });

      if (data.status === 'success') {
        formik?.setFieldValue('data', data?.data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
      formik.setFieldValue('loading', false);
    }
  };

  const onPostTrainingSave = async (payload) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/training/save', {
        workplace,
        ...payload,
      });
      if (data.status === 'success') {
        const temp = formik.values.data.map((el) => {
          if (el.training_type === data.data.training_type) {
            return {
              ...data.data,
            };
          }
          return {
            ...el,
          };
        });
        formik?.setFieldValue('data', temp);
        toast['success'](data?.msg);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostTrainingClarify = async () => {
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post('/api/project/riskassessment/training/clarify', {
        workplace,
      });
      if (data.status === 'success') {
        formik?.setFieldValue('info', data?.data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      formik.setFieldValue('loading', false);
      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostTrainingClarifySave = async () => {
    try {
      formik.setFieldValue('loading', true);
      const payload = {};
      const onlyCheckbox = formik.values.info.filter((el) => el.type === 'checkbox');

      onlyCheckbox.forEach((el) => {
        payload[`${el.id}`] = el.checked;
      });

      const { data } = await axios.post('/api/project/riskassessment/training/clarify/save', {
        workplace,
        ...payload,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        await onPostTraining();
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      formik.setFieldValue('loading', false);
      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  useEffect(() => {
    onPostTraining();
  }, []);

  const onChangeInfo = (elemID, event) => {
    const info = formik.values.info.map((el) => {
      if (el.id === elemID) {
        return {
          ...el,
          checked: event.target.checked,
        };
      }
      return el;
    });
    formik?.setFieldValue('info', info);
  };

  return {
    loading: formik?.values?.loading,
    data: formik.values.data,
    info: formik.values.info,
    onChangeInfo,
    onPostTraining,
    onPostTrainingSave,
    onPostTrainingClarify,
    onPostTrainingClarifySave,
  };
};

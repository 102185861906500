import { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";

import useInput from "../../../../hooks/useInput";

function PermissionsForm({ record, onChange }) {
  const [permissions, setPermissions] = useState(record.permissions || []);
  const resource = useInput("");
  const access = useInput("ro");

  function GetRows() {
    const rows = [];
    permissions.forEach((row) => {
      rows.push(
        <tr key={row.resource} valign="middle">
          <td>{row.resource}</td>
          <td>{row.access === "rw" ? "Запись" : "Чтение"}</td>
          <td className="text-center">
            <Button variant="light" size="sm" onClick={() => onDelete(row)}>
              <i className="bi bi-x-lg" />
            </Button>
          </td>
        </tr>
      );
    });
    return rows;
  }

  function canAdd() {
    return Boolean(resource.value.length);
  }

  function onAdd() {
    if (canAdd()) {
      if (!permissions.filter((i) => i.resource === resource.value).length) {
        const newPermissions = [...permissions];
        newPermissions.unshift({
          resource: resource.value,
          access: access.value,
        });
        setPermissions(newPermissions);
        onChange({ ...record, permissions: newPermissions });
      }
      resource.onChange("");
    }
  }

  function onDelete(row) {
    const newPermissions = permissions.filter(
      (i) => i.resource !== row.resource
    );
    setPermissions(newPermissions);
    onChange({ ...record, permissions: newPermissions });
  }

  function onEnter({ code }) {
    if (code === "Enter") {
      onAdd();
    }
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Ресурс</th>
            <th>Доступ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr valign="middle">
            <td>
              <Form.Control {...resource} onKeyDown={onEnter} />
            </td>
            <td className="col-4">
              <Form.Select
                onChange={access.onChange}
                defaultValue={access.value}
              >
                <option value="ro">Чтение</option>
                <option value="rw">Запись</option>
              </Form.Select>
            </td>
            <td className="text-right">
              <Button
                variant="success"
                size="sm"
                disabled={!canAdd()}
                onClick={onAdd}
              >
                <i className="bi bi-check-lg" />
              </Button>
            </td>
          </tr>

          <GetRows />
        </tbody>
      </Table>
    </>
  );
}

export default PermissionsForm;

import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';

const CellCheckbox = ({ record, onSave }) => {
  const [state, setState] = useState(record.check);

  useEffect(() => {
    setState(record.check);
  }, [record.check]);

  const handleChange = (event) => {
    setState(event.target.checked);
    onSave({
      training_type: record.training_type,
      check: event.target.checked,
      id: record.id,
    });
  };

  return <Checkbox checked={state} onChange={handleChange} />;
};

export default CellCheckbox;

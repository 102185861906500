import React from "react";

export default function useKeyEvents(key, callback) {
  const [keyPressed, setKeyPressed] = React.useState(false);

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === key) {
        e.preventDefault();
        setKeyPressed(true);
        callback && callback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [key, callback]);

  return keyPressed;
};
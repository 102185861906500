import styled, { keyframes } from "styled-components";

const slideInTop = keyframes`
    from {
        width: 0
    }
    to {
        width: 100%
    }
`;

export const StyledTabs = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
    list-style: none;
    padding: 0;
`;

export const StyledBTabsButton = styled.li`
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    line-height: 48px;
    padding: 0 16px 4px;
    position: relative;
    border-bottom: 4px solid #f4f5f7;

    button {
        background-color: transparent;
        border: 0;
    }

    &::after {
        content: "";
        height: 4px;
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 0;
        background-color: #00a79d;
    }

    &.tab-active::after {
        animation: ${slideInTop} 0.8s ease;
        width: 100%;
    }
`;

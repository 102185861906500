import React from "react";
import { toast } from "react-toastify";
import { Modal } from 'antd';
//import { Form, Formik, useFormik} from 'formik';
//import Input from '../../../../../atoms/input';
import axios from "axios";

//import FieldInput from '../../../../../formik/fields/input'
import Button from '../../../../../atoms/nButton';

import * as Styled from './styled';

export default function NewParent({showModal, table, data, parent, onClose, red}){

  //console.log("code " + parent)

function Save(){
  const form = document.getElementById('newParent');
  axios.post('/api/classifier/parent/insert',
    {
      table: table,
      //id: data.id,
      parent: parent,
      code: form.Cod.value,
      name: form.Name.value

    }
  )
  .then(function(response){
    //console.log(props.del)
    //console.log(response.data.code)
    if(response.data.code === 200){
    toast['success']('Раздел добавлен')
    console.log('table 2 :' + table);
    red();
    onClose();

    }else if(response.data.code === 201){
      //console.log(response.code)
      toast['error'](response.data.msg)
    }
    //setRight(response.data);
  })

}

  return (

      <Modal
      open= {showModal}
      title={"Добавить раздел"}
      //onOk={okSave}
      //modalRender={true}
      destroyOnClose={true}
      onCancel={onClose}
      width={800}
      height={300}
      footer={false}
      >
      <form id="newParent" preserve={false}>
        <table>
          <tr>
            <th width={'20%'} style={{textAlign: 'center', "padding-top": "10px"}}>
              Код
            </th>
            <th style={{textAlign: 'center', "padding-top": "10px"}}>
              Наименование раздела
            </th>
          </tr>
          <tr >
            <td align="center" style={{"padding-top": "10px"}}>

              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Код"}
                    id={'Cod'}
                    //defaultValue={data.code}
                    //formik={formik.values.Cod}

                    name= {'Сod'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>



            </td>
            <td style={{"padding-top": "10px"}}>
              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Рздел"}
                    id={'Name'}
                    //defaultValue={data.name}
                    //formik={formik.values.Cod}

                    name= {'Name'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>

            </td>
          </tr>
        </table>

        <Styled.Buttons jContent={'right'}>
        <Button
              variant={'secondary'}
              onClick={Save}
              //disabled={!isDisabled}
            >
              Добавить
            </Button>
        </Styled.Buttons>
        </form>

      </Modal>

  )


}

import React from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../../axios/axios';
import { logWarn } from '../../../../../utils/log';

export function useSoutResults({ workplace, onShow, onPostProjectHazard }) {
  const formikSout = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [],
    },
  });

  React.useEffect(() => {
    onPostSoutResults();
  }, []);

  const onPostSoutResults = async () => {
    try {
      const { data } = await axios.post(`/api/reference/sout`, {
        workplace,
      });

      if (data?.data) {
        formikSout?.setFieldValue('data', data?.data);
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostSoutSave = async () => {
    try {
      const { data } = await axios.post(`/api/reference/soutsave`, {
        workplace,
        sout: formikSout.values?.data?.map((sout) => ({
          id: sout?.id,
          value: sout?.value,
        })),
      });

      if (data?.status === 'success') {
        toast['success'](data?.msg);
        onPostProjectHazard();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    } finally {
      onShow.off();
    }
  };

  return {
    formikSout,
    onPostSoutSave,
  };
}

//import React from "react";
//import axios from "axios";

//import { responsiveArray } from "antd/es/_util/responsiveObserver";
//import axios from "axios";

//import axios from "axios";


export const classifiers = [
  {
    key: '1',
    date: '23.07.2023',
    name: 'Опасности',
    table: 'dangers'
  },
  {
    key: '2',
    date: '23.07.2023',
    name: 'Типы работ',
    table: 'work_types',

  },
  {
    key: '3',
    date: '23.07.2023',
    name: 'Источники опасностей',
    table: 'danger_sources',

  },
  {
    key: '4',
    date: '23.07.2023',
    name: 'Места выполнения работ',
    table: 'work_places',

  },
  {
    key: '5',
    date: '23.07.2023',
    name: "Меры управления рисками",
    table: 'mitigation_measures',

  },

];


export async function table(){
await fetch('/api/classifier/table')
.then(function(response){
  return Promise.all([response.json()])
  })
.then(function(arr){
  let dat  = arr[0].data;
  console.log('www '  + arr)
  return  dat;
})
//.then(function(data) {
//    console.log(data);
//})

  /*
  await axios.post('/api/classifier/table')
    .then(await function(response){

      table = response.data.data
      console.log(table)
      return table
    })
      */
}



export const reference = [

];



import React from 'react';

import * as Styled from './svg.styled';

export default function File({ fill = '#1E85EB', width = '35', height = '35', viewBox = '0 0 35 35' }) {
  const widthString = typeof width === 'number' ? `${width}px` : width;
  const heightString = typeof height === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M20.4154 2.91406H8.7487C7.97515 2.91406 7.23328 3.22135 6.6863 3.76833C6.13932 4.31532 5.83203 5.05718 5.83203 5.83073V29.1641C5.83203 29.9376 6.13932 30.6795 6.6863 31.2265C7.23328 31.7734 7.97515 32.0807 8.7487 32.0807H26.2487C27.0222 32.0807 27.7641 31.7734 28.3111 31.2265C28.8581 30.6795 29.1654 29.9376 29.1654 29.1641V11.6641L20.4154 2.91406Z"
        stroke="#1E85EB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.418 2.91406V11.6641H29.168"
        stroke="#1E85EB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23.3346 18.9609H11.668" stroke="#1E85EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.3346 24.7891H11.668" stroke="#1E85EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.5846 13.125H13.1263H11.668"
        stroke="#1E85EB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Svg>
  );
}

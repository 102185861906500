import React from 'react';
import { toast } from "react-toastify";

import axios from "../../../axios/axios";
import { logWarn } from "../../../utils/log";

export function useGetRequests() {
  const [dataRows, setDataRows] = React.useState([]);

  React.useEffect(() => {
    onGetRequests();
  }, [])

  const onGetRequests = async () => {

    try {
      const { data } = await axios.get('/api/user/registr/request/view');
      if (data?.data?.data?.length) {
        setDataRows(data?.data?.data);
      }

    } catch (e) {
      setDataRows(e.data);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  const onPostRequestFind = async (value) => {
    try {
      const { data } = await axios.post('/api/user/registr/request/find', {value});

      if (data?.data?.data?.length) {
        setDataRows(data?.data?.data);
      }

    } catch (e) {
      setDataRows(e.data);
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685386993950",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    dataRows,
    onGetRequests,
    onPostRequestFind
  }
}

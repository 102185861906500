import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Link from '../../../atoms/link';

export const Buttons = styled(Flex)`
  padding-bottom: 24px;
`;

export const RecoverAccount = styled(Link)`
  margin: 10px 0 13px 10px;
  display: block;
`;

export const PostalCode = styled.div`
  width: 192px;
`
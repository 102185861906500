import React from "react";

import { useFormikContext } from "../../formik/formik.context";

import { StyledInput } from "./decimal.styled";

export default function Decimal(props) {
  const { name, auxiliaryProps } = props;
  const formikProps = useFormikContext();
  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps = () => {}, setFieldValue } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};

  const handleChange = (e) => {
    let start = e.target.selectionStart;
    let val = e.target.value;

    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    const vIsNaN = isNaN(value) ? "0.0" : value;

    e.target.value = Number(vIsNaN).toFixed(1);
    e.target.setSelectionRange(start, start);
    setFieldValue(name, Number(vIsNaN).toFixed(1));
  };

  return (
    <StyledInput
      type={"text"}
      onChange={handleChange}
      value={value?.toString()}
    />
  );
}

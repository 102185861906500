import React from 'react';

import * as Styled from './styled';
import Tcell from './tCell';

export default function Table(props) {
  const { loading, head, data, skeletonSize = 10 } = props;
  const skeletonArray = [...Array(skeletonSize).keys()];

  if (!data?.length && !loading) {
    return null;
  }

  return (
    <Styled.Table>
      <table>
        <Styled.Thead>
          <tr>
            {head?.map((el, index) => (
              <Tcell
                key={`${el.key}-${index}`}
                type={el.type}
                label={el.label}
                width={el.width}
                align={el.align}
                onSort={el.onSort}
                isSortby={el.isSortby}
              />
            ))}
          </tr>
        </Styled.Thead>
        <Styled.Tbody>
          {loading && skeletonSize
            ? skeletonArray?.map((elData, index) => (
                <tr key={`${elData.key}-${index}`}>
                  {head?.map((elHead, i) => {
                    const findKey = elData.cells?.find((el) => el.key === elHead.key);

                    return <Tcell key={`${elHead.key}-${i}`} width={findKey?.width} content={<Styled.Sceleton />} />;
                  })}
                </tr>
              ))
            : ''}
          {!loading
            ? data?.map((elData, index) => (
                <tr key={`${elData.key}-${index}`}>
                  {head?.map((elHead, i) => {
                    const findKey = elData.cells?.find((el) => el.key === elHead.key);

                    return (
                      <Tcell
                        key={`${elHead.key}-${i}`}
                        type={findKey?.type}
                        align={findKey?.align}
                        width={findKey?.width}
                        label={findKey?.label}
                        content={findKey?.content}
                      />
                    );
                  })}
                </tr>
              ))
            : null}
        </Styled.Tbody>
      </table>
    </Styled.Table>
  );
}

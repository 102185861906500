import React from 'react';
import { Outlet } from 'react-router-dom';

import HeaderAdmin from '../../blocks/headerAdmin';

import { navMap } from './navMap';

const InformationLayout = () => {
  return (
    <>
      <HeaderAdmin navMap={navMap} />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default InformationLayout;

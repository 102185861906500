import { useFormikContext } from "../../formik/formik.context";

export function useFormikWrapper(props) {
  const formikProps = useFormikContext();
  const { columnId } = props;
  const { setFieldValue, getFieldProps } = formikProps ?? {};
  const { name, value } = getFieldProps("data") ?? {};
  const deleteRow = value.filter((_, index) => index !== columnId);

  const handleDelete = () => {
    setFieldValue(name, deleteRow);
  };

  return handleDelete;
}

import AutoCompleteFilter from './autoCompleteFilter';
import Checkbox from './checkboxField';
import CheckboxFilter from './checkboxFilter';
import DataView from './dateView';
import Decimal from './decimalField';
import DownloadLink from './downloadLink';
import Input from './inputField';
import IsNeedApprove from './isNeedApproveField';
import IsNeedApproveTwo from './isNeedApproveFieldTwo';
import Select from './selectField';
import SizView from './sizView';
import UpDownFilter from './upDownFilter';
import ViewField from './viewField';

function getComponent(component) {
  const components = {
    checkbox: Checkbox,
    isNeedApprove: IsNeedApprove,
    isNeedApproveTwo: IsNeedApproveTwo,
    select: Select,
    downloadLink: DownloadLink,
    input: Input,
    decimal: Decimal,
    sizView: SizView,
    dataView: DataView,
  };

  return components[component] ?? ViewField;
}

function getFilter(filter) {
  const filters = {
    autoComplete: AutoCompleteFilter,
    checkbox: CheckboxFilter,
    upDown: UpDownFilter,
  };

  return filters[filter] ?? ViewField;
}

export { Checkbox, getComponent, getFilter, Input, Select, ViewField };

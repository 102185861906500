import React from 'react';

import { getObjectProperty, ifObject } from '../../fp';
import { getComponent } from '../formikFields';
import Sceleton from '../skeleton';

import TCell from './cell';
import ControlRow from './controlRow';
import TRow from './row';

function TBodyCellInner(props) {
  const { el, index, elData, controlRows } = props;
  const { field, column, auxiliaryProps } = el;
  const TagName = getComponent(field);
  const objectPath = getObjectProperty(elData);
  const ifObjectPathValue = !!column.split('.')?.length
    ? objectPath(column)
    : elData[column];

  if (!ifObject(elData)) {
    return (
      <TCell>
        <Sceleton />
      </TCell>
    );
  }

  if (!column && controlRows) {
    return (
      <TCell>
        <ControlRow columnId={index} />
      </TCell>
    );
  }

  return (
    <TCell cell={auxiliaryProps?.cell}>
      <TagName
        {...el}
        index={index}
        name={`data.${index}.${column}`}
        value={ifObjectPathValue}
      />
    </TCell>
  );
}

function TBodyCell(props) {
  const { index, columns, elData, numberOfcolumns, ...ownerprops } = props;

  if (!columns?.length) {
    return [...Array(numberOfcolumns)].map((_, i) => (
      <TCell key={i}>
        <Sceleton />
      </TCell>
    ));
  }

  return (
    <React.Fragment>
      {columns?.map((el, key) => (
        <TBodyCellInner
          {...ownerprops}
          key={key}
          el={el}
          index={index}
          elData={elData}
        />
      ))}
    </React.Fragment>
  );
}

export default function TBody(props) {
  const {
    columns,
    data = [],
    numberOfcolumns,
    numberOfData,
    ...ownerprops
  } = props;

  if (!data?.length || !columns?.length) {
    return [...Array(numberOfData)].map((_, i) => (
      <TRow key={i}>
        <TBodyCell
          index={i}
          columns={columns}
          numberOfcolumns={numberOfcolumns}
        />
      </TRow>
    ));
  }

  return (
    <React.Fragment>
      {data?.map((elData, i) => (
        <TRow key={i}>
          <TBodyCell
            {...ownerprops}
            index={i}
            columns={columns}
            elData={elData}
          />
        </TRow>
      ))}
    </React.Fragment>
  );
}

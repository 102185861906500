import React, {useEffect, useState} from 'react'
import { Pagination } from 'antd';

import axios from '../../../../axios/axios'
import InputSearch from '../../../atoms/search';
import Table from '../../../blocks/table';

import ViewModal from './Modal/Modal';
import {getTableRows,tableHead} from './helpers';

export const url = '/api/logs'

const CONST_LIMIT = 50

function UsersLogs() {
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [record, setRecord] = useState(null)

  useEffect(() => {
    loadTable(1, CONST_LIMIT)
  }, [])


  const loadTable = async function(page, limit) {
    try {
      const {data} = await axios.get(url + '/list?limit=' + limit + '&page=' + page)
      setRows([...data.rows])
      setTotal(data.total)
    } catch (e) {
      console.error(e)
    }
  }

  const handleChangeSearchUsersLogs = async (event) => {

    if (event.target.value?.length < 3) {
      return null
    }

    try {
      const {data} = await axios.post('/api/logs/find', {
        page: 1,
        limit: CONST_LIMIT,
        value: event.target.value ?? 'pet'
      });

      setRows([...data.rows])
      setTotal(data.total)
    } catch (e) {
      console.error(e)
    }
  };

  const viewRecord = (props) => {
    setShowModal(true)
    setRecord(props)
  }

  const onShowSizeChange = (current, pageSize) => {
    loadTable(current, pageSize);
  };

  const onModalHide = () => {
    setShowModal(false)
  }

  const rowsTest = getTableRows(
    rows,
    viewRecord
  );



  return (
    <React.Fragment>
      <div style={{width: 465}}>
        <InputSearch placeholder={'Поиск'} onChange={handleChangeSearchUsersLogs} />
      </div>
      <div className="bg-white mt-3">
        <Table
          head={tableHead}
          data={rowsTest}
          class={'table'}
        />
        <div style={{
          display: 'flex',
          paddingTop: 25,
          justifyContent: 'flex-end'
        }}>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={50}
            total={total}
            onChange={onShowSizeChange}
          />
        </div>
      </div>
      {<ViewModal show={showModal} record={record} onHide={onModalHide} />}
    </ React.Fragment>
  )
}

export default UsersLogs

import React from 'react';

const MainLogo = () => {
  return (
    <svg width="179" height="67" viewBox="0 0 179 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.21727 1.04504V12.1876H7.75944V2.2448H4.08736V5.62342C4.08736 8.03672 3.99109 10.7396 2.42324 11.7877C2.01064 12.0497 1.36425 12.3117 0.717852 12.3117L0.539062 11.1533C0.979162 11.0706 1.44677 10.8086 1.70808 10.5603C2.60203 9.69155 2.68455 7.51269 2.68455 5.73374V1.03125H9.21727V1.04504Z"
        fill="#2EACE3"
      />
      <path
        d="M13.5078 8.68474L12.3525 12.1875H10.8672L14.6493 1.04492H16.3822L20.178 12.1875H18.6377L17.4549 8.68474H13.5078ZM17.1524 7.55394L16.0659 4.3408C15.8183 3.60992 15.6533 2.94798 15.4882 2.29984H15.4607C15.2957 2.96177 15.1169 3.6375 14.8968 4.31322L13.8104 7.54015H17.1524V7.55394Z"
        fill="#2EACE3"
      />
      <path
        d="M28.0445 1.04492V2.25846H23.2447V5.25096C23.5885 5.1958 24.2487 5.16822 24.62 5.16822C25.679 5.16822 26.7105 5.40265 27.4944 5.91289C28.3333 6.4645 28.911 7.33329 28.911 8.64337C28.911 9.67764 28.5671 10.4775 27.9895 11.0567C27.0818 11.953 25.5827 12.2978 24.0699 12.2978C23.0659 12.2978 22.3095 12.2288 21.8281 12.1599V1.04492H28.0445ZM23.2447 11.0843C23.5748 11.1532 23.9599 11.167 24.455 11.167C25.3489 11.167 26.2566 10.8912 26.793 10.312C27.1781 9.91208 27.3981 9.37426 27.3981 8.67095C27.3981 7.81595 27.0406 7.23676 26.4904 6.85063C25.9128 6.45071 25.1701 6.28523 24.3724 6.28523C24.0424 6.28523 23.6023 6.31281 23.2309 6.35418V11.0843H23.2447Z"
        fill="#2EACE3"
      />
      <path
        d="M40.3256 6.50546C40.3256 10.3392 38.0013 12.3801 35.1682 12.3801C32.2387 12.3801 30.1758 10.0909 30.1758 6.72611C30.1758 3.182 32.3763 0.865234 35.3332 0.865234C38.3589 0.865234 40.3256 3.19579 40.3256 6.50546ZM31.6886 6.68474C31.6886 9.07046 32.9814 11.1942 35.2369 11.1942C37.5062 11.1942 38.799 9.09804 38.799 6.56063C38.799 4.34039 37.6437 2.02362 35.2507 2.02362C32.8851 2.03741 31.6886 4.23007 31.6886 6.68474Z"
        fill="white"
      />
      <path
        d="M42.1953 1.18158C42.883 1.07126 43.7907 0.960938 44.9459 0.960938C46.3625 0.960938 47.4077 1.2919 48.0679 1.88488C48.673 2.40892 49.0444 3.22255 49.0444 4.21545C49.0444 5.22214 48.7418 6.02197 48.1917 6.60117C47.4352 7.41479 46.1975 7.8285 44.7946 7.8285C44.3683 7.8285 43.9695 7.81471 43.6394 7.73197V12.2H42.2091V1.18158H42.1953ZM43.6256 6.546C43.942 6.62875 44.3408 6.65633 44.8084 6.65633C46.5413 6.65633 47.6003 5.81512 47.6003 4.27061C47.6003 2.79505 46.555 2.09174 44.9734 2.09174C44.3408 2.09174 43.8732 2.1469 43.6256 2.20207V6.546Z"
        fill="white"
      />
      <path
        d="M51.3828 8.68474L50.2275 12.1875H48.7422L52.5243 1.04492H54.2572L58.053 12.1875H56.5127L55.3299 8.68474H51.3828ZM55.0274 7.55394L53.9409 4.3408C53.6933 3.60992 53.5283 2.94798 53.3632 2.29984H53.3357C53.1707 2.96177 52.9919 3.6375 52.7718 4.31322L51.6854 7.54015H55.0274V7.55394Z"
        fill="white"
      />
      <path d="M61.0648 2.27226H57.6953V1.04492H65.9197V2.27226H62.5227V12.2013H61.0648V2.27226Z" fill="white" />
      <path
        d="M76.0834 6.50546C76.0834 10.3392 73.7591 12.3801 70.926 12.3801C67.9966 12.3801 65.9336 10.0909 65.9336 6.72611C65.9336 3.182 68.1341 0.865234 71.091 0.865234C74.1167 0.865234 76.0834 3.19579 76.0834 6.50546ZM67.4602 6.68474C67.4602 9.07046 68.753 11.1942 71.0085 11.1942C73.2915 11.1942 74.5705 9.09804 74.5705 6.56063C74.5705 4.34039 73.4153 2.02362 71.0222 2.02362C68.643 2.03741 67.4602 4.23007 67.4602 6.68474Z"
        fill="white"
      />
      <path
        d="M77.957 1.18158C78.6447 1.07126 79.5524 0.960938 80.7077 0.960938C82.1242 0.960938 83.1695 1.2919 83.8296 1.88488C84.4348 2.40892 84.8061 3.22255 84.8061 4.21545C84.8061 5.22214 84.5035 6.02197 83.9534 6.60117C83.197 7.41479 81.9592 7.8285 80.5564 7.8285C80.13 7.8285 79.7312 7.81471 79.4011 7.73197V12.2H77.9708V1.18158H77.957ZM79.3874 6.546C79.7037 6.62875 80.1025 6.65633 80.5701 6.65633C82.303 6.65633 83.362 5.81512 83.362 4.27061C83.362 2.79505 82.3168 2.09174 80.7352 2.09174C80.1025 2.09174 79.6349 2.1469 79.3874 2.20207V6.546Z"
        fill="white"
      />
      <path
        d="M88.092 1.04492V5.74741C88.092 7.51257 88.0645 8.97434 87.9545 10.4637L87.982 10.4775C88.5459 9.3191 89.2335 8.11934 90.045 6.79547L93.6345 1.05871H95.0648V12.2013H93.7308V7.4574C93.7308 5.65088 93.7445 4.32701 93.8683 2.9204L93.8133 2.90661C93.2632 4.13394 92.5068 5.41644 91.7504 6.65757L88.2708 12.2013H86.7305V1.05871H88.092V1.04492Z"
        fill="white"
      />
      <path
        d="M96.5898 12.1862C96.7549 11.8828 96.9062 11.5381 97.0299 11.1795C97.5113 9.91084 97.8001 8.35253 98.7078 7.44238C98.9691 7.18036 99.2992 6.97351 99.6293 6.87697V6.82181C98.254 6.58738 97.1675 5.59447 97.1675 4.07754C97.1675 3.09843 97.5663 2.32617 98.1852 1.81593C98.8729 1.25053 99.9594 0.960938 101.362 0.960938C102.297 0.960938 103.288 1.04368 104.017 1.19537V12.2H102.586V7.37342H101.527C100.84 7.37342 100.317 7.52511 99.9318 7.92503C99.1754 8.69729 98.8591 10.3383 98.4603 11.4002C98.3502 11.676 98.2677 11.9104 98.1027 12.2138H96.5898V12.1862ZM102.614 2.20207C102.366 2.1469 101.899 2.06416 101.266 2.06416C99.8493 2.06416 98.6391 2.61577 98.6391 4.1465C98.6391 5.48415 99.8218 6.2702 101.39 6.2702C101.871 6.2702 102.325 6.2702 102.614 6.25641V2.20207Z"
        fill="white"
      />
      <path
        d="M2.32615 18.9727L4.5404 23.7165C4.81547 24.3784 5.09053 25.0266 5.35184 25.6885H5.37934C5.57189 25.1093 5.79194 24.4612 6.067 23.6751L7.79989 18.9727H9.31273L6.98846 24.6956C6.38332 26.185 5.68191 27.8812 4.91174 28.9292C4.18282 29.8394 3.37139 30.3083 2.39492 30.3083C2.03734 30.3083 1.76228 30.2807 1.58349 30.2117L1.73477 29.0396C1.84479 29.0671 2.00983 29.0947 2.25739 29.0947C3.41265 29.0809 4.08655 27.9639 4.43038 27.1779C4.5404 26.9296 4.5129 26.7642 4.37536 26.5022L0.675781 18.9864H2.32615V18.9727Z"
        fill="white"
      />
      <path d="M18.5415 18.9727V30.1152H17.1112V20.1724H11.9538V30.1152H10.5234V18.9727H18.5415Z" fill="white" />
      <path
        d="M21.0586 19.1093C21.7462 18.999 22.654 18.8887 23.8092 18.8887C25.2258 18.8887 26.271 19.2196 26.9312 19.8126C27.5363 20.3366 27.9076 21.1503 27.9076 22.1432C27.9076 23.1499 27.6051 23.9497 27.0549 24.5289C26.2985 25.3425 25.0608 25.7562 23.6579 25.7562C23.2316 25.7562 22.8327 25.7424 22.5027 25.6597V30.1278H21.0723V19.1093H21.0586ZM22.4889 24.4737C22.8052 24.5565 23.2041 24.5841 23.6717 24.5841C25.4046 24.5841 26.4636 23.7429 26.4636 22.1983C26.4636 20.7228 25.4183 20.0195 23.8367 20.0195C23.2041 20.0195 22.7365 20.0746 22.4889 20.1298V24.4737Z"
        fill="white"
      />
      <path
        d="M30.2461 26.6125L29.0908 30.1152H27.6055L31.3876 18.9727H33.1205L36.9163 30.1152H35.376L34.1932 26.6125H30.2461ZM33.8906 25.4817L32.8041 22.2685C32.5566 21.5376 32.3916 20.8757 32.2265 20.2276H32.199C32.034 20.8895 31.8552 21.5652 31.6351 22.241L30.5486 25.4679H33.8906V25.4817Z"
        fill="white"
      />
      <path
        d="M38.5508 19.1231C39.1834 18.9852 40.1736 18.8887 41.1776 18.8887C42.6079 18.8887 43.5432 19.1369 44.2308 19.7023C44.8084 20.1298 45.1523 20.7917 45.1523 21.6743C45.1523 22.75 44.4371 23.6877 43.2681 24.129V24.1566C44.3271 24.4186 45.5649 25.3012 45.5649 26.956C45.5649 27.9213 45.1798 28.6384 44.6297 29.19C43.8595 29.9071 42.5942 30.2381 40.7925 30.2381C39.8023 30.2381 39.0459 30.1691 38.5645 30.1002V19.1231H38.5508ZM39.9811 23.6877H41.2877C42.8005 23.6877 43.6944 22.8879 43.6944 21.8122C43.6944 20.5021 42.7042 19.9919 41.2601 19.9919C40.6 19.9919 40.2149 20.0471 39.9949 20.0884V23.6877H39.9811ZM39.9811 29.0245C40.2562 29.0797 40.6688 29.0935 41.1914 29.0935C42.6767 29.0935 44.0383 28.5419 44.0383 26.9284C44.0383 25.4115 42.7317 24.7771 41.1639 24.7771H39.9811V29.0245Z"
        fill="white"
      />
      <path
        d="M54.8501 18.9728V30.1153H53.3923V20.1725H49.7202V23.5512C49.7202 25.9645 49.6239 28.6673 48.056 29.7154C47.6435 29.9774 46.9971 30.2394 46.3507 30.2394L46.1719 29.0811C46.612 28.9983 47.0796 28.7363 47.3409 28.4881C48.2348 27.6193 48.3174 25.4404 48.3174 23.6615V18.959H54.8501V18.9728Z"
        fill="white"
      />
      <path
        d="M63.1024 24.8887H58.7839V28.9017H63.5975V30.1152H57.3398V18.9727H63.35V20.1862H58.7839V23.7027H63.1024V24.8887Z"
        fill="white"
      />
      <path
        d="M66.8991 18.9727V23.6338H72.2765V18.9727H73.7344V30.1152H72.2765V24.8887H66.8991V30.1152H65.4688V18.9727H66.8991Z"
        fill="white"
      />
      <path
        d="M77.5706 18.9727V23.6751C77.5706 25.4403 77.5431 26.9021 77.4331 28.3914L77.4606 28.4052C78.0245 27.2468 78.7121 26.0471 79.5236 24.7232L83.1131 18.9864H84.5434V30.129H83.1956V25.3851C83.1956 23.5786 83.2094 22.2547 83.3332 20.8481L83.2782 20.8343C82.728 22.0617 81.9716 23.3442 81.2152 24.5853L77.7357 30.129H76.1953V18.9864H77.5706V18.9727Z"
        fill="white"
      />
      <path
        d="M86.0703 30.114C86.2353 29.8106 86.3866 29.4658 86.5104 29.1073C86.9918 27.8386 87.2806 26.2803 88.1883 25.3701C88.4496 25.1081 88.7797 24.9012 89.1097 24.8047V24.7495C87.7344 24.5151 86.6479 23.5222 86.6479 22.0053C86.6479 21.0262 87.0468 20.2539 87.6657 19.7437C88.3533 19.1783 89.4398 18.8887 90.8426 18.8887C91.7779 18.8887 92.7681 18.9714 93.497 19.1231V30.1278H92.0667V25.3012H91.0077C90.32 25.3012 89.7974 25.4528 89.4123 25.8528C88.6559 26.625 88.3396 28.2661 87.9407 29.3279C87.8307 29.6037 87.7482 29.8382 87.5832 30.1415H86.0703V30.114ZM92.0942 20.1298C91.8466 20.0746 91.379 19.9919 90.7464 19.9919C89.3298 19.9919 88.1195 20.5435 88.1195 22.0742C88.1195 23.4119 89.3023 24.1979 90.8701 24.1979C91.3515 24.1979 91.8054 24.1979 92.0942 24.1841V20.1298Z"
        fill="white"
      />
      <path d="M9.94775 36.8984V48.041H8.51742V38.0982H3.36001V48.041H1.92969V36.8984H9.94775Z" fill="white" />
      <path
        d="M12.4492 37.0371C13.1369 36.9267 14.0446 36.8164 15.1998 36.8164C16.6164 36.8164 17.6616 37.1474 18.3218 37.7404C18.9269 38.2644 19.2983 39.078 19.2983 40.0709C19.2983 41.0776 18.9957 41.8774 18.4456 42.4566C17.6892 43.2703 16.4514 43.684 15.0486 43.684C14.6222 43.684 14.2234 43.6702 13.8933 43.5874V48.0555H12.4492V37.0371ZM13.8933 42.4015C14.2096 42.4842 14.6085 42.5118 15.0761 42.5118C16.809 42.5118 17.8679 41.6706 17.8679 40.1261C17.8679 38.6505 16.8227 37.9472 15.2411 37.9472C14.6085 37.9472 14.1408 38.0024 13.8933 38.0575V42.4015Z"
        fill="white"
      />
      <path
        d="M30.6029 42.3609C30.6029 46.1946 28.2786 48.2356 25.4455 48.2356C22.5161 48.2356 20.4531 45.9464 20.4531 42.5816C20.4531 39.0375 22.6536 36.7207 25.6105 36.7207C28.6362 36.7207 30.6029 39.0513 30.6029 42.3609ZM21.9797 42.5402C21.9797 44.9259 23.2725 47.0496 25.528 47.0496C27.7973 47.0496 29.0901 44.9535 29.0901 42.4161C29.0901 40.1959 27.9348 37.8791 25.5418 37.8791C23.1625 37.8929 21.9797 40.0855 21.9797 42.5402Z"
        fill="white"
      />
      <path
        d="M37.9051 36.457V37.4499C40.2431 37.5465 42.5949 38.9669 42.5949 42.4834C42.5949 45.9861 40.2569 47.3238 37.8914 47.4617V48.5098H36.5023V47.4617C34.178 47.31 31.8125 46.0689 31.8125 42.5523C31.8125 38.8979 34.3843 37.5878 36.5298 37.4637V36.4708H37.9051V36.457ZM36.5161 38.4842C35.0582 38.5669 33.2978 39.5736 33.2978 42.4696C33.2978 45.1311 34.8794 46.2619 36.5161 46.4274V38.4842ZM37.8776 46.4274C39.473 46.2895 41.0958 45.2139 41.0958 42.4558C41.0958 39.5874 39.473 38.5669 37.8776 38.4842V46.4274Z"
        fill="white"
      />
      <path
        d="M50.1883 42.8145H45.8699V46.8274H50.6834V48.041H44.4258V36.8984H50.4359V38.112H45.8699V41.6285H50.1883V42.8145Z"
        fill="white"
      />
      <path
        d="M60.3232 47.6841C59.8005 47.9461 58.7415 48.2081 57.3937 48.2081C54.258 48.2081 51.9062 46.2223 51.9062 42.5679C51.9062 39.079 54.2718 36.707 57.7101 36.707C59.0991 36.707 59.9656 37.0104 60.3507 37.2035L60.0068 38.3757C59.4567 38.1136 58.6865 37.9068 57.7651 37.9068C55.152 37.9068 53.4329 39.5754 53.4329 42.499C53.4329 45.2294 55.0007 46.9808 57.7101 46.9808C58.5903 46.9808 59.4705 46.8015 60.0481 46.5119L60.3232 47.6841Z"
        fill="white"
      />
      <path
        d="M69.5653 47.6841C69.0427 47.9461 67.9837 48.2081 66.6359 48.2081C63.5002 48.2081 61.1484 46.2223 61.1484 42.5679C61.1484 39.079 63.514 36.707 66.9522 36.707C68.3413 36.707 69.2078 37.0104 69.5928 37.2035L69.249 38.3757C68.6989 38.1136 67.9287 37.9068 67.0073 37.9068C64.3942 37.9068 62.675 39.5754 62.675 42.499C62.675 45.2294 64.2429 46.9808 66.9522 46.9808C67.8324 46.9808 68.7126 46.8015 69.2903 46.5119L69.5653 47.6841Z"
        fill="white"
      />
      <path
        d="M72.7308 36.8984V41.6009C72.7308 43.3661 72.7033 44.8279 72.5933 46.3172L72.6208 46.331C73.1846 45.1726 73.8723 43.9729 74.6837 42.649L78.2733 36.9122H79.7036V48.0548H78.3558V43.3109C78.3558 41.5044 78.3695 40.1805 78.4933 38.7739L78.4383 38.7601C77.8882 39.9875 77.1318 41.27 76.3754 42.5111L72.8958 48.0548H71.3555V36.9122H72.7308V36.8984Z"
        fill="white"
      />
      <path
        d="M91.7357 42.3609C91.7357 46.1946 89.4115 48.2356 86.5783 48.2356C83.6489 48.2356 81.5859 45.9464 81.5859 42.5816C81.5859 39.0375 83.7864 36.7207 86.7434 36.7207C89.769 36.7207 91.7357 39.0513 91.7357 42.3609ZM83.0988 42.5402C83.0988 44.9259 84.3916 47.0496 86.6471 47.0496C88.9163 47.0496 90.2091 44.9535 90.2091 42.4161C90.2091 40.1959 89.0539 37.8791 86.6608 37.8791C84.2953 37.8929 83.0988 40.0855 83.0988 42.5402Z"
        fill="white"
      />
      <path
        d="M95.0358 36.8984V41.5596H100.413V36.8984H101.871V48.041H100.413V42.8145H95.0358V48.041H93.6055V36.8984H95.0358Z"
        fill="white"
      />
      <path
        d="M106.164 44.5383L105.009 48.041H103.523L107.306 36.8984H109.038L112.834 48.041H111.294L110.111 44.5383H106.164ZM109.809 43.4075L108.722 40.1943C108.475 39.4634 108.31 38.8015 108.144 38.1534H108.117C107.952 38.8153 107.773 39.491 107.553 40.1667L106.467 43.3937H109.809V43.4075Z"
        fill="white"
      />
      <path
        d="M122.338 36.8986V48.0411H120.881V38.0983H117.208V41.4769C117.208 43.8902 117.112 46.5931 115.544 47.6412C115.132 47.9032 114.485 48.1652 113.839 48.1652L113.66 47.0068C114.1 46.9241 114.568 46.6621 114.829 46.4139C115.723 45.5451 115.806 43.3662 115.806 41.5873V36.8848H122.338V36.8986Z"
        fill="white"
      />
      <path
        d="M126.256 36.8984V41.0631C126.586 41.0079 127.247 40.9804 127.632 40.9804C129.915 40.9804 131.923 41.9871 131.923 44.4555C131.923 45.5036 131.593 46.262 131.07 46.8412C130.162 47.8066 128.608 48.1513 127.082 48.1513C126.078 48.1513 125.321 48.0824 124.84 48.0134V36.8984H126.256ZM126.256 46.9378C126.586 47.0067 126.944 47.0205 127.439 47.0205C129.035 47.0205 130.41 46.2483 130.41 44.5383C130.41 42.7869 129.007 42.125 127.425 42.125C127.068 42.125 126.614 42.1525 126.256 42.2077V46.9378Z"
        fill="white"
      />
      <path
        d="M135.208 36.8984V41.5596H140.585V36.8984H142.043V48.041H140.585V42.8145H135.208V48.041H133.777V36.8984H135.208Z"
        fill="white"
      />
      <path
        d="M145.965 36.8986V41.0632C146.296 41.0081 146.928 40.9805 147.286 40.9805C149.583 40.9805 151.577 41.9872 151.577 44.4694C151.577 45.4899 151.247 46.2622 150.724 46.8276C149.816 47.7929 148.262 48.1376 146.763 48.1376C145.773 48.1376 145.017 48.0687 144.535 47.9997V36.8848H145.965V36.8986ZM145.965 46.9379C146.268 47.0068 146.626 47.0206 147.121 47.0206C148.702 47.0206 150.078 46.207 150.078 44.5246C150.078 42.787 148.592 42.1251 147.107 42.1251C146.584 42.1251 146.144 42.1802 145.965 42.2078V46.9379ZM154.259 36.8986V48.0411H152.801V36.8986H154.259Z"
        fill="white"
      />
      <path
        d="M166.208 43.1454C166.125 41.5871 166.029 39.7254 166.043 38.3326H165.988C165.603 39.6427 165.149 41.0355 164.585 42.5662L162.618 47.972H161.532L159.73 42.6628C159.207 41.0907 158.753 39.6565 158.437 38.3326H158.41C158.382 39.7254 158.3 41.5871 158.19 43.2558L157.887 48.041H156.512L157.282 36.8984H159.111L161.009 42.2904C161.477 43.6695 161.848 44.883 162.137 46.0414H162.192C162.467 44.9106 162.866 43.6971 163.361 42.2904L165.341 36.8984H167.17L167.858 48.041H166.455L166.208 43.1454Z"
        fill="white"
      />
      <path
        d="M171.461 36.8984V41.6009C171.461 43.3661 171.434 44.8279 171.324 46.3172L171.351 46.331C171.915 45.1726 172.603 43.9729 173.414 42.649L177.004 36.9122H178.434V48.0548H177.086V43.3109C177.086 41.5044 177.1 40.1805 177.224 38.7739L177.169 38.7601C176.619 39.9875 175.862 41.27 175.106 42.5111L171.626 48.0548H170.086V36.9122H171.461V36.8984Z"
        fill="white"
      />
      <path
        d="M1.91406 54.9648C2.60172 54.8545 3.50942 54.7441 4.66468 54.7441C6.08125 54.7441 7.12649 55.0751 7.78664 55.6681C8.39178 56.1921 8.76311 57.0057 8.76311 57.9986C8.76311 59.0053 8.46054 59.8052 7.91042 60.3844C7.154 61.198 5.91622 61.6117 4.5134 61.6117C4.08705 61.6117 3.68821 61.5979 3.35814 61.5152V65.9832H1.92782V54.9648H1.91406ZM3.34438 60.3292C3.66071 60.412 4.05955 60.4395 4.52715 60.4395C6.26004 60.4395 7.31903 59.5983 7.31903 58.0538C7.31903 56.5783 6.2738 55.8749 4.69219 55.8749C4.05955 55.8749 3.59194 55.9301 3.34438 55.9853V60.3292Z"
        fill="#2EACE3"
      />
      <path
        d="M12.0373 54.8262V59.5287C12.0373 61.2938 12.0098 62.7556 11.8998 64.2449L11.9273 64.2587C12.4912 63.1004 13.1788 61.9006 13.9903 60.5767L17.5798 54.84H19.0102V65.9825H17.6761V61.2387C17.6761 59.4321 17.6899 58.1083 17.8136 56.7017L17.7586 56.6879C17.2085 57.9152 16.4521 59.1977 15.6957 60.4388L12.2161 65.9825H10.6758V54.84H12.0373V54.8262Z"
        fill="#2EACE3"
      />
      <path
        d="M29.3114 65.6099C28.7888 65.8719 27.7298 66.1339 26.382 66.1339C23.2463 66.1339 20.8945 64.1481 20.8945 60.4937C20.8945 57.0047 23.2601 54.6328 26.6983 54.6328C28.0874 54.6328 28.9539 54.9362 29.3389 55.1293L28.9951 56.3014C28.445 56.0394 27.6748 55.8326 26.7534 55.8326C24.1403 55.8326 22.4211 57.5012 22.4211 60.4247C22.4211 63.1552 23.989 64.9066 26.6983 64.9066C27.5785 64.9066 28.4587 64.7273 29.0364 64.4377L29.3114 65.6099Z"
        fill="#2EACE3"
      />
      <path
        d="M32.5416 54.8262V59.7769H32.9817L36.7913 54.8262H38.5242L34.3157 59.9424C36.1449 60.1906 36.86 61.3214 37.4377 62.8245C37.8503 63.9691 38.1666 64.9896 38.6617 65.9825H37.1076C36.7088 65.2241 36.3924 64.2311 36.1036 63.3486C35.5948 61.873 34.8796 60.8663 33.0092 60.8663H32.5416V65.9825H31.125V54.84H32.5416V54.8262Z"
        fill="#2EACE3"
      />
      <path
        d="M41.8672 62.466L40.7119 65.9687H39.2266L43.0087 54.8262H44.7416L48.5374 65.9687H46.9971L45.8143 62.466H41.8672ZM45.5117 61.3352L44.4252 58.1221C44.1777 57.3912 44.0126 56.7292 43.8476 56.0811H43.8063C43.6413 56.743 43.4625 57.4188 43.2425 58.0945L42.156 61.3214H45.5117V61.3352Z"
        fill="white"
      />
      <path
        d="M59.5397 61.0732C59.4572 59.5149 59.3609 57.6532 59.3747 56.2604H59.3196C58.9346 57.5704 58.4807 58.9633 57.9168 60.494L55.9501 65.8998H54.8636L53.062 60.5905C52.5394 59.0184 52.0855 57.5842 51.7692 56.2604H51.7417C51.7142 57.6532 51.6317 59.5149 51.5216 61.1835L51.2191 65.9687H49.8438L50.6139 54.8262H52.4431L54.341 60.2182C54.8086 61.5972 55.18 62.8108 55.4688 63.9691H55.5238C55.7988 62.8383 56.1977 61.6248 56.6928 60.2182L58.6732 54.8262H60.5024L61.1901 65.9687H59.7872L59.5397 61.0732Z"
        fill="white"
      />
      <path
        d="M64.7816 54.8262V59.5287C64.7816 61.2938 64.754 62.7556 64.644 64.2449L64.6715 64.2587C65.2354 63.1004 65.9231 61.9006 66.7345 60.5767L70.3241 54.84H71.7544V65.9825H70.4066V61.2387C70.4066 59.4321 70.4203 58.1083 70.5441 56.7017L70.4891 56.6879C69.939 57.9152 69.1825 59.1977 68.4261 60.4388L64.9466 65.9825H63.4062V54.84H64.7816V54.8262Z"
        fill="white"
      />
    </svg>
  );
};

export default MainLogo;

import styled from 'styled-components';

import Flex from '../../../atoms/flex';

export const Buttons = styled(Flex)`
  padding-top: 24px;
`;

export const Form = styled.form`
  height: calc(100vh - 208px);
  overflow-y: auto;
`;

export const ParentArrow = styled.div`
  transform: rotate(${props => props.rotate}deg);
  height: 24px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Parent = styled.div`
  padding: 0;
  font-size: 12px;
  font-family: GolosTextWebVF, serif;
  color: #000;
  display: flex;
  align-items: center;
  height: 24px;
`;

export const WorkplacesLi = styled.li`
  padding-bottom: 10px;
  list-style: none;
`

export const AproveCopyMapTitle = styled.p`
  font-family: GolosTextWebVF, serif;
  margin-top: 38px;
  font-size: 16px;
  text-align: center;
  line-height: normal;
`
import { useQuery } from '@apollo/client';

import { ANALYTICS_IDENTIFIED_HAZARDS } from '../schemas/reports';

export const useChartIdentifiedHazards = ({ id, nn }) => {
  const { data, loading } = useQuery(ANALYTICS_IDENTIFIED_HAZARDS, {
    variables: {
      id,
      nn,
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  return {
    data: data?.dash7,
    loading,
  };
};

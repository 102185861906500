import React from 'react';

import Close from '../../../assets/svg/close';

import * as Styled from './styled';

export default function Dialog(props) {
  const {
    isRender = false,
    isClose = false,
    title,
    children,
    width,
    height,
    onClose,
    settings,
    centered,
    styled,
    paddingWrapper,
    withOutOverflow,
    maskClosable,
  } = props;

  if (!isRender) {
    return null;
  }

  const styles = styled ?? null;

  return (
    <Styled.Dialog
      title={
        title ? (
          <Styled.H3
            tagName={'h3'}
            fSize={settings?.title?.size ?? 32}
            align={settings?.title?.align ?? 'center'}
            fWeight={settings?.title?.fWeight ?? 600}
            lHeight={settings?.title?.lHeight ?? 40}
            margin={settings?.title?.margin}
            dangerouslyHTML={title}
          />
        ) : (
          ''
        )
      }
      centered={centered}
      open={isRender}
      footer={null}
      width={width}
      closable={isClose}
      style={styles}
      closeIcon={<Close width={settings?.close?.width ?? 16} height={settings?.close?.height ?? 16} />}
      onCancel={onClose}
      keyboard={false}
      maskClosable={maskClosable}
    >
      {withOutOverflow ? (
        children
      ) : (
        <Styled.DialogWrapper height={height} padding={paddingWrapper}>
          {children}
        </Styled.DialogWrapper>
      )}
    </Styled.Dialog>
  );
}

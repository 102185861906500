import * as actionTypes from '../actionTypes/expertOrganizationTypes';
import {AUTH_LOGOUT} from "../actionTypes/loginTypes";

const initialState = {
  list: [],
  organization: {
	fullName: "",
	shortName: "",
	inn: "",
	kpp: "",
	okved: "",
	okogu: "",
	ogrn: "",
	 oktmo: "",
	okpo: "",
	assessment: "",
	phone: "",
	email: "",
	chiefJobTitle: "",
	chiefFullName: "",
    temp_accreditations: "",
	// "expertOrganizationStructures": [
	//   {
	// 	"parentId": null,
	// 	"name": "АУП"
	//   },
	//   {
	// 	"parentId": "37e206d5-b9cd-492d-86bb-b00e3c32e490",
	// 	"name": "Экспертные отдел"
	//   }
	// ],
	// "experts": [
	//
	// ],
	// "accreditations": [
	//   {
	// 	"value": "935d92a1-4498-4e31-8a5f-f37d58c14b23"
	//   },
	//   {
	// 	"value": "ccfad0f0-5250-42ea-822e-20b0f1b1990d"
	//   },
	//   {
	// 	"value": "94494479-23b7-4d99-b0a2-24b48f56a790"
	//   }
	// ],
	addresses: [
	  {
		status: "ra",
		postalCode: "",
		regionId: null,
		regionName: "",
		autonomousId: null,
		autonomousName: null,
		districtId: null,
		districtName: "",
		cityId: null,
		cityName: "",
		innerCityId: null,
		innerCityName: null,
		localityId: null,
		localityName: "",
		streetId: null,
		streetName: "",
		additionalElementId: null,
		additionalElementName: null,
		house: "",
		estate: null,
		building: null,
		structure: null
	  },
	  {
		status: "la",
		postalCode: "",
		regionId: null,
		regionName: "",
		autonomousId: null,
		autonomousName: null,
		districtId: null,
		districtName: "",
		cityId: null,
		cityName: "",
		innerCityId: null,
		innerCityName: null,
		localityId: null,
		localityName: "",
		streetId: null,
		streetName: "",
		additionalElementId: null,
		additionalElementName: null,
		house: "",
		estate: null,
		building: null,
		structure: null
	  }
	]
  }
}

export function expertOrganizationReducer(state = initialState, action) {

  switch (action.type) {
	case actionTypes.GET_EXPERT:
	  return { ...state, organization: {...initialState.organization,...action.payload} }
	case actionTypes.EXPERT_DATA_CLEAR:
	  return {...state, organization: initialState.organization}
	case AUTH_LOGOUT:
	  return initialState
	default:
	  return state
  }
}

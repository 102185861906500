import React from "react";
import { Outlet } from "react-router-dom";

import HeaderAdmin from "../../blocks/headerAdmin";
import UserModal from "../../user/UserModal";

import {navMap} from "./navMap";


function ManagementLayout() {
    return (
        <>
            <UserModal />
            <HeaderAdmin navMap={navMap} />
            <main className="">
                <Outlet />
            </main>
        </>
    )
}

export default ManagementLayout;

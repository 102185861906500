import styled, {css, keyframes} from 'styled-components';

export const Table = styled.div`
  border-radius: 0px 0px 10px 10px;
  border-bottom: 1px solid rgb(232, 232, 232);
  overflow: auto;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
`
export const Td = styled.td`
  padding: 0;
  text-align: ${props => props.align ?? 'center'};

  ${props => 
    props.width && css`
      width: ${props.width}px;
  `}
`

export const Header = styled.div`
  font-family: 'GolosTextWebVF', serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: ${props => props.align ?? 'left'};
  padding: 18px;
  white-space: nowrap;
  color: #18171C;
`

export const View = styled.div`
  font-family: 'GolosTextWebVF', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 18px;
  color: #52575C;
`

export const Thead = styled.thead`
  border-bottom: 3px solid #18171C;
`

export const Tbody = styled.tbody`
  tr {
    border-top: 1px solid #E8E8E8;
  }
`
const load = keyframes`
from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;

export const Sceleton = styled.div`
  background: #e1e1e1;
  border-radius: 4px;
  height: 30px;
  position: relative;
  overflow: hidden;
  margin: 5px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );
    animation: ${load} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;

export const SortButton = styled.button`
  background-color: transparent;
  border: 0;
  transform: ${props => props.isDown ? 'rotate(180deg)' : 'rotate(0)'};
  display:  ${props => props.isSortby ? 'block' : 'none'}
`;

export const HeadFlex = styled.div`
  display: flex;
  justify-content: center;
`
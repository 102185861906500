import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';

export const Buttons = styled(Flex)`
  padding-bottom: 0;
`;

export const FlexFields = styled(Flex)`
  padding-bottom: 30px;
`;

export const FlexConfirm = styled(Flex)`
  padding: 30px 0;
`;

export const ButtonConfirm = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`;

export const Label = styled.div`
  font-family: GolosTextWebVF, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
  color: #000;
`

export const Name = styled.p`
  font-family: GolosTextWebVF, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
`
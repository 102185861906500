import React from 'react';

import * as Styled from './svg.styled';

export default function Phone({
  fill = "#25282B",
  width = "14",
  height = "14",
  viewBox = "0 0 14 14"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M12.8333 9.87002V11.62C12.834 11.7825 12.8007 11.9433 12.7356 12.0921C12.6705 12.241 12.5751 12.3746 12.4554 12.4844C12.3357 12.5943 12.1943 12.6779 12.0404 12.7299C11.8865 12.782 11.7235 12.8013 11.5617 12.7867C9.76665 12.5916 8.04242 11.9783 6.5275 10.9958C5.11807 10.1002 3.92311 8.90528 3.0275 7.49585C2.04166 5.97405 1.42814 4.24143 1.23667 2.43835C1.22209 2.27704 1.24126 2.11446 1.29296 1.96096C1.34466 1.80747 1.42775 1.66642 1.53695 1.54679C1.64615 1.42717 1.77905 1.3316 1.92721 1.26615C2.07537 1.20071 2.23553 1.16684 2.3975 1.16668H4.1475C4.4306 1.1639 4.70505 1.26415 4.9197 1.44874C5.13434 1.63334 5.27455 1.88969 5.31417 2.17002C5.38803 2.73005 5.52501 3.27994 5.7225 3.80918C5.80099 4.01797 5.81797 4.24488 5.77145 4.46303C5.72492 4.68118 5.61684 4.88141 5.46 5.04002L4.71917 5.78085C5.54958 7.24125 6.75877 8.45044 8.21917 9.28085L8.96 8.54002C9.1186 8.38318 9.31884 8.27509 9.53699 8.22857C9.75513 8.18205 9.98205 8.19903 10.1908 8.27752C10.7201 8.475 11.27 8.61199 11.83 8.68585C12.1134 8.72582 12.3722 8.86855 12.5571 9.08689C12.7421 9.30522 12.8404 9.58393 12.8333 9.87002Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Svg>
  )
}
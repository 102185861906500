import React, {useEffect, useState} from 'react'
import {Button, Modal, Spinner, Tab,Tabs} from 'react-bootstrap'

import axios from '../../../../../axios/axios'
import {url} from '../Roles'

import RoleForm from './Form'
import PermissionsForm from './Permissions'

function isLoaded(props, record) {
  return !props.record || Object.keys(record).length > 0
}

async function loadRecord(id) {
  try {
    const {data} = await axios.get(url + '/' + id)
    return data
  } catch (e) {
    console.error(e)
  }
}

const EditModal = (props) => {
  const [record, setRecord] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.record) {
      setLoading(true)
      loadRecord(props.record.id).then((res) => {
        setRecord(res)
        setLoading(false)
      })
    }
  }, [props.record])

  function onHide() {
    if (!loading) {
      setRecord({})
      props.onHide()
    }
  }

  function onChange(data) {
    setRecord({...data})
  }

  async function onSubmit() {
    setLoading(true)
    try {
      !record.id ? await axios.post(url, record) : await axios.put(url + '/' + record.id, record)
      props.onUpdate()
      onHide()
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal {...props} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {isLoaded(props, record) ? (record.id ? record.displayName : 'Создание роли') : 'Загрузка...'}
        </Modal.Title>
        <Button variant="default" className="close-button" onClick={onHide}>
          <div className="d-flex align-items-center justify-content-center">
            <i className="bi bi-x ft-sz" /> Закрыть
          </div>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {
          !isLoaded(props, record)
            ? <div className="text-center"><Spinner animation="border" /></div>
            : (!props.record
                ? <RoleForm record={record} disabled={loading} onChange={onChange} onSubmit={onSubmit} />
                : <Tabs defaultActiveKey="permissions">
                    <Tab eventKey="main" title="Настройки">
                      <Tab.Content className="mt-2">
                        <RoleForm record={record} disabled={loading} onChange={onChange} onSubmit={onSubmit} />
                      </Tab.Content>
                    </Tab>
                    <Tab eventKey="permissions" title="Разрешения">
                      <Tab.Content className="mt-2">
                        <PermissionsForm record={record} disabled={loading} onChange={onChange} onSubmit={onSubmit} />
                      </Tab.Content>
                    </Tab>
                  </Tabs>
            )
        }
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button variant="default" className="add-button" onClick={onSubmit} disabled={loading}>
          {loading && isLoaded(props, record) && <Spinner animation="border" size="sm" className="me-1" />}
          Отправить
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal
import React from 'react';

import * as Styled from './styled';

export default function Quantity(props) {
  const {
    name,
    onShowQuantityEdit,
    formik
  } = props;

  const {value} = formik?.getFieldProps(name) ?? {};

  const handleEdit = () => {
    formik?.setFieldValue('editQuantity', {
      id: value?.id,
      quantity: value?.quantity
    });
    onShowQuantityEdit.on();
  };

  return (
    <Styled.Quantity onClick={handleEdit}>
      {value?.quantity  ?? 0}
    </Styled.Quantity>
  )
}

import styled from 'styled-components';

import Button from '../../../../atoms/nButton';

export const IconButton = styled(Button)`
  border: none;
  border-radius: 0;
  background: transparent;
`
export const EditUserLink = styled.a`
  font-size: 14px;
  color: #1E84EB;
  font-family: "Golos Text VF", sans-serif;
`
import React from 'react';

import * as Styled from './svg.styled';

export default function Arrow({
  width = "8",
  height = "12",
  viewBox = "0 0 8 12"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
     <path
      d="M1.5 11L6.5 6L1.5 1"
      stroke="#25282B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
 </Styled.Svg>
  )
}
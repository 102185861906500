import React from "react";
import { Spin } from 'antd';

import * as Styled from './styled'

export default function Spinner({isRender}) {

  if (!isRender) {
    return null
  }
  
  return (
    <Styled.Spinner>
      <Spin />
    </Styled.Spinner>
  )
}
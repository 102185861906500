import React from 'react'

export default function Timestamp(props) {
  const {
    timestamp
  } = props;

  const date = new Date(timestamp)
  let day = date.getDate()
  let month = date.getMonth() + 1

  if (day < 10) {
    day = '0' + day
  }
  
  if (month < 10) {
    month = '0' + month
  }

  return (
    <span style={{
      fontSize: 14,
      lineHeight: 1.2,
      display: 'block'
    }}>
      {day}/{month}/{date.getFullYear()} <br />{date.getHours()}:{date.getMinutes()}
    </span>
  )
};

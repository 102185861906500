import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import * as hooks from '../../../../hooks';
import Table from '../../../blocks/table';

import { getTableRows, tableHead } from './helpers';
import { useReports } from './hooks';
import ListOfReports from './listOfReports';
import * as Styled from './styled';

const { useBoolean } = hooks;

export default function Reports({ project }) {
  const [showListOfReports, onShowListOfReports] = useBoolean(false);
  const auth = useSelector((state) => state.authReducer);

  const { formik, dataRows, dataReportsList, onGenerateReport, onPostRiskassessmenReport } = useReports({
    project,
    onShowListOfReports,
  });

  const rows = getTableRows({
    data: dataRows,
    reload: onPostRiskassessmenReport,
  });

  const isStartRole = auth.user.roles.some((user) => 'start-role' === user.roleName);

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <Styled.FillButton type={'submit'} variant={'primary'} onClick={onShowListOfReports.on}>
        Сформировать отчет
      </Styled.FillButton>
      {!isEmpty(rows) ? <Table head={tableHead} data={rows} class={'table'} /> : null}

      <ListOfReports
        formik={formik}
        show={showListOfReports}
        onGenerateReport={onGenerateReport}
        reportsList={dataReportsList}
        onClose={onShowListOfReports.off}
      />
    </div>
  );
}

import React from 'react';

function ClassifiersCard({classifiersList}) {
  return (
    <>
    {classifiersList.selectedNSIdictionary[0]?.map((el) =>
    <>
      {el.children.map((item) =>
      <>
      <tr>
        <td>{item.value.code}</td>
        <td>{item.value.name}</td>
      </tr>
      </>
      )}
    </>
    )}
    </>
  );
}

export default ClassifiersCard;

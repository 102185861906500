import React from "react";
import { Spin } from 'antd';

import Delete from "../../../../../../assets/svg/delete";
import axios from "../../../../../../axios/axios";
import Link from '../../../../../atoms/link';



function deleteURI(url, reload) {
  const token = localStorage.getItem('token');
  //url+=`?bearer=${token ?? ''}`
  let urlSp = url.split("/")
  console.log(urlSp)
  url = "/api/delete/reports?uid=" + urlSp[2] + "&file=" + urlSp[3]
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    reload()
  });
}

export function DeleteReport(props) {
  const {link, reload} = props;
  //console.log(idfile)
  const onDownloadFileLink = () => {
    deleteURI(link, reload)
  }

  if (!link) {
    return <Spin size="small" />
  }

  return (
    <Link
      onLink={onDownloadFileLink}
    >
      <Delete />
    </Link>

  )
}

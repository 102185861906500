import React from "react";

import { ReactComponent as DeleteIcon } from "../../../image/delete.svg";

import { useFormikWrapper } from "./controlRow.hook";
import { StyledControlButton } from "./controlRow.styled";

function DeleteRow(props) {
  const handleDelete = useFormikWrapper(props);

  return (
    <StyledControlButton type="button" onClick={handleDelete}>
      <DeleteIcon />
    </StyledControlButton>
  );
}

export default function ControlRow(props) {
  return (
    <React.Fragment>
      <DeleteRow {...props} />
    </React.Fragment>
  );
}

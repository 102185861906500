import React, { useCallback } from 'react';
import {Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {useDispatch} from "react-redux";

import { saveJobTitleUploadFileNew} from "../../../../../../store/actions/employersActions";
import {treeSearch} from "../../../../../core/appFunction";

const UploadStructureModal = ({ show, onHide }) => {
    const employer = useSelector(state => state.employersReducer.employer);
    const structureNew = useSelector(state => state.employersReducer.structureNew);
    const { structure } = employer || {};
    const dispatch = useDispatch();

    const saveJobTitleUploadFile = (e, item) => {
      dispatch(saveJobTitleUploadFileNew(item.employer, item.id, e.target.files[0]))
    }



  // {treeSearch(organizer.structureNew, 1).jobTitles.length>0 ? 'Должности загружены' : 'Загрузить'}
    const renderStructure = useCallback(() => {
        if (!structure || !structure.length) {
            return null;
        }

        return structure?.map((item) => (<Row className="mb-2" key={item.id} style={{wordBreak: "break-word"}}><Col lg={7}>{item.name}</Col><Col lg={5} className="d-flex align-items-center"><form>

            <label
                htmlFor={"filePicker_"+item.id}
                className="btn download-button"
                // style={{height: "auto", width:"auto", paddingRight: 12}}
            >
              { treeSearch(structureNew, item.id)?.jobTitles.length>0 ? 'Загружены' : 'Загрузить'}
            </label>
            <input
                id={"filePicker_"+item.id}
                style={{
                    visibility:
                        "hidden",
                    display: "none",
                }}
                type={"file"}
                accept=".csv"
                onChange={(e) => saveJobTitleUploadFile(e, item)}
                multipart
            />
        </form></Col></Row>))
    }, [structure, structureNew]);

    return (
        <Modal size="lg" show={show} backdropClassName="" contentClassName="rounded-0" centered backdrop="static">
            <Modal.Body>
                <button className="btn close-button rounded-0" style={{top: "0", right: "0", display: "flex", position: "absolute", alignItems: "center"}} onClick={onHide}>
                    <i className="bi bi-x ft-sz"></i>
                    <span style={{paddingTop: "3px"}}>Закрыть</span>
                </button>
                <Row className="mb-3">
                    <Col lg={7}><h5>Выбрать структурное подразделение</h5></Col>
                    <Col lg={5}><h5>Должности</h5></Col>
                </Row>

                {renderStructure()}
                <button type="button" className="btn save-button" onClick={onHide}>Закрыть</button>
            </Modal.Body>
        </Modal>
    )
};

export default UploadStructureModal;

import styled from 'styled-components';

import Flex from '../../atoms/flex';
import Button from '../../atoms/nButton';
import Text from '../../atoms/text';
import Dialog from '../dialog';

export const Buttons = styled(Flex)`
  padding-bottom: 24px;
`;

export const Pagination = styled(Flex)`
  padding: 24px;
  justify-content: center;
`;

export const SectionHead = styled(Flex)`
  padding: 18px 0 42px;
`

export const ButtonConfirmation = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 40px;
`

export const FlexConfirmation = styled(Flex)`
`

export const TextConfirmation = styled(Text)`
  margin-bottom: 40px;
`

export const ButtonSectionHead = styled(Button)`
  font-weight: 400;
  line-height: normal;
  height: 100%;
  padding: 3px;
  font-size: 10px;
  min-width: 170px;
  justify-content: center;
  width: 100%;
`

export const FlexButtonSectionHead = styled(Flex)`
`

export const EditDialog = styled(Dialog)`
  padding-left: 0;
`


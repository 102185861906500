import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0 102px 83px;
  padding-left: 402px;
  display: table;
  width: 100%;
`;


import { AUTH_LOGOUT } from "../actionTypes/loginTypes";
import * as actionTypes from "../actionTypes/matrixTypes";

const initialState = {
    oldList: {},
    list: []
};

export function matrixReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.MATRIX_DATA_SUCCESS:
            return { ...state, oldList: action.payload };
        case actionTypes.FETCH_MATRIX_LIST_SUCCESS: {
            return {...state, list: action.payload }
        }
        case actionTypes.FETCH_MATRIX_TITLES_SUCCESS: {
            return {...state, titles: action.payload }
        }

        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export const getMatrixList = (state) => {
    return state.list;
}

export const getMatrixTitles = (state) => {
    return state.titles;
}

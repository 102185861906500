import styled from "styled-components";

export const UL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    margin-left: 24px;
  }
`

import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { employerAddStructure } from "../../../../../../store/actions/employersActions";

import StructureTreeItem from "./StructureTreeItem";

const StructureTree = ({ structureList }) => {
  const employerId = useSelector((state) => state.employersReducer.employer.id);
  const dispatch = useDispatch();

  console.log(structureList);

  const createTree = structure => structure && (
    <StructureTreeItem
      structureId={structure.value.id}
      key={structure.value.id}
      structure={structure}
    >
      {structure.children?.map((structureChild) => {
        return <Fragment key={structureChild.value.id}>{createTree(structureChild)}</Fragment>
      })}
    </StructureTreeItem>
  )

  const onClickAddStructure = () => {
    let name = prompt('Введите имя подразделения', '');

    if (name) {
      dispatch(employerAddStructure({
        name,
        employer: employerId,
        parentId: null
      }));
    }
  }

  return (
    <div className="recursive-tree">
      <h5 className="edit-structure-position-h5">Структурные подразделения</h5>
      <div>
        {structureList?.map((structure) => createTree(structure))}
      </div>
      <div className="actions-structure-positions">
        <button className="btn btn-actions-structure-positions add-button" onClick={onClickAddStructure}>Добавить подразделение</button>
      </div>
    </div>
  )
}

export default StructureTree;

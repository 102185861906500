import styled, { css } from 'styled-components';

export const Button = styled.button`
  font-family: 'GolosTextWebVF', serif;
  font-size: 20px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  color: #fff;
  cursor: pointer;
  padding: 11px 35px;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 8px;

  &:active,
  &:focus,
  &:hover,
  &:focus-visible {
    border-color: transparent;
    outline: none;
  }

  ${(props) =>
    props.variant === 'primary' &&
    css`
      background: #0078d6;
      border-color: #0078d6;
      color: #fff;

      &:hover {
        background: #014880;
        border-color: #014880;
      }
    `}}

  ${(props) =>
    props.isError &&
    css`
      background: #ededef;
      border-color: #ededef;
      cursor: default;

      &:hover {
        background: #ededef;
        border-color: #ededef;
        cursor: default;
      }
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background: #18171c;
      border-color: #18171c;
      color: #fff;

      &:hover {
        background: #3b3b3b;
        border-color: #3b3b3b;
      }

      &:disabled,
      &[disabled='disabled'] {
        background: #f6f6f7;
        border-color: #f6f6f7;
        color: #ccccd3;
        cursor: default;
      }
    `}}

  ${(props) =>
    props.variant === 'link' &&
    css`
      font-size: 14px;
      padding: 0;
      background: transparent;
      border-color: transparent;
      color: #0078d6;
      text-decoration: underline;

      &:hover {
        background: transparent;
        border-color: transparent;
        color: #0078d6;
      }
    `}}

  ${(props) =>
    props.variant === 'icon' &&
    css`
      padding: 0;
      background: transparent;
      border-color: transparent;
      color: #52575c;
    `}
`;

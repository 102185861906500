import {
  AUTH,
  AUTH_CHANGE_EMAIL,
  AUTH_FAIL,
  AUTH_LOGOUT,
  LOGIN_DATA_LOAD,
  UPDATE_USER_ROLE,
} from '../actionTypes/loginTypes';
let loadedState = JSON.parse(localStorage.getItem('auth_state'));

let defaultState = {
  token: null,
  user: { userName: null, roles: [{ roleName: '' }] },
  isLoggedIn: false,
  isDataLoad: false,
};

const timeNow = new Date().getTime() / 1000;

if (loadedState?.expTime < timeNow) {
  localStorage.removeItem('token');
  localStorage.removeItem('auth_state');
} else if (loadedState === null || !loadedState.isLoggedIn) {
  loadedState = defaultState;
}

const initialState = loadedState;

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH:
      localStorage.setItem('token', action.payload.Authorization.token);
      localStorage.setItem(
        'auth_state',
        JSON.stringify({
          ...state,
          ...action.payload.Authorization,
          user: action.payload.user,
          isDataLoad: false,
          isLoggedIn: true,
          expTime: action.payload.expiration_time,
        })
      );
      return { ...state, ...action.payload.Authorization, user: action.payload.user, isLoggedIn: true };
    case LOGIN_DATA_LOAD:
      return { ...state, isDataLoad: true };
    case AUTH_FAIL:
      return { ...state, ...defaultState };

    case AUTH_CHANGE_EMAIL:
      localStorage.setItem(
        'auth_state',
        JSON.stringify({
          ...state,
          user: {
            ...state.user,
            email: action.payload.email,
            verifyEmail: action.payload.verifyEmail,
          },
        })
      );
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
          verifyEmail: action.payload.verifyEmail,
        },
      };

    case UPDATE_USER_ROLE:
      const role = state.user.roles[0];
      const updated = {
        ...role,
        roleName: action.payload,
      };

      return {
        ...state,
        user: {
          ...state.user,
          roles: [updated],
        },
      };

    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('auth_state');
      return defaultState;
    default:
      return state;
  }
}

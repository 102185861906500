import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../axios/axios';
import { convertSortDirections } from '../../../../utils/convert';
import { logWarn } from '../../../../utils/log';

export const useMedicalExamination = ({ workplaceId }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: {},
      text: '',
      tableParams: {
        page: 1,
        pageSize: 50,
        field: '',
        sort: '',
      },
    },
  });

  const onPostMedicalExamination = async ({ page, pageSize, field, sort }) => {
    try {
      formik.setFieldValue('loading', true);

      const { data } = await axios.post(
        `/api/project/riskassessment/medicalexamination${page ? `?page=${page}` : ''}`,
        {
          workplaceId,
          pagination: pageSize ?? '',
          field: sort && field ? field : '',
          sort: sort ?? '',
        }
      );

      if (data?.data) {
        formik?.setFieldValue('data', data?.data);
        formik.setFieldValue('text', data?.text);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onRefetchMedicalExamination = async () => {
    const params = {
      page: formik.values.tableParams.page,
      pageSize: formik.values.tableParams.pageSize,
      field: formik.values.tableParams.field,
      sort: formik.values.tableParams.sort,
    };
    await onPostMedicalExamination(params);
  };

  const onChangeTable = (pagination, filters, sorter) => {
    const params = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      field: sorter.columnKey,
      sort: convertSortDirections(sorter.order),
    };
    formik.setFieldValue('tableParams', params);
    onPostMedicalExamination(params);
  };

  useEffect(() => {
    onPostMedicalExamination({ page: 1, pageSize: 50 });
  }, []);

  return {
    data: formik.values.data,
    loading: formik.values.loading,
    text: formik.values.text,
    onChangeTable,
    onPostMedicalExamination,
    onRefetchMedicalExamination,
  };
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.data) {
      if (node.data.some((item) => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.data)) {
        parentKey = getParentKey(key, node.data);
      }
    }
  }
  return parentKey;
};

export const useFactorReference = ({ show, workplaceId }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: [],
    },
  });

  const onPostFactorReference = async () => {
    try {
      formik.setFieldValue('loading', true);

      const { data } = await axios.post(`/api/project/riskassessment/medicalexamination/reference`, { workplaceId });

      if (data?.status === 'success') {
        formik?.setFieldValue('data', data?.data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onSaveFactorReference = async (selected) => {
    const body = [];
    selected.forEach((el) => {
      const parentId = getParentKey(el, formik.values.data);
      if (parentId) {
        body.push({
          id: el,
          parentid: parentId,
        });
      }
    });

    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post(`/api/project/riskassessment/medicalexamination/save`, {
        workplaceId,
        data: body,
      });

      if (data?.status === 'success') {
        toast['success'](data?.msg);
        onPostFactorReference();
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  useEffect(() => {
    if (show) {
      onPostFactorReference();
    }
  }, [show]);

  return {
    loading: formik.values.loading,
    data: formik.values.data,
    onSaveFactorReference,
  };
};

export const useWorkType = ({ workplaceId }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: [],
    },
  });

  const onPostWorkTypes = async () => {
    try {
      formik.setFieldValue('loading', true);

      const { data } = await axios.post(`/api/project/riskassessment/medicalexamination/typework`, { workplaceId });

      if (data?.status === 'success') {
        formik?.setFieldValue('data', data?.data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onSaveWorkTypes = async (selected) => {
    const body = selected.map((el) => {
      return {
        id: el,
      };
    });
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post(`/api/project/riskassessment/medicalexamination/typework/save`, {
        workplaceId,
        data: body,
      });
      if (data?.status === 'success') {
        toast['success'](data?.msg);
        onPostWorkTypes();
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      formik.setFieldValue('loading', false);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  useEffect(() => {
    onPostWorkTypes();
  }, []);

  return {
    loading: formik.values.loading,
    data: formik.values.data,
    onSaveWorkTypes,
  };
};

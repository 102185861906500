import { useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export function usePostProjectEvaluationcardHazard({ project, workplace, onShowAddDanger, onShowAddMeasures }) {
  const [loading, setLoading] = useState(0);

  const loadingOn = () => setLoading((prev) => prev + 1);
  const loadingOff = () => setLoading((prev) => prev - 1);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [],
    },
  });

  const formikAddDanger = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: {
        work_place: {
          id: null,
          name: null,
        },
        work_type: {
          id: null,
          name: null,
        },
        source_danger: {
          id: null,
          name: null,
        },
        danger: {
          id: null,
          name: null,
        },
        measures: [],
        custom_measures: [],
        
      }
    },
  });

  const onPostMeasuresproject = async () => {
    try {
      loadingOn();
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/measuresproject`, {
        project,
      });
      if (data?.data) {
        formik?.setFieldValue('data', data?.data);
        loadingOff();
      }
    } catch (e) {
      loadingOff();
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostEvaluationcardEdithazard = async (hazard_id) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/editprojecthazard', {
        hazard_id,
      });

      if (data.status === 'success') {
        formikAddDanger?.setFieldValue('data', data?.data);
        onShowAddDanger.on();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    } finally {
    }
  };

  const onPostEvaluationcardDelete = async (hazard_id) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/deletehazard', {
        hazard_id,
        workplace_id: workplace,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        onPostMeasuresproject();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostEvaluationcardAddMeasuresProject = async () => {
    try {
      const { data: responseData } = await axios.post(`/api/project/riskassessment/evaluationcard/addmeasuresproject`, {
        project_id: project,
        measures: formikAddDanger.values?.data?.custom_measures?.map((el) => ({ id: el.id })),
      });

      if (responseData?.status === 'success') {
        toast['success'](responseData?.msg);
        onPostMeasuresproject();
        onShowAddMeasures.off();
        formikAddDanger.setFieldValue('data.custom_measures', []);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostEvaluationcardDeleteMeasuresProject = async (measure_id) => {
    try {
      loadingOn();
      const { data } = await axios.post('/api/project/riskassessment/evaluationcard/deletemeasuresproject', {
        measure_id,
        project_id: project,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        onPostMeasuresproject();
        loadingOff();
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      loadingOff();
      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  return {
    loading: Boolean(loading),
    dataRows: formik.values?.data,
    formikAddDanger,
    onPostMeasuresproject,
    onPostEvaluationcardEdithazard,
    onPostEvaluationcardDelete,
    onPostEvaluationcardAddMeasuresProject,
    onPostEvaluationcardDeleteMeasuresProject,
  };
}

import styled from 'styled-components';

import * as risk from './helpers';

function getColorRisk(color) {
  return (type) => {
    return color[type] || '#AAA';
  }
}

const typeColor = getColorRisk(risk.color);

export const RiskLevel = styled.span`
  font-family: GolosTextWebVF, serif;
  min-width: 155px;
  height: 28px;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  padding: 0 30px;
  border-radius: 10px;
  color: #fff;
  background-color: ${props => typeColor(props.type)};
`


import React from 'react';
import { isEmpty } from 'lodash';

import AddCircle from '../../../../assets/svg/addCircle';
import Delete from '../../../../assets/svg/delete';
import Flex from '../../../atoms/flex';

import * as Styled from './styled';

function ManagementMeasure(props) {
  const {
    onCurrentMeasures,
    id,
    index,
    isHideActions,
    onShowMeasures,
    name,
    onPostDeleteSettlement,
    hazard_id,
    nameField,
    formik,
    isDelete,
  } = props;

  const { value } = formik?.getFieldProps(`${nameField}.${index}.visible`) ?? {};

  const onCheckbox = (e) => {
    const eTargetCheked = e.target.checked;
    formik?.setFieldValue(`${nameField}.${index}.visible`, eTargetCheked);
  };

  return (
    <Flex paddingItem={['0 15px 0 0']} widthItem={['calc(100% - 15px)']} aItems={'center'} wrap={'nowrap'}>
      <Styled.InputTextmeasure
        onClick={() => {
          onCurrentMeasures({
            index,
            id: id ?? null,
            visible: value,
            nameField,
          });
          onShowMeasures.on();
        }}
      >
        {name}
      </Styled.InputTextmeasure>
      {!isHideActions ? (
        <>
          <Styled.CustomCheckbox onChange={onCheckbox} checked={value} />
          {isDelete ? (
            <Styled.DeleteFieldsButton
              onClick={() =>
                onPostDeleteSettlement({
                  hazard_id,
                  measure_id: id,
                  currentIndex: index,
                  nameField,
                })
              }
            >
              <Delete />
            </Styled.DeleteFieldsButton>
          ) : (
            ''
          )}
        </>
      ) : null}
    </Flex>
  );
}

export function ManagementMeasures(props) {
  const { isHideActions, formik, onPostDeleteSettlement, onCurrentMeasures, onShowMeasures } = props;
  const { values, setFieldValue } = formik ?? {};
  const { value: hazard_id } = formik?.getFieldProps('data.id') ?? {};

  const onAddPlusMeasures = () => {
    setFieldValue(`data.custom_measures.${values?.data?.custom_measures?.length ?? 0}`, {
      name: null,
      id: null,
      visible: false,
    });
  };

  return (
    <React.Fragment>
      {formik?.status === 'addDanger' || formik?.status === 'onlyMeasures' ? (
        ''
      ) : (
        <>
          <Styled.ExistingMeasures>Существующие меры управления</Styled.ExistingMeasures>
          <Styled.ExistingMeasuresHint>
            Выберите те, которые применяются на данном рабочем месте
          </Styled.ExistingMeasuresHint>
        </>
      )}
      {!isEmpty(values?.data?.measures)
        ? values?.data?.measures?.map((el, index) => (
            <ManagementMeasure
              key={index}
              index={index}
              id={el?.id}
              onCurrentMeasures={onCurrentMeasures}
              onShowMeasures={onShowMeasures}
              name={el?.name}
              formik={formik}
              onPostDeleteSettlement={onPostDeleteSettlement}
              hazard_id={hazard_id}
              nameField={'data.measures'}
              status={formik?.status}
            />
          ))
        : ''}
      <Styled.AddFieldsButton varialt={'link'} onClick={onAddPlusMeasures}>
        Меры управления риском
        <Styled.IconCircle>
          <AddCircle />
        </Styled.IconCircle>
      </Styled.AddFieldsButton>
      {values?.data?.custom_measures?.map((el, index) => (
        <ManagementMeasure
          key={index}
          index={index}
          id={el?.id}
          formik={formik}
          isHideActions={isHideActions}
          onCurrentMeasures={onCurrentMeasures}
          onShowMeasures={onShowMeasures}
          name={el?.name}
          onPostDeleteSettlement={onPostDeleteSettlement}
          hazard_id={hazard_id}
          nameField={'data.custom_measures'}
          isDelete={true}
        />
      ))}
    </React.Fragment>
  );
}

import React, { memo } from 'react';
import { CalculatorOutlined, EditOutlined, FileTextOutlined, TeamOutlined } from '@ant-design/icons';

import File from '../../../../../assets/svg/file';

import * as Styled from './styled';
import { IconWrap, Text } from './styled';

const getIcon = (icon) => {
  switch (icon) {
    case 'create':
      return <EditOutlined style={{ fontSize: 24, color: '#1E85EB' }} />;
    case 'pm':
      return <TeamOutlined style={{ fontSize: 24, color: '#1E85EB' }} />;
    case 'opr':
      return <CalculatorOutlined style={{ fontSize: 24, color: '#1E85EB' }} />;
    case 'report':
      return <FileTextOutlined style={{ fontSize: 24, color: '#1E85EB' }} />;
    default:
      return <File />;
  }
};

const DocumentCard = ({ item }) => {
  if (item?.icon) {
    return (
      <Styled.Block href={item?.downloadLink} target="_blank" rel="noreferrer">
        <IconWrap>{getIcon(item?.icon)}</IconWrap>
        <Text>{item?.documentName}</Text>
      </Styled.Block>
    );
  }

  return (
    <Styled.Block href={item?.downloadLink} download>
      <IconWrap>{getIcon(item?.icon)}</IconWrap>
      <Text>{item?.documentName}</Text>
    </Styled.Block>
  );
};

export default memo(DocumentCard);

import React from "react";

import { StyledBTabsButton, StyledTabs } from "./tabs.styled";

const TabsButton = (props) => {
    const { tabs = [], activeIndex = 0, onClickTab = () => {} } = props;

    if (!tabs?.length) {
        return null;
    }

    return (
        <StyledTabs>
            {tabs?.map((el, index) => {
                if (el?.notRenderTab) {
                    return null;
                }

                return (
                    <StyledBTabsButton
                        key={index}
                        className={activeIndex === index ? "tab-active" : ""}
                    >
                        <button onClick={(e) => onClickTab(e, index)}>
                            {el.title}
                        </button>
                    </StyledBTabsButton>
                );
            })}
        </StyledTabs>
    );
};

const TabsContent = (props) => {
    const { tabs = [], activeIndex = 0 } = props;

    if (!tabs?.length) {
        return null;
    }

    return <div className="content-tabs">{tabs[activeIndex].children}</div>;
};

const Tabs = (props) => {
    const { tabs = [] } = props;
    const [toggleState, setToggleState] = React.useState(0);

    const toggleTab = (e, idx) => {
        e.preventDefault();
        setToggleState(idx);
    };

    if (!tabs?.length) {
        return null;
    }

    return (
        <React.Fragment>
            <TabsButton
                tabs={tabs}
                activeIndex={toggleState}
                onClickTab={toggleTab}
            />
            <TabsContent tabs={tabs} activeIndex={toggleState} />
        </React.Fragment>
    );
};

export default Tabs;

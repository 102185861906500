import React from 'react';

import axios from '../../../axios/axios';
import { useFormikContext } from '../../formik/formik.context';

import { StyledIsNeedApprove } from './isNeedApproveField.styled';

function isString(value) {
  return typeof value === 'string';
}

function isNumber(value) {
  return typeof value === 'number';
}

export default function IsNeedApproveTwo(props) {
  const { name, index, disabled, auxiliaryProps } = props;
  const formikProps = useFormikContext();
  const token = localStorage.getItem('token');

  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;

  const {
    values,
    getFieldProps = () => {},
    setFieldValue,
  } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};

  const handleChange = (e) => {
    const checked = e.target.checked ?? false;

    const { danger, event, profession, source } = values.data[index] ?? {};

    axios
      .post(`/kb/set?bearer=${token}`, {
        danger: danger?.id,
        event: event?.id,
        profession: profession?.id,
        source: source?.id,
        setted: checked,
      })
      .catch((error) => console.warn(error));

    if (isNumber(value)) {
      return setFieldValue(name, checked ? 1 : 0);
    }

    return setFieldValue(name, checked);
  };

  const parseValue = isString(value) ? JSON.parse(value) : value;

  return (
    <StyledIsNeedApprove disabled={disabled}>
      <input
        type={'checkbox'}
        className={'checkboxField'}
        checked={parseValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <span />
    </StyledIsNeedApprove>
  );
}

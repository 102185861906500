import { Component } from "react";
import { Checkbox,  Modal, Select } from "antd";

import AddCircle from '../../../../../assets/svg/addCircle';
import Delete from '../../../../../assets/svg/delete.jsx';
import * as Styled from '../modalhac/styled';

class ModalNew extends Component
{

  constructor(props){
    super(props);
    this.state = {
      typical_id: "",
      ok01694: [],
      dataOk: [],
      typical: "",
      typicalId: "",
      modalOk: 1,
      open: true,
      list: [],
      modalAdd: false,
    }
    this.modalOpen = "";
    this.okValue = "";
    this.okLabel = "";
    //console.log(this.props)
  }

  async okModal(){

    this.setState({
      open: false
    });
    //console.log(this.state.typical)

  }
  async saveBundle(){
    //console.log(1)
    fetch('/api/classifier/bundle/typicalok/savebundle', {
      method: 'POST',
      body: JSON.stringify({
        typical_id: this.state.typicalId,
        ok01694: this.state.dataOk
      })
    }).then((response) => {
      return response.json()
    }).then((data) => {
      console.log(data)
    })

    this.props.modalOk();
  }

  componentDidMount(){
    fetch('/api/classifier/bundle/typicalok/listtypical', {
      method: 'POST'
    })
    .then((response) => {return response.json()})
    .then((data) => {
      this.setState({
        list: data.data,
        ok01694: data.ok01694
      });
    })
    this.setState({
      typical: this.props.typical
    })
  }

  okChange(value, label){
    console.log(label)
    this.okLabel = label.label
    this.okValue = label.value
  }

  handleDelete(dat){

    let a = this.state.dataOk.filter(item => item.id !== dat.id);
    this.setState({
      dataOk: a
    });
    console.log(a, dat)
  }

  async modalSave(){
    //console.log(this.okValue)
    let temp = this.state.dataOk;
    temp.push({
      id: this.okValue,
      name: this.okLabel,
      check: false,
    })
    this.setState({
      dataOk: temp,
      modalAdd: false
    })
    //console.log(this.state.dataOk)
  }

  checkIn(dat){
    let temp = this.state.dataOk;
    temp.map((data) => {
      if(data.id === dat.id){
      data.check = true
      }else{ data.check = false}
    })
    this.setState({
      dataOk: temp
    })

    console.log(this.state.dataOk)
  }

  addList(){
    this.setState({
      modalAdd: true
    })
  }

  changeTypical(value, label)
  {
    this.setState({
      typical: label,
      typicalId: value
    })
  }

  render() {
    //console.log(this.state.ok01694)
    if(this.state.open){
      return (
        <>
          <Modal
            title="Добавление типовой позиции"
            onOk={() => this.okModal()}
            onCancel={() => this.props.modalCancel()}
            open={this.props.modalNew}
          >
          <br />
          <Select
            showSearch
            style={{ width: '440px'}}
            options={this.state.list}
            filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
            onChange={(value, label)  => this.changeTypical(value, label)}
          />
          </Modal>
        </>
        )
    }else{
      return (
        <>
          <Modal
            title="Добавление связи"
            onOk={() => this.saveBundle(this.state)}
            onCancel={() => this.props.modalCancel()}
            open={this.props.modalNew}
          >
          <table style={{'font-size': '16px'}}>
            <tr>
            <td style={{'padding-top': '10px'}}>
                <b>Типовая позиция</b>
              </td>
            </tr>
            <tr>
              <td>{this.state.typical.label}</td>
            </tr>
          </table>
          <table>
            <tr>
              <td style={{width: "80%", 'padding-top': '10px'}}>
                <b>ОКПДТР</b>
              </td>
              <td></td>
              <td></td>
            </tr>
            {this.state.dataOk.map((dat) =>
            <tr>
              <td>{dat.name}</td>
              <td><Checkbox checked={dat.check} onChange={() => this.checkIn(dat)}>
                    {/* {dat.check ? 'Основной' : ''} */}
                </Checkbox>
              </td>
              <td>
              <Styled.IconButton
                variant={'link'}
                onClick={() => this.handleDelete(dat)}
              >
                <Delete />
              </Styled.IconButton>
              </td>
            </tr>
            )}
          </table>
          <br />
          <Styled.AddFieldsButton varialt={'link'} onClick={() => this.addList(this.state.data)}>

            <Styled.IconCircle>
              <AddCircle />

            </Styled.IconCircle>
            Добавить
          </Styled.AddFieldsButton>
          </Modal>

          <Modal
          title="Добавление ОКПДТР"
          open={this.state.modalAdd}
          onOk={() => this.modalSave(this.state)}
          onCancel={() => this.modalCancel()}

          >
          <br />
           <Select
              showSearch
              style={{
                width: '440px',
              }}
              onChange={(value, label) =>  this.okChange(value, label)}
              options={this.state.ok01694}
              filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }

           />
          </Modal>
        </>
        )
    }
  }


}
 export default ModalNew;

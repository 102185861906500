import React from 'react'

import Link from '../../../../../atoms/link';

export default function AdditionalControls(props) {
  const {
    id,
    onRowId,
    onPostEvaluationcardAdditionaledit,
    onShowAdditionalControls
  } = props;

  const handleClick = () => {
    onRowId(id)
    onPostEvaluationcardAdditionaledit(id);
    onShowAdditionalControls.on();
  }

  return (
    <Link onLink={handleClick}>
      Добавить
    </Link>
  )
};
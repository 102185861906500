import React from "react";

import { useFormikContext } from "../../formik/formik.context";

// import { StyledInput } from "./upDownFilter.styled";

function getSortByString(arr) {
  return (field) =>
    [...arr].sort((a, b) => {
      let sa = a[field].toLowerCase(),
        sb = b[field].toLowerCase();

      if (sa < sb) {
        return -1;
      }
      if (sa > sb) {
        return 1;
      }
      return 0;
    });
}

export default function UpDownFilter(props) {
  const [sortArrow, setSortArrow] = React.useState(false);
  const formikProps = useFormikContext();
  const { column } = props;
  const { values, setFieldValue } = formikProps ?? {};
  const sortByString = getSortByString(values?.data ?? []);

  const handleClickSort = () => {
    setSortArrow((prevState) => sortArrow !== prevState);

    setFieldValue("data", sortByString(column));
  };

  return (
    <div onClick={handleClickSort}>upDownFilter {sortArrow ? "+" : "-"} </div>
  );
}

import React from 'react';

import * as Styled from '../../styled';

const App = () => {

  return (
    <Styled.SectionTopButton>
        Классификатор мер управления
      </Styled.SectionTopButton>
  )
}

export default App;

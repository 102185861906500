import React from 'react';
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export function useClassifierMeasures({formik, isShow}) {
  const [loadingMeasuresExceptions, setLoadingMeasuresExceptions] = React.useState(0);

  const loadingOn = () => setLoadingMeasuresExceptions((prev) => prev + 1);
  const loadingOff = () => setLoadingMeasuresExceptions((prev) => prev - 1);

  React.useEffect(() => {
    if (isShow) {
      onPostProjectControlmeasures();
    }
  }, [isShow]);

  const onPostProjectControlmeasures = async () => {
    try {
      const { data } = await axios.post('/api/project/controlmeasures');
      if (data.status === 'success') {
        formik?.setFieldValue('controlmeasures', data?.data);
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1702110797953',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onPostMeasuresExceptions = async ({
    parent_id,
    project_id
  }) => {
    try {
      loadingOn();
      const { data } = await axios.post('/api/project/controlmeasures/exceptions', {
        parent_id,
        project_id,
      });

      if (data.status === 'success') {
        formik?.setFieldValue('measuresExceptions', data?.data);
        loadingOff();
      }
      
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1702110785295',
        errorMessage: `${e.data?.msg}`,
      });
      loadingOff();
    }
  };

  return {
    onPostMeasuresExceptions,
    loadingMeasuresExceptions,
  };
}

function Unauthorized() {
    return (
        <div className="form-login">
            <div className="content-page-error">
            <div className="row">
                <div className="col-12">
                    <div className="page-not-found">
                        <h1 className="title-page-errors">
                            401
                        </h1>
                        <p className="sub-title-pages-error">
                            Internal server error
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <a href="/" className="btn vvesti-button">На главную</a>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Unauthorized;

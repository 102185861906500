
export const convertSortDirections = (method) => {
  if (method === 'ascend') {
    return 'ASC'
  } else if (method === 'descend') {
    return 'DESC'
  } else {
    return ''
  }
}

import React from 'react'

import Burger from '../../../../../../assets/svg/burger';
import Delete from '../../../../../../assets/svg/delete';
import Pencil from '../../../../../../assets/svg/pencil';

import * as Styled from './styled';

const content = ({
  id,
  onRowId,
  onPostEvaluationcardEdithazard,
  onPostEvaluationcardDelete
}) => {

  const handleClick = () => {
    onPostEvaluationcardEdithazard(id);
    onRowId(id)
  }

  const handleDelete = () => {
    onPostEvaluationcardDelete(id)
  }

  return (
    <div>
      <Styled.PopoverButton
        onClick={handleClick}
      >
        <Pencil
          fill={'#1B263B'}
          width={15}
          height={15}
        />
        Редактировать
      </Styled.PopoverButton>
      <Styled.PopoverButton
        onClick={handleDelete}
      >
        <Delete />
        Удалить
      </Styled.PopoverButton>
    </div>
  );
}

export default function ActionsRow(props) {
  const {
    id,
    onRowId,
    onPostEvaluationcardEdithazard,
    onPostEvaluationcardDelete
  } = props;

  return (
    <Styled.Popover
      placement="rightBottom"
      content={content({
        id,
        onRowId,
        onPostEvaluationcardEdithazard,
        onPostEvaluationcardDelete
      })}
    >
      <Styled.IconButton>
        <Burger />
      </Styled.IconButton>
    </Styled.Popover>
  )
};
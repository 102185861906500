import React from 'react';

import useKeyEvents from '../../../../../hooks/useEventKey';
import Button from '../../../../atoms/nButton';
import Dialog from '../../../../blocks/dialog';
import Portal from '../../../../blocks/portal';
import FieldInput from '../../../fields/input';

import * as Styled from './styled';

export default function AddDivision(props) {
  const { isRender, formik, onShowAddDivision, onPostAddStructure } = props;

  const { value: titleValue } = formik.getFieldProps('title') ?? {};
  const { value: divisionValue } = formik.getFieldProps('division') ?? {};

  const closeShowAddDivision = () => {
    formik.setFieldValue('division', {});
    onShowAddDivision.off();
  };

  const handleAddDivision = () => {
    onPostAddStructure({
      ...divisionValue,
      parent: titleValue?.id ?? null,
    });
  };

  useKeyEvents('Enter', handleAddDivision);

  const onSubmitForm = (event) => {
    event.preventDefault();
  };

  return (
    <Portal id={'addDivision'}>
      <Dialog
        isRender={isRender}
        isClose={true}
        centered={true}
        onClose={closeShowAddDivision}
        title={'ДОБАВИТЬ ПОДРАЗДЕЛЕНИЕ'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px',
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          },
        }}
      >
        <form onSubmit={onSubmitForm}>
          <FieldInput
            margin={'0 0 30px'}
            placeholder={'Наименование подразделения'}
            formik={formik}
            name={'division.name'}
          />
          <Styled.Buttons jContent={'center'}>
            <Button variant={'secondary'} onClick={handleAddDivision}>
              Добавить
            </Button>
          </Styled.Buttons>
        </form>
      </Dialog>
    </Portal>
  );
}

export const getArrMap = (originalArr) => {
  const temp = [];
  originalArr.forEach((a) => {
    const recursion = (item) => {
      if (item.data) {
        item.data.forEach((el) => {
          temp.push(el);
          if (el.data) {
            recursion(el);
          }
        });
      }
    };
    recursion(a);
  });

  return temp;
};

export const getExpandedKeys = (arrMap, text) => {
  const newExpandedKeys = [];

  arrMap.forEach((item) => {
    if (item?.name?.toLowerCase()?.includes(text?.toLowerCase())) {
      newExpandedKeys.push(item?.parentId || item?.parentid);
      const recursion = (key) => {
        const filtered = arrMap?.find((el) => el.id === key);
        if (filtered?.parentId || filtered?.parentid) {
          newExpandedKeys.push(filtered?.parentId || filtered?.parentid);
          recursion(filtered?.parentId || filtered?.parentid);
        }
      };
      recursion(item?.parentId || item?.parentid);
    }
  });

  return { newExpandedKeys };
};

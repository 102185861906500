import React from "react";

import DateForm from "../../../../core/DateForm";

function EmployerCard({ employer, setVisible, setOrganizationId }) {
  return (
    <tr>
      <DateForm className={"td-text-employers"} date={employer.date} />
      <td
        onClick={() => {
          setOrganizationId(employer.id);
          setVisible(true);
        }}
        className="td-text-employers text-decoration-underline"
      >
        <span role="button">{employer.shortName}</span>
      </td>
      <td className="td-text-employers">{employer.inn}</td>
      <td className="td-text-employers">{employer.assessment}</td>
      <td className="td-text-employers">{employer.region}</td>
      <td className="td-text-employers" />
    </tr>
  );
}

export default EmployerCard;

import { AUTH_LOGOUT } from "../actionTypes/loginTypes";
import * as actionTypes from "../actionTypes/NSITypes";

const initialState = {
    okved: null,
    typesOfWorks: null,
    selectedNSIdictionary: null,
    workPlaces: null,
    nas: null,
    ok01694: null,
    sout: null,
    dangerousSituations: null,
    instrumentsAndEquipments: null,
    workPlaces2: null,
    materials: null,
    bioObjects: null,
    machinery: null,
    danger: null,
    dangerEvent: null,
    riskManagement: null,
    medical: null,
    medicalVPF: null,
    medicalVPF302: null,
    siz: null, //для меры СИЗ
    siz_new: null,
    workStages: null,
    name: "",
    organization: null,
    expertOrganization: null,
    typeCompany: null,
    // stagesWork: null,
    requirement: null, //для меры работам
    medicalSout: null, //для меры медицинским осмотрам
    personnelRequire: null, //для меры персоналу
    soutData: {
        /*
    soutId: 'failed' || {}
    */
    },
    soutMedicalData: {
        /*
    soutMedId: 'failed' || {}
    */
    },
};

export function NSIReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_OKVEDS:
            for (let i = 0; i < action.payload[0].length; i++) {
                action.payload[0][
                    i
                ].codeName = `${action.payload[0][i].classOKVED} ${action.payload[0][i].economicActivity}`; //codeName
            }
            return { ...state, okved: action.payload };
        case actionTypes.GET_TYPES_OF_WORKS:
            return { ...state, typesOfWorks: action.payload };
        case actionTypes.SET_SELECTED_CLAFFISIERS:
            return {
                ...state,
                selectedNSIdictionary: action.payload,
                name: action.name,
            };
        case actionTypes.GET_WORK_PLACES:
            return { ...state, workPlaces: action.payload };
        case actionTypes.GET_OK01694:
            for (let i = 0; i < action.payload[0].length; i++) {
                action.payload[0][
                    i
                ].codeName = `${action.payload[0][i].code} ${action.payload[0][i].professionName}`; //codeName 47
            }
            return { ...state, ok01694: action.payload };
        case actionTypes.GET_PROF_STANDART:
            for (let i = 0; i < action.payload[0].length; i++) {
                action.payload[0][
                    i
                ].codeName = `${action.payload[0][i].code} ${action.payload[0][i].name}`; //codeName 47
            }
            return { ...state, profStandart: action.payload };

        case actionTypes.GET_NAS_CLASSIFIERS:
            return { ...state, nas: action.payload };
        case actionTypes.GET_SOUT:
            let soutGroups = {};
            for (let i = 0; i < action.payload[0].length; i++) {
                action.payload[0][i].code = "";
                soutGroups[action.payload[0][i].name] = "0.0";

                // soutGroups[action.payload[0][i].name] = [...soutGroups[action.payload[0][i].name] ?? [], {id:action.payload[0][i].id, code: action.payload[0][i].code, name: action.payload[0][i].name}]
            }
            return { ...state, sout: action.payload, soutGroups: soutGroups };
        case actionTypes.GET_DANGEROUS_SITUATION:
            return { ...state, dangerousSituations: action.payload };
        case actionTypes.GET_INSTRUMENTS_AND_EQUIPMENTS:
            return { ...state, instrumentsAndEquipments: action.payload };
        case actionTypes.GET_WORK_PLACES2:
            return { ...state, workPlaces2: action.payload };
        case actionTypes.GET_MATERIALS:
            return { ...state, materials: action.payload };
        case actionTypes.GET_BIO_OBJECTS:
            return { ...state, bioObjects: action.payload };
        case actionTypes.GET_MACHINERY:
            return { ...state, machinery: action.payload };
        case actionTypes.GET_DANGER:
            return { ...state, danger: action.payload };
        case actionTypes.GET_DANGER_EVENT:
            return { ...state, dangerEvent: action.payload };
        case actionTypes.GET_RISK_MANAGEMENT:
            return { ...state, riskManagement: action.payload };
        case actionTypes.GET_MEDICAL:
            return { ...state, medical: action.payload };
        case actionTypes.GET_MEDICAL_VPF:
            return { ...state, medicalVPF: action.payload };
        case actionTypes.GET_MEDICAL_VPF302:
            return { ...state, medicalVPF302: action.payload };
        case actionTypes.GET_SIZ:
            return { ...state, siz: action.payload };
        case actionTypes.GET_SIZ_NEW:
            return { ...state, siz_new: action.payload };
        case actionTypes.GET_WORK_STAGES:
            return { ...state, workStages: action.payload };
        case actionTypes.GET_ORGANIZATION:
            return { ...state, organization: action.payload };
        case actionTypes.GET_EXPERT_ORGANIZATION:
            return { ...state, expertOrganization: action.payload };
        case actionTypes.GET_TYPE_COMPANY:
            return { ...state, typeCompany: action.payload };
        case actionTypes.GET_REQUIREMENT:
            return { ...state, requirement: action.payload };
        case actionTypes.GET_MEDICAL_SOUT:
            return { ...state, medicalSout: action.payload };
        case actionTypes.GET_PERSONNEL_REQUIRE:
            return { ...state, personnelRequire: action.payload };
        case actionTypes.ADD_SOUTDATA:
            return {
                ...state,
                soutData: { ...state.soutData, ...action.payload },
            };
        case actionTypes.ADD_SOUTMEDICALDATA:
            return {
                ...state,
                soutMedicalData: {
                    ...state.soutMedicalData,
                    ...action.payload,
                },
            };
        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

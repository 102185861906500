import { Component } from "react";
import { toast } from "react-toastify";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Modal, Select } from "antd";

import AddCircle from '../../../../../assets/svg/addCircle';
import Delete from '../../../../../assets/svg/delete.jsx';

import * as Styled from './styled';

const { confirm } = Modal;



class ClassTyp extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      props: props,
      data: props.data,
      modalOpen: props.isModalOpen,
      type: "",
      typical: "",
      ok01694: [],
      modalAdd: false,
      modalNew:  false,
    }
    this.checkit = this.checkIn.bind(this);
    //console.log(props.data)
    //this.typical = props.data?.typical_profession_id ?? "";
    this.dat = [];
    //this.source = props.data?.danger_source_id ?? "";
    //this.place = props.data?.work_place_id ?? "";
    //this.modalAdd = false;
    this.ok01694  = '';
  }

  async componentDidMount()
  {

  }

  async modalSave(){

    //console.log(this.state)
    //console.log(this.state.data[0].typical_id)
    //console.log(this.ok01694);
    fetch('/api/classifier/bundle/typicalok/saveok', {
      method: 'POST',
      body: JSON.stringify({
        typical_id: this.state.data[0].typical_id,
        ok01694: this.ok01694
      })
    }).then((response) => {return response.json()})
    .then((data) => {
      //console.log(data);
      toast['success'](data.msg)
        this.setState({
          data: data.data
        })
      this.setState({
        modalAdd:  false,
      });
    })

  }


  okChange(value){
    this.ok01694 = value;
  }

  checkIn(dat){
    //console.log(dat)
    fetch('/api/classifier/bundle/typicalok/check',
      {
        method: 'POST',
        body: JSON.stringify({
          id: dat.id,
          typical_id: dat.typical_id,
          ok_id: dat.ok_id,
        })
      }).then((response) => {
        return response.json();
      }).then((data) => {
        toast['success'](data.msg)
        this.setState({
          data: data.data
        })
        //console.log(data)
      })

    //console.log(dat);
  }



  async addList(){
    //console.log(data)
    await  fetch('/api/classifier/bundle/typicalok/listok',  {
      method: 'POST'
    }).then((response) => {
      return response.json();
    }).then((data) => {
      //console.log(data.data)
      this.setState({
        ok01694: data.data,
      })
      //consol` e.log(this.ok01694)
      this.setState({
        modalAdd: true,
      });
    //console.log(data)
    })
  }

  handleDelete(dat){
    this.dat = dat;
    confirm({
      title: "Пдтвердите удаление связки",
      icon: <ExclamationCircleOutlined />,
      content: this.dat.name + " -> " + this.dat.profession_name,
      okText: "Подтвердить",
      cancelText: "Отмена",
      onOk: ()  => {
        fetch('/api/classifier/bundle/typicalok/delete', {
          method: 'POST',
          body: JSON.stringify({
            id: this.dat.id,
            typical_id: this.dat.typical_id
          })
        }).then((response) => {return response.json()})
        .then((data) => {
            toast['success'](data.msg)
            this.setState({
              data:  data.data,
            })

        })
      }
  })
}



  modalCancel(){
    this.setState({
      modalAdd: false
    });
  }

  modalOk(){
    this.setState({
      modalOpen: false
    });
  }

  render() {

    //this.checkIn();
    return (
      <>
      <Modal
          title={this.props.title}
          open={this.state.modalOpen}
          onOk={() => this.props.modalCancel()}
          onCancel={this.props.modalCancel}
          >
          <table style={{'font-size': '16px'}}>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Типовая позиция</b>
              </td>
            </tr>
            <tr>
              <td>
              <p>{this.state.data[0].name}</p>

              </td>
            </tr>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>ОКПДТР</b>
              </td>
            </tr>



          </table>
          <table>
          {this.state.data.map((dat) =>
            <tr>
              <td style={{width: "80%"}}>{dat.profession_name}</td>
              <td><Checkbox checked={dat.check} onChange={() => this.checkIn(dat)}>
                    {/* {dat.check ? 'Основной' : ''} */}
                </Checkbox>
              </td>
              <td>
              <Styled.IconButton
              variant={'link'}
              onClick={() => this.handleDelete(dat)}
          >
          <Delete />
          </Styled.IconButton>
              </td>
            </tr>
          )}
          </table>
          <br/>
          <Styled.AddFieldsButton varialt={'link'} onClick={() => this.addList(this.state.data)}>

            <Styled.IconCircle>
              <AddCircle />

            </Styled.IconCircle>
            Добавить
          </Styled.AddFieldsButton>
        </Modal>

        <Modal
          title="Добавление ОКПДТР"
          open={this.state.modalAdd}
          onOk={() => this.modalSave(this.state)}
          onCancel={() => this.modalCancel()}

          >
          <br />
           <Select
              showSearch
              style={{
                width: '440px',
              }}
              onChange={(value) =>  this.okChange(value)}
              options={this.state.ok01694}
              filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }

           />
          </Modal>
</>
    )
  }
}
export default ClassTyp;

import styled from "styled-components";

export const StyledControlButton = styled.button`
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  background-color: transparent;

  &:hover {
    color: #212529;
    border-color: #212529;
  }
`;

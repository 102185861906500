import { Component } from "react";
import { toast } from "react-toastify";
import { Modal, Select } from "antd";

//import * as Styled from './styled.js';


class ClassHac extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      props: props,
      data: [],
      modalOpen: props.isModalOpen,
      type: "",
      typical: ""
    }
    //console.log(props)
    //this.typical = props.data?.typical_profession_id ?? "";
    this.sout = props.data?.sout_id ?? "";
    this.event = props.data?.danger_id ?? "";
  }

  async componentDidMount()
  {
    /*
    await fetch('/api/classifier/bundle/work/id',
      {
        method: 'POST',
        body: JSON.stringify({id: this.state.props.id})
      }
    ).then((response) => {
      return response.json();
    }).then((data) => this.setState({data: data.data}))
    */
  }

  async modalSave(){
    await fetch('/api/classifier/bundle/eventsout/save',
      {
        method: 'POST',
        body: JSON.stringify({
          id: this.props.id,
          //work_place_id: this.typical,
          danger_id: this.event,
          sout_id: this.sout,
        })
      }
    ).then(
      (response) => {return response.json()}
    ).then((data) => {
      if(data.code === 201){
        toast['error'](data.msg)
      }else{
        toast['success'](data.msg)
        this.props.modalCancel()
      }


    }
    )

  }


  eventChange(value){
    this.event = value;
  }

  soutChange(value){
    this.sout = value;
  }

  render() {


    return (
      <>
      <Modal
          title={this.props.title}
          open={this.state.modalOpen}
          onOk={() => this.modalSave(this.state)}
          onCancel={this.props.modalCancel}
          >
          <table style={{'font-size': '16px'}}>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Опасное событие</b>
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  showSearch
                  id="typical"
                  style={{
                  width: '440px',
                  }}

                  filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.danger_id ?? '']}
                  options={this.props.sprType.event}

                  onChange={(value) => this.eventChange(value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>СОУТ</b>
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  showSearch
                  style={{
                    width: '440px',
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.sout_id ?? '']}
                  options={this.props.sprType.sout}

                  onChange={(value) => this.soutChange(value)}
                />
              </td>
            </tr>



          </table>
        </Modal>
</>
    )
  }
}
export default ClassHac;

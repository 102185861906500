import React from 'react';

import * as Styled from './svg.styled';

export default function Unlocked({
  fill = "#25282B",
  width = "22",
  height = "22",
  viewBox = "0 0 22 22"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.9992V6.99918C6.99876 5.75923 7.45828 4.56304 8.28938 3.64284C9.12047 2.72264 10.2638 2.14408 11.4975 2.01947C12.7312 1.89485 13.9671 2.23308 14.9655 2.96849C15.9638 3.7039 16.6533 4.78402 16.9 5.99918"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Svg>
  )
}
import React from "react";

export default function useBoolean(initialValue) {
    const [value, setValue] = React.useState(initialValue);

    const updateValue = React.useRef({
        toggle: () => setValue((oldValue) => !oldValue),
        on: () => setValue(true),
        off: () => setValue(false),
    });

    return [value, updateValue.current];
}

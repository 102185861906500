export const navMap = [
  {
    id: '1',
    path: '/analitic/classifiers/retion',
    label: 'Заявки на изменение'
  },
  {
    id: '2',
    path: '/analitic/classifiers/requisition',
    label: 'Классификаторы',
  },
  {
    id: '3',
    path: '/classifiers/matrix',
    label: 'Матрицы'
  }
];

import styled, { css } from 'styled-components';

import Flex from '../../../atoms/flex';
import Input from '../../../atoms/input';
import Button from '../../../atoms/nButton';
import Text from '../../../atoms/text';

export const InputText = styled(Input)`
  background: #fff;
  font-size: ${(props) => props.fSize ?? 16}px;
  line-height: ${(props) => props.lHeight ?? 22}px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const FieldLabel = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  padding: ${(props) => props.padding ?? '0 0 12px'};
  display: inline-block;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}}
`;

export const FieldNumber = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}}
`;

export const Valide = styled(Flex)`
  padding-top: 8px;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ValideMessage = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  color: #aaa;
  padding-left: 9px;
  display: inline-block;
`;

export const InputTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #18171C;
  border-radius: 8px;
  padding: ${(props) => props.padding ?? '10px 20px'};

  &:hover {
    border-color: #AAA;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: #014880;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: #ffd8d8;

      &:hover {
        border-color: #ffd8d8;
      }
    `}}
`;

export const ShowHidePassword = styled(Button)`
  margin-left: 10px;
`;

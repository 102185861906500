export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((y, f) => f(y), x);

export function curryFilter(fn) {
  return (array) => array?.filter(fn);
}

export function curryFind(fn) {
  return (array) => array?.find(fn);
}

export function curryMap(fn) {
  return (array) => array?.map(fn);
}

export function curryFlat(fn) {
  return (array) => array?.flat(fn);
}

export function conditionally(options) {
  return (props) => {
    return options.if(props) ? options.then(props) : options.else(props);
  };
}

export const ifArray = conditionally({
  if: (array) => !!array?.length,
  then: (array) => array,
  else: () => [],
});

export const ifObject = conditionally({
  if: (object) => !!Object.keys(object ?? {}).length,
  then: (object) => object,
  else: () => {},
});

export function arrLength(arr) {
  return arr?.filter(Boolean)?.length;
}

export function getObjectProperty(object) {
  if (object === null) {
    // null or undefined
    return object;
  }

  return (path) => {
    const parts = path.split('.');

    return parts.reduce((object, key) => object?.[key], object);
  };
}

import styled from 'styled-components';

export const InputWrap = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;

  .ant-input-number {
    width: 50px;

    .ant-input-number-handler-wrap {
      display: none;
    }
  }
`;

export const BookIconWrap = styled.div`
  width: 34px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #c9c8d3;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const DescriptionText = styled.p``;

export const DescriptionLi = styled.li`
  position: relative;
  padding-left: 28px;
  font-size: 12px;
  margin-bottom: 8px;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: #000000;
    border-radius: 50%;
  }
`;

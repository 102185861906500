export const EMPLOYER_DATA_SUCCESS = 'EMPLOYER_DATA_SUCCESS';
export const EMPLOYER_DATA_CLEAR = 'EMPLOYER_DATA_CLEAR';
export const EMPLOYERS_DATA_SUCCESS = 'EMPLOYERS_DATA_SUCCESS';
export const UPDATE_EMPLOYER_SUCCESS = 'UPDATE_EMPLOYER_SUCCESS';
export const EMPLOYER_STRUCTURE_LOAD = 'EMPLOYER_STRUCTURE_LOAD';

export const EMPLOYER_STRUCTURE_NEW = 'EMPLOYER_STRUCTURE_NEW';
export const EMPLOYER_SELECTED_STRUCTURE_SET = 'EMPLOYER_SELECTED_STRUCTURE_SETS';
export const EMPLOYER_OPENED_STRUCTURES_ADD = 'EMPLOYER_OPENED_STRUCTURES_ADD';
export const EMPLOYER_OPENED_STRUCTURES_DELETE = 'EMPLOYER_OPENED_STRUCTURES_DELETE';
export const EMPLOYER_SELECTED_STRUCTURE_CLEAR = 'EMPLOYER_SELECTED_STRUCTURE_CLEAR';
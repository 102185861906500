import styled from 'styled-components';

import Flex from '../../../../../atoms/flex';
import Button from '../../../../../atoms/nButton';
import Text from '../../../../../atoms/text';

export const Buttons = styled(Flex)`
  justify-content: flex-end;
`;

export const SelectSout = styled.select`
  font-family: GolosTextWebVF, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: #52575C;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #52575C;
  width: 76px;
`;

export const SelectSoutWrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 12px;
  padding-right: 12px;
  align-content: center;
  align-items: center;
`;

export const SelectSoutText = styled(Text)`
  font-size: 14px;
  line-height: normal;
`;

export const ParentArrow = styled.div`
  transform: rotate(${props => props.rotate}deg);
  height: 24px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Parent = styled.div`
  padding: 0;
  font-size: 12px;
  font-family: GolosTextWebVF, serif;
  color: #000;
  display: flex;
  align-items: center;
`;

export const WorkplacesLi = styled.li`
  padding-bottom: 10px;
`

export const WorkplacesUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    margin-left: 24px;
  }
`

export const Form = styled.form`
  height: calc(100vh - 187px);
  overflow-y: auto;
  margin-bottom: 30px;
`

export const ButtonBottom = styled(Button)`
  font-size: 17px;
`
import {useEffect, useState} from 'react'
import {Alert, Button, Spinner,Table} from 'react-bootstrap'

import axios from '../../../../axios/axios'

import EditModal from './Modal/Modal'

export const url = '/api/matrix'

function Matrices() {
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [record, setRecord] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadTable = async function() {
    setLoading(true)
    try {
      const {data} = await axios.get(url + '/list')
      setRows([...data.rows])
      setTotal(data.total)
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    loadTable()
  }, [])

  function createRecord() {
    setRecord(null)
    setShowModal(true)
  }
  function editRecord(record) {
    setRecord(record)
    setShowModal(true)
  }
  function deleteRecord(record) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Вы уверены, что хотите удалить эту матрицу?!')) {
      setLoading(true)
      axios.delete(url + '/' + record.id).then(() => {
        loadTable()
      }).finally(() => {
        setLoading(false)
      })
    }
  }
  function onModalHide() {
    setShowModal(false)
  }

  function GetTable() {
    if (total === 0) {
      return (
        <Alert variant="light" className="py-4">Записей не найдено</Alert>
      )
    }

    return (
      <Table hover>
        <thead>
        <tr className="tr-bg">
          <th>Матрица</th>
          <th>Описание</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows?.map((item) => <GetTableRow key={item.id} {...item} />)}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan={3} className="text-end">
            <Button variant="light" onClick={loadTable} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" className="me-1" /> : <i className="bi bi-arrow-repeat" />}
            </Button>
          </td>
        </tr>
        </tfoot>
      </Table>
    )
  }
  function GetTableRow(props) {
    return (
      <tr valign="middle">
        <td>{props.name}</td>
        <td>{props.description}</td>
        <td className="text-end">
          <Button variant="link" size="sm" className="me-1 text-secondary" onClick={() => editRecord(props)}>
            Изменить
          </Button>
          <Button variant="link" size="sm" className="text-secondary" onClick={() => deleteRecord(props)}>
            Удалить
          </Button>
        </td>
      </tr>
    )
  }
  function GetBusy() {
    return (
      <Alert variant="light" className="py-4 text-center">
        <Spinner animation="border" />
      </Alert>
    )
  }

  return (
    <>
      <div className="text-end">
        <Button variant="default" className="add-button" onClick={createRecord}>
          Добавить
        </Button>
      </div>
      <div className="bg-white mt-3">
        {total === null ? <GetBusy /> : <GetTable />}
      </div>
      <EditModal show={showModal} record={record} onHide={onModalHide} onUpdate={loadTable} />
    </>
  )
}

export default Matrices

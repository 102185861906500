import React from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import axios from "../../../../axios/axios";
import { logWarn } from "../../../../utils/log";

export function useReports({
  project,
  onShowListOfReports
}) {

  const formikReports = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [],
      reportsList: []
    },
  });


  React.useEffect(() => {

    onPostRiskassessmenReport();
    onGetReportsProject();
  }, [])

  const onPostRiskassessmenReport = async () => {

    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/reports/reports?uid=${project}&bearer=${token ?? ''}`);

      if (data) {
        formikReports?.setFieldValue('data', data);
      }
      console.log(data)
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  const onGetReportsProject = async () => {

    try {

      const { data } = await axios.post(`/api/reports/list`);

      if (data?.length) {
        formikReports?.setFieldValue('reportsList', data?.map(el => ({
          ...el,
          isNotDisabled: el?.Formats,
          Formats: []
        })));
      }

    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  const onGenerateReport = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.post(`/api/reports/reports?bearer=${token ?? ''}`, {
        UUID: project,
        Reports: formikReports.values.reportsList?.map(el => ({
          Name: el.Name,
          Report: el.Report,
          Formats: el.Formats
        }))
      });
      //for(let i = 1; i <  1000; i++){}

      if (data === 'OK') {
        toast['success']('Отчет формируется');
        //setTimeout(console.log('10 sec'))
        let promise = new Promise((resolve, reject) => {
          setTimeout(() => resolve("готово!"), 5000)
        });
        onShowListOfReports.off();
        await promise;
        onGetReportsProject();
        onPostRiskassessmenReport();



      }

    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    formik: formikReports,
    dataRows: formikReports?.values?.data,
    dataReportsList: formikReports?.values?.reportsList,
    onGetReportsProject,
    onGenerateReport,
    onPostRiskassessmenReport
  }

}

import styled, { css } from 'styled-components';

export const List = styled.ul`
  display: ${(props) => (props.flex ?? 'flex')};
  align-items: center;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin-bottom: 35px;
`;
export const ListItem = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

export const Button = styled.button`
  color: #101331;
  display: block;
  font-family: GolosTextWebVF, serif;
  padding: 25px 15px 23px;
  border: 0;
  border-bottom: 2px solid ${(props) => (props.current ? '#000' : 'rgb(237, 237, 239)')};
  font-size: 14px;
  font-weight: ${(props) => (props.current ? 500 : 400)};
  line-height: 17px;
  background-color: transparent;

  &:disabled {
    color: #ccccd3;
    cursor: not-allowed;
  }
`;

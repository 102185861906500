import { toast } from "react-toastify";

import axios from "../../../../axios/axios";
import { logWarn } from "../../../../utils/log";

export function usePostControlmeasuresSave({
  formik,
  project,
  onShowEditClassifierMeasures,
  onPostProjectRiskassessment,
  onConfirmClassifierMeasures
}) {

  const onPostControlmeasuresSave = async ({
    id,
    rate
  }) => {

    try {
      const {data} = await axios.post('/api/project/controlmeasures/save', {
        project_id: project,
        id,
        rate
      });

      if (data?.data) {
        toast['success'](data?.msg);
        onPostMeasuresExceptions();
      }

    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685587487927",
        errorMessage: `${e}`,
      });
    } finally {
      onConfirmClassifierMeasures.off();
      onShowEditClassifierMeasures.off();
      onPostProjectRiskassessment();
    }
  }

  const onPostMeasuresExceptions = async () => {
    try {
      const { data } = await axios.post('/api/project/controlmeasures/exceptions', {
        project_id: project,
        parent_id : formik?.values?.currentContMeasure?.parent_id
      });

      if (data.status === 'success') {
        formik?.setFieldValue('measuresExceptions', data?.data);
      }
      
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1702110785295',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  return {
    onPostControlmeasuresSave,
  }
}

import React from 'react';
import {Button} from 'react-bootstrap'

export default function Details(props) {
  const {
    item,
    viewRecord
  } = props;

  if (!Object.keys(item?.params)?.length) {
    return null
  }

  return (
    <div style={{
      paddingRight: 10
    }}>
      <Button
        variant="link"
        size="sm"
        className="text-secondary"
        onClick={() => viewRecord(item)}
      >
        Подробности
      </Button>
    </div>
  )
};
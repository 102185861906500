import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  overflow: auto;
  width: 100%;
  padding: 8px;
`;

export const StyledTable = styled.div`
  width: 100%;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
`;

export const StyledTRow = styled.div`
  display: table-row;

  &:hover > div {
    background-color: rgba(0, 0, 0, 0.075);
  }
`;

export const StyledThead = styled(StyledTRow)`
  background: #ffffff;

  &:hover > div {
    background-color: transparent;
  }
`;

export const StyledTCell = styled.div`
  vertical-align: middle;
  position: relative;
  text-align: ${({ column, cell }) =>
    (column?.alignText || cell?.alignText) ?? 'left'};
  white-space: ${({ column }) => column?.whiteSpace ?? 'normal'};
  padding: 16px;
  word-break: ${({ cell }) => cell?.wordBreak ?? 'normal'};
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

export const StyledTCellSeparator = styled.span`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: ${({ separator }) => separator?.alignLeft ?? '0'}px;
`;

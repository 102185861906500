import { Checkbox } from 'antd';
import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';
// import Text from '../../../atoms/text';

export const Buttons = styled(Flex)`
  padding: 24px 0;
`;

export const AddFieldsButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  border: 0;
  background: transparent;
  color: #000;
  padding: 0;
  margin-bottom: 10px;
`;

export const ExistingMeasures = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  border: 0;
  background: transparent;
  color: #000;
  padding: 0;
  margin-bottom: 4px;
`;

export const ExistingMeasuresHint = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  border: 0;
  background: transparent;
  color: red;
  padding: 0;
  margin-bottom: 8px;
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #000;
  margin-left: 10px;
`;

export const DeleteFieldsButton = styled(Button)`
  border: 0;
  background: transparent;
  padding: 0;
  width: 24px;
  position: absolute;
  right: -36px;
  top: 50%;
  margin-top: -7px;
`;

export const InputText = styled.div`
  padding-bottom: 25px;
`;

export const InputTextInput = styled.div`
  border: 1px solid rgb(24, 23, 28);
  border-radius: 10px;
  padding: 10px 20px;
  height: 44px;
  font-size: 16px;
  line-height: 22px;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  background-color: ${(props) => (props.disabled ? 'rgb(246, 246, 247)' : 'transparent')};
`;

export const InputTextLabel = styled.label`
  font-family: GolosTextWebVF, serif;
  padding: 0px 0px 17px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`;

export const InputTextmeasure = styled.div`
  border: 1px solid rgb(24, 23, 28);
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  min-height: 44px;
  line-height: 22px;
  font-family: GolosTextWebVF, serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  white-space: break-spaces;
`;

export const CustomCheckbox = styled(Checkbox)`
  &:where(.css-dev-only-do-not-override-fpg3f5).ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
  }
`;

import React from 'react';
import { Skeleton } from 'antd';

import * as Styled from '../styled';

const InfoBlock = ({ gridName, title, loading, children }) => {
  return (
    <Styled.InfoBlock gridName={gridName}>
      <Styled.InfoBlockTitle>{title}</Styled.InfoBlockTitle>
      {loading ? <Skeleton.Input active={true} /> : children}
    </Styled.InfoBlock>
  );
};

export default InfoBlock;

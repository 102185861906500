import React from "react";

import InputSearch from '../../atoms/search';
import Table from '../../blocks/table';
import {FormikProvider} from '../../formik/formik.context'
import HeaderUsers from "../../Menu/MenuAdministration/HeaderUsers";

import {getTableRows,tableHead} from './helpers';
import {useGetEnterprises} from './hooks'
import * as Styled from './styled';

export default function Requests() {
  const {dataRows, onGetEnterprises, onPostEnterpriseFind} = useGetEnterprises();

  const rows = getTableRows(
    dataRows,
    onGetEnterprises
  );

  const handleChangeSearchRequest = (event) => {
    onPostEnterpriseFind(event.target.value)
  }

  return (
    <FormikProvider>
      <HeaderUsers />
      <Styled.Section>
        <Styled.SectionHead
          jContent={'space-between'}
          widthItem={[465, 170]}
        >
          <InputSearch
            placeholder={'Поиск'}
            onChange={handleChangeSearchRequest}
          />
        </Styled.SectionHead>
        <Table
          head={tableHead}
          data={rows}
          class={'table'}
        />
      </Styled.Section>

    </FormikProvider>
  );
}

import React from 'react';
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

const getData = (array, formik, onPostStructureWorkplace, parentId = null) => {
  return array?.map((el, parentIndex) => {
    if (el.structure?.length) {
      return {
        children: getData(el.structure, formik, onPostStructureWorkplace, parentIndex, el.id),
        id: el.id,
        title: (
          <span
            style={{ display: 'block', width: '100%' }}
            // onClick={() => handleClickParent(el, formik)}
            onClick={() => {
              formik.setFieldValue('title', {
                ...el,
                name: el.name ?? el.parent,
              });
              formik.setFieldValue('prevTitle', {
                ...el,
                name: el.name ?? el.parent,
              });
              onPostStructureWorkplace(el);
              formik.setFieldValue('structureEl', el);
            }}
          >
            {el.name ?? el.parent}
          </span>
        ),
        name: el.name ?? el.parent,
        key: el.id,
      };

    }

    return {
      id: el.id,
      title: (
        <span
          style={{ display: 'block', width: '100%' }}
          onClick={() => {
            formik.setFieldValue('title', {
              ...el,
              name: el.name ?? el.parent,
            });
            onPostStructureWorkplace(el);
            formik.setFieldValue('structureEl', el);
          }}
        >
          {el.name ?? el.parent}
        </span>
      ),
      name: el.name ?? el.parent,
      key: el.id,
      parentId,
    };
  });
};

export function usePostStructureWorkplace({ id, formik, onShowEditWorkplace }) {
  const onPostStructureWorkplace = async (structure) => {
    try {
      const { data } = await axios.post('/api/project/structure/workplace', {
        project: id,
        structure: structure?.id,
      });

      if (data?.data?.length) {
        formik.setFieldValue('right', data?.data);
      }
    } catch (e) {
      formik.setFieldValue('right', []);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  const onDeleteStructureWorkplace = async (workplace) => {
    try {
      const { data } = await axios.post('/api/project/structure/deleteworkplace', {
        ...workplace,
        project: id,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostEditworkplace = async (workplace) => {
    try {
      const { data } = await axios.post('/api/project/structure/editworkplace', {
        ...workplace,
        project: id,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
      }
      onShowEditWorkplace.off();
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
    /* finally {
      onShowEditWorkplace.off();
    } */
  };

  return {
    onDeleteStructureWorkplace,
    onPostStructureWorkplace,
    onPostEditworkplace,
  };
}

export function usePostStructure({ id, formik, onShowTitleEdit, onShowEditWorkplace, onShowAddDivision }) {
  const { onDeleteStructureWorkplace, onPostStructureWorkplace, onPostEditworkplace } = usePostStructureWorkplace({
    id,
    formik,
    onShowEditWorkplace,
  });

  const onPostStructure = async () => {
    try {
      const { data } = await axios.post('/api/project/structure', { id });

      if (data?.data?.length) {
        formik.setFieldValue('left', getData(data?.data, formik, onPostStructureWorkplace));
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostStructureFind = async (e) => {
    const eTarget = e.target.value;

    if (eTarget.length === 0) {
      await onPostStructure();
    }

    if (eTarget.length < 2) {
      return null;
    }

    try {
      const { data } = await axios.post('/api/project/structure/findstructure', {
        project: id,
        value: eTarget,
      });

      if (data?.data?.length) {
        formik.setFieldValue('left', getData(data?.data, formik, onPostStructureWorkplace));
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  const onDeleteStructure = async (structure) => {
    try {
      const { data } = await axios.post('/api/project/structure/deletestructure', {
        project: id,
        structure: structure?.id,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        formik.setFieldValue('left', getData(data?.data, formik, onPostStructureWorkplace));
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostEditStructure = async (structure) => {
    try {
      const { data } = await axios.post('/api/project/structure/edit', {
        ...structure,
        project: id,
      });

      if (data.status === 'success') {
        toast['success'](data?.msg);
        onPostStructure();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    } finally {
      onShowTitleEdit.off();
    }
  };

  const onPostAddStructure = async (structure) => {
    try {
      const { data } = await axios.post('/api/project/structure/newstructure', {
        ...structure,
        project: id,
      });

      if (data.status === 'success') {
        toast['success']('Подразделение добавлено.');
        onPostStructure();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    } finally {
      formik.setFieldValue('division', {});
      onShowAddDivision.off();
    }
  };

  const onPostChangeStructure = async (changes) => {
    try {
      const { data } = await axios.post('/api/project/structure/changestructure', {
        ...changes,
        project: id,
      });

      if (data.status === 'success') {
        onPostStructure(id);
        //formik.setFieldValue('left', data?.data);
      }

      if (data.status === 'success' && data?.msg) {
        toast['success'](data?.msg);
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    } finally {
      onShowEditWorkplace.off();
    }
  };

  return {
    onPostStructureFind,
    onDeleteStructure,
    onPostStructure,
    onPostEditStructure,
    onDeleteStructureWorkplace,
    onPostStructureWorkplace,
    onPostEditworkplace,
    onPostAddStructure,
    onPostChangeStructure,
  };
}

import React from 'react';

import * as Styled from './styled';


export default function Container(props) {
  const {
    children,
  } = props;


  if (!children) {
    return null
  }

  return (
    <Styled.Container>
      {children}
    </Styled.Container>
  )
}
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Unauthorized from './Errors/Error401';
import Forbidden from './Errors/Error403';
import PageNotFound from './Errors/Error404';
import InternalServerError from './Errors/Error500';
import Login from './formik/forms/login';
import RecoverAccount from './formik/forms/recoverAccount';
import RecoverPassword from './formik/forms/recoveryPassword';
import Register from './formik/forms/registration';
import AuthLayout from './layouts/AuthLayout';
import ProtectedLayout from './layouts/ProtectedLayout';
import UnauthorizedLayout from './layouts/unauthorizedLayout';
import AnalyticsLayout from './Menu/Analitycs/AnalyticsLayout';
import AnalyticsDashboard from './Menu/Analitycs/dashboard';
import ManagementLayout from './Menu/Management';
import ManagementResults from './Menu/Management/results';
import AdministrationActions from './Menu/MenuAdministration/AdministrationActions';
import AdministrationCooperation from './Menu/MenuAdministration/AdministrationCooperation';
import AdministrationECP from './Menu/MenuAdministration/AdministrationECP';
import AdministrationRoles from './Menu/MenuAdministration/AdministrationRoles';
import AdministrationUserLogs from './Menu/MenuAdministration/AdministrationUserLogs';
import ContainerClassifiers from './Menu/MenuOPROganization/Classifiers/ClassifiersList/ContainerClassifiers';
import OrganizationClassifier from './Menu/MenuOPROganization/OrganizationClassifier';
import OrganizationEmployers from './Menu/MenuOPROganization/OrganizationEmployers';
import OrganizationMatrix from './Menu/MenuOPROganization/OrganizationMatrix';
import OrganizationOrganization from './Menu/MenuOPROganization/OrganizationOrganization';
import Classif from './pages/classif';
import ClassifEdit from './pages/classif/tableClassif';
//import Classifiers from './pages/classifiers';
//import DemoLayot from './pages/demolayot';
import Enterprises from './pages/enterprises';
import Grade from './pages/grade';
import InformationLayout from './pages/information';
import ManualPage from './pages/information/manual';
import RegulatoryPage from './pages/information/regulatory';
//import HazardMeasure from './pages/KnowledgeBase/hazardMeasure';
import DangerMeasure from './pages/KnowledgeBase/dangermeasure';
import EventSout from './pages/KnowledgeBase/eventsout'
import Hac from './pages/KnowledgeBase/hac';
import KnowledgeBaseLayout from './pages/KnowledgeBase/KnowledgeBaseLayout';
import SourceDanger from './pages/KnowledgeBase/sourcedanger'
import TypeSource from './pages/KnowledgeBase/typesource'
import TypiicalOk from './pages/KnowledgeBase/typicalok';
import Offer from './pages/offer';
import Requests from './pages/requests';
import UsersList from './pages/userlist';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/offer" element={<Offer />} />
        <Route element={<UnauthorizedLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recover-account" element={<RecoverAccount />} />
          <Route path="/reset-password" element={<RecoverPassword />} />

        </Route>



        <Route element={<AuthLayout />}>
        {/* <Route path="/demo" element={<DemoLayot />} /> */}
          {/* <Route path="/userslist"  element={<UsersList/>} /> */}
          <Route path="/401" element={<Unauthorized />} />
          <Route path="/403" element={<Forbidden />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/500" element={<InternalServerError />} />
          <Route element={<ProtectedLayout availableRoles={['admin-role', 'analytic-role']} />}>
            <Route path="/organizations" element={<OrganizationEmployers />} />
            <Route path="/organizations/employers" element={<OrganizationEmployers />} />
            <Route path="/organizations/organizations" element={<OrganizationOrganization />} />
            <Route path="/organizations/classifiers" element={<OrganizationClassifier />} />
            <Route path="/organizations/matrix" element={<OrganizationMatrix />} />
            <Route path="/organizations/classifiers/container" element={<ContainerClassifiers />} />
          </Route>

          <Route path="/information" element={<InformationLayout />}>
            <Route index element={<Navigate to="regulatory" />} />
            <Route path="regulatory" element={<RegulatoryPage />} />
            <Route path="manual" element={<ManualPage />} />
          </Route>




          <Route path="/administration" element={<ProtectedLayout availableRoles={['admin-role']} />}>
            <Route index element={<Navigate to="userslist" />} />
            <Route path="requests" element={<Requests />} />
            <Route path="userslist" element={<UsersList />} />
            <Route path="enterprises" element={<Enterprises />} />
            <Route path="userslogs" element={<AdministrationUserLogs />} />
            <Route path="roles" element={<AdministrationRoles />} />
            <Route path="cooperation" element={<AdministrationCooperation />} />
            <Route path="actions" element={<AdministrationActions />} />
            <Route path="ecp" element={<AdministrationECP />} />
          </Route>

          <Route path="/analitic" element={<ProtectedLayout availableRoles={['admin-role']} /> }>
            <Route path="classifiers" element={<Classif />}>
              <Route index element={<Navigate to="requisition" />} />
              <Route path="requisition" element={<ClassifEdit />} />
            </Route>
          </Route>

          <Route path="/knowledge-base" element={<ProtectedLayout availableRoles={['admin-role']} />}>
            <Route element={<KnowledgeBaseLayout />}>
              <Route index element={<Navigate to='hac' />} />
              <Route path="hac" element={<Hac />} />
              <Route path="sourcedanger" element={<SourceDanger />} />
              <Route path="typesource" element={<TypeSource />}  />
              <Route path="dangermeasure"  element={<DangerMeasure />} />
              <Route path="eventsout" element={<EventSout />} />
              <Route path="typicalok" element={<TypiicalOk />} />
            </Route>
          </Route>
          <Route path="/grade/projects" element={<Grade />} />
          {/*<Route path="/userslist"  element={<UsersList/>} />*/}

          <Route
            element={<ProtectedLayout availableRoles={['admin-role', 'pm-role', 'analytic-role', 'customer-role']} />}
          >
            <Route element={<ManagementLayout />}>
              <Route path="/management/results" element={<ManagementResults />} />
            </Route>

            <Route element={<AnalyticsLayout />}>
              <Route path="/analytics/dashboard" element={<AnalyticsDashboard />} />
            </Route>
          </Route>
{/*
          <Route element={<KnowledgeBaseLayout />}>
            <Route path="/knowledge-base/hazard-measure" element={<HazardMeasure />} />
          </Route>

          <Route element={<KnowledgeBaseLayout />}>
            <Route path="/knowledge-base/hac" element={<Hac />} />

          </Route>
 */}
          <Route
            path="*"
            element={
              <div style={{paddingTop: 30}}>
                <h1>Ошибка 404</h1>
                <p><b>Страница не найдена</b></p>
                <p>страница, на которкую вы пытаетесь попасть, не существует или была удалена</p>
                <p><a href="/">Перейти на главную страницу</a></p>
              </div>
            }
          />

          {/*<Route path="/analytics"  element={<Dashboards/>} />*/}
          {/*<Route path="/analytics/dashboards"  element={<Dashboards/>} />*/}
          {/*<Route path="/analytics/reports"  element={<Reports/>} />*/}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import React from "react";
import Form from 'react-bootstrap/Form';

import {cx} from "../../../utils";

import './styles.scss';

function CustomSelect({className, options, placeholder, ...props}) {

  const classNames = cx('select', className)

  return (
    <Form.Select className={classNames} {...props}>
      <option hidden value="">{placeholder}</option>
      {options?.map((el, index) => {
        return (
          <option key={index} value={el.value}>{el.label}</option>
        )
      })}
    </Form.Select>
  )
}

export default CustomSelect

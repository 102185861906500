import React from 'react'


function DateForm({date, className}) {


    return (
        <td className={className}>
            {date.split('/').join('.')}
        </td>
    );
}

export default DateForm;

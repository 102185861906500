import React from "react";

import HeaderAdmin from "../../blocks/headerAdmin";

import Roles from "./Roles/Roles";
import {navMap} from './navMap';

function AdministrationRoles() {
  return (
    <>
      <HeaderAdmin navMap={navMap} />
      <main>
        <Roles />
      </main>
    </>
  );
}

export default AdministrationRoles;

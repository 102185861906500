import styled, {css} from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction ?? 'row'};
  flex-wrap: ${props => props.wrap ?? 'wrap'};
  position: relative;

  ${props =>
    props.gap &&
      css`
        gap: ${props.gap};
      `
  }}

  ${props =>
    props.height &&
      css`
        height: ${props.height};
      `
  }}

  ${props =>
    props.jContent &&
      css`
        justify-content: ${props.jContent};
      `
  }}

  ${props =>
    props.aItems &&
      css`
        align-items: ${props.aItems};
      `
  }}

  ${props =>
    props.minWidth &&
      css`
        min-width: ${props.minWidth}px;
      `
  }}

`;

export const FlexItem = styled.div`
  width: auto;

  ${props =>
    props.grow &&
      css`
      flex-grow: ${props.grow};
      `
  }}

  ${props =>
    props.widthItem &&
      css`
        width: ${typeof props.widthItem === 'number' ? props.widthItem + 'px' : props.widthItem};
        flex-basis: ${typeof props.widthItem === 'number' ? props.widthItem + 'px' : props.widthItem};
      `
  }

  ${props =>
    props.heightItem &&
      css`
        height: ${props.heightItem };
      `
  }

  ${props =>
    props.paddingItem &&
      css`
        padding: ${props.paddingItem};
      `
  }}

`;

import * as actionTypes from '../actionTypes/actionTypes';
import {AUTH_LOGOUT} from "../actionTypes/loginTypes";

const initialState = { 
  LNAlist: [], 
  // LNAcard: { 
  //   projectId: '', 
  //   name: '', 
  //   number: '', 
  //   date: '', 
  //   paragraphs: [ 
  //     { 
  //       name: '', 
  //     } 
  //   ], 
  // } 
}

export function LNAReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LNA:
      return {...state, LNAlist: [...action.payload]}

      case actionTypes.POST_LNA:
        
        // const newLNA = { 
        //   projectId: action.payload.projectId, 
        //   name: action.payload.name, 
        //   number: action.payload.number, 
        //   date: action.payload.date, 
        //   paragraphs: [ 
        //     { 
        //       name: action.payload.paragraphsName, 
        //     } 
        //   ], 
        // } 
          return { 
            ...state, LNAlist: [...action.payload],  
          };

    case actionTypes.PUT_LNA:
    return {
      ...state, LNAlist: state.LNAlist.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            nameLNA: action.payload.nameLNA,
            numberLNA: action.payload.numberLNA,
            dateAcceptLNA: action.payload.dateAcceptLNA,
            pointsLNA: action.payload.pointsLNA,
            uploadFile: action.payload.uploadFile,
          };
        }
        return el;
      }),
    };
    case AUTH_LOGOUT:
      return initialState

    default:
      return state
  }
}

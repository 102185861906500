import React from "react";

import LabelWithBadge from "../labelWithBadge";

export const columnsOrganization3 = [
  {
    title: 'Общее кол-во РМ',
    column: 'workplace'
  },
  {
    title: 'РМ на которых прошла Оценка',
    column: 'workplaceApproved'
  },
  {
    title: <LabelWithBadge color="#E61E47"/>,
    column: 'risk_3'
  },
  {
    title: <LabelWithBadge color="#F4CD48"/>,
    column: 'risk_2'
  },
  {
    title: <LabelWithBadge color="#00A74D"/>,
    column: 'risk_1'
  }
]

export const columnsOrganization4 = [
  {
    title: 'Общее кол-во РМ',
    column: 'workplace'
  },
  {
    title: 'РМ на которых прошла Оценка',
    column: 'workplaceApproved'
  },
  {
    title: <LabelWithBadge color="#E61E47"/>,
    column: 'risk_4'
  },
  {
    title: <LabelWithBadge color="#FD967F"/>,
    column: 'risk_3'
  },
  {
    title: <LabelWithBadge color="#FCFF53"/>,
    column: 'risk_2'
  },
  {
    title: <LabelWithBadge color="#00A74D"/>,
    column: 'risk_1'
  },
]

export const columnsOrganization5 = [
  {
    title: 'Общее кол-во РМ',
    column: 'workplace'
  },
  {
    title: 'РМ на которых прошла Оценка',
    column: 'workplaceApproved'
  },
  {
    title: <LabelWithBadge color="#E61E47"/>,
    column: 'risk_5'
  },
  {
    title: <LabelWithBadge color="#FD967F"/>,
    column: 'risk_4'
  },
  {
    title: <LabelWithBadge color="#FEC250"/>,
    column: 'risk_3'
  },
  {
    title: <LabelWithBadge color="#FCFF53"/>,
    column: 'risk_2'
  },
  {
    title: <LabelWithBadge color="#00A74D"/>,
    column: 'risk_1'
  },
]

export const getOrgColumns = (matrix) => {
  // eslint-disable-next-line no-console
  console.log(matrix, 'matrix');
  switch (matrix) {
   case 3:
     return columnsOrganization3;
   case 4:
     return columnsOrganization4;
   case 5:
     return columnsOrganization5;
   default:
     return columnsOrganization5
 }
}

export const columnsHazard3 = [
  {
    title: 'Всего выявлено опасностей',
    column: 'hazardAll'
  },
  {
    title: <LabelWithBadge color="#E61E47"/>,
    column: 'hazard_3'
  },
  {
    title: <LabelWithBadge color="#F4CD48"/>,
    column: 'hazard_2'
  },
  {
    title: <LabelWithBadge color="#00A74D"/>,
    column: 'hazard_1'
  }
]

export const columnsHazard4 = [
  {
    title: 'Всего выявлено опасностей',
    column: 'hazardAll'
  },
  {
    title: <LabelWithBadge color="#E61E47"/>,
    column: 'hazard_4'
  },
  {
    title: <LabelWithBadge color="#FD967F"/>,
    column: 'risk_3'
  },
  {
    title: <LabelWithBadge color="#F4CD48"/>,
    column: 'hazard_2'
  },
  {
    title: <LabelWithBadge color="#00A74D"/>,
    column: 'hazard_1'
  }
]

export const columnsHazard5 = [
  {
    title: 'Всего выявлено опасностей',
    column: 'hazardAll'
  },
  {
    title: <LabelWithBadge color="#E61E47"/>,
    column: 'hazard_5'
  },
  {
    title: <LabelWithBadge color="#FD967F"/>,
    column: 'hazard_4'
  },
  {
    title: <LabelWithBadge color="#FEC250"/>,
    column: 'hazard_3'
  },
  {
    title: <LabelWithBadge color="#FCFF53"/>,
    column: 'hazard_2'
  },
  {
    title: <LabelWithBadge color="#00A74D"/>,
    column: 'hazard_1'
  },
]

export const getHazardColumns = (matrix) => {
  switch (matrix) {
    case 3:
      return columnsHazard3;
    case 4:
      return columnsHazard4;
    case 5:
      return columnsHazard5;
    default:
      return columnsHazard5
  }
}

export const columnsDangers = [
  {
    title: 'Наименование',
    column: 'a'
  },
  {
    title: 'Источник возникновения',
    column: 'b'
  },
  {
    title: 'Уровень риска (балл)',
    column: 'c'
  },
  {
    title: 'Уровень риска',
    column: 'd'
  },
  {
    title: 'Перечень РМ на которых идентифицировано',
    column: 'e'
  },
  {
    title: 'Структурное подразделение РМ',
    column: 'f'
  },
]

export const testData = [
  {
    total: '123',
    hasAssessment: '10',
    highRisk: '20%',
    countRMHighRisk: '5',
    countRMMidRisk: '3',
    countRMLowRisk: '9'
  },
]

export const testData2 = [
  {
    highRisk: '123',
    countRMHighRisk: '5',
    countRMMidRisk: '3',
    countRMLowRisk: '9'
  },
]

export const testData3 = [
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
  {
    a: 'Наименование',
    b: 'Работа на высоте',
    c: '10',
    d: 'Риск',
    e: 'открыть',
    f: 'Подразделение 1'
  },
]

import { Fragment } from 'react';
import _ from 'lodash';

export const HighlightedText = ({ text, highlight }) => {
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text?.split(regex);

  if (highlight?.length < 3) {
    return text;
  }

  return (
    <>
      {parts?.map((part, index) => (
        <Fragment key={index}>
          {part.toLowerCase() === highlight?.toLowerCase() ? (
            <mark style={{ padding: 0, background: 'transparent', color: '#1E85EB' }}>{part}</mark>
          ) : (
            part
          )}
        </Fragment>
      ))}
    </>
  );
};

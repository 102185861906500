import React from 'react';
import { Select } from 'antd';

import Dialog from '../../../dialog';
import Portal from '../../../portal';

import { usePostOk01694 } from './hooks';
import * as Styled from './styled';

export default function Ok01694Profession(props) {
  const { formik, show, onShow, workplace, onPostRiskassessmentOne } = props;

  const { loading, dataOK, onDataOK, onFindOK, findOK, onPostOk01694Save, onPostOk01694Find } = usePostOk01694({
    onShow,
    onPostRiskassessmentOne,
    formik,
    workplace,
  });

  const onClose = () => {
    onFindOK([]);
    //onDataOK([]);
    onShow.off();
  };

  const onClickOk01694Save = () => {
    onPostOk01694Save({
      ok01694_id: dataOK?.ok01694_id,
      workplace,
    });
  };

  const options = () => {
    if (findOK?.length) {
      return findOK.map((el) => ({ label: el.name, id: el.id, value: el.id }));
    }

    if (dataOK?.profession?.length) {
      return dataOK?.profession?.map((el) => ({ label: el.name, id: el.id, value: el.id }));
    }

    return [];
  };

  const onSelectProf = (_, option) => {
    onDataOK((prevState) => ({
      ...prevState,
      ok01694: option?.label ?? null,
      ok01694_id: option?.id ?? null,
    }));
  };

  const hasDefaultValue = !!options().find((el) => el.id === dataOK?.ok01694_id);

  return (
    <Portal id={'ok01694Profession'}>
      <Dialog
        isRender={show}
        isClose={true}
        centered={true}
        destroyOnClose={true}
        maskClosable={false}
        onClose={onClose}
        title={'ОК-016 94'}
        width={'auto'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 20px',
          },
          close: {
            height: 20,
            width: 20,
            right: '8px',
            top: '8px',
          },
        }}
      >
        <Styled.SFlex>
          <Styled.SFlexItem padding={'10px 10px 0 0'} grow={1}>
            <Styled.InputTextInput>{dataOK?.ok01694}</Styled.InputTextInput>
          </Styled.SFlexItem>
          <Styled.SFlexItem padding={'10px 0 0 10px'}>
            <Styled.ButtonBottom variant={'secondary'} onClick={onClickOk01694Save}>
              Сохранить
            </Styled.ButtonBottom>
          </Styled.SFlexItem>
        </Styled.SFlex>
        <Styled.PossibleTitle>Классификатор ОК-016 94</Styled.PossibleTitle>
        <Select
          loading={loading}
          style={{ width: 600 }}
          size="large"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
          options={options()}
          placeholder="Начните поиск по классификатору (минимум 3 символа)"
          defaultValue={hasDefaultValue ? dataOK?.ok01694_id : null}
          //value={!isEmpty(options()) ? dataOK?.ok01694_id : null}
          onSearch={onPostOk01694Find}
          onChange={onSelectProf}
        />
      </Dialog>
    </Portal>
  );
}

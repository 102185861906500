import React from 'react';

import * as hooks from '../../../../hooks';
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';
import Table from '../../../blocks/table';
import AddDanger from '../addDanger';
import AdditionalControls from '../additionalControls';

import { getTableRows, tableHead } from './helpers';
import { usePostProjectEvaluationcardHazard } from './hooks';
import * as Styled from './styled';

const { useBoolean } = hooks;

export function GeneralHazardsOrganization(props) {
  const { isRender, onClose, project, workplace, onRefetchTable } = props;
  const [rowId, setRowId] = React.useState(null);
  const [showAddDanger, onShowAddDanger] = useBoolean(false);
  const [showAdditionalControls, onShowAdditionalControls] = useBoolean(false);

  const {
    dataRows,
    onPostProjectEvaluationcardHazard,
    onPostEvaluationcardEdithazard,
    onPostEvaluationcardDelete,
    formikAddDanger,
    onPostEvaluationcardAdditionaledit,
    additionalControlsFormik,
  } = usePostProjectEvaluationcardHazard({
    isRender,
    project,
    workplace,
    onShowAddDanger,
  });

  const rows = getTableRows({
    data: dataRows?.data,
    onPostEvaluationcardEdithazard,
    onPostEvaluationcardDelete,
    onShowAdditionalControls,
    onPostEvaluationcardAdditionaledit,
    setRowId,
  });

  const handleAddDanger = () => {
    formikAddDanger.setStatus('addDanger');
    onShowAddDanger.on();
  };

  const onHandleClose = () => {
    onRefetchTable();
    onClose();
  };

  return (
    <React.Fragment>
      <Portal id={'generalHazardsOrganization'}>
        <Dialog
          isRender={isRender}
          isClose={true}
          title={'ПЕРЕЧЕНЬ ОБЩИХ ОПАСНОСТЕЙ В ОРГАНИЗАЦИИ'}
          onClose={onHandleClose}
          innerTop={'20px'}
          width={1400}
          settings={{
            title: {
              size: 14,
              fWeight: 600,
              lHeight: 17,
              align: 'left',
              margin: '20px -20px 17px',
            },
            close: {
              height: 20,
              width: 20,
              right: '8px',
              top: '8px',
            },
          }}
        >
          <Styled.ButtonSection variant={'primary'} onClick={handleAddDanger}>
            Добавить опасность
          </Styled.ButtonSection>
          <Table head={tableHead} data={rows} class={'table'} />
        </Dialog>
      </Portal>
      <AdditionalControls
        rowId={rowId}
        formik={additionalControlsFormik}
        commonHazards={true}
        showAdditionalControls={showAdditionalControls}
        onShowAdditionalControls={onShowAdditionalControls}
        onPostProjectHazard={onPostProjectEvaluationcardHazard}
        onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
      />
      <Portal id={'addDangerSecond'}>
        <AddDanger
          rowId={rowId}
          project={project}
          workplace={workplace}
          type={'generalHazardsOrganization'}
          formik={formikAddDanger}
          showAddDanger={showAddDanger}
          setRowId={setRowId}
          onShowAddDanger={onShowAddDanger}
          onPostProjectEvaluationcardHazard={onPostProjectEvaluationcardHazard}
          disabledWorkPlace={true}
          disabledWorkType={true}
        />
      </Portal>
    </React.Fragment>
  );
}

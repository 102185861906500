import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

import Button from '../../../atoms/nButton';
import FieldInput from '../../fields/input';

import * as Styled from './styled';

dayjs.extend(weekday);
dayjs.extend(localeData);

export default function OrganizationDataForm(props) {
  const { formik, onSubmitOrganizationData } = props;

  const onSubmit = () => onSubmitOrganizationData(formik.values);

  const onChangeDateStart = (date) => {
    formik.setFieldValue('start_date', date ? dayjs(date).toISOString() : date);
  };

  const onChangeDateEnd = (date) => {
    formik.setFieldValue('expiration_date', date ? dayjs(date).toISOString() : date);
  };

  const startDate = formik.values.start_date ? dayjs(formik.values.start_date) : null;
  const endDate = formik.values.expiration_date ? dayjs(formik.values.expiration_date) : null;

  return (
    <div>
      <Styled.Dates widthItem={[220, 220]}>
        <Styled.Field margin={'0 0 24px'}>
          <Styled.FieldLabel fSize={16} fWeight={500}>
            Срок действия с
          </Styled.FieldLabel>

          <DatePicker disabled size="large" value={startDate} onChange={onChangeDateStart} />
        </Styled.Field>

        <Styled.Field margin={'0 0 24px'}>
          <Styled.FieldLabel fSize={16} fWeight={500}>
            Срок действия по
          </Styled.FieldLabel>

          <DatePicker size="large" value={endDate} onChange={onChangeDateEnd} />
        </Styled.Field>

        {/*<FieldInput*/}
        {/*  margin={'0 0 24px'}*/}
        {/*  formik={formik}*/}
        {/*  label={'Срок действия с'}*/}
        {/*  name={'start_date'}*/}
        {/*/>*/}
        {/*<FieldInput*/}
        {/*  margin={'0 0 24px'}*/}
        {/*  formik={formik}*/}
        {/*  label={'Срок действия по'}*/}
        {/*  name={'expiration_date'}*/}
        {/*/>*/}
      </Styled.Dates>
      <FieldInput margin={'0 0 24px'} formik={formik} label={'Организация'} name={'enterprise'} />
      <FieldInput margin={'0 0 24px'} formik={formik} label={'ИНН'} name={'inn'} />
      <FieldInput margin={'0 0 24px'} formik={formik} label={'КПП'} name={'kpp'} />
      <FieldInput margin={'0 0 24px'} formik={formik} label={'Номер договора'} name={'contract_number'} />
      <Styled.Buttons>
        <Button type={'submit'} variant={'secondary'} disabled={!formik.isValid} onClick={onSubmit}>
          Сохранить
        </Button>
      </Styled.Buttons>
    </div>
  );
}

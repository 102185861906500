const color = {
  1: 'rgba(52, 201, 36, 0.75)',
  2: 'rgba(234, 200, 10, 0.75)',
  3: '#FFB02E',
  4: 'rgba(246, 74, 70, 0.75)',
  5: 'rgba(176, 0, 0, 0.75)'
}

const name = {
  1: 'Низкий',
  2: 'Умеренный',
  3: 'Средний',
  4: 'Значительный',
  5: 'Высокий'
}

export {
  color,
  name
}

// 1,risk,Низкий (Приемлемый)
// 3,risk,Значительный (Ограниченно допустимый)
// 2,risk,Средний (Допустимый)
// 4,risk,Умеренный (Практически приемлемый)
// 5,risk,Высокий (Неприемлемый)
// 6,risk,Низкий (Практически приемлемый)

// 1 Низкий
// 2 Умеренный
// 3 Средний
// 4 Значительный
// 5 Высокий

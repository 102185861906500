const colors = {
  primary: '#18171C',
  primaryHover: '#3B3B3B',

  activeBg: '#EFF2F9',
};

export const theme = {
  token: {
    colorPrimary: colors.primary,
    colorPrimaryHover: colors.primaryHover,
    colorPrimaryActive: colors.primaryHover,

    controlItemBgActive: colors.activeBg,

    boxShadow: 'none',
    controlOutline: 'none',
  },

  // components: {
  //   Pagination: {
  //     itemActiveBg: colors.activeBg
  //   }
  // }
};

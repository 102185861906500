import React from 'react';
import { toast } from "react-toastify";

import axios from "../../../../../../axios/axios";
import { logWarn } from "../../../../../../utils/log";

export function usePostReferenceWorkplace() {

  const [referenceWorkPlace, setReferenceWorkPlace] = React.useState([]);

  React.useEffect(() => {
    onPostProjectWorkplace();
  }, [])

  const onPostProjectWorkplace = async () => {

    try {
      const { data } = await axios.post(`/api/reference/work_type`);

      if (data?.data) {
        setReferenceWorkPlace(data?.data);
      }

    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: "1685084102343",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    referenceWorkPlace,
  }
}

import React from 'react';

import Button from '../../../../atoms/nButton';
import Dialog from '../../../../blocks/dialog';
import Portal from '../../../../blocks/portal';
import FieldInput from '../../../fields/input';

import * as Styled from './styled';

export default function EditStructure(props) {
  const {
    isRender,
    formik,
    name,
    onShowTitleEdit,
    onPostTitleEdit
  } = props;

  const {touched} = formik.getFieldMeta(name) ?? {};
  const {value} = formik.getFieldProps(name) ?? {};
  const isDisabled = touched && value?.name;

  const closeShowEditStructure = () => {
    formik.setFieldValue('title', formik.values.prevTitle);
    onShowTitleEdit.off();
  }

  const onEditStructure = () => {
    const {getFieldProps} = formik;
    const {value} = getFieldProps(name) ?? {};

    onPostTitleEdit(value);
  }

  return (
    <Portal id={'editWorkplace'}>
      <Dialog
        isRender={isRender}
        isClose={true}
        centered={true}
        onClose={closeShowEditStructure}
        title={'ИЗМЕНИТЬ ПОДРАЗДЕЛЕНИЕ'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
        <form>
          <FieldInput
            margin={'0 0 30px'}
            placeholder={'Наименование подразделения'}
            formik={formik}
            name={`${name}.name`}
          />
          <Styled.Buttons jContent={'center'}>
            <Button
              variant={'secondary'}
              onClick={onEditStructure}
              disabled={!isDisabled}
            >
              Изменить
            </Button>
          </Styled.Buttons>
      </form>
    </Dialog>
  </Portal>
)}


import styled from 'styled-components';

import Flex from '../../../../../atoms/flex';
import Button from '../../../../../atoms/nButton';

export const Buttons = styled(Flex)`
  justify-content: flex-end;
`;

export const ParentArrow = styled.div`
  transform: rotate(${props => props.rotate}deg);
  height: 24px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Parent = styled.div`
  padding: 0;
  font-size: 12px;
  font-family: GolosTextWebVF, serif;
  color: ${props => props?.active ? 'rgb(1, 72, 128)' : '#000'};
  display: flex;
  align-items: center;
`;

export const WorkplacesLi = styled.li`
  padding-bottom: 10px;
`

export const WorkplacesUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    margin-left: 24px;
  }
`

export const Form = styled.form`
  height: calc(100vh - 187px);
  overflow-y: auto;
  margin-bottom: 30px;
`

export const ButtonBottom = styled(Button)`
  font-size: 17px;
`
import React, { useCallback, useState } from 'react';
import Chart from 'react-apexcharts';
import { DotChartOutlined } from '@ant-design/icons';
import { Empty, Skeleton } from 'antd';
import { isEmpty } from 'lodash';

import { useChartHighestLevelRisk } from '../../../../../graphql/queries/useChartHighestLevelRisk';
import TypeButton from '../typeButton';

import './styles.scss';

const convertNameToRisk = (val) => {
  switch (val) {
    case 'Низкий':
      return 1;
    case 'Умеренный':
      return 2;
    case 'Средний':
      return 3;
    case 'Значительный':
      return 4;
    case 'Высокий':
      return 5;
    default:
      return 1;
  }
};

const getLabelColor = (val) => {
  switch (val) {
    case 5:
      return '#BE3A3A';
    case 4:
      return '#F1716E';
    case 3:
      return '#F8BE5C';
    case 2:
      return '#E8D041';
    case 1:
      return '#61D155';
    default:
      return '#aaaaaa';
  }
};

const buttons = [
  {
    label: 'Профессии',
    type: 'prof',
  },
  {
    label: 'Виды работ',
    type: 'works',
  },
];

const ChartHighestRiskLevel = ({ projectId }) => {
  const [type, setType] = useState('prof');

  const { data, loading } = useChartHighestLevelRisk({ id: projectId, type: type === 'prof' });

  const onSelectType = useCallback((val) => setType(val), []);

  const series = [
    {
      name: 'hazard',
      data: data?.map((el) => convertNameToRisk(el.name)),
    },
  ];

  const options = {
    chart: {
      width: 380,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 1,
        dataLabels: {
          position: 'bottom', // top, center, bottom
        },
      },
    },
    colors: [(opts) => getLabelColor(opts.value)],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data?.map((el) => el.val),
      minHeight: undefined,
      maxHeight: 80,
      labels: {
        trim: true,
        style: {
          cssClass: 'high-level-risk__label',
        },
      },
    },
  };

  return (
    <div className="chart-block high-level-risk">
      <h4 className="chart-block__title">Профессии/виды работ, характеризующиеся наиболее высокими уровнями риска</h4>
      <div className="indent-hazard__wrap">
        <div className="chart-block__chart">
          {loading ? (
            <Skeleton.Node active={true}>
              <DotChartOutlined style={{ fontSize: 150, color: '#bfbfbf' }} />
            </Skeleton.Node>
          ) : isEmpty(data) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Chart options={options} width={390} height={230} series={series} type="bar" />
          )}
        </div>
        <div className="chart-block__actions-col">
          {buttons.map((el) => (
            <TypeButton key={el.type} type={el.type} label={el.label} currentType={type} callback={onSelectType} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChartHighestRiskLevel;

import React from 'react';
import { Outlet } from 'react-router-dom';

import HeaderAdmin from '../../blocks/headerAdmin';

import { navMap } from './navMap';

import './styles.scss';

function AnalyticsLayout() {
  return (
    <>
      <HeaderAdmin navMap={navMap} />
      <main className="analytics-main">
        <Outlet />
      </main>
    </>
  );
}

export default AnalyticsLayout;

import React from 'react';

import Tabs from '../tabs';

import { getTabs } from './helpers';
import * as Styled from './styled';

export default function RiskAssessmentCard(props) {
  const { isRender, onIsRenderCard, onPostProjectRiskassessment, project, rowId } = props;

  const tabs = getTabs({
    project,
    workplace: rowId,
  });

  const onClose = () => {
    onPostProjectRiskassessment();
    onIsRenderCard.off();
  };

  return (
    <form>
      <Styled.EditDialog
        isRender={isRender}
        isClose={true}
        title={'Карта оценки профессионального риска'}
        onClose={onClose}
        innerTop={'20px'}
        width={1600}
        settings={{
          title: {
            size: 20,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '20px -20px 17px',
          },
          close: {
            height: 20,
            width: 20,
            right: '8px',
            top: '8px',
          },
        }}
      >
        <Tabs tabs={tabs} />
      </Styled.EditDialog>
    </form>
  );
}

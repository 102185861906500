import React from 'react';
import { Table } from 'antd';

import * as Fields from '../fields';

const UsersTable = ({ loading, data, onChangeTable, onPostUserView, onPostUser }) => {
  const columns = [
    {
      key: 'userName',
      title: 'Логин',
      dataIndex: 'userName',
      align: 'center',
      sorter: true,
      render: (userName) => userName,
    },
    {
      key: 'email',
      title: 'E-mail',
      dataIndex: 'email',
      align: 'center',
      sorter: true,
      render: (email) => email,
    },
    {
      key: 'inn',
      title: 'ИНН',
      dataIndex: 'inn',
      align: 'center',
      sorter: true,
      render: (inn) => inn,
    },
    {
      key: 'kpp',
      title: 'КПП',
      dataIndex: 'kpp',
      align: 'center',
      sorter: true,
      render: (kpp) => kpp,
    },
    {
      key: 'displayName',
      title: 'Роль',
      dataIndex: 'displayName',
      align: 'center',
      sorter: true,
      render: (displayName) => displayName,
    },
    {
      key: 'created_at',
      title: 'Дата регистрации',
      dataIndex: 'created_at',
      align: 'center',
      sorter: true,
      render: (created_at) => created_at,
    },
    {
      key: 'isLockedOut',
      title: 'Статус',
      align: 'center',
      sorter: true,
      render: (record) => (record?.isLockedOut ? 'Заблокирован' : 'Действующий'),
    },
    {
      key: 'edit',
      title: '',
      align: 'center',
      render: (record) => <Fields.EditUser id={record?.id} onPostUserWiew={onPostUserView} />,
    },
    {
      key: 'actions',
      title: '',
      width: 100,
      align: 'center',
      render: (record) => (
        <Fields.ActionsRow id={record?.id} isLockedOut={record?.isLockedOut} onPostUser={onPostUser} />
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading}
      columns={columns}
      dataSource={data?.data}
      onChange={onChangeTable}
      pagination={{
        defaultPageSize: 50,
        current: data?.current_page,
        total: data?.total,
      }}
    />
  );
};

export default UsersTable;

import React from "react";

import Button from "../../../atoms/nButton";
import Dialog from "../../../blocks/dialog";

import * as Styled from "./styled";

export default function ForgotLogin({ formik, isRender, onCloseDialog }) {

  const dangerouslyHTML = `
    Проверьте почту ${formik?.values.email ?? ''},<br />
    чтобы восстановить свою учетную запись.`

  return (
    <Dialog
      isRender={isRender}
      isClose={false}
      title={"Проверьте свою почту"}
    >
      <Styled.P
        tagName={"p"}
        fSize={16}
        align={"center"}
        fWeight={400}
        lHeight={22}
        dangerouslyHTML={dangerouslyHTML}
      />
      <Styled.Buttons direction={"row"} jContent={"center"}>
        <Button type={"submit"} variant={"secondary"} onClick={onCloseDialog}>
          Готово
        </Button>
      </Styled.Buttons>
    </Dialog>
  );
}
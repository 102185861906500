import React from 'react'

import Link from '../../../../../atoms/link';

export default function AdditionalControls(props) {
  const {
    id,
    reliability_probability,
  } = props;

  return (
    <Link onLink={() => {}}>
      {reliability_probability}
    </Link>
  )
};
import React from "react";

import './styles.scss';

function LabelWithBadge({text, color}) {
    const styles = {
        backgroundColor: color
    }
    return (
        <div className="label-badge" >
            <div style={styles} className="label-badge__box" />
            <span>{text}</span>
        </div>
    )
}

export default LabelWithBadge

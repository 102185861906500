import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { DotChartOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton } from 'antd';
import { isEmpty } from 'lodash';

import { useChartAdditionalMeasures } from '../../../../../graphql/queries/useChartAdditionalMeasures';
import { cx } from '../../../../../utils';

import '../charts.scss';
import './styles.scss';

const options = {
  chart: {
    width: 400,
    type: 'radar',
    dropShadow: {
      enabled: true,
      blur: 1,
      left: 1,
      top: 1,
    },
  },
  stroke: {
    width: 2,
  },
  fill: {
    opacity: 0.9,
  },
  markers: {
    size: 0,
  },
  xaxis: {
    categories: ['Низкий','Умеренный','Средний','Значительный','Высокий'],
  },

  colors: ['#93CDDD', '#32AB9C'],
};

const ChartAdditionalMeasures = ({ projectId }) => {
  const [type, setType] = useState('hazard');

  const { data, loading } = useChartAdditionalMeasures({ id: projectId, type: type === 'hazard' });

  const after = data?.filter((el) => el.type === 'after');
  const before = data?.filter((el) => el.type === 'befor');

  const series = [
    {
      name: 'ДО реализации мер',
      data: after?.map((el) => el.cnt),
    },
    {
      name: 'ПОСЛЕ реализации мер',
      data: before?.map((el) => el.cnt),
    },
  ];

  const onSelectType = (val) => setType(val);

  return (
    <div className="chart-block add-measures">
      <h4 className="chart-block__title">Распределение по уровням риска</h4>
      <div className="chart-block__actions">
        <Button
          size="small"
          type="text"
          className={cx({ 'active-type': type === 'hazard' })}
          onClick={() => onSelectType('hazard')}
        >
          опасностей
        </Button>
        <Button
          size="small"
          type="text"
          className={cx({ 'active-type': type === 'work' })}
          onClick={() => onSelectType('work')}
        >
          рабочих мест
        </Button>
      </div>
      <div className="chart-block__chart">
        {loading ? (
          <Skeleton.Node active={true}>
            <DotChartOutlined style={{ fontSize: 150, color: '#bfbfbf' }} />
          </Skeleton.Node>
        ) : isEmpty(data) && !loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Chart options={options} width={400} height={400} series={series} type="radar" />
        )}
      </div>
    </div>
  );
};

export default ChartAdditionalMeasures;

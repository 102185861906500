import axios from "../../axios/axios";
import nsiAxios from "../../axios/nsiAxios";
import NsiAxios from "../../axios/nsiAxios";
import { logWarn } from "../../utils/log";
import * as actionTypes from "../actionTypes/employerTypes";

export const fetchEmployers = () => {
  let url = "/api/employers/screen/list";
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(getDataSuccess(response.data));
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665742907683",
          errorMessage: `${error}`,
        })
      );
  };
};

export const fetchEmployer = (id) => {
  let url = `/api/employers/${id}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(getEmployerSuccess(response.data));
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665742922427",
          errorMessage: `${error}`,
        })
      );
  };
};

export const updateEmployer = (organization, structureFile, jobTotleFile) => {
  let url = `/api/employers/${organization.id ?? ""}`;
  return (dispatch) => {
    axios
      .put(url, organization)
      .then(() => {
        dispatch(fetchEmployers());
        dispatch({ type: actionTypes.UPDATE_EMPLOYER_SUCCESS });
        if (organization.id && structureFile) {
          dispatch(saveStructureUploadFile(organization.id, structureFile));
        }

        if (organization.id && jobTotleFile) {
          dispatch(saveJobTitleUploadFile(organization.id, jobTotleFile));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665742936996",
          errorMessage: `${error}`,
        })
      );
  };
};

export const createEmployer = (organization) => {
  let url = `/api/employers/`;

  return (dispatch) => {
    axios
      .post(url, organization)
      .then((response) => {
        if (response) {
          dispatch(fetchEmployers());
          dispatch({ type: actionTypes.UPDATE_EMPLOYER_SUCCESS });
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665742988875",
          errorMessage: `${error}`,
        })
      );
  };
};

export const deleteEmployer = (organizationId) => {
  let url = `/api/employers/${organizationId}`;

  return (dispatch) => {
    axios
      .delete(url)
      .then((response) => {
        if (response) {
          dispatch(fetchEmployers());
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743018364",
          errorMessage: `${error}`,
        })
      );
  };
};

const getEmployerSuccess = (data) => {
  return {
    type: actionTypes.EMPLOYER_DATA_SUCCESS,
    payload: data.data,
  };
};

const getDataSuccess = (data) => {
  return {
    type: actionTypes.EMPLOYERS_DATA_SUCCESS,
    payload: data.data,
  };
};

export const saveStructureUploadFile = (organizationId, file) => {
  let url = `/structure/upload/`;

  var formData = new FormData();

  formData.append("id", organizationId);
  formData.append("file", file);

  return () => {
    nsiAxios
      .post(url, formData, {
        headers: {},
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743050162",
          errorMessage: `${error}`,
        })
      );
  };
};

export const saveJobTitleUploadFile = (organizationId, file) => {
  let url = `post/upload/`;

  var formData = new FormData();

  formData.append("id", organizationId);
  formData.append("file", file);

  return () => {
    nsiAxios
      .post(url, formData, {
        headers: {},
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743064028",
          errorMessage: `${error}`,
        })
      );
  };
};

export const saveJobTitleUploadFileNew = (organizationId, structure, file) => {
  let url = `post/upload/`;

  var formData = new FormData();

  formData.append("id", organizationId);
  formData.append("structure", structure);
  formData.append("file", file);

  return (dispatch) => {
    nsiAxios
      .post(url, formData)
      .then((response) => {
        if (response) {
          alert("Должности успешно загружены");
          dispatch(employerStructure(organizationId));
        }
      })
      .catch((error) => {
        alert(
          "Ошибка, возможно должности уже были загружены или неверный формат загружаемого файла"
        );
        logWarn({
          errorNumber: "1665743085764",
          errorMessage: `${error}`,
        });
      });
  };
};

export const employerStructure = (employerId) => {
  let url = `/structure/list/?id=${employerId}`;

  return (dispatch) => {
    NsiAxios.get(url)
      .then((response) => {
        dispatch({
          type: actionTypes.EMPLOYER_STRUCTURE_LOAD,
          payload: response.data,
        });
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743116352",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerStructureNew = (employerId) => {
  let url = `/structure/list/?id=${employerId}`;

  return (dispatch) => {
    NsiAxios.get(url)
      .then((res) =>
        dispatch({
          type: actionTypes.EMPLOYER_STRUCTURE_NEW,
          payload: res.data,
        })
      )
      .catch((error) =>
        logWarn({
          errorNumber: "1665743136659",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerSetSelectedStructure = (payload) => ({
  type: actionTypes.EMPLOYER_SELECTED_STRUCTURE_SET,
  payload,
});

export const employerAddOpenedStructures = (payload) => ({
  type: actionTypes.EMPLOYER_OPENED_STRUCTURES_ADD,
  payload,
});

export const employerDeleteOpenedStructures = (payload) => ({
  type: actionTypes.EMPLOYER_OPENED_STRUCTURES_DELETE,
  payload,
});

export const employerClearSelectedStructure = () => ({
  type: actionTypes.EMPLOYER_SELECTED_STRUCTURE_CLEAR,
});

export const employerAddPosition = (data) => {
  let url = "/post/save/";

  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (res) {
          dispatch(employerStructureNew(data.employer));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743173443",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerUpdatePosition = (data) => {
  let url = "/post/save/";

  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (res) {
          dispatch(employerStructureNew(data.employer));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743187785",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerDeletePosition = (positionId, employerId) => {
  let url = `/post/save/?employer=${employerId}&rowId=${positionId}`;

  return (dispatch) => {
    axios
      .delete(url)
      .then((res) => {
        if (res) {
          dispatch(employerStructureNew(employerId));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743244026",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerAddStructure = (data) => {
  let url = "/structure/save/";

  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (res) {
          dispatch(employerStructureNew(data.employer));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743259703",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerUpdateStructure = (data) => {
  let url = "/structure/save/";

  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (res) {
          dispatch(employerStructureNew(data.employer));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743294676",
          errorMessage: `${error}`,
        })
      );
  };
};

export const employerDeleteStructure = (structureId, employerId) => {
  let url = `/structure/save/?employer=${employerId}&rowId=${structureId}`;

  return (dispatch) => {
    axios
      .delete(url)
      .then((res) => {
        if (res) {
          dispatch(employerStructureNew(employerId));
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665743325672",
          errorMessage: `${error}`,
        })
      );
  };
};

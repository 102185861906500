import React from 'react'

import {usePostUserEdit} from './hooks';
import * as Styled from './styled';

export default function EditUserLink(props) {
    const {id, onPostUser} = props;
    const {onPostUserEdit} = usePostUserEdit({id, onPostUser})

    const handleEdit = () => {
      onPostUserEdit();
    }

    return (
      <Styled.EditUserLink
        // onClick={handleEdit}
      >
        <a className="editUser">Открыть</a>

      </Styled.EditUserLink>
    )
  };

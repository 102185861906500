import styled from 'styled-components';

export const Date = styled.span`
  font-family: GolosTextWebVF, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #52575c;
  padding: 8px;
`;

import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Link from '../../../atoms/link';
import List from '../../../atoms/list';
import Button from '../../../atoms/nButton';
import InputSearch from '../../../atoms/search';
import Text from '../../../atoms/text';

export const Wrapper = styled.div`
  min-width: 1060px;
  width: 100%;
`;

export const TopContent = styled(Flex)`
  margin: 0;
`;

export const MiddleContent = styled(Flex)`
  margin: 48px 0 17px;
  text-align: center;
`;

export const AddLine = styled(Flex)`
  margin-bottom: 22px;
`;

export const AddLineInputSearch = styled(InputSearch)`
  margin-right: 35px;
`

export const DownloadFormRM = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

export const ImportRM = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 40px;
  white-space: nowrap;
`;

export const Add = styled(Button)`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  padding: 15px 10px;
  white-space: nowrap;
`;


export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-align: center;
  display: block;
  margin: 48px 0 17px;
`

export const AddFieldsButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  border: 0;
  background: transparent;
  color: #000;
  padding: 0;
  margin-bottom: 10px;
`;

export const IconCircle = styled.div`
  height: 25px;
  width: 24px;
  border-radius: 36px;
  background: #000;
  margin-left: 10px;
  line-height: 21px;
`;

export const DeleteFieldsButton = styled(Button)`
  border: 0;
  background: transparent;
  margin: 0 0 27px;
  padding: 0;
  width: 24px;
  height: 51px;
`;

export const WorkplaceList = styled(List)`
  align-items: flex-start;
  flex-direction: column;

  li {
    min-width: 550px;
    width: 100%
  }
`

export const WorkplaceListText = styled(Text)`
  margin-left: 35px;
  outline: none;
`

export const FlexHeader = styled(Flex)`
  height: 158px;
  justify-content: flex-end;
`

import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Link from '../../../atoms/link';
import Button from '../../../atoms/nButton';
import Text from '../../../atoms/text';

export const Buttons = styled(Flex)`
  margin: 33px 0 0;
  justify-content: flex-end;
`;

export const LegalAddressLabel = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-bottom: 17px;
`

export const LegalAddressLink = styled(Link)`
  margin-bottom: 55px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

export const FillButton = styled(Button)`
  margin: 31px 0 27px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  padding: 16px 10px;
`;

export const AddFieldsButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  border: 0;
  background: transparent;
  color: #000;
  padding: 0;
  margin-bottom: 10px;
`;

export const IconCircle = styled.div`
  height: 25px;
  width: 24px;
  border-radius: 36px;
  background: #000;
  margin-left: 10px;
  line-height: 21px;
`;

export const DeleteFieldsButton = styled(Button)`
  border: 0;
  background: transparent;
  margin: 0 0 27px;
  padding: 0;
  width: 24px;
  height: 51px;
`;

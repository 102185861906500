import styled, { keyframes } from 'styled-components';

const loading = keyframes`
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
`;

const StyledSpinner = styled.div`
  ${({ type }) =>
    type === 'fullScreen' &&
    `
        position: fixed;
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 998;
    `}

  .spinner {
    color: #00a79d;
    font-size: ${({ type }) => (type === 'fullScreen' ? '10px' : '7px')};
    margin: ${({ type }) =>
      type === 'fullScreen' ? '-25px 0 0 -25px' : '-14px 0 0 34px'};
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    position: ${({ type }) => (type === 'fullScreen' ? 'fixed' : 'relative')};
    left: ${({ type }) => (type === 'fullScreen' ? '50%' : '0%')};
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  .spinner,
  .spinner::before,
  .spinner:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: ${loading} 1.8s infinite ease-in-out;
    animation: ${loading} 1.8s infinite ease-in-out;
  }

  .spinner::before,
  .spinner::after {
    content: '';
    position: absolute;
    top: 0;
  }

  .spinner::before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner::after {
    left: 3.5em;
  }
`;

export default StyledSpinner;

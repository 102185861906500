import React from 'react';

import * as risk from './helpers';
import * as Styled from './styled';

function getRiskName(name) {
  return (type) => {
    return name[type] || '';
  }
}

const typeName = getRiskName(risk.name);

export default function RiskLevel(props) {
  const {
    type,
    name
  } = props;

  const nameRisk = typeName(type);

  return (
    <Styled.RiskLevel type={type}>
      {name ?? nameRisk}
    </Styled.RiskLevel>
  )
};

import React from "react";

import icon from '../../image/search.svg';
import {cx} from "../../utils";

import './styles.scss';

function Input(
    {
        className,
        classNameInput,
        id,
        name,
        label,
        error,
        hasSearch,
        size,
        ...props
    }) {

    const classNames = cx('base-input', className, {
        [`base-input--${name}`]: name,
        [`base-input--search`]: hasSearch,
    })

    const classNamesInput = cx('form-control rounded-0', classNameInput, {
        'active': error,
        'input-search': hasSearch,
        [`form-control-${size}`]: size
    })

    return (
        <div className={classNames}>
            {label ? <label className="col-form-label grey-text-modal-user" htmlFor={id}>{label}</label> : null}
            <input name={name} className={classNamesInput} id={id} {...props} />
            {hasSearch ? <div className="input-icon"><img src={icon} alt="icon"/></div> : null}
            {error ? <span className="error">{error}</span> : null}
        </div>
    )
}

export default Input;

import React from 'react'

/*import {
 CheckOutlined,
  UsbOutlined
} from '@ant-design/icons';*/
import {usePostSendEmail, usePostUserEdit} from './hooks';
import * as Styled from './styled';

export default function ActionsRow(props) {
  const {id, isLockedOut, onPostUser} = props;
  const {onPostUserSendEmail} = usePostSendEmail({id, onPostUser})
  const {onPostUserDelete} = usePostUserEdit({id, onPostUser})

  const handleDelete = () => {
    onPostUserDelete();
  }

  const handleSendEmail = () => {
    onPostUserSendEmail();
  }

  return (
    <Styled.IconButton

    >
      <button
        type="button"
        className="btn btn-primary send-notice"
        onClick={handleSendEmail}
      >
        <i className="bi bi-envelope" />
      </button>
      <button
        type="button"
        className="btn btn-default stop_akk"
        onClick={handleDelete}
      >
        <i className= {isLockedOut ? `bi bi-check-circle`: `bi bi-x-octagon` } />
        {/*{
          isLockedOut ? <CheckOutlined /> : <UsbOutlined />
        }*/}
      </button>
    </Styled.IconButton>
  )
};

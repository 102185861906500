import React from 'react';

import Button from '../../../../atoms/nButton';
import Dialog from '../../../../blocks/dialog';
import Portal from '../../../../blocks/portal';
import FieldInput from '../../../fields/input';

import * as Styled from './styled';

export default function EditWorkplace(props) {
  const {
    isRender,
    formik,
    name,
    onShowEditWorkplace,
    onPostEditworkplace,
    onPostStructureWorkplace,
    structureEl
  } = props;

  const {touched} = formik.getFieldMeta(name) ?? {};
  const {value} = formik.getFieldProps(name) ?? {};
  const isDisabled = touched && value?.name;
  //const num = value.num;
  //console.log(value.num)
  const closeShowAddWorkplace = () => {
    if (isDisabled && structureEl?.id) {
      onPostStructureWorkplace(structureEl)
    }

    onShowEditWorkplace.off();
  }

  const onAddWorkplace = () => {
    const {value: parrent } = formik.getFieldProps('title') ?? {};

    onPostEditworkplace({
      ...value,
      structure: parrent.id,
    });
  }
//console.log(num)
  return (
    <Portal id={'editWorkplace'}>
      <Dialog
        isRender={isRender}
        isClose={true}
        centered={true}
        onClose={closeShowAddWorkplace}
        title={'ИЗМЕНИТЬ РАБОЧЕЕ МЕСТО'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
        <form>
        <table>
          <tr>
            <td width={'20%'}>
              <FieldInput
                  margin={'0 0 30px'}
                  placeholder={'Наименование рабочего места'}
                  formik={formik}
                  name= {`${name}.num`}

                />
            </td>
            <td width={'80%'}>
                <FieldInput
                  margin={'0 0 30px'}
                  placeholder={'Наименование рабочего места'}
                  formik={formik}
                  name={`${name}.name`}
                />
            </td>
          </tr>
        </table>
          <Styled.Buttons jContent={'center'}>
            <Button
              variant={'secondary'}
              onClick={onAddWorkplace}
              disabled={!isDisabled}
            >
              Изменить
            </Button>
          </Styled.Buttons>
      </form>
    </Dialog>
  </Portal>
)}


import React from 'react';
import { toast } from 'react-toastify';

import axios from '../../../../../axios/axios';
import { useBoolean } from '../../../../../hooks';
import { logWarn } from '../../../../../utils/log';

export function usePostOk01694({ onShow, onPostRiskassessmentOne, formik, workplace }) {
  const [loading, onLoading] = useBoolean(false);
  const [dataOK, setDataOK] = React.useState([]);
  const [findOK, setFindOK] = React.useState([]);
  const ok01694Id = formik.values?.data?.ok01694_id;
  const ok01694 = formik.values?.data?.ok01694;

  React.useEffect(() => {
    if (ok01694 && ok01694Id) {
      onPostOk01694();
    }
  }, [ok01694, ok01694Id]);

  const onPostOk01694 = async () => {
    try {
      const { data } = await axios.post(`/api/reference/profession_ok`, {
        workplace_id: workplace,
        ok01694: ok01694 ?? null,
        ok01694_id: ok01694Id ?? null,
      });

      if (data?.data) {
        setDataOK(data?.data);
      }
    } catch (e) {
      console.log(e);
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostOk01694Save = async ({ workplace, ok01694_id }) => {
    try {
      const { data } = await axios.post(`/api/reference/saveprofession_ok`, {
        workplace_id: workplace,
        ok01694_id,
      });

      if (data?.status === 'success') {
        toast['success'](data?.msg);
        onPostRiskassessmentOne();
        setFindOK([]);
        onShow.off();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostOk01694Find = async (text) => {
    if (text?.length < 3) {
      return null;
    }

    try {
      onLoading.on();
      const { data } = await axios.post('/api/reference/ok01694', {
        value: text,
      });

      if (data?.data?.length) {
        setFindOK(
          data?.data?.map((el) => ({
            id: el?.id,
            name: el?.profession_name,
          }))
        );
        onLoading.off();
      }
    } catch (e) {
      onLoading.off();
      logWarn({
        errorNumber: '1685587487927',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    loading,
    dataOK,
    onDataOK: setDataOK,
    findOK,
    onFindOK: setFindOK,
    onPostOk01694Save,
    onPostOk01694Find,
  };
}

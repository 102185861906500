import React from 'react';
import {WarningOutlined} from '@ant-design/icons';
import {Modal} from 'antd';

import Delete from '../../../../../../assets/svg/delete';
import Edit from '../../../../../../assets/svg/edit';
import Flex from '../../../../../atoms/flex';

import * as Styled from './styled';

export default function WorkplaceInput(props) {
  const {
    name,
    formik,
    onRightName,
    onDeleteStructureWorkplace,
    onShowEditWorkplace,
  } = props;
  const [mouseLeave, setMouseLeave] = React.useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const {value} = formik.getFieldProps(`${name}.name`) ?? {};

  const {value: parrent } = formik.getFieldProps('title') ?? {};
  const {value: row} = formik.getFieldProps(`${name}`) ?? {};
//console.log(row.num)
  const handleDelete = () => {

    modal.confirm({
      title: 'Вы действительно хотите удалить рабочее место?',
      icon: <WarningOutlined style={{ color: '#D73C1D' }} />,
      okText: 'Да',
      cancelText: 'Отменить',
      onOk: () => {
        onDeleteStructureWorkplace({
          structure: parrent?.id,
          workplace: row?.id
        })
        formik.setFieldValue(name, null);
      },
      confirmLoading: true,
    });
  }

  const handleMouseEnter = () => {
    setMouseLeave(true);
  }

  const handleMouseLeave = () => {
    setMouseLeave(false);
  }

  const handleEditworkplace = () => {
    onRightName(name);
    onShowEditWorkplace.on();
  }

  return (
    <React.Fragment>
      <Styled.Border
        active={row?.active ?? false}
      >
        <Flex
          wrap={'nowrap'}
          aItems={'center'}
          jContent={'space-between'}
          paddingItem={['0 35px 0 0']}
          grow={[1, 0]}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >

          <Styled.WorkplaceListText>
            <table>
              <tr>
                <td width={'10%'}>{row.num}</td>
                <td width={'90%'}>{value}</td>
              </tr>
            </table>


          </Styled.WorkplaceListText>
          {mouseLeave ? <Styled.IconButtons>
            <Styled.IconButton
              variant={'link'}
              onClick={handleEditworkplace}
            >
              <Edit />
            </Styled.IconButton>
            <Styled.IconButton
              variant={'link'}
              onClick={handleDelete}
            >
              <Delete />
            </Styled.IconButton>
          </Styled.IconButtons> : ''}
        </Flex>
      </Styled.Border>
      {contextHolder}
    </React.Fragment>

  )
}

import React from 'react';

import * as Styled from './styled';

export default function Flex(props) {
  const {
    children,
    height,
    direction,
    grow,
    jContent,
    aItems,
    minWidth,
    widthItem,
    heightItem,
    paddingItem,
    ...styled
  } = props;


  if (!children) {
    return null
  }

  return (
    <Styled.Flex
      {...styled}
      height={height}
      direction={direction}
      jContent={jContent}
      aItems={aItems}
      minWidth={minWidth}
    >
      {React.Children.toArray(children).map((child, index) => (
        <Styled.FlexItem
          key={index}
          grow={grow && grow[index]}
          widthItem={widthItem && widthItem[index]}
          heightItem={heightItem && heightItem[index]}
          paddingItem={paddingItem && paddingItem[index]}
          // grow={(grow as number[])[index]}
        >
          {child}
        </Styled.FlexItem>
      ))}
    </Styled.Flex>
  )
}
import { toast } from "react-toastify";
import axios from "axios";

import { store } from "../index";
import { logout } from "../store/actions/authActions";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
});

function showToast(data, method = "info") {
  if (typeof data === "string") {
    toast[method](data);
  } else if (data?.details && typeof data?.details === "string") {
    toast[method](data?.details);
  } else if (data?.message && typeof data?.message === "string") {
    toast[method](data?.message);
  }
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error?.response?.status) {
        case 400:
          showToast(error?.response?.data, "error");
          break;
        case 401:
          store.dispatch(logout());
          break;
        case 403:
          if (error?.config?.method && error?.config?.method !== "get") {
            showToast(error?.response?.data, "error");
          }
          break;
        case 422:
          showToast(error?.response?.data, "warning");
          break;
        default:
          showToast(error?.response?.data, "error");
          console.error(error);
      }
      return Promise.reject(error?.response);
    }
  }
);

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token ?? ""}`;
  return config;
});

export default instance;

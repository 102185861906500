import styled, {css} from 'styled-components';

export const Grade = styled.div`
  font-family: 'GolosTextWebVF', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: ${props => props.align ?? 'left'};
  padding: 18px;
  white-space: nowrap;
  color: #52575C;
`

export const Colors = styled.span`

  ${props => 
    props.index === 0 && css`
      color: #E3366A;
  `}

  ${props => 
    props.index === 1 && css`
      color: #A8AB19;
  `}

  ${props => 
    props.index === 2 && css`
      color: #51AD08;
  `}
`
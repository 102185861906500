import React, {useEffect, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'

/**
 * @param amount Количество строк вариантов
 * @param values Уже выбранные варианты с ключами: id, min, max
 * @param titles Названия возможных вариантов из словаря: Маловероятно, Возможно и т.д.
 * @param maxValue Максимальное значение варианта от 1 до max
 * @param onChange Функция передачи внутренних значений наружу
 * @param parentDisabled Флаг отключения полей формы снаружи
 * @returns {JSX.Element}
 * @constructor
 */
function FormOptions({amount = 0, values = [], titles = [], maxValue = 10, onChange, disabled: parentDisabled}) {
  const [record, setRecord] = useState([])

  // Забиваем значения по умолчанию при изменении количества строк или значений
  useEffect(() => {
    const rows = []
    for (let i = 0; i < amount; i++) {
      rows.push(values[i] || {id: 0, min: 0, max: 0})
    }
    setRecord(rows)
    if (onChange && typeof onChange === 'function') {
      onChange(rows)
    }
  }, [amount])

  function selectedTitles() {
    return record?.map((i) => i.id)
  }
  function selectedOptions() {
    const selected = []
    for (const row of record) {
      if (row.min > 0 && row.max > 0) {
        for (let i = row.min; i <= row.max; i++) {
          selected.push(i)
        }
      } else if (row.min > 0) {
        selected.push(row.min)
      } else if (row.max > 0) {
        selected.push(row.max)
      }
    }
    return [...new Set(selected)]
  }

  function onValueChange(index, field, value) {
    value = Number(value)
    const newRecord = [...record]
    if (!newRecord[index]) {
      newRecord[index] = {id: 0, min: 0, max: 0}
    }
    newRecord[index][field] = value
    // Если отключается какой-то ряд
    if (field === 'id' && !value) {
      newRecord[index].min = 0
      newRecord[index].max = 0
      // То отключаем и все остальные ряды за ним
      if (index < newRecord.length) {
        const count = record.length - index - 1
        const fill = new Array(count).fill(1)?.map(() => {
          return {id: 0, min: 0, max: 0}
        })
        newRecord.splice(index + 1, count, ...fill)
      }
    }
    setRecord(newRecord)
    if (onChange && typeof onChange === 'function') {
      onChange(newRecord)
    }
  }

  function GetTitles({index, placeholder = '--'}) {
    const options = [
      <option key="0" value="0">{placeholder}</option>
    ]
    titles.forEach((i) => {
      options.push(
        <option key={i.id} value={i.id} disabled={selectedTitles().includes(i.id)}>{i.title}</option>
      )
    })

    const value = record[index]?.id || 0
    const disabled = parentDisabled || (index > 0 && !record[index - 1]?.id)
    return (
      <Form.Select size="sm" defaultValue={value} disabled={disabled} onChange={({target}) => onValueChange(index, 'id', target.value)}>
        {options}
      </Form.Select>
    )
  }

  function GetOptions({index, type, placeholder = '--'}) {
    const options = [
      <option key="0" value="0">{placeholder}</option>
    ]
    for (let i = 1; i <= maxValue; i++) {
      let disabled = type === 'min' && selectedOptions().includes(i)
      if (!disabled) {
        if (type === 'min' && record[index]?.max) {
          disabled = i >= record[index].max
        }
        if (type === 'max' && record[index]?.min) {
          disabled = i < record[index].min
        }
      }
      if (!disabled) {
        const max = Math.max(...selectedOptions())
        if (max <= maxValue) {
          disabled = i < max
        }
      }
      options.push(
        <option key={i} value={i} disabled={disabled}>{i}</option>
      )
    }

    const value = record[index] ? record[index][type] : 0
    const disabled = parentDisabled || (record[index] && !record[index].id)
    return (
      <Form.Select size="sm" defaultValue={value} disabled={disabled} onChange={({target}) => onValueChange(index, type, target.value)}>
        {options}
      </Form.Select>
    )
  }

  function GetRows() {
    const rows = []
    for (let i = 0; i < amount; i++) {
      rows.push(
        <Row key={i} className="mt-2">
          <Form.Group as={Col} md={6}>
            {i === 0 && <Form.Label>Название</Form.Label>}
            <GetTitles index={i} />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            {i === 0 && <Form.Label>От</Form.Label>}
            <GetOptions index={i} type="min"/>
          </Form.Group>
          <Form.Group as={Col} md={3}>
            {i === 0 && <Form.Label>До</Form.Label>}
            <GetOptions index={i} type="max" />
          </Form.Group>
        </Row>
      )
    }
    return rows
  }

  return (
    <>
      <div className="mt-3">
        <GetRows />
      </div>
    </>
  )
}

export default FormOptions

import React from 'react';

import * as Styled from './svg.styled';

export default function SortUp({
  stroke = "#25282B",
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  ...styled
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      {...styled}
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M7 15L12 20L17 15" stroke="#B5B9C1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 9L12 4L7 9" stroke="#1E85EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Styled.Svg>
  )
}

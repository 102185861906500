import React from "react";
import { useDispatch, useSelector } from "react-redux";

import deleteIcon from "../../../../../../image/delete.svg";
import editIcon from "../../../../../../image/ecp.svg";
import { employerAddPosition,
employerAddStructure,employerDeletePosition,   employerDeleteStructure, employerUpdatePosition, employerUpdateStructure } from "../../../../../../store/actions/employersActions";
import IconButton from "../../../../../common/IconButton/IconButton";

const StructurePositionList = ({ employerId }) => {
  const selectedStructure = useSelector(state => state.employersReducer.selectedStructure);
  const dispatch = useDispatch();

  const onClickAddPosition = () => {
    let name = prompt('Введите название должности', '');

    if (name) {
      dispatch(employerAddPosition({
        name,
        employer: selectedStructure.value.employer,
        structure: selectedStructure.value.id
      }));
    }
  }

  const onClickUpdatePosition = (job) => {
    let { id, name, employer } = job;

    name = prompt('Изменить название должности', name);

    if (name) {
      dispatch(employerUpdatePosition({
        id, name, employer,
        structure: selectedStructure.value.id
      }));
    }
  }

  const onClickDeletePosition = (job) => {
    if (window.confirm(`Вы действительно хотите удалить должность:  ${job.name}`)) {
      dispatch(employerDeletePosition(job.id, employerId));
    }
  }

  const onClickAddStructure = () => {
    let name = prompt('Введите имя подразделения', '');

    if (name) {
      dispatch(employerAddStructure({
        name,
        employer: selectedStructure.value.employer,
        parentId: selectedStructure.value.id
      }));
    }
  }

  const onClickUpdateStructure = () => {
    let name = prompt('Редактирование имени подразделения', selectedStructure.value.name);

    if (name) {
      const { employer, id, parentId } = selectedStructure.value;

      dispatch(employerUpdateStructure({ id, parentId,  name, employer }));
    }
  }

  const onClickDeleteStructure = () => {
    if (window.confirm(`Вы действительно хотите удалить подразделение:  ${selectedStructure.value.name}`)) {
      dispatch(employerDeleteStructure(selectedStructure.value.id, employerId));
    }
  }

  return (
    <div className="window-edit-action">
      {selectedStructure &&
        (<>
          <table className="table-h5">
            <tbody>
              <tr>
                <td><IconButton icon={editIcon} onClick={onClickUpdateStructure} title="Редактировать подразделение" /></td>
                <td>
                  <h5 className="break-word">
                    {selectedStructure.value.name}
                  </h5>
                </td>
                <td><IconButton icon={deleteIcon} onClick={onClickDeleteStructure} title="Удалить подразделение" /></td>
              </tr>
              </tbody>
          </table>
          {selectedStructure.value.jobTitles.length ?
            (
              <div className="card_bg">
                <table className="table-positions table table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th><span>Список должностей</span></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedStructure?.value?.jobTitles?.map(job => (
                      <tr key={job.id}>
                        <td><IconButton icon={editIcon} onClick={() => onClickUpdatePosition(job)} title="Редактировать должность" /></td>
                        <td className="break-word">{job?.name || ''}</td>
                        <td><IconButton icon={deleteIcon} onClick={() => onClickDeletePosition(job)} title="Удалить должность" /></td>
                      </tr>))
                    }
                  </tbody>
                </table>
              </div>
            ) : (<p>Должностей нет</p>)
          }
          <div className="actions-structure-positions">
            <button className="btn btn-actions-structure-positions add-button" onClick={onClickAddStructure}>Добавить подразделение</button>
            <button className="btn btn-actions-structure-positions add-button" onClick={onClickAddPosition}>Добавить должность</button>
          </div>
        </>)
      }
    </div>
  )
}

export default StructurePositionList;

import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { csrfWithLogin } from '../../../../store/actions/authActions';
import { logWarn } from '../../../../utils/log';

function convertUrlParams(url) {
  const searchParams = new URLSearchParams(url);

  return {
    token: searchParams.get('token'),
  };
}

export function usePostRecoveryPassword() {
  const dispatch = useDispatch();
  const [respData, setRespData] = React.useState(null);

  const onPostRecoveryPassword = async ({ password }) => {
    const body = {
      ...convertUrlParams(window.location.search),
      password: password,
    };

    try {
      const { data } = await axios.post('/api/user/recovery/password/change', body);

      setRespData(data);

      if (data.status === 'success') {
        toast.success('Пароль успешно изменен.', {
          position: 'bottom-right',
          autoClose: 100,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });

        dispatch(
          csrfWithLogin({
            userName: data.user.user_name,
            password: password,
          })
        );
      }
    } catch (e) {
      setRespData(e.data);

      logWarn({
        errorNumber: '676644435454',
        errorMessage: e,
      });
    }
  };

  return {
    respData,
    onPostRecoveryPassword,
  };
}

import React from 'react'

import Link from '../../../../../atoms/link';

export default function AdditionalMeasures(props) {
  const {
    id,
    onRowId,
    onPostEvaluationcardEdithazard
  } = props;

  const handleClick = () => {
    onRowId(id);
    onPostEvaluationcardEdithazard(id);
  }

  return (
    <Link onLink={handleClick}>
      Добавить
    </Link>
  )
};

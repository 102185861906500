import {useEffect, useState} from 'react'
import {Alert, Button, Spinner,Table} from 'react-bootstrap'
import styled from 'styled-components';

import axios from '../../../../axios/axios'

import EditModal from './Modal/Modal'

export const url = '/api/roles'

export const Th = styled.th`
  font-family: "GolosTextWebVF", serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export const Td = styled.td`
  font-family: "GolosTextWebVF", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #52575C;
`;

function Roles() {
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [record, setRecord] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadTable = async function() {
    setLoading(true)
    try {
      const {data} = await axios.get(url + '/list')
      setRows([...data.rows])
      setTotal(data.total)
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    loadTable()
  }, [])

  function createRecord() {
    setRecord(null)
    setShowModal(true)
  }
  function editRecord(record) {
    setRecord(record)
    setShowModal(true)
  }

  function onModalHide() {
    setShowModal(false)
  }

  function GetTable() {
    if (total === 0) {
      return (
        <Alert variant="light" className="py-4">Записей не найдено</Alert>
      )
    }

    return (
      <Table hover className="table table-hover roleslist">
        <thead>
        <tr className="tr-bg">
          <th className="title_card col-2 text-center" scope="col">Наименование</th>
          <th className="title_card" scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {rows?.map((item) => <GetTableRow key={item.id} {...item} />)}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan={3} className="text-end">
            <Button variant="light" onClick={loadTable} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" className="me-1" /> : <i className="bi bi-arrow-repeat" />}
            </Button>
          </td>
        </tr>
        </tfoot>
      </Table>
    )
  }
  function GetTableRow(props) {
    return (
      <tr valign="middle">
        <Td className="text-center">{props.displayName}</Td>
        <td className="text-end">
          <Button variant="link" size="sm" className="me-1 text-secondary" onClick={() => editRecord(props)}>
            Изменить
          </Button>
        </td>
      </tr>
    )
  }
  function GetBusy() {
    return (
      <Alert variant="light" className="py-4 text-center">
        <Spinner animation="border" />
      </Alert>
    )
  }

  return (
    <>
      <div className="bg-white mt-3">
        {total === null ? <GetBusy /> : <GetTable />}
      </div>
      <EditModal show={showModal} record={record} onHide={onModalHide} onUpdate={loadTable} />
    </>
  );
}

export default Roles;

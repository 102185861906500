import React, { useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Image, Skeleton } from 'antd';

import { useUploadFiles } from './hooks';
import * as Styled from './styled';

const FileListItem = ({ name, fileId, link, onRemove }) => {
  const [visible, setVisible] = useState(false);
  const onClick = () => onRemove(fileId);

  const onShowPreview = () => setVisible(true);

  return (
    <Styled.UploadFileListItemWrap>
      <Styled.UploadFileListItem title={name} onClick={onShowPreview}>
        {name}
      </Styled.UploadFileListItem>
      <Button
        shape="circle"
        type="text"
        size="small"
        icon={<DeleteOutlined style={{ fontSize: 14, color: '#D73C1D' }} />}
        onClick={onClick}
      />
      <Image
        style={{ display: 'none' }}
        src={link}
        preview={{
          visible,
          src: link,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </Styled.UploadFileListItemWrap>
  );
};

export default function UploadFiles({ formik, workplace, onPostRiskassessmentOne }) {
  const { onUploadFiles, onRemoveFiles } = useUploadFiles({
    workplace,
    onPostRiskassessmentOne,
  });

  const handleFileEvent = (e) => {
    onUploadFiles(e.target.files[0]);
  };

  const disabled = formik?.values?.data?.files?.length > 2;

  return (
    <form>
      <Styled.P tagName={'p'}>Визуальный осмотр</Styled.P>
      <Styled.UploadFileFlex paddingItem={['0 34px 0 0']}>
        <label htmlFor="fileUpload">
          <Styled.UploadFileLink disabled={disabled}>
            <Styled.Icon>
              <PlusOutlined style={{ fontSize: 18 }} />
            </Styled.Icon>
            Добавить фото
          </Styled.UploadFileLink>
        </label>
        <Styled.UploadFileList>
          {formik?.values?.loading ? (
            <>
              <Skeleton.Input size="small" active={true} />
              <Skeleton.Input size="small" active={true} />
              <Skeleton.Input size="small" active={true} />
            </>
          ) : null}
          {!formik?.values?.loading &&
            formik?.values?.data?.files?.map((file) => (
              <FileListItem key={file.id} name={file.name} fileId={file.id} link={file.link} onRemove={onRemoveFiles} />
            ))}
        </Styled.UploadFileList>
      </Styled.UploadFileFlex>
      <input
        id={'fileUpload'}
        type={'file'}
        accept="image/png, image/jpeg"
        multiple
        onChange={handleFileEvent}
        hidden={true}
        disabled={disabled}
      />
    </form>
  );
}

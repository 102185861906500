import React from "react";

import axios from "../../../../../axios/axios";
import { logWarn } from "../../../../../utils/log";

export function usePostUserEdit({id, onPostUser}) {

  const [ respDataUserEdit, setRespDataUserEdit] = React.useState(null);

  const onPostUserDelete = async() => {

    try {
      const { data } = await axios.post('/api/user/delete', {id});
      if (data.status === 'success') {
        onPostUser()
      }
    } catch (e) {
      setRespDataUserEdit(e.data);

      logWarn({
        errorNumber: "1685378212184",
        errorMessage: `${e}`,
      });
    }
  }

  return {
    respDataUserEdit,
    onPostUserDelete
  }
}
import React from "react";

import { useFormikContext } from "../../formik/formik.context";

// import { StyledInput } from "./checkboxFilter.styled";

function CheckboxPopup(props) {
  const { column, formik } = props;
  const { initialValues, values, setFieldValue } = formik ?? {};

  const handleChange = (el) => (e) => {
    // e.preventDefault();
    // console.log(
    //   "handleChange 1112 includes(el)",
    //   initialValues?.data?.filter((filter) => {
    //     return filter[column] === el;
    //   })
    // );
  };

  return (
    <div
      style={{
        border: "1px solid red",
        position: "absolute",
        background: "white",
        zIndex: 1,
      }}
    >
      <ul>
        {initialValues?.data.map((el, index) => (
          <li key={index}>
            <input type={"checkbox"} onChange={handleChange(el[column])} />
            {el[column]}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function CheckboxFilter(props) {
  const [sort, setSort] = React.useState(false);
  const formikProps = useFormikContext();

  const handleClick = () => {
    // setShow(true);
    // const resultSearch = values?.data?.filter((el) =>
    //   el[column]?.includes(value)
    // );
    // console.log("resultSearch 1112", !value?.length);
    // if (!value?.length) {
    //   return setFieldValue("data", initialValues?.data);
    // }
    // return setFieldValue("data", resultSearch);
  };

  return (
    <React.Fragment>
      <div
        style={{
          border: "1px solid green",
          cursor: "pointer",
          position: "relative",
        }}
        onClick={handleClick}
      >
        Фильтр + -
      </div>
      {/* <CheckboxPopup {...props} show={show} formik={formikProps} /> */}
    </React.Fragment>
  );
}

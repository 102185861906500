import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../axios/axios';
import * as hooks from '../../../hooks';
import useDebounce from '../../../hooks/useDebounce';
import { convertSortDirections } from '../../../utils/convert';
import { logWarn } from '../../../utils/log';

const { useBoolean } = hooks;

export function usePostProject() {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loading: false,
      data: {},
      search: '',
      tableParams: {
        page: 1,
        pageSize: 50,
        field: '',
        sort: '',
      },
    },
  });

  const onPostProject = async ({ page, pageSize, field, sort }) => {
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post(`/api/project${page ? `?page=${page}` : ''}`, {
        pagination: pageSize ?? '',
        field: sort && field ? field : '',
        sort: sort ?? '',

        //text: 'something here',
      });
      formik.setFieldValue('loading', false);
      if (data?.data.length) {
        formik?.setFieldValue('data', data);
        formik.setFieldValue('loading', false);
      }


    } catch (e) {
      formik.setFieldValue('loading', false);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostProjectFind = async ({ value, sort, field }) => {
    try {
      formik.setFieldValue('loading', true);
      const { data } = await axios.post('/api/project/find', {
        value,
        field: sort && field ? field : '',
        sort: sort ?? '',
      });
      formik.setFieldValue('loading', false);
      if (data?.data?.length) {
        formik?.setFieldValue('data', data);
        formik.setFieldValue('loading', false);
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      formik.setFieldValue('loading', false);

      logWarn({
        errorNumber: '1685386993950',
        errorMessage: `${e}`,
      });
    }
  };

  const onRefetchProjects = async () => {
    const params = {
      page: formik.values.tableParams.page,
      pageSize: formik.values.tableParams.pageSize,
      field: formik.values.tableParams.field,
      sort: formik.values.tableParams.sort,
    };
    await onPostProject(params);
  };

  const onPostProjectDelete = async (id) => {
    try {
      const { data } = await axios.post('/api/project/delete', { id });

      if (data.status === 'success') {
        onRefetchProjects();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685378212184',
        errorMessage: `${e}`,
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    const params = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      field: sorter.columnKey,
      sort: convertSortDirections(sorter.order),
    };
    formik.setFieldValue('tableParams', params);
    onPostProject(params);
  };

  // debounce find projects
  useDebounce(
    () =>
      onPostProjectFind({
        value: formik.values.search,
        field: formik.values.tableParams.field,
        sort: formik.values.tableParams.sort,
      }),
    [formik.values.search],
    500
  );

  //;

  useEffect(() => {
    onPostProject({ page: 1, pageSize: 50 });
  }, []);

  return {
    formik,
    data: formik.values.data,
    tableParams: formik.values.tableParams,
    loading: formik.values.loading,
    onRefetchProjects,
    onChangeTable,
    onPostProjectFind,
    onPostProjectDelete,
  };
}

import React from "react";
import { Spin } from 'antd';

import axios from "../../../../../../axios/axios";
import Link from '../../../../../atoms/link';


function downloadURI(url, filename) {
  url = "/api" + url;
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    console.log(href)
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    console.log(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export function DownloadReport(props) {
  const {link, file} = props;

  const onDownloadFileLink = () => {
    downloadURI(link, file)
  }

  if (!link) {
    return <Spin size="small" />
  }

  return (
    <Link
      onLink={onDownloadFileLink}
    >
      Скачать
    </Link>
  )
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import Button from '../../../atoms/nButton';
import Dialog from '../../../blocks/dialog';
import { userYup } from '../../../pages/userlist/valide.yup';
import FieldInput from '../../fields/input';

import EmailSupport from './emailSupport';
import ForgotLogin from './forgotLogin';
import { usePostRecoverAccount, usePostRecoverLogin } from './hooks';
import Login from './login';
import ResetPassword from './resetPassword';
import * as Styled from './styled';

export default function RecoverAccount() {
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userYup,
    initialValues: {
      email: '',
      userName: '',
    },
  });

  const { respData, onPostRegistr } = usePostRecoverAccount(formik);
  const { respRecoverLogin, onPostRecoverLogin } = usePostRecoverLogin(formik);
  const [status, setStatus] = React.useState(null);
  const { isValid, dirty } = formik ?? {};

  console.log(status, 'status');

  React.useEffect(() => {
    if (respData?.status === 'success') {
      setStatus('resetPassword');
    }
  }, [respData]);

  React.useEffect(() => {
    if (respRecoverLogin?.status === 'success') {
      setStatus('forgotLogin');
    }
  }, [respRecoverLogin]);

  const onGoBack = () => {
    setStatus(null);
  };

  const onSubmit = async () => {
    setStatus('login');
  };

  const handleClose = () => navigate('/');

  const handleRecoverAccount = () => {
    setStatus('emailSupport');
  };

  const handleLoginSubmit = async () => {
    onPostRegistr();
  };
  // console.log('formik', formik)
  return (
    <form>
      <EmailSupport isRender={status === 'emailSupport'} onCloseDialog={handleClose} />
      <Login
        formik={formik}
        isRender={status === 'login'}
        onLink={onPostRecoverLogin}
        onSubmit={handleLoginSubmit}
        onGoBack={onGoBack}
      />
      <ForgotLogin formik={formik} isRender={status === 'forgotLogin'} onCloseDialog={handleClose} />
      <ResetPassword formik={formik} isRender={status === 'resetPassword'} onCloseDialog={handleClose} />
      <Dialog isRender={!status} isClose={true} centered={true} title={'Восстановление пароля'} onClose={handleClose}>
        <FieldInput margin={'0 0 24px'} formik={formik} placeholder={'Адрес электронной почты'} name={'email'} />
        <Styled.RecoverAccount onLink={handleRecoverAccount}>Забыли адрес электронной почты</Styled.RecoverAccount>
        <Styled.Buttons direction={'row'} jContent={'flex-end'}>
          <Button type={'submit'} variant={'secondary'} disabled={!(isValid && dirty)} onClick={onSubmit}>
            Продолжить
          </Button>
        </Styled.Buttons>
      </Dialog>
    </form>
  );
}

import * as yup from 'yup';

export const innProjectYup = yup.object().shape({
  project: yup.object().shape({
    inn: yup
      .string()
      .matches(/^[0-9]+$/, 'Только цифры')
      .min(10, 'Не меньше 10 цифр')
      .max(12, 'Не больше 12 цифр')
      .required('Обязательное поле для заполнения'),
  }),
});

export const projectYup = yup.object().shape({
  project: yup.object().shape({
    shortName: yup.string().required('Обязательное поле для заполнения'),
  }),
});

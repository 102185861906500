import React from 'react';
import styled from 'styled-components';

import { useFormikContext } from '../../formik/formik.context';

const StyledSelect = styled.select`
  height: ${({ styled }) => styled?.height ?? 'auto'};
  font-size: ${({ styled }) => styled?.fontSize ?? '15px'};
`;

export default function Select(props) {
  const { name, auxiliaryProps } = props;

  const formikProps = useFormikContext();

  const { options = [], labelKey = '', styled } = auxiliaryProps ?? {};
  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps = () => {}, setFieldValue } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};

  const handleChange = (e) => {
    const value = e.target.value;

    if (!auxiliaryProps?.notBoolean) {
      return setFieldValue(name, JSON.parse(value));
    }

    return setFieldValue(name, value);
  };

  if (!options?.length) {
    return [];
  }

  return (
    <StyledSelect
      className="form-select rounded-0 td-text title_card"
      styled={styled}
      onChange={handleChange}>
      {options?.map((el) => (
        <OptionItem key={el?.id} el={el} value={value} labelKey={labelKey} />
      ))}
    </StyledSelect>
  );
}

function OptionItem(props) {
  const { el, value, labelKey = '', selectedKey = 'id' } = props;
  const { id, label = '' } = el ?? {};
  const ifSelected = selectedKey
    ? el[selectedKey] === value
    : value === id.toString();

  const itemLabel = !!labelKey ? el[labelKey] : label;

  return (
    <option value={id} selected={ifSelected}>
      {itemLabel}
    </option>
  );
}

import React from 'react';

import RecursionListItem from '../recursionListItem';

import * as Styled from './styled';

const RecursionList = (props) => {
  const {
    fieldValue,
    search,
    arr,
    originalArr,
    formik,
    onShow,
    isRenderList = true,
    currentMeasures,
    expandedKeys,
    setExpandedKeys,
    onCallbackAfterChange,
  } = props;

  if (!isRenderList) {
    return null;
  }

  if (!arr?.length) {
    return null;
  }

  return (
    <Styled.UL>
      {arr?.map((el, index) => {
        return (
          <RecursionListItem
            key={index}
            fieldValue={fieldValue}
            search={search}
            el={el}
            onShow={onShow}
            formik={formik}
            originalArr={originalArr}
            currentMeasures={currentMeasures}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
            onCallbackAfterChange={onCallbackAfterChange}
          />
        );
      })}
    </Styled.UL>
  );
};

export default RecursionList;

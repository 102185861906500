import React from 'react';

import * as Styled from './styled';

export default function Grade(props) {
  const { label, align } = props;

  const arrayLabel = label?.split('/');

  return (
    <Styled.Grade align={align}>
      {arrayLabel.map((el, index) => (
        <React.Fragment key={index}>
          {index !== 0 && '/'}
          <Styled.Colors key={index} index={index}>
            {el}
          </Styled.Colors>
        </React.Fragment>
      ))}
    </Styled.Grade>
  );
}

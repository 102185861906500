import React, { useState} from "react";
import { toast } from "react-toastify";
//import ReactDOM from "react";
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Modal,Row, Tree } from 'antd';
//import { Space} from "antd";
import axios from "axios";

import Delete from '../../../../../assets/svg/delete';
import Edit from  '../../../../../assets/svg/edit';  //'../../../../../../../assets/svg/edit';
import InputSearch from '../../../../atoms/search';

import EditParent from "./editparent/editparent.jsx";
import NewModal from "./newmodal/newmodal.jsx";
import NewParent from "./newparent/newparent";
import Position from './Position.jsx';
import * as Styled from './styled.js';

const { confirm } = Modal;

export default function ModalContent({showModal, table, name, onClose}) {




  //const [data, setData] = useState(null);
  const [title, setTitle] = useState('Загрузка ...');
  const [change, setChange] = useState(false)
  const [data, setData] = useState([])
  const [right, setRight] = useState([]);
  const [position, setPosition] =useState();
  const [positionId, setPositionId] =useState();
  const [showNewModal, setShowNewModal] = useState(false)
  const [showNewPortal, setShowNewPortal] = useState(false);
  const [showEditParent, setShowEditParent] = useState(false);
  const [mouseLeave, setMouseLeave] = React.useState(false);
  const [parent, setParent] = useState('');
  const [editParent, setEditParent] = useState({
    code: "",
    name: "",
    id: ""
  });
  const handleMouseEnter = () => {
    setMouseLeave(true);
  }

  const handleMouseLeave = () => {
    setMouseLeave(false);
  }

/*
console.log(showModal)
  return (
    <div className="modal">
        <div>I'm a modal dialog</div>
        <button onClick={onClose}>Close</button>
    </div>
);
*/
//const close = {()=> setShowModal(false)}
/*
const onSelect = (selectedKeys, info) => {
  console.log('selected', selectedKeys, info);
};
*/
//const aaa = ReactDOM.createRoot(document.getElementById('root'));
//console.log(position)
  if(showModal) {

    //console.log(table)
    const readDb = () => {axios.post('/api/classifier', {table: table}).then(function(response){
      let res = response.data;
      let data = res.data;
      //setData(res.data);
      return data;
    }
    ).then((data)=>{
      //console.log(data);
      //const treeData = data;
      setTitle(name)
      setChange(true)
      setData(data)
      //setState({title3: "New3"})
      //console.log(data)
    })



    }

    if(!change){
          readDb();
    }
  }
  function positionFind(table, parent){
    setRight([]);
    //setParent(parent)
     axios.post('/api/classifier/position',
      {
        table: table,
        parent: parent
      }
     )
     .then(function(response){
      //console.log(response.data.data);
      setRight(response.data.data)
      //console.log(right);
     })
  }

  const onSelect = (selectedKeys, info) => {
    //console.log('selected', selectedKeys, info);
    setPosition(info.selectedNodes[0]?.title);
    setPositionId(info.selectedNodes[0]?.id);

    setParent(info.selectedNodes[0]?.id)

    //console.log(parent)

    if(info.selectedNodes[0]  != null){
      positionFind(table, info.node.id)
      //setPosition(info.node.name);
    }else{
      setRight(null)
    }
  };

  function closeOn(){
    setChange(false);
    setTitle('Загрузка ...');
    setPosition('')
    setData([]);
    onClose();
    setRight([])
  }
  //console.log(data);



  function deletePos(){
    //console.log("del " + parent);

    axios.post('/api/classifier/position',
      {
        table: table,
        parent: parent
      }
     )
     .then(function(response){
      //console.log(response.data.data);
      setRight(response.data.data)
      console.log(right)
  })

  }

  function insertPosition(){
    //console.log(parent);
    if(parent === ''){
      toast['error']("Необходимо выбрать раздел")
    }else{
    setShowNewModal(true);
    }
  }

  function insertParent(){
    //console.log(parent, position)
    setShowNewPortal(true);
  }

  function handleEditParent(){
    //console.log("qq " + parent)
    axios.post('/api/classifier/parent',
      {
        table: table,
        parent: parent
      }
    )
    .then(function(response){
      //console.log(response.data.data)
      setEditParent(response.data.data);
      //console.log('qqq' +  editParent.id)
      setShowEditParent(true);
    })

    //redDb();

  }

  function handleDeleteParent(){
    //console.log('del');

    confirm({
      title: 'Подтвердите удаление позиции.',
      icon: <ExclamationCircleOutlined />,
      content: position,
      okText: 'Подвердить',
      //okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        console.log('OK', positionId);

        axios.post('/api/classifier/parent/delete',
          {
            table: table,
            id: positionId
          }
        )
        .then(function(response){
          //console.log(props.del)
          toast['success']("Раздел удален");
          //props.del()

          console.log(response);
          //setRight(response.data);
        })
      },
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  function redDb(){
    //console.log('table:  ' + table);
    axios.post('/api/classifier', {table: table})
      .then(function(response){
        //console.log(response);
        setData(response.data.data)
        console.log(data);
        //console.log("parent " + parent)
      })


  }




  return (
    //<Space>
    <>
    <Styled.ModalBack
      open= {showModal}
      title={title}
      onOk={closeOn}
      onCancel={closeOn}
      width={1200}
      height={1000}
      footer={[]}
    >

      <br></br>

          <Row  justify={'center'}>
            <Styled.ColCenter span={11}>
              <h4 align="center">Разделы классификатора</h4>
            </Styled.ColCenter>
            <Styled.Colu span={1}></Styled.Colu>
            <Col span={1} />
            <Styled.ColCenter span={9}>
              <h5 align="center">{position}</h5>
              </Styled.ColCenter>
              <Styled.ColCenter span={2}>
              <Styled.IconButtons>
              <Styled.IconButton
                    variant={'link'}
                    onClick={handleEditParent}
                >
                <Edit
                variant={'link'}
                  onClick={handleEditParent}
                />
                </Styled.IconButton>
                <Styled.IconButton
                    variant={'link'}
                    onClick={handleDeleteParent}
                >
                   <Delete/>
                </Styled.IconButton>
                </Styled.IconButtons>
              </Styled.ColCenter>




          </Row>

      <Row>
        <Styled.ColCenter span={11}>
          <Row>
            <Styled.ColCenter span={16}>
              <InputSearch />
            </Styled.ColCenter>
            <Col span={1}></Col>
            <Styled.ColCenter span={7}>
              <Styled.Add variant={'primary'} onClick={insertParent}>Добавить раздел</Styled.Add>
            </Styled.ColCenter>
          </Row>
        </Styled.ColCenter>
        <Styled.Colu span={1}></Styled.Colu>
        <Col span={1} />
        <Styled.ColCenter span={11}>
          <Row>
            <Styled.ColCenter span={16}>
              <InputSearch />
            </Styled.ColCenter>
            <Col span={1}></Col>
            <Styled.ColCenter span={7}>
              <Styled.Add variant={'primary'} onClick={insertPosition}>Добавить позицию</Styled.Add>
            </Styled.ColCenter>
          </Row>
        </Styled.ColCenter>
      </Row>

      <Row widtn={1000}>
        <Col span={11}>
            <Tree
              //showLine
              wordwrap={'word'}
              //isLeaf={false}
              virtual={true}
              switcherIcon={<DownOutlined />}
              //onExpand={onExpand}
              //expandedKeys={expandedKeys}
              //autoExpandParent={autoExpandParent}
              //defaultExpandedKeys={['0-0-0']}
              //fieldNames={title: title, key: id, children: children}
              height={300}
              //fieldNames = { title: title, key: key, children: children }
              blockNode={false}
              onSelect={onSelect}
              treeData={data}
            />
        </Col>
        <Styled.Colu span={1}></Styled.Colu>
        <Col span={1} />
        <Col span={11}>
          {right?.length
            ? right?.map((el, index) => {
              //console.log(index);
          return(<Position
            el={el}
            right={right}
            table={table}
            parent={parent}
            del={deletePos}
            //ok={onOk}
          />)
          }) : ""}
        {/* <table>
        <Styled.Tbody>
        {right?.length
          ? right?.map((el, index) => {

            return (
              <tr>
                <td width="10%">
                  {el.code}
                </td>
                <td width="70%">
                  <span  onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>{el.name}</span>{el.name}
                </td>
                <td>
                {mouseLeave ? <Styled.IconButtons>
                <Styled.IconButton
                    variant={'link'}
                    //onClick={handleEditworkplace}
                >
                <Edit />
                </Styled.IconButton>
                <Styled.IconButton
                    variant={'link'}
                    //onClick={handleEditworkplace}
                >
                <Delete />
                </Styled.IconButton>
                </Styled.IconButtons>
                : ''}

                </td>
              </tr>
            )
          })
        : ""}
        </Styled.Tbody>
      </table> */}

        </Col>
      </Row>

    </Styled.ModalBack>

    <NewModal
        showModal = {showNewModal}
        table = {table}
        //data = {data}

        parent = {parent}
    //onOpen={() => setShowModal(false)}
        onClose={() => setShowNewModal(false)}
        del={deletePos}
      />

      <NewParent
        showModal = {showNewPortal}
        table = {table}
        //data = {data}

        parent = {parent}
    //onOpen={() => setShowModal(false)}
        onClose={() => setShowNewPortal(false)}
        red = {redDb}
        //del={deletePortal}
        />

      <EditParent
        showModal = {showEditParent}
        table = {table}
        //data = {data}
        data = {editParent}
        parent = {parent}

        onClose={() => setShowEditParent(false)}
        red = {redDb}
        //del={deletePortal}
      />

      </>
    //</Space>
  );
}

import styled from 'styled-components';

export const Link = styled.a`
  height: 20px;
  left: 834px;
  font-family: 'GolosTextWebVF', serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: #0078D6;
  font-family: 'Golos Text', serif;
  cursor: pointer;
`;


import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';

import DocumentCard from '../components/documentCard';
import { useInformation } from '../hooks';
import * as Styled from '../regulatory/styled';

const countMap = Array.from({ length: 16 }, (_, idx) => `${++idx}`);

const ManualPage = () => {
  const { loading, data, getRegulators } = useInformation({ tab_id: 2 });

  useEffect(() => {
    getRegulators();
  }, []);

  return (
    <Styled.ListOne>
      {countMap.map((el) => (
        <Skeleton key={el} avatar loading={loading} />
      ))}
      {!isEmpty(data) && !loading ? data.map((el) => <DocumentCard key={el.id} item={el} />) : null}
    </Styled.ListOne>
  );
};

export default ManualPage;

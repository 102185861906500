import React from 'react';

import * as Styled from './svg.styled';

export default function Copy({
  fill = "#52575C",
  width = "16",
  height = "16",
  viewBox = "0 0 16 16"
}) {

  const widthString = typeof width  === 'number' ? `${width}px` : width;
  const heightString = typeof height  === 'number' ? `${height}px` : height;

  return (
    <Styled.Svg
      width={widthString}
      height={heightString}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path 
        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M3.33203 9.99967H2.66536C2.31174 9.99967 1.9726 9.8592 1.72256 9.60915C1.47251 9.3591 1.33203 9.01996 1.33203 8.66634V2.66634C1.33203 2.31272 1.47251 1.97358 1.72256 1.72353C1.9726 1.47348 2.31174 1.33301 2.66536 1.33301H8.66536C9.01899 1.33301 9.35813 1.47348 9.60817 1.72353C9.85822 1.97358 9.9987 2.31272 9.9987 2.66634V3.33301"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Svg>
  )
}

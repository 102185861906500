import { useFormikContext } from '../../formik/formik.context';

function toolsPPNotZero(toolsPP) {
  const { value } = toolsPP ?? {};

  return value !== '0' ? toolsPP : '';
}

function getCheckValue(name) {
  const { value } = name ?? {};

  return value ? `- ${value}` : '';
}

export default function SizView(props) {
  const { index, auxiliaryProps } = props;
  const formikProps = useFormikContext();
  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps } = ifFormikProps ?? {};

  const name = getFieldProps(`data.${index}.name`) ?? '';
  const type = getFieldProps(`data.${index}.type`) ?? '';
  const toolsPP = getFieldProps(`data.${index}.toolsPP`) ?? '';
  const tools_pp = getFieldProps(`data.${index}.tools_pp`) ?? '';
  const checkToolsPP = getCheckValue(toolsPPNotZero(toolsPP));
  const checkTools_pp = getCheckValue(toolsPPNotZero(tools_pp));
  const checkType = getCheckValue(type);

  return `${name.value} ${(checkTools_pp || checkToolsPP) ?? checkType}`;
}

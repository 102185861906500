import React from 'react';

import GeneralInfoOrganization from '../../../../formik/forms/generalInfoOrganization';
import ListOfJobs from '../../../../formik/forms/listOfJobs';
import ProfessionalRisks from '../../../../formik/forms/professionalRisks';
import Reports from '../../../../formik/forms/reports';

export function getTabs(props) {
  const { formikGrade, onCloseGeneralInfo, onPostProject, onClickShowLegalAddress } = props;

  const tabs = [
    {
      label: 'Общие сведения об организации',
      content: (
        <GeneralInfoOrganization
          id={formikGrade.values.project?.id}
          formik={formikGrade}
          onCloseGeneralInfo={onCloseGeneralInfo}
          onPostProject={onPostProject}
          onClickShowLegalAddress={onClickShowLegalAddress}
        />
      ),
    },
    {
      label: 'Перечень рабочих мест',
      content: <ListOfJobs id={formikGrade.values.project?.id} onPostProject={onPostProject} />,
      disabled: formikGrade.status === 'new',
    },
    {
      label: 'Оценка профессиональных рисков',
      content: <ProfessionalRisks project={formikGrade.values.project?.id} onPostProject={onPostProject} />,
      disabled: formikGrade.status === 'new',
    },
    {
      label: 'Отчеты',
      content: <Reports project={formikGrade.values.project?.id} />,
      disabled: formikGrade.status === 'new',
    },
  ];

  return tabs;
}

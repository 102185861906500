import React from 'react';

import ErrorClose from '../../../../assets/svg/errorClose';
import EyeOff from '../../../../assets/svg/eyeOff';
import EyeOn from '../../../../assets/svg/eyeOn';
import Warning from '../../../../assets/svg/warning';

import * as Styled from './styled';

function ShowHidePassword(props) {
  const { isRender, typeInput, onTypeInput } = props;

  const handleEyeOff = () => onTypeInput('password');
  const handleEyeOn = () => onTypeInput('text');

  if (!isRender) {
    return null;
  }

  if (typeInput === 'text') {
    return (
      <Styled.ShowHidePassword variant={'link'} onClick={handleEyeOff}>
        <EyeOn />
      </Styled.ShowHidePassword>
    );
  }

  if (typeInput === 'password') {
    return (
      <Styled.ShowHidePassword variant={'link'} onClick={handleEyeOn}>
        <EyeOff />
      </Styled.ShowHidePassword>
    );
  }

  return null;
}

export default function FieldInput(props) {
  const {
    name,
    formik,
    label,
    type = 'text',
    placeholder,
    margin,
    disabled,
    isError = true,
    isWarning = false,
    required,
    autoComplete,
    onRequest,
    autoFocus,
    styled,
  } = props;

  const [typeInput, setTypeInput] = React.useState(type);
  const { setFieldValue } = formik ?? {};
  const { value } = formik?.getFieldProps(name) ?? {};
  const { error, touched } = formik?.getFieldMeta(name) ?? {};
  const { setTouched } = formik?.getFieldHelpers(name) ?? {};

  const handleChange = (e) => {
    setTouched(true); // https://github.com/jaredpalmer/formik/issues/2866#issuecomment-1723277871
    setFieldValue(name, e.target.value);
  };

  return (
    <Styled.Field margin={margin}>
      {label ? (
        <Styled.FieldLabel
          tagName={'label'}
          fSize={styled?.label?.fSize ?? 16}
          lHeight={styled?.label?.lHeight}
          fWeight={styled?.label?.fWeight ?? 500}
          padding={styled?.label?.padding}
        >
          {label}
        </Styled.FieldLabel>
      ) : (
        ''
      )}
      <Styled.InputTextWrap error={!isWarning && isError && error && touched}>
        <Styled.InputText
          name={name}
          fSize={styled?.input?.fSize}
          lHeight={styled?.input?.lHeight}
          padding={styled?.input?.padding}
          type={typeInput ?? type}
          value={value}
          required={required}
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          fWeight={400}
          onChange={onRequest || handleChange}
          // onBlur={handleBlur}
          disabled={disabled}
        />
        <ShowHidePassword isRender={type === 'password'} typeInput={typeInput} onTypeInput={setTypeInput} />
      </Styled.InputTextWrap>
      {isError && error && touched ? (
        <Styled.Valide>
          <ErrorClose />
          <Styled.ValideMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            {error}
          </Styled.ValideMessage>
        </Styled.Valide>
      ) : (
        ''
      )}
      {isWarning && error && touched ? (
        <Styled.Valide>
          <Warning />
          <Styled.ValideMessage tagName={'span'} fSize={10} lHeight={10} fWeight={400}>
            {error}
          </Styled.ValideMessage>
        </Styled.Valide>
      ) : (
        ''
      )}
    </Styled.Field>
  );
}

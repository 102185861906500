import React from 'react';

// import Arrow from '../../../../../../assets/svg/arrow';
import Dialog from '../../../dialog';

import { useSoutResults } from './hooks';
import * as Styled from './styled';

const soutsArray = [
  { value: '', label: '-' },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3.1, label: 3.1 },
  { value: 3.2, label: 3.2 },
  { value: 3.3, label: 3.3 },
  { value: 3.4, label: 3.4 },
  { value: 4, label: 4 },
];

function SelectSout(props) {
  const { options, formik, name } = props;
  const { value } = formik?.getFieldProps(name) ?? {};

  const handleChange = (e) => {
    formik?.setFieldValue(`${name}.value`, e.target.value);
  };

  return (
    <Styled.SelectSoutWrapper widthItem={['calc(100% - 86px)']}>
      <Styled.SelectSoutText>{value?.name}</Styled.SelectSoutText>

      <Styled.SelectSout onChange={handleChange}>
        <option hidden value="">
          {Math.round(value.value) === 0 ? '-' : value.value}
        </option>
        {options?.map((el, index) => {
          return (
            <option key={index} value={el.value}>
              {el.label}
            </option>
          );
        })}
      </Styled.SelectSout>
    </Styled.SelectSoutWrapper>
  );
}

export default function SoutResults(props) {
  const { onPostProjectHazard, show, onShow, workplace } = props;
  const { formikSout, onPostSoutSave } = useSoutResults({
    workplace,
    onShow,
    onPostProjectHazard,
  });

  return (
    <Dialog
      isRender={show}
      isClose={true}
      centered={true}
      onClose={onShow.off}
      title={'Введите классы условий труда по результатам СОУТ'}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 20px',
        },
        close: {
          height: 28,
          width: 28,
          right: '8px',
          top: '8px',
        },
      }}
    >
      <Styled.Form>
        {formikSout?.values?.data?.map((sout, index) => (
          <SelectSout
            key={sout?.id}
            formik={formikSout}
            label={sout?.name}
            options={soutsArray}
            name={`data.${index}`}
          />
        ))}
      </Styled.Form>
      <Styled.Buttons>
        <Styled.ButtonBottom variant={'secondary'} onClick={onPostSoutSave}>
          Сохранить
        </Styled.ButtonBottom>
      </Styled.Buttons>
    </Dialog>
  );
}

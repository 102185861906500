import React from 'react';

import * as Styled from './styled';


export default function Input(props) {

  const {
    value,
    onChange,
    type = 'text',
    autoComplete,
    autoFocus,
    disabled,
    placeholder,
    required,
    ...styled
  } = props;

    const ref = React.useRef();

  React.useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current?.focus();
    }
  }, [])

  return (
    <Styled.Input
      {...styled}
      ref={ref}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      type={type}
      required={required}
      autoComplete={autoComplete}
      disabled={disabled}
    />
  );
}


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Tree } from 'antd';
import { isEmpty } from 'lodash';

import { getArrMap, getExpandedKeys } from '../../../../../../utils';
import Dialog from '../../../../../blocks/dialog';
import { HighlightedText } from '../../../../../highlightedText';
import { useFactorReference } from '../../hooks';

import './styles.scss';

const ClarifyFactor = (props) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selected, setSelected] = useState([]);
  const { show, onShow, workplace, refetchTable } = props;

  const { loading, data, onSaveFactorReference } = useFactorReference({ show, workplaceId: workplace });

  const arrMap = useMemo(() => getArrMap(data), [data]);
  const defaultCheckedKeys = useMemo(() => arrMap.filter((el) => el.check).map((item) => item.id), [arrMap]);

  useEffect(() => {
    if (!isEmpty(defaultCheckedKeys)) {
      setSelected(defaultCheckedKeys);
    }
  }, [defaultCheckedKeys]);

  const treeData = useMemo(() => {
    const loop = (arr) =>
      arr.map((item) => {
        if (item.data) {
          return {
            title: item.name,
            key: item.id,
            children: loop(item?.data),
          };
        }
        return {
          title: item.name,
          key: item.id,
        };
      });
    return loop(data);
  }, [data]);

  const treeDataUpdated = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        if (item.children) {
          return {
            title: <HighlightedText text={item.title} highlight={searchValue} />,
            className: 'parent',
            disableCheckbox: true,
            key: item.key,
            children: loop(item.children),
          };
        }

        return {
          title: <HighlightedText text={item.title} highlight={searchValue} />,
          key: item.key,
        };
      });

    return loop(treeData);
  }, [searchValue, treeData]);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (e) => {
    const text = e.target.value;

    if (text.length > 2) {
      const { newExpandedKeys } = getExpandedKeys(arrMap, text);
      setExpandedKeys(newExpandedKeys);
    } else {
      setExpandedKeys([]);
    }

    setSearchValue(text);
    setAutoExpandParent(true);
  };

  const onCheck = useCallback((checkedKeys) => {
    setSelected(checkedKeys);
  }, []);

  const onClose = () => {
    onShow.off();
    setSelected([]);
    setExpandedKeys([]);
    setSearchValue('');
  };

  const onSubmit = async () => {
    await onSaveFactorReference(selected);
    await refetchTable({ page: 1, pageSize: 50 }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog
      isRender={show}
      isClose={true}
      centered={true}
      onClose={onClose}
      title={'УТОЧНИТЬ ФАКТОР'}
      width={800}
      destroyOnClose={true}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 20px',
        },
      }}
    >
      <div className="clarify-form">
        <Input
          style={{ marginBottom: 12, width: 300 }}
          placeholder="Поиск"
          value={searchValue}
          suffix={<SearchOutlined />}
          onChange={onChange}
        />
        <Tree
          checkable
          selectable={false}
          switcherIcon={<DownOutlined style={{ fontSize: 12 }} />}
          treeData={treeDataUpdated}
          expandedKeys={expandedKeys}
          checkedKeys={selected}
          autoExpandParent={autoExpandParent}
          onExpand={onExpand}
          onCheck={onCheck}
        />
        <div style={{ display: 'flex', marginTop: 12 }}>
          <Button
            size="large"
            style={{ marginLeft: 'auto', width: 170 }}
            type="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ClarifyFactor;

import * as actionTypes from "../actionTypes/projectTypes";
import {
    DELETE_HAZARD,
    DELETE_WORKPLACE,
    GENERATE_RM,
} from "../actionTypes/projectTypes";

export const initialState = {
    id: null,
    projectId: null,
    employers: [],
    expertOrganizationId: null,
    employerId: null,
    matrixId: null,
    ok01694Оbj: null,
    profStandartObj: null,
    structure: [],
    jobTitle: [],
    workList: [],
    currentProject: null,
    commissionChief: "",
    commissionMembers: { first: "", second: "", third: "" },
    projectISLoaded: true,
    workPlaceISLoaded: true,
    workPlace: {
        id: null,
        code: "",
        structure: "",
        jobTitle: "",
        name: "Mike",
        ok01694: "",
        profStandart: "",
        numbers: "",
        numberSimilar: "",
        hazards: [],
        sout: {
            soutFinal: "",
            souts: [],
            soutDate: "",
        },
        address: {
            expertOrganization: null,
            workplace: null,
            employer: null,
            postalCode: "",
            regionId: null,
            regionName: "",
            autonomousId: null,
            autonomousName: null,
            districtId: null,
            districtName: "",
            cityId: null,
            cityName: "",
            innerCityId: null,
            innerCityName: null,
            localityId: null,
            localityName: null,
            streetId: null,
            streetName: "",
            additionalElementId: null,
            additionalElementName: null,
            house: "",
            estate: null,
            building: null,
            structure: null,
        },
        questionsList: [],
        visualInspectionsFileList: [],
        documentsFileList: [],
    },
    expert_organization: [],
    employerJobTitle: [],
    currentHazard: null,
};

export function projectReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_EMPLOYER_ORGANIZATION:
            return { ...state, employerOrganization: action.payload };
        case actionTypes.SET_EXPERT_ORGANIZATION:
            return { ...state, expertOrganization: action.payload };
        case actionTypes.STRUCTURE_LOAD:
            return { ...state, structure: action.payload };
        case actionTypes.JOB_TITLE_LOAD:
            return { ...state, jobTitle: action.payload };
        case actionTypes.WORK_LIST_LOAD:
            return { ...state, workList: action.payload };
        case actionTypes.GET_CURRENT_PROJECT:
            return {
                ...state,
                ...action.payload,
                commissionMembers: {
                    first: action.payload.commissionMembers[0] ?? {
                        member: "",
                    },
                    second: action.payload.commissionMembers[1] ?? {
                        member: "",
                    },
                    third: action.payload.commissionMembers[2] ?? {
                        member: "",
                    },
                },
            };
        case actionTypes.UPDATE_STATE_CURRENT_PROJECT:
            return { ...state, ...action.payload };
        case actionTypes.PROJECT_IS_LOADED:
            return { ...state, projectISLoaded: action.payload };
        case actionTypes.UPDATE_WORKLIST:
            return { ...state, workList: [...state.workList, action.payload] };
        case actionTypes.GET_EMPLOYERS_INFO_FOR_JOBTITLE:
            return { ...state, employerJobTitle: action.payload };
        case actionTypes.CLEAR_PROJECT:
            return initialState;
        case actionTypes.GET_WORKPLACE:
            return {
                ...state,
                workPlace: action.payload,
                workPlaceISLoaded: true,
            };
        case actionTypes.UPDATE_WORKPLACE:
            return {
                ...state,
                workPlace: { ...state.workPlace, ...action.payload },
            };
        case actionTypes.GET_WORKPLACE_HAZARDS:
            return {
                ...state,
                workPlace: { ...state.workPlace, hazards: action.payload },
            };
        case actionTypes.CLEAR_WORKPLACE:
            return { ...state, workPlace: initialState.workPlace };
        case actionTypes.GET_QUESTIONS_LIST:
            return {
                ...state,
                workPlace: {
                    ...state.workPlace,
                    questionsList: action.payload,
                },
            };
        case DELETE_HAZARD:
            return {
                ...state,
                workPlace: {
                    ...state.workPlace,
                    hazards: state.workPlace.hazards.filter(
                        (hazard) => hazard.id !== action.payload
                    ),
                },
            };
        case DELETE_WORKPLACE:
            return {
                ...state,
                workList: state.workList.filter(
                    (workplace) => workplace.id !== action.payload
                ),
            };
        case GENERATE_RM:
            return {
                ...state,
                workPlace: { ...state.workPlace, code: action.payload },
            };
        case actionTypes.GET_VISUAL_INSPECTIONS_FILELIST:
            return {
                ...state,
                workPlace: {
                    ...state.workPlace,
                    visualInspectionsFileList: action.payload,
                },
            };
        case actionTypes.GET_DOCUMENTS_FILELIST:
            return {
                ...state,
                workPlace: {
                    ...state.workPlace,
                    documentsFileList: action.payload,
                },
            };
        case actionTypes.SAVE_SHEDULE_FILE_LINK:
            return { ...state, schedule_file: action.payload };
        case actionTypes.CHANGE_CURRENT_HAZARD:
            return { ...state, currentHazard: action.payload };
        default:
            return state;
    }
}

import React from 'react';
import { Table } from 'antd';

const MedicalTable = ({ loading, data, onChangeTable }) => {
  const columns = [
    {
      key: 'num',
      title: '№ п/п',
      dataIndex: 'num',
      align: 'center',
      width: 75,
      render: (num) => num,
    },
    {
      key: 'name',
      title: 'Наименование вредного (опасного) производственного фактора / вида работ',
      dataIndex: 'name',
      align: 'center',
      sorter: true,
      render: (name) => name,
    },
    {
      key: 'order',
      title: 'Соответствие № пункта в Приказе Минздрава РФ от 28.01.2021 № 29н',
      dataIndex: 'order',
      align: 'center',
      width: 210,
      sorter: true,
      render: (order) => order,
    },
    {
      key: 'periodicity',
      title: 'Периодичность осмотров',
      dataIndex: 'periodicity',
      align: 'center',
      width: 195,
      sorter: true,
      render: (periodicity) => periodicity,
    },
    {
      key: 'doctor ',
      title: 'Участие врачей-специалистов',
      dataIndex: 'doctor',
      align: 'center',
      width: 250,
      sorter: true,
      render: (doctor) => doctor,
    },
    {
      key: 'research ',
      title: 'Лабораторные и функциональные исследования',
      dataIndex: 'research',
      align: 'center',
      width: 335,
      sorter: true,
      render: (research) => research,
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading}
      columns={columns}
      dataSource={data?.data}
      onChange={onChangeTable}
      pagination={{
        defaultPageSize: 50,
        current: data?.current_page,
        total: data?.total,
      }}
    />
  );
};

export default MedicalTable;

import styled, {css} from 'styled-components';

import Flex from '../../../../../atoms/flex';
import Input from '../../../../../atoms/input';
import Text from '../../../../../atoms/text';

export const InputText = styled(Input)`
  background: #FFF;
  border: 1px solid #18171C;
  border-radius: 10px;
  font-size: ${props => props.fSize ?? 16}px;
  line-height: ${props => props.lHeight ?? 22}px;;
  padding: ${props => props.padding ?? '10px 20px'};

  ${props =>
    props.error &&
      css`
      border-color: #FFD8D8;
      `
  }}
`;

export const FieldLabel = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  padding: ${props => props.padding ?? '0 0 12px'};
  display: inline-block;
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  
  ${props =>
    props.margin &&
      css`
      margin: ${props.margin};
      `
  }}
`

export const Valide = styled(Flex)`
  padding-top: 8px;
  flex-wrap: nowrap;
  align-items: center;
`

export const ValideMessage = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  color: #AAA;
  padding-left: 9px;
  display: inline-block;
`
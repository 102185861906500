import React from 'react';
import { Tree } from 'antd';

import * as Styled from './styled';

export default function StructuralUnits(props) {
  const { formik, onPostStructureWorkplace, onPostChangeStructure } = props;

  const { values } = formik ?? {};

  const [expandedKeys] = React.useState(values.left?.map((el) => el.key));

  const onDragStart = (info) => {
    onPostStructureWorkplace(info?.node);
  };

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...values.left];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if ((info.node.props.children || []).length > 0 && info.node.props.expanded && dropPosition === 1) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    onPostChangeStructure({
      id: info?.dragNode?.id,
      parent: !info?.dropToGap ? info?.node?.id : null,
    });

    formik.setFieldValue('left', data);
  };

  return (
    <Styled.Tree>
      <Tree
        className="draggable-tree"
        defaultExpandedKeys={expandedKeys}
        draggable
        blockNode
        onDrop={onDrop}
        onDragStart={onDragStart}
        treeData={values.left}
      />
    </Styled.Tree>
  );
}

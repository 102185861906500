import React from 'react';

import { useFormikContext } from '../../formik/formik.context';

import { StyledInput } from './input.styled';

// function isString(value) {
//   return typeof value === "string";
// }

export default function Input(props) {
  const { name, auxiliaryProps, ...owenprops } = props;
  const formikProps = useFormikContext();
  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps = () => {}, setFieldValue } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};

  const typeNumber = auxiliaryProps?.typeField === 'number';
  const propsTypeNumber = typeNumber ? { min: 0, max: undefined } : {};
  const typeField = auxiliaryProps?.typeField ?? 'text';

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const valueNumber = typeNumber ? parseInt(value ?? 0, 10) : value;

    setFieldValue(name, valueNumber);
  };

  return (
    <StyledInput
      {...propsTypeNumber}
      {...owenprops}
      type={typeField}
      onChange={handleChange}
      value={value}
    />
  );
}

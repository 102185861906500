import {Button,Modal} from 'react-bootstrap'

const ViewModal = (props) => {
  if (!props.record) {
    return
  }
  const record = props.record || {}

  function getData(record) {
    const data = []
    if (record && Object.keys(record).length) {
      Object.keys(record).forEach((key) => {
        let value = record[key]
        if (typeof val !== 'string') {
          value = JSON.stringify(value)
        }
        data.push({key, value})
      })
    }
    return data
  }

  return (
    <Modal {...props} backdrop="static">
      <Modal.Header>
        <Modal.Title>{record.method} запрос</Modal.Title>
        <Button variant="default" className="close-button" onClick={props.onHide}>
          <div className="d-flex align-items-center justify-content-center">
            <i className="bi bi-x ft-sz" /> Закрыть
          </div>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <dl>
          <dt>endpoint</dt>
          <dd>{record.endpoint}</dd>

          <dt className="mt-2">user</dt>
          <dd>{record.user.userName}</dd>

          <dt className="mt-2">ip</dt>
          <dd>{record.ip}</dd>

          <dt className="mt-2">timestamp</dt>
          <dd>{record.timestamp}</dd>

          <dt className="mt-2">params</dt>
          <dd style={{wordWrap: 'break-word'}}>
            <ul>
              {getData(record.params)?.map((i) => <li className="mt-1"><strong>{i.key}</strong>: {i.value}</li>)}
            </ul>
          </dd>

        </dl>
      </Modal.Body>
    </Modal>
  )
}

export default ViewModal

import { useEffect } from 'react';
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export const useAnalytics = ({ formik, onLoading }) => {
  const onPostAnalytics = async () => {
    try {
      onLoading.on();
      const { data } = await axios.post(`/api/analytics`);

      if (data.status === 'success') {
        formik?.setFieldValue('projectList', data.data);
        onLoading.off();
      }
    } catch (e) {
      onLoading.off();
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  useEffect(() => {
    onPostAnalytics();
  }, []);

  return {
    onPostAnalytics,
  };
};

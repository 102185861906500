import React, { useEffect } from 'react';
import { message } from 'antd';
import { useFormik } from 'formik';

import { useDashboardInfo, useDashboardInfoHazards, useDashboardTraining } from '../../../../graphql/queries/useDashboardInfo';
import { useBoolean } from '../../../../hooks';
import Flex from '../../../atoms/flex';
import ChartAdditionalMeasures from '../components/chartAdditionalMeasures';
import ChartDistributionByLevelRisk from '../components/chartDistributionByLevelRisk';
import ChartHighestRiskLevel from '../components/chartHighestRiskLevel';
import ChartIdentifiedHazards from '../components/chartIdentifiedHazards';
import AnalyticsHead from '../components/head/AnalyticsHead';
import InfoBlock from '../components/infoBlock';

import { useAnalytics } from './hooks';
import {DashboardTraining} from './training';

import './styles.scss';

const AnalyticsDashboard = () => {
  const [loading, onLoading] = useBoolean(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectList: [],
      selectedProject: null,
      projectInfo: {},
      showAverage: false,
    },
  });

  useAnalytics({ formik, onLoading });

  const { dashInfo, dashLoading, dashError } = useDashboardInfo({
    id: formik.values.selectedProject,
  });

  const { dashInfoHazards, dashLoadingHazards, dashErrorHazards } = useDashboardInfoHazards({
    id: formik.values.selectedProject,
    type: formik.values.showAverage,
  });

  const { dashInfoTraining, dashLoadingTraining, dashErrorTraining } = useDashboardTraining({
    id: formik.values.selectedProject,
  });

  useEffect(() => {
    if (dashError) {
      message.error(dashError?.message);
    }
    if (dashErrorHazards) {
      message.error(dashErrorHazards?.message);
    }
    if (dashErrorTraining) {
      message.error(dashErrorTraining?.message);
    }
  }, [dashError, dashErrorHazards, dashErrorTraining]);

  const onSelectProject = (value) => {
    formik.setFieldValue('selectedProject', value);
  };

  const onChangeAverage = (checked) => {
    formik.setFieldValue('showAverage', checked);
  };

  return (
    <div className="analytics-dashboard">
      <AnalyticsHead loading={loading} projectList={formik.values.projectList} onSelect={onSelectProject} />
      <Flex
        gap={'32px'}
        aItems={'stretch'}
        widthItem={['100%', '100%', '100%', '100%']}
        wrap={'nowrap'}
      >
        <Flex
          gap={'32px'}
          direction={'column'}
          height={'100%'}
          heightItem={['auto', 'auto', '100%']}
          wrap={'nowrap'}
        >
          <InfoBlock title="Количество рабочих мест" loading={dashLoading} value={dashInfo?.dash1?.cnt} />
          <InfoBlock
            isShowSwitch
            large
            title={
              formik.values.showAverage
                ? 'идентифицированно опасностей в среднем на 1 рабочее место'
                : 'идентифицированно опасностей суммарно на всех рабочих местах'
            }
            loading={dashLoadingHazards}
            value={dashInfoHazards?.dash4?.cnt}
            onChange={onChangeAverage}
          />
          <ChartDistributionByLevelRisk projectId={formik.values.selectedProject} />
        </Flex>
        <Flex
          gap={'32px'}
          direction={'column'}
          height={'100%'}
          heightItem={['auto', 'auto', '100%']}
          wrap={'nowrap'}
        >
          <InfoBlock title="Количество работников" loading={dashLoading} value={dashInfo?.dash2?.cnt} />
          <ChartIdentifiedHazards projectId={formik.values.selectedProject} />
          <ChartHighestRiskLevel projectId={formik.values.selectedProject} />
        </Flex>
        <Flex
          gap={'32px'}
          direction={'column'}
          height={'100%'}
          heightItem={['auto', 'auto', '100%']}
          wrap={'nowrap'}
        >
          <Flex
            gap={'32px'}
            aItems={'stretch'}
            widthItem={['100%', '100%']}
            wrap={'nowrap'}
          >
            <InfoBlock small title="Вид экономической деятельности" loading={dashLoading} value={dashInfo?.dash3?.okved2} />
            <InfoBlock
              title="Количество рабочих мест с вредными условиями труда"
              loading={dashLoading}
              value={dashInfo?.dash9?.cnt}
            />
          </Flex>
          <Flex
            gap={'32px'}
            aItems={'stretch'}
            widthItem={['100%', '100%']}
            wrap={'nowrap'}
          >
            <InfoBlock
              title="Количество рабочих мест, подлежащих прохождению обязательных медицинских осмотров"
              loading={dashLoading}
              value={dashInfo?.dash10?.cnt}
            />
            <DashboardTraining
              title="Количество рабочих мест подлежащих обучению"
              loading={dashLoadingTraining}
              values={dashInfoTraining?.dash11}
            />
          </Flex>
          <ChartAdditionalMeasures projectId={formik.values.selectedProject} />
        </Flex>
      </Flex>
    </div>
  );
};

export default AnalyticsDashboard;

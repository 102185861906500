import React, { useState } from "react";
import { useSelector } from "react-redux";

import ModalAddOrganizations from "./Organizations/ModalAddOrganizations";
import StringDataOrganization from "./Organizations/StringDataOrganization";
import HeaderOrganization from "./HeaderOrganization";

function OrganizationOrganization() {
  const organizationList = useSelector(
    (state) => state.expertOrganizationReducer.list
  );

  const [openModal, setOpenModal] = useState(false);
  const [organizationId, setOrganizationId] = useState("false");

  function addNewGrade(e) {
    e.preventDefault();
    setOrganizationId("false");
    setOpenModal(true);
  }

  function openOrganizationFromID(id) {
    setOrganizationId(id);
    setOpenModal(true);
  }

  return (
    <>
      <HeaderOrganization />
      <main>
        <section>
          <div className="d-flex justify-content-between">
            <div className="form-floating mb-3" />
            <div>
              <button
                type="button"
                onClick={(e) => {
                  setOpenModal(true);
                  addNewGrade(e);
                }}
                className="btn add-button btn-test"
              >
                Добавить
              </button>
              {openModal ? (
                <ModalAddOrganizations
                  setVisible={setOpenModal}
                  visible={openModal}
                  organizationId={organizationId}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="card_bg">
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1">
                    <div className="title_card">Дата</div>
                  </th>
                  <th className="title_card col-4">
                    <div className="title_card">
                      Наименование эксперной организации
                    </div>
                  </th>
                  <th className="title_card">
                    <div className="title_card">Субъект</div>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {organizationList && organizationList.length ? (
                  organizationList?.map((organization) => (
                    <StringDataOrganization
                      setVisible={setOpenModal}
                      setOrganizationId={setOrganizationId}
                      key={organization.id}
                      organization={organization}
                      open={openOrganizationFromID}
                    />
                  ))
                ) : (
                  <svg className="spinner" viewBox="0 0 50 50">
                    <circle
                      className="path"
                      cx="25"
                      cy="25"
                      r="20"
                      fill="none"
                      stroke-width="5"
                    ></circle>
                  </svg>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </>
  );
}

export default OrganizationOrganization;

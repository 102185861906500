import React from 'react';

import { useFormikContext } from '../../formik/formik.context';
import Spinner from '../../spinner';

const styles = {
  marginTop: '20px',
  marginBottom: 0,
};

function DownloadLink(props) {
  const { name, index, auxiliaryProps } = props;
  const formikProps = useFormikContext();
  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps = () => {} } = ifFormikProps ?? {};
  const { value } = getFieldProps(name) ?? {};
  const link = getFieldProps(`data.${index}.link`) ?? {};
  const { value: status } = getFieldProps(`data.${index}.status`) ?? {};

  if (status === 2 || status === 3) {
    return (
      <a href={link?.value ?? '#'} target="_blank" rel="noreferrer">
        {value || ''}
      </a>
    );
  }

  return (
    <React.Fragment>
      <Spinner loading={true} type={'small'} />
      <p style={styles}>{value}</p>
    </React.Fragment>
  );
}

export default DownloadLink;

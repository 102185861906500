import React from 'react';
import { Select } from 'antd';

import AddCircle from '../../../../assets/svg/addCircle';
import Delete from '../../../../assets/svg/delete';
import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';
import { innProjectYup } from '../../../pages/grade/valide.yup';
import { Field } from '../../fields/autoComplete/styled';
import FieldInput from '../../fields/input';
import { FieldLabel } from '../../fields/select/styled';

import * as Fields from './fields';
import { usePostOkvedFind, usePostSaveProject, usePostSearchInnKpp } from './hooks';
import * as Styled from './styled';

const styled = {
  label: {
    fSize: 14,
    lHeight: 14,
    fWeight: 400,
    padding: '0 0 17px',
  },
  input: {
    fSize: 14,
    lHeight: 14,
    fWeight: 400,
    padding: '16px 11px',
  },
};

const paddingItem = '0 31px 0 0';

export default function GeneralInfoOrganization(props) {
  const { id, formik, onCloseGeneralInfo, onPostProject, onPostUser, onClickShowLegalAddress } = props;
  const { values } = formik ?? {};
  const { onPostSearchInnKpp } = usePostSearchInnKpp(formik);

  const { onPostSaveProject } = usePostSaveProject({
    formik,
    onPostProject,
    onCloseGeneralInfo,
  });

  // const { onPostSaveUser } = usePostSaveUser({
  //   formik,
  //   onPostUser,
  //   onCloseGeneralInfo,
  // });

  const { loadingOkved, dataOkved, onPostOkvedFind } = usePostOkvedFind();

  const legalAddress = [
    values.project.postalCode,
    values.project.regionName,
    values.project.cityName,
    values.project.streetName,
    values.project.house,
    values.project.building,
  ]
    .filter(Boolean)
    .join(', ');

  const options = dataOkved?.length ? dataOkved?.map((el) => ({ label: el.name, id: el.id, value: el.id })) : [];

  const onSelectAutoComplete = (_, option) => {
    const { setFieldValue } = formik;

    setFieldValue('project.idOkved', option.id);
    setFieldValue('project.okved', option.label);
  };

  const onAddCommissions = () => {
    formik.setFieldValue(`commission.${values.commission.length}`, {
      full_name: '',
      id: null,
      isChief: false,
    });
  };

  const onAddExperts = () => {
    formik.setFieldValue(`expert.${values.expert.length}`, {
      id: null,
      job_title: null,
      name: null,
    });
  };

  const onDeleteCommission = (currentIndex) => (e) => {
    e.preventDefault();

    const deleteFied = values.commission.filter((_, index) => {
      return currentIndex !== index;
    });

    formik.setFieldValue(`commission`, deleteFied);
  };

  const onDeleteRowExpert = (currentIndex) => (e) => {
    e.preventDefault();

    const deleteFied = values.expert.filter((_, index) => currentIndex !== index);

    formik.setFieldValue(`expert`, deleteFied);
  };

  const onSaveProject = () => {
    onPostSaveProject();
  };

  const onFillInINN = async () => {
    innProjectYup
      .validate(formik.values)
      .then(() => {
        onPostSearchInnKpp(id);
      })
      .catch((err) => {
        const error = err;
        formik.setFieldError('project.inn', error.errors);
      });
  };

  const isDisabled = !formik.values.project.shortName || Object.keys(formik?.errors?.project ?? {})?.length;

  return (
    <div style={{
      width: '100%'
    }}>
      <Flex widthItem={[188, 188, 228]} paddingItem={[paddingItem, paddingItem]} aItems={'flex-start'}>
        <FieldInput
          styled={styled}
          margin={'0 0 27px'}
          label={'ИНН'}
          formik={formik}
          isWarning={true}
          isError={false}
          name={'project.inn'}
        />
        <FieldInput
          styled={styled}
          margin={'0 0 27px'}
          label={'КПП'}
          formik={formik}
          isError={false}
          isWarning={true}
          name={'project.kpp'}
        />
        <Styled.FillButton type={'submit'} variant={'primary'} onClick={onFillInINN}>
          Заполнить по ИНН/КПП
        </Styled.FillButton>
      </Flex>
      <FieldInput
        styled={styled}
        margin={'0 0 27px'}
        label={'Полное наименование организации'}
        formik={formik}
        widthItem
        name={'project.fullName'}
      />
      <Flex widthItem={[640, 188, 157]} paddingItem={[paddingItem, paddingItem]}>
        <FieldInput
          styled={styled}
          margin={'0 0 27px'}
          label={'Краткое наименование организации'}
          formik={formik}
          name={'project.shortName'}
        />
        <FieldInput styled={styled} margin={'0 0 27px'} label={'ОКПО'} formik={formik} name={'project.okpo'} />
        <FieldInput styled={styled} margin={'0 0 27px'} label={'ОГРН'} formik={formik} name={'project.ogrn'} />
      </Flex>
      <Flex widthItem={[640, 188, 157]} paddingItem={[paddingItem, paddingItem]}>
        <Field margin={'0 0 27px'}>
          <FieldLabel style={{ padding: '0 0 17px', lineHeight: '14px' }}>ОКВЭД</FieldLabel>
          <Select
            loading={loadingOkved}
            style={{ width: '100%' }}
            size="large"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
            options={options}
            value={values.project.idOkved}
            onSearch={onPostOkvedFind}
            onChange={onSelectAutoComplete}
          />
        </Field>
        <FieldInput styled={styled} margin={'0 0 27px'} label={'ОКТМО'} formik={formik} name={'project.oktmo'} />
        <FieldInput styled={styled} margin={'0 0 27px'} label={'ОКОГУ'} formik={formik} name={'project.okogu'} />
      </Flex>
      <Styled.LegalAddressLabel tagName={'p'}>Юридический адрес: {legalAddress}</Styled.LegalAddressLabel>
      <Styled.LegalAddressLink onLink={onClickShowLegalAddress} widthItem>
        Изменить
      </Styled.LegalAddressLink>
      <Flex widthItem={[471, 577]} paddingItem={[paddingItem, paddingItem]} aItems={'flex-end'}>
        <FieldInput
          styled={styled}
          margin={'0 0 27px'}
          label={'Руководитель организации'}
          formik={formik}
          placeholder={'Должность'}
          name={'project.chiefJobTitle'}
        />
        <FieldInput
          styled={styled}
          margin={'0 0 27px'}
          formik={formik}
          placeholder={'ФИО'}
          name={'project.chiefFullName'}
        />
      </Flex>
      <Flex widthItem={[471, 577]} paddingItem={[paddingItem, paddingItem]} aItems={'flex-end'}>
        <Fields.ChairmanInput
          styled={styled}
          margin={'0 0 27px'}
          label={'Председатель комиссии по ОПР'}
          formik={formik}
          placeholder={'Должность'}
          name={`isChief.job_title`}
        />
        <Fields.ChairmanInput
          styled={styled}
          margin={'0 0 27px'}
          formik={formik}
          placeholder={'ФИО'}
          name={`isChief.full_name`}
        />
      </Flex>
      <Styled.AddFieldsButton varialt={'link'} onClick={onAddCommissions}>
        Члены комиссии по ОПР
        <Styled.IconCircle>
          <AddCircle />
        </Styled.IconCircle>
      </Styled.AddFieldsButton>
      {values?.commission?.map((el, index) => {
        if (el?.isChief) {
          return null;
        }

        return (
          <Flex widthItem={[470, 526]} paddingItem={[paddingItem, paddingItem]} aItems={'flex-end'}>
            <FieldInput
              styled={styled}
              margin={'0 0 27px'}
              formik={formik}
              placeholder={'Должность'}
              name={`commission.${index}.job_title`}
            />
            <FieldInput
              styled={styled}
              margin={'0 0 27px'}
              formik={formik}
              placeholder={'ФИО'}
              name={`commission.${index}.full_name`}
            />
            <Styled.DeleteFieldsButton onClick={onDeleteCommission(index)}>
              <Delete />
            </Styled.DeleteFieldsButton>
          </Flex>
        );
      })}
      <Styled.AddFieldsButton varialt={'link'} onClick={onAddExperts}>
        Эксперт(-ы), проводившие оценку профессиональных рисков
        <Styled.IconCircle>
          <AddCircle />
        </Styled.IconCircle>
      </Styled.AddFieldsButton>
      {values?.expert.map((_, index) => (
        <Flex widthItem={[470, 526]} paddingItem={[paddingItem, paddingItem]} aItems={'flex-end'}>
          <FieldInput
            styled={styled}
            margin={'0 0 27px'}
            formik={formik}
            placeholder={'Должность'}
            name={`expert.${index}.job_title`}
          />
          <FieldInput
            styled={styled}
            margin={'0 0 27px'}
            formik={formik}
            placeholder={'ФИО'}
            name={`expert.${index}.name`}
          />
          <Styled.DeleteFieldsButton onClick={onDeleteRowExpert(index)}>
            <Delete />
          </Styled.DeleteFieldsButton>
        </Flex>
      ))}
      <Styled.Buttons jContent={'flex-end'}>
        <Button variant={'secondary'} onClick={onSaveProject} disabled={isDisabled}>
          Сохранить
        </Button>
      </Styled.Buttons>
    </div>
  );
}

import React from "react";

import HeaderAdmin from '../../blocks/headerAdmin';
import UserModal from "../../user/UserModal";

import {navMap} from './navMap';

function HeaderOrganization() {

  return (
    <React.Fragment>
      <UserModal />
      <HeaderAdmin navMap={navMap} />
    </React.Fragment>
  );
}

export default HeaderOrganization;

import axios from "../../axios/axios";
import { logWarn } from "../../utils/log";
import * as actionTypes from "../actionTypes/expertOrganizationTypes";

export const fetchOrganization = (id) => {
  let url = `/api/expert-organizations/${id}`;
  return (dispatch) => {
    id &&
      id !== "false" &&
      axios
        .get(url)
        .then((res) =>
          dispatch({
            type: actionTypes.GET_EXPERT,
            payload: res.data.data,
          })
        )
        .catch((error) =>
          logWarn({
            errorNumber: "1665742698634",
            errorMessage: `${error}`,
          })
        );
  };
};

export const updateOrganization = (organization) => {
  let url = `/api/expert-organizations/full/${organization.id ?? ""}`;
  return (dispatch) => {
    axios
      .put(url, organization)
      .then((response) => {
        if (response) {
          dispatch({ type: actionTypes.UPDATE_ORGANIZATION_SUCCESS });
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665742729617",
          errorMessage: `${error}`,
        })
      );
  };
};

export const createOrganization = (organization) => {
  let url = `/api/expert-organizations/full/`;

  return (dispatch) => {
    axios
      .post(url, organization)
      .then((response) => {
        if (response) {
          dispatch({ type: actionTypes.CREATE_ORGANIZATION_SUCCESS });
        }
      })
      .catch((error) =>
        logWarn({
          errorNumber: "1665742755927",
          errorMessage: `${error}`,
        })
      );
  };
};

import { useQuery } from '@apollo/client';

import { ANALYTICS_HIGHEST_LEVEL_RISK } from '../schemas/reports';

export const useChartHighestLevelRisk = ({ id, type }) => {
  const { data, loading } = useQuery(ANALYTICS_HIGHEST_LEVEL_RISK, {
    variables: {
      id,
      type,
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  return {
    data: data?.dash6,
    loading,
  };
};

import React from 'react';

import * as status from './helpers';
import * as Styled from './styled';

function getStatusName(name) {
  return (type) => {
    return name[type] || '';
  }
}

const typeName = getStatusName(status.name);

export default function Status(props) {
  const {
    type
  } = props;

  const nameStatus = typeName(type);

  return (
    <Styled.Status type={type}>
      {nameStatus}
    </Styled.Status>
  )
};
import React from "react";
import { useSelector } from "react-redux";

import HeaderOrganization from "../../HeaderOrganization";

import ClassifiersCard from "./ClassifiersCard";

function ContainerClassifiers() {
  const classifiersList = useSelector((state) => state.NSIReducer);

  return (
    <>
      <HeaderOrganization />
      <main>
        <div className="card_bg">
          {classifiersList.name === "medical" ||
          classifiersList.name === "medicalVPF" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    Код
                  </th>
                  <th className="title_card col-10" scope="col">
                    Наименование
                  </th>
                  <th className="title_card col-10" scope="col">
                    Периодичность
                  </th>
                  <th className="title_card col-10" scope="col">
                    Врач
                  </th>
                  <th className="title_card col-10" scope="col">
                    Исследование
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.code}</td>
                            <td>{el.value?.name}</td>
                            <td>{el.value?.periodicity}</td>
                            <td>{el.value?.doctor}</td>
                            <td>{el.value?.research}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.code}</td>
                            <td>{el.name}</td>
                            <td>{el.periodicity}</td>
                            <td>{el.doctor}</td>
                            <td>{el.research}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : classifiersList.name === "siz" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    ~№
                  </th>
                  <th className="title_card col-10" scope="col">
                    Наименование профессий
                  </th>
                  <th className="title_card col-10" scope="col">
                    &nbsp;
                  </th>
                  <th className="title_card col-10" scope="col">
                    &nbsp;
                  </th>
                  <th className="title_card col-10" scope="col">
                    &nbsp;
                  </th>
                  <th className="title_card col-10" scope="col">
                    &nbsp;
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.code}</td>
                            <td>{el.value?.name}</td>
                            <td>{el.value?.clothes}</td>
                            <td>{el.value?.standard}</td>
                            <td>{el.value?.toolsPP}</td>
                            <td>{el.value?.standardToolsPP}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.code}</td>
                            <td>{el.name}</td>
                            <td>{el.clothes}</td>
                            <td>{el.standard}</td>
                            <td>{el.toolsPP}</td>
                            <td>{el.standardToolsPP}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : classifiersList.name === "riskmanagement" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    Код
                  </th>
                  <th className="title_card col-10" scope="col">
                    Наименование
                  </th>
                  <th className="title_card col-10" scope="col">
                    Вид меры
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.code}</td>
                            <td>{el.value?.name}</td>
                            <td>{el.value?.typeManagement}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.code}</td>
                            <td>{el.name}</td>
                            <td>{el.typeManagement}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : classifiersList.name === "ok01694" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    Код
                  </th>
                  <th className="title_card col-1" scope="col">
                    КЧ
                  </th>
                  <th className="title_card col-10" scope="col">
                    Наименование
                  </th>
                  <th className="title_card col-1" scope="col">
                    Вид меры
                  </th>
                  <th className="title_card col-1" scope="col">
                    Код выпуска ЕТКС
                  </th>
                  <th className="title_card col-2" scope="col">
                    Код ОКЗ
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.code}</td>
                            <td>{el.value?.number}</td>
                            <td>{el.value?.professionName}</td>
                            <td>{el.value?.codeETC}</td>
                            <td>{el.value?.codeOKZ}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.code}</td>
                            <td>{el.number}</td>
                            <td>{el.professionName}</td>
                            <td>{el.codeETC}</td>
                            <td>{el.codeOKZ}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : classifiersList.name === "okved" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    Код
                  </th>
                  <th className="title_card col-10" scope="col">
                    Наименование
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>
                              {el.value?.classOKVED}.{el.value?.subClassOKVED}
                              .{el.value?.number}
                            </td>
                            <td>{el.value?.economicActivity}</td>
                          </>
                        ) : (
                          <>
                            <td>
                              {el.classOKVED}.{el.subClassOKVED}.{el.number}
                            </td>
                            <td>{el.economicActivity}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : classifiersList.name === "organization" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    Название
                  </th>
                  <th className="title_card col-10" scope="col">
                    ИНН
                  </th>
                  <th className="title_card col-10" scope="col">
                    ОГРН
                  </th>
                  <th className="title_card col-10" scope="col">
                    Тел.
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.shortName}</td>
                            <td>{el.value?.inn}</td>
                            <td>{el.value?.ogrn}</td>
                            <td>{el.value?.phone}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.shortName}</td>
                            <td>{el.inn}</td>
                            <td>{el.ogrn}</td>
                            <td>{el.phone}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : classifiersList.name === "medicalSout" ? (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card col-1" scope="col">
                    &nbsp;
                  </th>
                  <th className="title_card col-10" scope="col">
                    &nbsp;
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.typeOrder}</td>
                            <td>{el.value?.comment}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.typeOrder}</td>
                            <td>{el.comment}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          ) : (
            <table className="table table-hover">
              <thead>
                <tr className="tr-bg">
                  <th className="title_card br-8 col-2" scope="col">
                    Код
                  </th>
                  <th className="title_card col-10" scope="col">
                    Наименование
                  </th>
                </tr>
              </thead>

              <tbody>
                {classifiersList.selectedNSIdictionary &&
                classifiersList.selectedNSIdictionary.length ? (
                  classifiersList.selectedNSIdictionary[0].map((el) => (
                    <>
                      <tr>
                        {el.value ? (
                          <>
                            <td>{el.value?.code}</td>
                            <td>{el.value?.name}</td>
                          </>
                        ) : (
                          <>
                            <td>{el.code}</td>
                            <td>{el.name}</td>
                          </>
                        )}
                      </tr>
                      {el.children ? (
                        <ClassifiersCard classifiersList={classifiersList} />
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                ) : (
                  <div>Данных нет</div>
                )}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </>
  );
}

export default ContainerClassifiers;

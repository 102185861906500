import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  padding-top: ${props => props.paddingTop ?? 0}px;
`

export const Text = styled.span`
  font-size: 14px;
  line-heigth: 20px;
  font-weight: 400;
  color: #52575C;
`

export const Label = styled.span`
  position: absolute;
  left: 50%;
  top: 0;
  white-space: nowrap;
  color: #52575C;
  background-color: #DCDCDC;
  padding: 3px;
  border-radius: 10px;
  display: block;
  font-size: 10px;
  line-heigth: 20px;
  font-weight: 400;
`;
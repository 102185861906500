import React from "react";
import { toast } from "react-toastify";
import { Modal } from 'antd';
import axios from "axios";

//import { Form, Formik, useFormik} from 'formik';
//import Input from '../../../../../atoms/input';
//import axios from "axios";
//import FieldInput from '../../../../../formik/fields/input'
import Button from '../../../../../atoms/nButton';

import * as Styled from './styled';

export default function EditParent({showModal, table, data, parent, onClose, del, editParent}){

  //console.log("code " + data.code)
  //const [name, setName] = React.useState(data.name);
  //const [code, setCode] = React.useState(data.code)
  //console.log(data)

function okSave(){
  //let form = document.forms.editForm;
  //console.log(formData.get('Name'));
  const form = document.getElementById('editParentForm');
  //console.log(data.id, form.Cod.value, form.Name.value)
  axios.post('/api/classifier/parent/edit',
    {
      table: table,
      id: data.id,
      //parent: parent,
      code: form.Cod.value,
      name: form.Name.value,
      //nameevent: form.NameEvent.value ?  form.NameEvent.value : '',
      //codeevent: form.CodEvent.value ?  form.CodEvent.value : '',
    }
  )
  .then(function(response){
    //console.log(props.del)
    console.log(response)
    if(response.status === 200){
    toast['success']("Позиция сохранена")
    //console.log("ed");
    //setRight(response.data);
    onClose();
    }else if(response.status === 201){
      toast['error']("Код " + form.Cod.value  + "  существует")
    }
  })


}



  return (

      <Modal
      open= {showModal}
      title={"Редактирование позиции"}
      //onOk={okSave}
      //modalRender={true}
      destroyOnClose={true}
      onCancel={onClose}
      width={800}
      height={300}
      footer={false}
      >
      <form id="editParentForm" preserve={false}>
        <table>
          <tr>
            <th width={'20%'} style={{textAlign: 'center', "padding-top": "10px"}}>
              Код
            </th>
            <th style={{textAlign: 'center', "padding-top": "10px"}}>
             Наименование раздела
            </th>
          </tr>
          <tr >
            <td align="center" style={{"padding-top": "10px"}}>

              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Код"}
                    id={'Cod'}
                    defaultValue={data.code}
                    //formik={formik.values.Cod}

                    name= {'Сod'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>



            </td>
            <td style={{"padding-top": "10px"}}>
              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Код"}
                    id={'Name'}
                    defaultValue={data.name}
                    //value={""}
                    //formik={formik.values.Cod}

                    name= {'Name'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>

            </td>
          </tr>
          </table>


        <Styled.Buttons jContent={'right'}>
        <Button
              variant={'secondary'}
              //type={'submit'}
              onClick={okSave}


              //disabled={!isDisabled}
            >
              Изменить
            </Button>
        </Styled.Buttons>
        </form>

      </Modal>

  )


}

import React from 'react';

import * as Styled from './styled';


export default function Button(props) {
  const {
    isRender = true,
    children,
    disabled,
    variant,
    onClick,
    ...styled
  } = props;


  if (!isRender || !children) {
    return null
  }

  return (
    <Styled.Button
      {...styled}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      type={'button'}
    >
      {children}
    </Styled.Button>
  )
}
import React from 'react';

import { useBoolean } from '../../../../hooks';
import EvaluationCard from '../../../blocks/riskAssessmentCard/components/evaluationCard';
import { useRiskAssessmentEvaluationCard } from '../hazardAssessment/hooks';

import ClarifyTrainingInfoModal from './components/clarifyTrainingInfo';
import TrainingTable from './components/table';
import { useTraining } from './hooks';
import * as Styled from './styled';

const Training = ({ project, workplace }) => {
  const [showInfo, onShowInfo] = useBoolean(false);
  const { loading, data, info, onChangeInfo, onPostTrainingSave, onPostTrainingClarify, onPostTrainingClarifySave } =
    useTraining({ workplace });

  const { formikEvaluationCard } = useRiskAssessmentEvaluationCard({ project, workplace });

  const onOpenInfoModal = () => {
    onPostTrainingClarify();
    onShowInfo.on();
  };

  return (
    <>
      <EvaluationCard formik={formikEvaluationCard} project={project} workplace={workplace} />
      <Styled.EvaluationCardEx>
        <Styled.ActionButton size="large" onClick={onOpenInfoModal}>
          Уточнить информацию
        </Styled.ActionButton>
      </Styled.EvaluationCardEx>
      <TrainingTable data={data} loading={loading} onSave={onPostTrainingSave} />
      <ClarifyTrainingInfoModal
        loading={loading}
        show={showInfo}
        data={info}
        onShow={onShowInfo}
        onChangeInfo={onChangeInfo}
        onPostTrainingClarifySave={onPostTrainingClarifySave}
      />
    </>
  );
};

export default Training;

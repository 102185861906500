import styled from 'styled-components';

import Button from '../../../../../atoms/nButton';

export const WorkplaceListText = styled.div`
  margin-left: 35px;
  padding-right: 15px;
  background-color: #fff;
  color: #101331;
  font-size: 16px;
  line-height: 48px;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
`
export const WorkplaceNumberText = styled.div`
  margin-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  color: #101331;
  font-size: 16px;
  line-height: 48px;
  width: 100%;
  box-sizing: border-box;
`

export const Border = styled.div`
  border:  2px solid ${props => props.active ? '#1E84EB' : 'transporent'};
`
export const IconButtons = styled.div`
  display: flex;
`

export const IconButton = styled(Button)`
  margin: 0 10px 0 0;
`

import React, { useCallback, useState } from 'react';
import Chart from 'react-apexcharts';
import { DotChartOutlined } from '@ant-design/icons';
import { Empty, Skeleton } from 'antd';
import { isEmpty } from 'lodash';

import { useChartIdentifiedHazards } from '../../../../../graphql/queries/useChartIdentifiedHazards';
import TypeButton from '../typeButton';

import '../charts.scss';
import './styles.scss';

const buttons = [
  {
    label: 'Все',
    type: 0,
  },
  {
    label: 'Высокий',
    type: 5,
  },
  {
    label: 'Значительный',
    type: 4,
  },
  {
    label: 'Средний',
    type: 3,
  },
  {
    label: 'Умеренный',
    type: 2,
  },
  {
    label: 'Низкий',
    type: 1,
  },
];

const ChartIdentifiedHazards = ({ projectId }) => {
  const [type, setType] = useState(0);

  const { data, loading } = useChartIdentifiedHazards({ id: projectId, nn: type });

  const onSelectType = useCallback((val) => setType(val), []);

  const series = [
    {
      data: data?.map((el) => el.cnt),
    },
  ];

  const options = {
    chart: {
      width: 380,
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data?.map((el) => el.val),
    },
  };

  return (
    <div className="chart-block indent-hazard">
      <h4 className="chart-block__title">Идентифицированные опасности</h4>
      <div className="indent-hazard__wrap">
        <div className="chart-block__chart">
          {loading ? (
            <Skeleton.Node active={true}>
              <DotChartOutlined style={{ fontSize: 150, color: '#bfbfbf' }} />
            </Skeleton.Node>
          ) : isEmpty(data) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Chart options={options} width={390} height={230} series={series} type="bar" />
          )}
        </div>
        <div className="chart-block__actions-col">
          {buttons.map((el) => (
            <TypeButton key={el.type} type={el.type} label={el.label} currentType={type} callback={onSelectType} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChartIdentifiedHazards;

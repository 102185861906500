import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';

import * as Styled from './styled';

const CellNumber = ({ record, onSave }) => {
  const [state, setState] = useState(record.internship_amt);

  useEffect(() => {
    setState(record.internship_amt);
  }, [record.internship_amt]);

  const handleChange = (value) => {
    setState(value);
    onSave({
      training_type: record.training_type,
      periodicity: value,
      id: record.id,
    });
  };

  return (
    <Styled.InputWrap>
      <InputNumber value={state} onChange={handleChange} />
      <span>{record.periodicity}</span>
    </Styled.InputWrap>
  );
};

export default CellNumber;

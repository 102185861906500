import React from 'react';

import Dialog from '../../blocks/dialog';
import Portal from '../../blocks/portal';
import OrganizationDataForm from '../../formik/forms/organizationDataForm';
import UserInfoForm from '../../formik/forms/UserInfoForm';

export default function EditUserInfo(props) {
  const { formik, isShowUserWiew, isShowOrganizationData, onPostOrganizationData, onPostViewEditSave, dataRoles } =
    props;

  const [showUserWiew, onShowUserWiew] = isShowUserWiew ?? [];
  const [showOrganizationData, onShowOrganizationData] = isShowOrganizationData ?? [];

  const onCloseUserWiew = () => {
    onShowUserWiew.off();
  };

  const onCloseOrganizationData = () => {
    onShowOrganizationData.off();
  };

  return (
    <form>
      <Portal id={'infoUsers'}>
        <Dialog
          isRender={showUserWiew}
          isClose={true}
          title={'ДАННЫЕ ПОЛЬЗОВАТЕЛЯ'}
          onClose={onCloseUserWiew}
          settings={{
            title: {
              size: 14,
              fWeight: 600,
              lHeight: 17,
              align: 'left',
              margin: '2px -20px 17px',
            },
            close: {
              height: 20,
              width: 20,
              right: '8px',
              top: '8px',
            },
          }}
        >
          <UserInfoForm
            formik={formik}
            onShowUserWiew={onShowUserWiew}
            onShowOrganizationData={onShowOrganizationData}
            onPostOrganizationData={onPostOrganizationData}
            dataRoles={dataRoles}
          />
        </Dialog>
      </Portal>
      <Portal id={'organizationData'}>
        <Dialog
          isRender={showOrganizationData}
          isClose={true}
          title={'ДАННЫЕ ОРГАНИЗАЦИИ'}
          onClose={onCloseOrganizationData}
          settings={{
            title: {
              size: 14,
              fWeight: 600,
              lHeight: 17,
              align: 'left',
              margin: '2px -20px 17px',
            },
            close: {
              height: 20,
              width: 20,
              right: '8px',
              top: '8px',
            },
          }}
        >
          <OrganizationDataForm formik={formik} onSubmitOrganizationData={onPostViewEditSave} />
        </Dialog>
      </Portal>
    </form>
  );
}

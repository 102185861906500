import styled from 'styled-components';

import * as status from './helpers';

function getColorStatus(color) {
  return (type) => {
    return color[type] || '';
  }
}

const typeColor = getColorStatus(status.color);

export const Status = styled.span`
  font-family: GolosTextWebVF, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 18px;
  color: ${props => typeColor(props.type)};
  white-space: nowrap;
`
import Matrices from './OrganizationMatrix/Matrices'
import HeaderOrganization from './HeaderOrganization'

function OrganizationMatrix() {
    return (
      <>
          <HeaderOrganization/>
          <main>
              <Matrices/>
          </main>
      </>
    )
}

export default OrganizationMatrix

import React from 'react';
import {WarningOutlined} from '@ant-design/icons';
import {Modal} from 'antd';

import Delete from '../../../../../../assets/svg/delete';
import Edit from '../../../../../../assets/svg/edit';

import * as Styled from './styled';

export default function TitleEdit(props) {
  const {
    formik,
    onDelete,
    onShowTitleEdit,
  } = props;
  const [modal, contextHolder] = Modal.useModal();
  const {value} = formik.getFieldProps('title.name') ?? {};
  const {value: structure} = formik.getFieldProps('title') ?? {};

  const handleDelete = () => {

    modal.confirm({
      title: 'Вы действительно хотите удалить подразделение?',
      icon: <WarningOutlined style={{ color: '#D73C1D' }} />,
      okText: 'Да',
      cancelText: 'Отменить',
      onOk: () => {
        onDelete(structure);
        formik.setFieldValue('title', {});
        formik.setFieldValue('right', []);
      },
      confirmLoading: true,
    });
  };

  const handleEdit = () => {
    onShowTitleEdit.on();
  };

  return (
    <React.Fragment>
      <Styled.TitleEditFlex
        wrap={'nowrap'}
        aItems={'center'}
        jContent={'center'}
      >
        <Styled.IconButtonEdit
          variant={'link'}
          onClick={handleEdit}
        >
          {value?.length ?  <Edit /> : ''}
        </Styled.IconButtonEdit>
        <Styled.Text>{value ?? ''}</Styled.Text>
        <Styled.IconButtonDelete
          variant={'link'}
          onClick={handleDelete}
        >
          {value?.length ?  <Delete /> : ''}
        </Styled.IconButtonDelete>
      </Styled.TitleEditFlex>
      {contextHolder}
    </React.Fragment>

  )
}

export const navMap = [
  {
    id: '1',
    path: '/information/regulatory',
    label: 'Нормативные документы',
  },
  {
    id: '2',
    path: '/information/manual',
    label: 'Руководство пользователя',
  },
];

import React from 'react';

import Search from '../../../assets/svg/search';

import * as Styled from './styled';

export default function InputSearch(props) {

  const {
    value,
    onChange,
    type = 'text',
    disabled,
    placeholder,
    padding,
    margin,
    width,
    ...styled
  } = props;

  return (
    <Styled.Search
      padding={padding}
      width={width}
      margin={margin}
    >
      <Styled.Input
        {...styled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        disabled={disabled}
      />
      <Styled.Icon>
        <Search />
      </Styled.Icon>
    </Styled.Search>
  );
}


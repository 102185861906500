import React from 'react';
import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export function usePostProjectRiskassessment({ project, formik, onShowQuantityEdit, onIsRenderSpinner, onFetchTable }) {
  React.useEffect(() => {
    onPostProjectRiskassessment({ page: 1, pageSize: 50 });
  }, []);

  const [loadingMeasuresExceptions, setLoadingMeasuresExceptions] = React.useState(0);

  const loadingOn = () => setLoadingMeasuresExceptions((prev) => prev + 1);
  const loadingOff = () => setLoadingMeasuresExceptions((prev) => prev - 1);

  const onPostMeasuresExceptions = async ({
    parent_id,
    project_id
  }) => {
    try {
      loadingOn();
      const { data } = await axios.post('/api/project/controlmeasures/exceptions', {
        parent_id,
        project_id,
      });

      if (data.status === 'success') {
        formik?.setFieldValue('measuresExceptions', data?.data);
        loadingOff();
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1702110785295',
        errorMessage: `${e.data?.msg}`,
      });
      loadingOff();
    }
  };
//console.log(formik)
  const onPostProjectRiskassessment = async ({ page, pageSize, field, sort }) => {
    try {
      onFetchTable.on();
      const { data } = await axios.post(`/api/project/riskassessment${page ? `?page=${page}` : ''}`, {
        project,
        page: pageSize ?? '',
        field: sort && field ? field : '',
        sort: sort ?? '',
      });
//console.log(1)
      if (data?.data) {
        formik?.setStatus('riskassessment');
        formik?.setFieldValue('data', data?.data);
        onFetchTable.off();
      }
    } catch (e) {
      onIsRenderSpinner.off();
      toast['error'](e.data?.msg);
      onFetchTable.off();

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostRiskassessmentNumber = async (value) => {
    try {
      onFetchTable.on();
      const { data } = await axios.post('/api/project/riskassessment/number', {
        ...value,
        project,
        page: '',
        field: '',
        sort: '',
      });

      if (data.status === 'success') {
        onFetchTable.off();
        const updatedData = formik.values.data.data.map((el) => {
          if (el.id === value.workplace) {
            return {
              ...el,
              quantity: value.quantity,
            };
          }
          return {
            ...el,
          };
        });

        toast['success'](data?.msg);
        formik?.setFieldValue('data.data', updatedData);
      }
    } catch (e) {
      onFetchTable.off();
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    } finally {
      formik.setFieldValue('editQuantity', null);
      onShowQuantityEdit.off();
    }
  };

  const onPostRiskassessmentFind = (current) => async (value) => {
    try {
      onFetchTable.on();
      const { data } = await axios.post(`/api/project/riskassessment/find${current ? `?page=${current}` : ''}`, {
        value,
        project,
      });

      if (data.status === 'success') {
        onFetchTable.off();
        toast['success'](data?.msg);
        formik?.setStatus('riskassessmentFind');
        formik?.setFieldValue('data', data?.data);
      }
    } catch (e) {
      onFetchTable.off();
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    }
  };

  const onAutomaticEvaluation = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/project/fillrisk/?project_id=${project}&bearer=${token}`);

      console.log('onAutomaticEvaluation', data);

      if (data.status === 'Автоматическая оценка завершена') {
        onPostProjectRiskassessment(formik.values.tableParams);
        toast['success'](data?.status);
      }
    } catch (e) {
      toast['error'](e.data?.msg);
      logWarn({
        errorNumber: '1689182252015',
        errorMessage: `${e.data?.msg}`,
      });
    } finally {
      onIsRenderSpinner.off();
    }
  };

  return {
    dataRows: formik?.values?.data,
    onPostProjectRiskassessment,
    onPostRiskassessmentNumber,
    onPostRiskassessmentFind,
    onAutomaticEvaluation,
    onPostMeasuresExceptions,
    loadingMeasuresExceptions
  };
}

export function usePostRiskassessmentDelete({ project, onPostProjectRiskassessment, onPostProject, tableParams }) {
  const onPostRiskassessmentDelete = async (workplace) => {
    try {
      const { data } = await axios.post('/api/project/riskassessment/delete', {
        project,
        workplace,
      });

      if (data.status === 'success') {
        onPostProjectRiskassessment(tableParams);
        onPostProject();
      }
    } catch (e) {
      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685378212184',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    onPostRiskassessmentDelete,
  };
}

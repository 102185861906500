import React, { useEffect, useState } from "react";
// import { AddressSuggestions } from "react-dadata";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

import nsiAxios from "../../../../../../axios/nsiAxios";
import { dictionaryTypes } from "../../../../../../Components/core/appDictionaryTypes";
import {
  createEmployer,
  employerStructure,
  updateEmployer,
} from "../../../../../../store/actions/employersActions";
import { ROLE_EXPERT } from "../../../../../../store/constants";
import { getUserRole } from "../../../../../../store/selectors/";
import AppButton from "../../../../../core/AppButton";
import Input from "../../../../../input";
import ModalFIAS from "../../../../MenuRating/ModalFIAS";
import UploadStructureModal from "../UploadStructureModal";

import AddStructure from "./addStructure";

import "./generalInformation.css";

function ModalObshieSvedeniyaObOrganiz({ setVisible }) {
  const nsiDictionary = useSelector((state) => state.NSIReducer);
  const getDataOrganization = useSelector(
    (state) => state.employersReducer.employer
  );
  const [structureFile, setStructureFile] = useState("");
  const [jobTitleFile, setJobTitle] = useState("");
  const [organizer, setOrganization] = useState(getDataOrganization);
  const [selectedOkved, setSelectedOkved] = useState(false);
  const [disabled, setDisabled] = useState("btn save-button ");
  const [inpActive] = useState("form-control rounded-0 active");
  const [inp] = useState("form-control rounded-0");

  const [showStructureModal, setShowStructureModal] = useState(false);

  const dispatch = useDispatch();
  const userRole = useSelector((state) => getUserRole(state.authReducer));
  const isCardDisabled = userRole === ROLE_EXPERT;

  const getOkved = () => {
    const url = `/dictionary/data/?id=${dictionaryTypes.okved}&rowId=${organizer.okved}`;

    nsiAxios.get(url).then((res) => {
      if (res?.data[0]) {
        const val = res.data[0];

        if (!val || !val.classOKVED || !val.economicActivity) {
          return;
        }

        val.classOKVED = val.classOKVED.trim();

        const selected = nsiDictionary.okved[0].length
          ? nsiDictionary.okved[0].find(
              (item) => item.classOKVED.trim() === val.classOKVED
            )
          : false;
        if (selected) {
          setSelectedOkved(selected);
        } else {
          val.codeName = val.classOKVED + " " + val.economicActivity;
          setSelectedOkved(val);
          nsiDictionary.okved[0].unshift(val);
        }
      }
    });
  };

  useEffect(() => {
    const selected = nsiDictionary?.okved?.length
      ? nsiDictionary.okved[0].find((item) => item.id === organizer.okved)
      : false;

    if (selected) {
      setSelectedOkved(selected);
    } else if (organizer.okved) {
      getOkved();
    }
  }, [nsiDictionary.length, organizer.okved]);

  useEffect(() => {
    setOrganization(getDataOrganization);
  }, [getDataOrganization]);

  useEffect(() => {
    if (organizer.id) {
      dispatch(employerStructure(organizer.id));
    }
  }, [organizer.id]);

  useEffect(() => {}, [structureFile]);

  useEffect(() => {
    if (setVisible === false) {
      setJobTitle("");
      setStructureFile("");
    }
  }, [setVisible]);

  function addFormGeneral(e) {
    e.preventDefault();
    if (validInput()) {
      if (organizer.id) {
        dispatch(updateEmployer(organizer, structureFile, jobTitleFile));
      } else {
        dispatch(createEmployer(organizer));
      }
    }
  }

  function validInput() {
    if (
      organizer.fullName.length > 0 &&
      organizer.shortName.length > 0 &&
      (organizer.inn.length === 12 || organizer.inn.length === 10) &&
      organizer.okpo.length >= 8 &&
      organizer.ogrn.length === 13 &&
      organizer.okved.length > 0 &&
      (organizer.oktmo.length === 8 || organizer.oktmo.length === 11) &&
      organizer.kpp.length === 9 &&
      organizer.chiefJobTitle.length > 3 &&
      organizer.chiefFullName.length > 3
    ) {
      setDisabled("btn save-button");
      setVisible(false);
      return true;
    } else {
      setVisible(true);
      setDisabled("btn save-button");
      alert("Заполните все поля");
      return false;
    }
  }

  // Добавление и изменение адреса

  const [openModalFIAS, setOpenModalFIAS] = useState(false);
  const [typeAdress, setTypeAdress] = useState("");

  function openFIASreal(e) {
    e.preventDefault();
    setOpenModalFIAS(true);
    setTypeAdress("ra");
  }
  function openFIASlegal(e) {
    e.preventDefault();
    setOpenModalFIAS(true);
    setTypeAdress("la");
  }

  const reText = /^[А-ЯЁA-Za-zа-яё0-9.№—"\s-]+$/;
  const reNumb = /^[0-9]+$/;
  const reName = /^[А-ЯЁа-яё.—\s-]+$/;

  const makeAddressString = (status) => {
    const addressObj = organizer?.addresses?.find((el) => el.status === status);

    if (!addressObj) {
      return "";
    }

    const addressKeys = [
      "postalCode",
      "regionName",
      "districtName",
      "localityName",
      "cityName",
      "streetName",
      "house",
      "building",
    ];
    let addressString = " ";

    addressKeys.forEach(
      (k) => (addressString += addressObj[k] ? addressObj[k] + ", " : "")
    );

    return addressString.slice(0, -2);
  };

  const handleChangeNumInput = (e) => {
    const { name, value } = e.target;
    setOrganization({
      ...organizer,
      [name]: reNumb.test(value) ? value : "",
    });
  };

  return (
    <>
      {openModalFIAS ? (
        <ModalFIAS
          visibleFIAS={openModalFIAS}
          setVisibleFIAS={setOpenModalFIAS}
          typeAdress={typeAdress}
          create={setOrganization}
          organization={organizer}
        />
      ) : (
        <></>
      )}
      <div className="modal-dialog modal-dialog-centered modalWidthObshieSvedeniya">
        <div className="modal-content rounded-0">
          <div className="modal-body">
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label
                    htmlFor="user-name"
                    className="col-form-label grey-text-modal-user"
                  >
                    Полное наименование предприятия* - обязательное поле
                  </label>
                  <input
                    value={organizer.fullName}
                    disabled={isCardDisabled}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (reText.test(e.target.value) &&
                          e.target.value.length <= 300)
                      ) {
                        setOrganization({
                          ...organizer,
                          fullName: e.target.value,
                        });
                      } else {
                        alert(
                          "Допускаются цифры, русские и латинские буквы, некоторые символы"
                        );
                      }
                    }}
                    type="text"
                    autoComplete="off"
                    className={organizer.fullName.length ? inp : inpActive}
                    id="user-name"
                    placeholder="Общество с ограниченной ответственностью Компания"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mb-3 col-5 ">
                    <label
                      htmlFor="name-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      Краткое наименование предприятия* - обязательное поле
                    </label>
                    <input
                      value={organizer.shortName}
                      disabled={isCardDisabled}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reText.test(e.target.value) &&
                            e.target.value.length <= 100)
                        ) {
                          setOrganization({
                            ...organizer,
                            shortName: e.target.value,
                          });
                        } else {
                          alert(
                            "Допускаются цифры, русские и латинские буквы, некоторые символы"
                          );
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.shortName.length ? inp : inpActive}
                      id="name-company"
                      placeholder="ООО Компания"
                    />
                  </div>
                  <div className="mb-3 col-2">
                    <label
                      htmlFor="inn-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      ИНН* - обязательное поле
                    </label>
                    <input
                      value={organizer.inn}
                      disabled={isCardDisabled}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reNumb.test(e.target.value) &&
                            e.target.value.length <= 12)
                        ) {
                          setOrganization({
                            ...organizer,
                            inn: e.target.value,
                          });
                        } else {
                          alert(
                            "ИНН должен состоять только из чисел, введите 10 или 12 знаков"
                          );
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={
                        organizer.inn.length === 12 ||
                        organizer.inn.length === 10
                          ? inp
                          : inpActive
                      }
                      id="inn-company"
                      placeholder="1234567891"
                    />
                  </div>
                  <div className="mb-3 col-2">
                    <label
                      htmlFor="okpo-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      ОКПО
                    </label>
                    <input
                      value={organizer.okpo}
                      disabled={isCardDisabled}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reNumb.test(e.target.value) &&
                            e.target.value.length <= 10)
                        ) {
                          setOrganization({
                            ...organizer,
                            okpo: e.target.value,
                          });
                        } else {
                          alert(
                            "ОКПО должен состоять только из чисел, введите от 8 до 10 знаков"
                          );
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.okpo.length >= 8 ? inp : inpActive}
                      id="okpo-company"
                      placeholder="1234567891"
                    />
                  </div>
                  <div className="mb-3 col-2">
                    <label
                      htmlFor="ogrn-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      ОГРН* - обязательное поле
                    </label>
                    <input
                      value={organizer.ogrn}
                      disabled={isCardDisabled}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reNumb.test(e.target.value) &&
                            e.target.value.length <= 13)
                        ) {
                          setOrganization({
                            ...organizer,
                            ogrn: e.target.value,
                          });
                        } else {
                          alert(
                            "ОГРН должен состоять только из чисел, введите 13 знаков"
                          );
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.ogrn.length === 13 ? inp : inpActive}
                      id="ogrn-company"
                      placeholder="1234567891011"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="mb-3 col-5">
                    <label
                      htmlFor="okved-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      ОКВЭД* - обязательное поле
                    </label>
                    {isCardDisabled ? (
                      <select
                        disabled={true}
                        className={organizer.okved.length ? inp : inpActive}
                        id="okved-company"
                      >
                        {selectedOkved ? (
                          <option selected>{selectedOkved.codeName}</option>
                        ) : (
                          <option></option>
                        )}
                      </select>
                    ) : (
                      <Multiselect
                        className={
                          organizer.okved
                            ? "my-multiselect multiselect-border multiselect-height "
                            : "my-multiselect multiselect-border multiselect-height b-red"
                        }
                        options={
                          nsiDictionary.okved
                            ? nsiDictionary.okved[0]
                            : [
                                {
                                  codeName: "Данных нет",
                                },
                              ]
                        }
                        caseSensitiveSearch={false}
                        selectedValues={selectedOkved ? [selectedOkved] : null}
                        selectionLimit="1"
                        closeIcon="close"
                        hidePlaceholder={true}
                        onSelect={(e, s) => {
                          setOrganization({
                            ...organizer,
                            okved: s.id,
                          });
                          setSelectedOkved(s);
                        }}
                        onRemove={() => {
                          setOrganization({
                            ...organizer,
                            okved: "",
                          });
                          setSelectedOkved("");
                        }}
                        displayValue="codeName"
                        placeholder="ОКВЭД"
                      />
                    )}
                  </div>
                  <div className="mb-3 col-2">
                    <label
                      htmlFor="oktmo-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      ОКТМО
                    </label>
                    <input
                      value={organizer.oktmo}
                      disabled={isCardDisabled}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reNumb.test(e.target.value) &&
                            e.target.value.length <= 11)
                        ) {
                          setOrganization({
                            ...organizer,
                            oktmo: e.target.value,
                          });
                        } else {
                          alert(
                            "ОКТМО должен состоять только из чисел, 8 или 11 знаков"
                          );
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={
                        organizer.oktmo.length === 11 ||
                        organizer.oktmo.length === 8
                          ? inp
                          : inpActive
                      }
                      id="oktmo-company"
                      placeholder="1234567891"
                    />
                  </div>

                  <Input
                    id="okogu-company"
                    label="ОКОГУ"
                    className="mb-3 col-2"
                    name="okogu"
                    placeholder="1234567891"
                    value={organizer.okogu}
                    disabled={isCardDisabled}
                    onChange={handleChangeNumInput}
                    maxLength={10}
                    autoComplete="off"
                  />
                  <div className="mb-3 col-2">
                    <label
                      htmlFor="kpp-company"
                      className="col-form-label grey-text-modal-user"
                    >
                      КПП* - обязательное поле
                    </label>
                    <input
                      value={organizer.kpp}
                      disabled={isCardDisabled}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reNumb.test(e.target.value) &&
                            e.target.value.length <= 9)
                        ) {
                          setOrganization({
                            ...organizer,
                            kpp: e.target.value,
                          });
                        } else {
                          alert(
                            "КПП должен состоять только из чисел, введите 9 знаков"
                          );
                        }
                      }}
                      type="text"
                      autoComplete="off"
                      className={organizer.kpp.length === 9 ? inp : inpActive}
                      id="kpp-company"
                      placeholder="123456789"
                    />
                  </div>
                </div>
                <div className="mb-5 d-flex col-5 justify-content-between">
                  <Input
                    id="okato-company"
                    name="okato"
                    className="mb-3 mr-20"
                    label="ОКАТО"
                    placeholder="12345678901"
                    value={organizer.okato}
                    disabled={isCardDisabled}
                    onChange={handleChangeNumInput}
                    maxLength={11}
                    autoComplete="off"
                  />
                  <Input
                    id="total-employees"
                    name="total_employees"
                    className="mb-3"
                    label="Общее количество работников"
                    placeholder="123456"
                    value={organizer.total_employees}
                    disabled={isCardDisabled}
                    onChange={handleChangeNumInput}
                    maxLength={6}
                    autoComplete="off"
                  />
                </div>

                <div className="mb-5 d-flex justify-content-between">
                  <div className="col-6 pddng-rght-30">
                    <div className="mb-1">
                      <span className="class-title-adresss">
                        Фактический адрес
                      </span>
                      :{makeAddressString("ra")}
                    </div>
                    <div className="href-text">
                      <AppButton
                        className="button-transparent"
                        disabled={isCardDisabled}
                        onClick={openFIASreal}
                      >
                        {organizer.addresses[0]?.postalCode !== "" ||
                        organizer.addresses[0]?.districtName !== "" ||
                        organizer.addresses[0]?.regionName !== "" ||
                        organizer.addresses[0]?.cityName !== "" ||
                        organizer.addresses[0]?.localityName ||
                        organizer.addresses[0]?.streetName !== "" ||
                        organizer.addresses[0]?.streetName !== ""
                          ? "Изменить"
                          : "Добавить"}
                      </AppButton>
                    </div>
                  </div>

                  <div className="col-6 pl-10">
                    <div className="mb-1">
                      <span className="class-title-adresss">
                        Юридический адрес
                      </span>
                      : {makeAddressString("la")}
                    </div>
                    <div className="href-text">
                      <AppButton
                        className="button-transparent"
                        disabled={isCardDisabled}
                        onClick={openFIASlegal}
                      >
                        {(organizer.addresses &&
                          organizer.addresses.length > 0 &&
                          organizer.addresses[1].postalCode !== "") ||
                        organizer.addresses[1].districtName !== "" ||
                        organizer.addresses[1].regionName !== "" ||
                        organizer.addresses[1].cityName !== "" ||
                        organizer.addresses[1].localityName ||
                        organizer.addresses[1].streetName !== "" ||
                        organizer.addresses[1].streetName !== ""
                          ? "Изменить"
                          : "Добавить"}
                      </AppButton>
                    </div>
                  </div>
                </div>
                {organizer.id && organizer.id !== null ? (
                  <div className="d-flex justify-content-between">
                    {getDataOrganization?.structure?.length > 0 ? (
                      <>
                        <button
                          disabled={isCardDisabled}
                          type="button"
                          className="btn btn-link"
                          onClick={() => setShowStructureModal(true)}
                        >
                          Добавить должности
                        </button>
                        <UploadStructureModal
                          show={showStructureModal}
                          onHide={() => setShowStructureModal(false)}
                        />
                      </>
                    ) : (
                      <div className="d-flex align-items-center mb-3 col-6">
                        Структура
                        <AddStructure
                          classBtn={
                            "href-text pddng-lft-25 href-text href_as_button d-none"
                          }
                          dataStructu={{}}
                        />
                        <div className="pddng-lft-20" />
                        <div className="pddng-lft-20">
                          <form>
                            <label
                              htmlFor="filePicker"
                              className="btn download-button"
                              style={{
                                height: "auto",
                                width: "auto",
                                paddingRight: 12,
                              }}
                            >
                              {structureFile ? structureFile.name : "Загрузить"}
                            </label>
                            <input
                              disabled={isCardDisabled}
                              id="filePicker"
                              style={{
                                visibility: "hidden",
                                display: "none",
                              }}
                              type={"file"}
                              accept=".csv"
                              multipart
                              onChange={(e) =>
                                setStructureFile(e.target.files[0])
                              }
                            />
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="d-flex justify-content-between">
                  <div className="mb-3 col-6">
                    <label
                      htmlFor="manager-position"
                      className="col-form-label grey-text-modal-user"
                    >
                      Руководитель
                    </label>
                    <input
                      disabled={isCardDisabled}
                      value={organizer.chiefJobTitle}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reName.test(e.target.value) &&
                            e.target.value.length <= 30)
                        ) {
                          setOrganization({
                            ...organizer,
                            chiefJobTitle: e.target.value,
                          });
                        } else {
                          alert(
                            "Допускаются цифры, русские и латинские буквы, некоторые символы"
                          );
                        }
                      }}
                      type="text"
                      className={
                        organizer.chiefJobTitle.length > 3 ? inp : inpActive
                      }
                      autoComplete="off"
                      id="manager-position"
                      placeholder="Должность"
                    />
                  </div>
                  <div className="mb-3 col-6 pl-10">
                    <label
                      htmlFor="manager-name"
                      className="col-form-label position-relative grey-text-modal-user"
                    >
                      ФИО
                    </label>
                    <input
                      disabled={isCardDisabled}
                      value={organizer.chiefFullName}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (reName.test(e.target.value) &&
                            e.target.value.length <= 30)
                        ) {
                          setOrganization({
                            ...organizer,
                            chiefFullName: e.target.value,
                          });
                        } else {
                          alert(
                            "Допускаются цифры, русские и латинские буквы, некоторые символы"
                          );
                        }
                      }}
                      autoComplete="off"
                      name="fio1"
                      type="text"
                      className={
                        organizer.chiefFullName.length > 3 ? inp : inpActive
                      }
                      id="manager-name"
                      placeholder="ФИО"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0">
              <button
                disabled={isCardDisabled}
                type="button"
                className={disabled}
                onClick={addFormGeneral}
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalObshieSvedeniyaObOrganiz;

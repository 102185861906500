import {AUTH_LOGOUT} from "../actionTypes/loginTypes";
import * as actionTypes from '../actionTypes/projectTypes';

export const initialState = {
  sheduleWorks: [
  //   {
  //   id: 2,
  //   project_id: null,
  //   date: 3,
  //   work_stage: null,
  //   employer_structure: null,
  //   value: null,
  //   comment: null,
  //   created_at: null,
  //   updated_at: null,
  //   deleted_at: null,
  // }
]
}


export function sheduleWorksReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SHEDULE_WORKS:
    return {...state, sheduleWorks: action.payload}

    case actionTypes.ADD_SHEDULE_WORKS:
      return {
        ...state, sheduleWorks: action.payload,
      };

      case actionTypes.REMOVE_SCHEDULE_WORKS: {
          const id = action.payload;
          return {
              ...state, sheduleWorks: state.sheduleWorks.filter(schedule => schedule.id !== id)
          }
      }
      
    case actionTypes.CLEAR_SHEDULE_WORKS: 
      return initialState;

    case AUTH_LOGOUT:
      return initialState

    default:
      return state
  }

}

import React from 'react';

import * as Styled from './styled';

export default function List(props) {
  const {
    children,
    ...styled
  } = props;


  if (!children) {
    return null
  }

  return (
    <Styled.List
      {...styled}
    >
      {React.Children.toArray(children).map((child, index) => (
        <Styled.Li
          key={index}
        >
          {child}        
        </Styled.Li>
      ))}
    </Styled.List>
  )
}
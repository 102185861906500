import styled from 'styled-components';

import Settings from '../../../assets/svg/settings';
import Button from '../../atoms/nButton';

export const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  color: #101331;
  white-space: nowrap;
  overflow: auto;
`;

export const Account = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NameUser = styled.span`
  font-family: "GolosTextWebVF", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  display: block;
  position: relative;
  top: -3px
`;

export const SvgAnimate = styled(Settings)`
  &:hover {
    transform: scale(0.95);
  }
`

export const SettingsButton = styled(Button)`
  padding: 0;
  border: 0;
  background: transparent;
  display: inline;
  margin-left: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  &:hover {
    background: #B5B9C1;
    position: relative;
  }
`;


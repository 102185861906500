const color = {
  0: '#E3366A',
  1: '#A8AB19',
  2: '#51AD08',
}

const name = {
  0: 'Не оценено',
  1: 'В работе',
  2: 'Завершено',
}

export {
  color,
  name
}
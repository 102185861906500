import React from "react";
import {Table, Tooltip} from 'antd';

import * as Cells from "../../../../blocks/tableCells";
import RemoveButtonWithConfirm from "../../../../removeButtonWithConfirm";
import * as Fields from "../fields";

const ProfRiskTable = ({loading, data, formik, actions}) => {

  const columns = [
    {
      key: 'number',
      title: 'Номер',
      dataIndex: 'number',
      sorter: true,
      render: (number) => number
    },
    {
      key: 'workplace',
      title: 'Наименование рабочего места',
      sorter: true,
      render: (record) => {
        return (
          <Cells.Workplace
            id={record?.id}
            type={record?.workplace_type}
            workplace={record?.workplace}
            onIsRenderCard={actions.onIsRenderCard}
            onRowId={actions.setRowId}
            onShowInfoOrganization={actions.onShowInfoOrganization}
          />
        )
      }
    },
    {
      key: 'structure',
      title: 'Структурное подразделение',
      sorter: true,
      render: (record) => {
        return (
          <Tooltip title={record.parent}>
            {record?.structure}
          </Tooltip>
        )
      }
    },
    {
      key: 'quantity',
      title: 'Количество работников',
      sorter: true,
      render: (text, record, index) => {
        return (
          <Fields.Quantity
            onShowQuantityEdit={actions.onShowQuantityEdit}
            formik={formik}
            name={`data.data.${index}`}
          >
            {record?.quantity}
          </Fields.Quantity>
        )
      }
    },
    {
      key: 'date',
      title: 'Дата изменения',
      sorter: true,
      render: (record) => record.date
    },
    {
      key: 'risk_code',
      title: 'Уровень риска',
      sorter: true,
      render: (record) => {
        return (
          <Cells.RiskLevel
            type={record?.risk_code}
            name={record.risk}
          />
        )
      }
    },
    {
      key: 'status',
      title: 'Статус',
      sorter: true,
      render: (record) => {
        return (
          <Cells.Status
            type={record?.status}
          />
        )
      }
    },
    {
      title: '',
      render: (record) => <RemoveButtonWithConfirm itemId={record.id} onClick={actions.onPostRiskassessmentDelete} />
    }
  ]




  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading}
      columns={columns}
      dataSource={data?.data}
      onChange={actions.onChangeTable}
      pagination={{
        defaultPageSize: 20,
        current: data.current_page,
        total: data.total
      }}

    />
  )
}

export default ProfRiskTable

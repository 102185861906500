import styled, { css } from 'styled-components';

import Flex from '../../../../atoms/flex';
import Input from '../../../../atoms/input';
import Button from '../../../../atoms/nButton';

export const Buttons = styled(Flex)`
  margin-top: 26px;
  padding-bottom: 0px;
`;

export const InputText = styled(Input)`
  background: #fff;
  font-size: 16px;
  line-height: 22px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }
`;
export const FieldLabel = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  padding: '0 0 12px';
  display: inline-block;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}}
`;
export const InputTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #18171C;
  border-radius: 8px;
  padding: ${(props) => props.padding ?? '10px 20px'};

  &:hover {
    border-color: #AAA;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: #014880;
  }`;

export const AddFieldsButton = styled(Button)`
font-size: 14px;
font-weight: 400;
line-height: 14px;
border: 0;
background: transparent;
color: #000;
padding: 0;
margin-bottom: 10px;
`;
export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #000;
  margin-left: 10px;
  margin-right: 10px;
`;
export const IconButton = styled(Button)`
  margin: 0 10px 0 0;
`


import { useFormikContext } from '../../formik/formik.context';

export default function DataView(props) {
  const { index, auxiliaryProps } = props;
  const formikProps = useFormikContext();
  const ifFormikProps = auxiliaryProps?.formikProps
    ? formikProps[auxiliaryProps?.formikProps]
    : formikProps;
  const { getFieldProps } = ifFormikProps ?? {};

  const { value } = getFieldProps(`data.${index}.date`) ?? '';

  return value.slice(0, 10).replaceAll('-', '/');
}

import React from 'react';

import Dialog from '../../../../../blocks/dialog';

import * as Styled from './styled';

const ClarifyTrainingInfoModal = ({ loading, show, data, onShow, onChangeInfo, onPostTrainingClarifySave }) => {
  const onSave = () => {
    onPostTrainingClarifySave();
    onShow.off();
  };

  return (
    <Dialog
      isRender={show}
      isClose={true}
      centered={true}
      onClose={onShow.off}
      title={'УТОЧНИТЬ ИНФОРМАЦИЮ ПО ОБУЧЕНИЮ'}
      width={660}
      destroyOnClose={true}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 20px',
        },
      }}
    >
      <Styled.Container>
        {data?.map((el) => {
          if (el.type === 'title') {
            return <Styled.Title key={el.id}>{el.text}</Styled.Title>;
          }
          if (el.type === 'checkbox') {
            return (
              <Styled.Checkbox key={el.id} checked={el.checked} onChange={(event) => onChangeInfo(el.id, event)}>
                <Styled.Text>{el.text}</Styled.Text>
              </Styled.Checkbox>
            );
          }
        })}
      </Styled.Container>
      <Styled.Footer>
        <Styled.ActionButton size="large" type="primary" loading={loading} onClick={onSave}>
          Сохранить
        </Styled.ActionButton>
      </Styled.Footer>
    </Dialog>
  );
};

export default ClarifyTrainingInfoModal;

// const data = [
//   {
//     id: 1,
//     type: 'title',
//     text: 'IV. Организация и проведение обучения по оказанию первой помощи пострадавшим',
//   },
//   {
//     id: 2,
//     type: 'checkbox',
//     text: 'some text',
//     checked: true,
//   },
// ];

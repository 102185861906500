import React from "react";

import Email from "../../../../assets/svg/email";
import Phone from "../../../../assets/svg/phone";
import Dialog from "../../../blocks/dialog";

import * as Styled from "./styled";

export default function EmailSupport({ isRender, onCloseDialog }) {
  return (
    <Dialog
      isRender={isRender}
      isClose={true}
      title={"Восстановление аккаунта"}
      onClose={onCloseDialog}
    >
      <Styled.P
        tagName={"p"}
        fSize={16}
        align={"center"}
        fWeight={400}
        lHeight={22}
        dangerouslyHTML={`Для вашей безопасности нам требуется получить<br />
          от вас информацию, которая известна только владельцу этой учетной записи.<br />
          Для этого обратитесь в нашу службу поддержки.<br />
          Это наилучший способ подтвердить ваши права
          <br />на учетную запись.`}
      />
      <Styled.H5Left
        tagName={"h5"}
        fSize={18}
        align={"center"}
        fWeight={600}
        lHeight={22}
      >
        Техническая поддержка
      </Styled.H5Left>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"left"}
        fWeight={400}
        lHeight={22}
      >
        <Styled.IconContacts>
          <Phone />
        </Styled.IconContacts>
          +7 (499) 164-98-64
      </Styled.Contacts>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"left"}
        fWeight={400}
        lHeight={22}
      >
        <Styled.IconContacts>
          <Email />
        </Styled.IconContacts>
         riskhelp@vcot.info
      </Styled.Contacts>
    </Dialog>
  );
}

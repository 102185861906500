import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { DotChartOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';

import { useChartLevelRisk } from '../../../../../graphql/queries/useChartLevelRisk';
import { cx } from '../../../../../utils';

import '../charts.scss';
import './styles.scss';

const options = {
  chart: {
    width: 380,
    type: 'donut',
  },
  labels: [
    'Низкий уровень риска',
    'Умеренный уровень риска',
    'Средний уровень риска',
    'Значительный уровень риска',
    'Высокий уровень риска',
  ],
  colors: ['#61D155', '#E8D041', '#F8BE5C', '#F1716E', '#BE3A3A'],
};

const ChartDistributionByLevelRisk = ({ projectId }) => {
  const [type, setType] = useState('hazard');

  const { data, loading } = useChartLevelRisk({ id: projectId, type: type === 'hazard' });

  const onSelectType = (val) => setType(val);

  const series = data?.map((el) => el.cnt) || [];

  return (
    <div className="chart-block dist-level-risk">
      <h4 className="chart-block__title">Распределение по уровням риска</h4>
      <div className="chart-block__actions">
        <Button
          size="small"
          type="text"
          className={cx({ 'active-type': type === 'hazard' })}
          onClick={() => onSelectType('hazard')}
        >
          опасностей
        </Button>
        <Button
          size="small"
          type="text"
          className={cx({ 'active-type': type === 'work' })}
          onClick={() => onSelectType('work')}
        >
          рабочих мест
        </Button>
      </div>
      <div className="chart-block__chart">
        {loading ? (
          <Skeleton.Node active={true}>
            <DotChartOutlined style={{ fontSize: 150, color: '#bfbfbf' }} />
          </Skeleton.Node>
        ) : isEmpty(data) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Chart options={options} width={390} height={230} series={series} type="donut" />
        )}
      </div>
    </div>
  );
};

export default ChartDistributionByLevelRisk;

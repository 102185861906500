import React from 'react';

import * as Fields from './fields';

export const getTableRows = (
  data,
  viewRecord,
) => {

  if (!data?.length) {
    return
  }

  return data?.map((item, index) => {
    
    return {
      key: index,
      cells: [
        {
          key: 'timestamp',
          content: <Fields.Timestamp
            align={'center'}
            timestamp={item?.timestamp}
          />,
        },
        {
          key: 'user.user_name',
          content: <Fields.NameIp
            name={item?.user?.user_name}
            ip={item?.ip}
          />,
        },
        {
          key: 'method',
          label: `${item?.method}: ${item?.endpoint}`,
          type: 'view',
          align: 'center',
        },
        {
          key: 'actions',
          width: 87,
          content: <Fields.Details
            item={item}
            viewRecord={viewRecord}
          />,
        }
      ],
    };
  });
};

export const tableHead = [
  {
    key: 'timestamp',
    label: 'Дата и время',
    type: 'header',
    align: 'center',
  },
  {
    key: 'user.user_name',
    label: 'Логин',
    type: 'header',
    align: 'center',
  },
  {
    key: 'method',
    label: 'Запрос',
    type: 'header',
    align: 'center',
  },
  {
    key: 'actions',
    label: '',
    width: 87,
    align: 'center',
  },
];
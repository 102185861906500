import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { employerClearSelectedStructure,employerStructure } from "../../../../../../store/actions/employersActions";
import { employerAddStructure } from "../../../../../../store/actions/employersActions";

import StructurePositionList from "./StructurePositionList";
import StructureTree from "./StructureTree";

import "./editStructurePosition.css";

function EditStructurePosition() {
    const employerId = useSelector((state) => state.employersReducer.employer.id);
    const structureList = useSelector((state) => state.employersReducer.structureNew);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (employerId) {
            dispatch(employerStructure(employerId));
            dispatch(employerClearSelectedStructure());
        }
    }, [employerId]);

    const onClickAddStructure = () => {
        let name = prompt('Введите имя подразделения', '');
    
        if (name) {
          dispatch(employerAddStructure({
            name, 
            employer: employerId, 
            parentId: null
          }));  
        }
      }

    return (
        <>
            {structureList?.length ? (
                <div className="edit-structure-position-wrapper">
                    <StructureTree structureList={structureList}/>
                    <StructurePositionList employerId={employerId} />
                </div>
                ) : <></>
            }
            {employerId && !structureList?.length ? (
                <div className="actions-structure-positions">
                    <button className="btn btn-actions-structure-positions add-button" onClick={onClickAddStructure}>Добавить подразделение</button>
                </div>
                ) : <></>
            }
        </>
    );
}

export default EditStructurePosition;

import { Component } from "react";
import { toast } from "react-toastify";
import { Modal, Select } from "antd";

//import * as Styled from './styled.js';


class ClassHac extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      props: props,
      data: [],
      modalOpen: props.isModalOpen,
      type: "",
      typical: "",
      //event: "12",
    }

    //console.log(props)
    this.source = props.data?.danger_sours_id ?? "";
    this.danger = props.data?.danger_id ?? "";
    this.measure = props.data?.measure_id ?? "";
    if(this.measure !== ""){
      const ev = this.props.sprType.danger.findIndex(item => item.value === this.danger)
      this.eventDan = props.sprType.danger[ev].label2;
    }else{
      this.eventDan = "";
    }
    //console.log(props.data)
  }

  async componentDidMount()
  {
    /*
    await fetch('/api/classifier/bundle/work/id',
      {
        method: 'POST',
        body: JSON.stringify({id: this.state.props.id})
      }
    ).then((response) => {
      return response.json();
    }).then((data) => this.setState({data: data.data}))
    */
  }

  async modalSave(){
    await fetch('/api/classifier/bundle/sourcemeasure/save',
      {
        method: 'POST',
        body: JSON.stringify({
          id: this.props.id,
          danger_sours_id: this.source,
          danger_id: this.danger,
          measure_id: this.measure,
        })
      }
    ).then(
      (response) => {return response.json()}
    ).then((data) => {
      if(data.code === 201){
        toast['error'](data.msg)
      }else{
        toast['success'](data.msg)
        this.props.modalCancel()
      }


    }
    )

  }

  sourceChange(value){
    this.source = value;
  }
  dangerChange(value){
    this.danger = value;
    const ind = this.props.sprType.danger.findIndex(item => item.value === value)
    this.setState({eventDan: value})
    //console.log(this.props.sprType.danger[ind].label2);
    this.eventDan = this.props.sprType.danger[ind].label2;


    //this.setState([eventDan = value ]);
  }

  measureChange(value){
    this.measure = value;
  }

  render() {


    return (
      <>
      <Modal
          title={this.props.title}
          open={this.state.modalOpen}
          onOk={() => this.modalSave(this.state)}
          onCancel={this.props.modalCancel}
          >
          <table style={{'font-size': '16px'}}>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Источник опасности</b>
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  showSearch
                  id="typical"
                  style={{
                  width: '440px',
                  }}

                  filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.danger_sours_id ?? '']}
                  options={this.props.sprType.source}

                  onChange={(value) => this.sourceChange(value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Опасность</b>
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  showSearch
                  style={{
                    width: '440px',
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.danger_id ?? '']}
                  options={this.props.sprType.danger}

                  onChange={(value) => this.dangerChange(value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                  <br />
                <b>Опасное событие</b><br />
                {this.eventDan}
              </td>
            </tr>

            <tr>
              <td style={{'padding-top': '20px'}}>
                <b>Меры управлениия риском</b>
              </td>
            </tr>
            <tr>
              <td style={{'padding-bottom': '20px'}}>
                <Select
                  showSearch
                  style={{
                    width: '440px',
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={[this.props.data?.measure_id ?? '']}
                  options={this.props.sprType.measure}

                  onChange={(value) => this.measureChange(value)}
                />
              </td>
            </tr>

          </table>
        </Modal>
</>
    )
  }
}
export default ClassHac;

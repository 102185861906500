import React from 'react';

import * as Styled from './styled';

export default function MeasuresAdditional(props) {
  const {
    measures,
    onPostEvaluationcardAdditionaledit,
    onShowAdditionalControls,
    onRowId,
    id
  } = props;

  const handleClick = () => {

    onPostEvaluationcardAdditionaledit(id);
    onRowId(id);
    onShowAdditionalControls.on()
  }

  if (!measures?.length) {
    return null
  }

  return (
    <Styled.Measures>
      {measures?.map((el, index) =>
        <Styled.MeasureItem key={`${index}-${el?.name}`}>
          <Styled.MeasureCount
            onClick={handleClick}
          >
            {el?.count} шт.
          </Styled.MeasureCount>
          <Styled.MeasureDividing>
            -
          </Styled.MeasureDividing>
          <Styled.MeasureName>
            {el?.name}
          </Styled.MeasureName>
        </Styled.MeasureItem>)}
    </Styled.Measures>
  )
}
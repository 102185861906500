import React from 'react';
import { useFormik } from 'formik';

import * as hooks from '../../../hooks';
import InputSearch from '../../atoms/search';
import { FormikProvider } from '../../formik/formik.context';
import HeaderGrade from '../../Menu/MenuRating/HeaderGrade';

import { usePostProjectEdit } from './fields/editRow/hooks';
import * as Fields from './fields';
import { usePostProject } from './hooks';
import * as Styled from './styled';
import ProjectsTable from './table';
import { projectYup } from './valide.yup';

const { useBoolean } = hooks;

export default function Grade() {
  const { loading, formik, data, onRefetchProjects, onChangeTable, onPostProjectDelete } = usePostProject();
  const showNewOrganization = useBoolean(false);

  const formikGrade = useFormik({
    enableReinitialize: true,
    validationSchema: projectYup,
    validateOnBlur: true,
    validateOnChange: true,
    initialTouched: {
      project: {
        inn: true,
        shortName: true,
      },
    },
    initialValues: {
      project: {
        inn: null,
        kpp: null,
        fullName: null,
        shortName: null,
        okogu: null,
        ogrn: null,
        okpo: null,
        oktmo: null,
        okved: null,
        postalCode: null,
        regionName: null,
        districtName: null,
        cityName: null,
        innerCityName: null,
        localityName: null,
        streetName: null,
        house: null,
        building: null,
        chiefJobTitle: null,
        chiefFullName: null,
      },
      isChief: {},
      commission: [],
      expert: [],
    },
  });

  const { respDataProjectEdit, onPostProjectEdit } = usePostProjectEdit(formikGrade);

  const onCreateNewProject = () => {
    const [_, onShow] = showNewOrganization;
    formikGrade.resetForm();
    formikGrade.setStatus('new');
    formikGrade.validateForm();
    onShow.on();
  };

  const handleChangeSearchProject = (event) => {
    formik.setFieldValue('search', event.target.value);
  };

  return (
    <FormikProvider formik={formikGrade}>
      <HeaderGrade />
      <Styled.Section>
        <Styled.SectionHead jContent={'space-between'} widthItem={[465, 170]}>
          <InputSearch placeholder={'Поиск'} onChange={handleChangeSearchProject} />
          <Styled.ButtonCreate variant={'primary'} onClick={onCreateNewProject}>
            Создать проект
          </Styled.ButtonCreate>
        </Styled.SectionHead>
        <ProjectsTable
          loading={loading}
          data={data}
          onChangeTable={onChangeTable}
          onPostProjectEdit={onPostProjectEdit}
          onPostProjectDelete={onPostProjectDelete}
        />
        <Fields.EditRow
          id={formikGrade.values.project?.id}
          respDataProjectEdit={respDataProjectEdit}
          isShowInfoOrganization={showNewOrganization}
          onPostProject={onRefetchProjects}
        />
      </Styled.Section>
    </FormikProvider>
  );
}

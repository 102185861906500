import React, { useEffect, useRef,useState } from 'react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

const customStyles = {
    control: (styles) => ({
        ...styles,
        borderRadius: 0
    }),
    placeholder: (styles) => ({
        ...styles,
        paddingLeft: '5px'
    }),
    singleValue: (styles) => ({
        ...styles,
        paddingLeft: '5px'
    }),
    input: (styles) => ({
        ...styles,
        paddingLeft: '5px'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    svg: (styles) => ({
        ...styles,
        transform: 'scale(0.7)'
    }),
    Input: (styles) => ({
        ...styles,
        border: 'none'
    })
}

const CustomSelect = (props) => {
    return <Select styles={customStyles} placeholder="Выбрать" {...props} />
}

const AsyncSelect = (props) => {
    const [inputValue, setInputValue] = useState('');
    const selectRef = useRef();
    
    const onInputChange = (text, { action }) => !['input-blur', 'menu-close'].includes(action) && setInputValue(text);
    
    useEffect(() => {
        setInputValue(props?.defaultInputValue || '');
        selectRef.current.blur();
        selectRef.current.focus();
        selectRef.current.blur();
        
    }, [props?.defaultInputValue]);


    return <SelectAsync 
                ref={selectRef}
                loadingMessage={() => <span>Идет загрузка...</span>} 
                noOptionsMessage={() => <span>Пусто</span>} 
                styles={customStyles} 
                placeholder="Выбрать"
                {...props} 
                inputValue={inputValue}
                onInputChange={onInputChange}
            />
}

export { AsyncSelect,CustomSelect as default };

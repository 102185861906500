import React from "react";

import polygonClose from "../../../../../image/Polygon_close.svg";
import polygonOpen from "../../../../../image/Polygon_open.svg";

function ModalAddClassifiers() {
  const [open1, setOpen1] = React.useState(false);
  const tooglePolygon1 = () => {
    setOpen1((prevState) => !prevState);
  };

  const [open1_1, setOpen1_1] = React.useState(false);
  const tooglePolygon1_1 = () => {
    setOpen1_1((prevState) => !prevState);
  };

  const [open1_2, setOpen1_2] = React.useState(false);
  const tooglePolygon1_2 = () => {
    setOpen1_2((prevState) => !prevState);
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modalWindow modal-dialog-centered">
        <div className="modal-content rounded-0">
          <div className="modal-header border-0">
            <h5 className="user-title pddng-lft-10" id="exampleModalLabel">
              Добавить
            </h5>
            <button
              type="button"
              className="btn close-button rounded-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="bi bi-x ft-sz"></i>
                <span>Закрыть</span>
              </div>
            </button>
          </div>
          <div className="modal-body pddng-tp-0">
            <div className="modal-body">
              <form>
                <div className="modalAddBox shadow px-4 py-4 fz-13 ">
                  {open1 ? (
                    <div>
                      <img
                        className="p-2"
                        src={polygonOpen}
                        onClick={tooglePolygon1}
                        alt=""
                      />
                      Объекты исследования
                      {open1_1 ? (
                        <div>
                          <img
                            className="pddng-lft-15"
                            src={polygonOpen}
                            onClick={tooglePolygon1_1}
                            alt=""
                          />
                          <span className="pddng-lft-5">Виды работ</span>
                          <div>
                            <img
                              className="pddng-lft-25"
                              src={polygonOpen}
                              alt=""
                            />
                            <span className="pddng-lft-5">
                              Работа на высоте
                            </span>
                          </div>
                          {open1_2 ? (
                            <div>
                              <div>
                                <img
                                  className="pddng-lft-25"
                                  src={polygonOpen}
                                  onClick={tooglePolygon1_2}
                                  alt=""
                                />
                                <span className="pddng-lft-5">
                                  Работа на воде
                                </span>
                              </div>
                              <span className="pddng-lft-65 text-danger">
                                Работа под водой
                              </span>
                            </div>
                          ) : (
                            <div>
                              <img
                                className="pddng-lft-25"
                                src={polygonClose}
                                onClick={tooglePolygon1_2}
                                alt=""
                              />
                              <span className="pddng-lft-5">
                                Работа на воде
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <img
                            className="pddng-lft-15"
                            src={polygonClose}
                            onClick={tooglePolygon1_1}
                            alt=""
                          />
                          <span className="pddng-lft-5">Виды работ</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <img
                        className="p-2"
                        src={polygonClose}
                        onClick={tooglePolygon1}
                        alt=""
                      />
                      Объекты исследования
                    </div>
                  )}
                  <div>
                    <img className="p-2" src={polygonClose} alt="" />
                    Места выполнения
                  </div>
                  <div>
                    <img className="p-2" src={polygonClose} alt="" />
                    НАС
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAddClassifiers;

import { useState } from 'react';

import axios from '../../../axios/axios';
import { useBoolean } from '../../../hooks';
import { logWarn } from '../../../utils/log';

export const useInformation = ({ tab_id }) => {
  const [loading, onLoading] = useBoolean(false);
  const [data, setData] = useState(null);

  const getRegulators = async () => {
    try {
      onLoading.on();
      const response = await axios.post('/api/regulations', {
        tab_id,
      });

      if (response?.data?.status === 'success') {
        onLoading.off();
        setData(response.data.data);
      }

      //console.log(response, 'response');
    } catch (e) {
      onLoading.off();
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const downloadDocument = async (id) => {
    try {
      const response = await axios.post(`/api/regulations/download`, {
        tab_id,
        id,
      });

      if (response.status === 200) {
        //console.log(response.data);
        const blob = response.data.blob();

        //console.log(blob);

        // const file = window.URL.createObjectURL(response.blob());
        // window.location.assign(file);
      }

      console.log(response, 'response');
    } catch (e) {
      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    loading,
    data,
    getRegulators,
    downloadDocument,
  };
};

import styled from 'styled-components';

export const Quantity = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #C9C8D3;
  padding: 5px 10px;
  color: #52575C;
  cursor: pointer;
`
